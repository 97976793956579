import React from 'react';
import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    List,
    ListItem,
    Avatar,
    ListItemText, Icon, Button
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {FormattedNumber} from "react-intl";

export class ModalPublicationTalkForSeller extends React.Component {
    render() {
        const {showModal, hiddenModal, type, visible, publicationsTalk, getPublication} = this.props;
        return (
            <Dialog open={visible} aria-labelledby="customized-dialog-title"
                    onClose={() => hiddenModal('viewPublicationsTalk', type)}>
                <DialogTitle className={'container-text'} id={'customized-dialog-title'}>
                    <h1>Negociación de {publicationsTalk.nombre}</h1>
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={() => hiddenModal('viewPublicationsTalk', type)}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <List>
                        <h4>Usuario comprador</h4>
                        <ListItem>
                            <Avatar className={'styles-img-avatar'}>
                                <img src={publicationsTalk.usuario_comprador.foto}
                                     alt={publicationsTalk.usuario_comprador.nombre}/>
                            </Avatar>
                            <div>
                                <ListItemText primary={publicationsTalk.usuario_comprador.nombre}
                                              secondary={publicationsTalk.usuario_comprador.correo_electronico}/>
                            </div>
                        </ListItem>
                        <div className={'line'}/>
                    </List>
                    <div className={'container-text'}>
                        <h4>Publicación negociada</h4>
                        <img src={publicationsTalk.foto ? publicationsTalk.foto : ''}
                             alt={publicationsTalk.nombre}/>
                        <h2>
                            <FormattedNumber value={publicationsTalk.precio} style={'currency'}
                                             currency={'USD'}
                                             minimumFractionDigits={0} maximumFractionDigits={0}/>
                        </h2>
                        <p>{publicationsTalk.nombre}</p>
                        {/*<p>Fecha de la negociación: {publicationsTalk.fecha_creacion}</p>*/}
                        <div>
                            <Button variant="contained" color="secondary"
                                    onClick={() => {
                                        getPublication(publicationsTalk, 'publication');
                                        showModal('viewPublication', 'publication');
                                    }}>

                                <Icon>remove_red_eye</Icon>
                                <p style={{color: 'white', marginLeft: 6}}>ver</p>
                            </Button>
                        </div>
                        {/*{publicationsTalk.tiempo_negociacion && (*/}
                        {/*    <p>Tiempo de la negociacion: {publicationsTalk.tiempo_negociacion}</p>*/}
                        {/*)}*/}
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}