import React from 'react';

export const ErrorComponent = (props) => {
    let errors = [];

    for (let key in props.message) {
        if (key !== 'code')
            errors.push(<li style={{marginBottom: 8}} key={key}>{props.message[key]}</li>);
    }

    return <ul>{errors}</ul>
};