import React from 'react';


export class FilterProfileSecundaryComponent extends React.Component {

    render() {
        const {filters, filter, focus} = this.props;
        return (
            <div className="container-sub-text">
                <div className="container-profile-menu2">
                    {filters.map((filterItem, index) => (
                        <div key={index}
                             className={filter !== filterItem ? "profile-menu-item2" : "profile-menu-item2  profile-menu-item2-active"}
                             onClick={() => focus(filterItem, 'secondary')}>
                            <h2>{filterItem}</h2>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}