import {
    PUBLICATIONS_TALK_FOCUS,
    PUBLICATIONS_TALK_HIDDEN_MODAL,
    PUBLICATIONS_TALK_RESET_STATES,
    PUBLICATIONS_TALK_SHOW_MODAL,
    PUBLICATIONS_TALKS_ME_GET_ERROR,
    PUBLICATIONS_TALKS_ME_GET_REQUESTING,
    PUBLICATIONS_TALKS_ME_GET_SUCCESS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    publicationsTalks: [],
    hasMore: true,
    hasLess: false,
    publicationsTalk: {},
    paginate: 1,
    viewPublicationsTalk: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PUBLICATIONS_TALKS_ME_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                paginate: action.paginate,
            };
        case PUBLICATIONS_TALKS_ME_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                publicationsTalks: [...state.publicationsTalks, ...action.publicationsTalks],
                hasLess: state.paginate > 1,
                hasMore: action.publicationsTalks.length > 0,
            };
        case PUBLICATIONS_TALKS_ME_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATIONS_TALK_SHOW_MODAL:
            return {
                ...state,
                viewPublicationsTalk: action.modal === 'viewPublicationTalk',
            };
        case PUBLICATIONS_TALK_HIDDEN_MODAL:
            return {
                ...state,
                viewPublicationsTalk: action.modal === 'viewPublicationTalk' && false,
            };
        case PUBLICATIONS_TALK_FOCUS:
            return {
                ...state,
                publicationsTalk: action.publicationsTalk,
            };
        case PUBLICATIONS_TALK_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
                publicationsTalk: {},
                modalViewPublicationsTalk: false,
            };
        default:
            return state;
    }
};

export default reducer;