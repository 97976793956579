import React from 'react';
//Material-ui
import {MuiThemeProvider} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
//button
import Button from '@material-ui/core/Button';
import "react-id-swiper/lib/styles/css/swiper.css";
import posed from "react-pose";
import {theme} from "../../../../theme/theme";
import {
    getPublicationFavoriteRequesting,
    removeFavoriteRequesting
} from "../../../../redux/publicationFavorite/actions";
import lottie from "lottie-web";
import {connect} from "react-redux";

////effect

const Box = posed.div({
    visible: {
        x: '0%',
        delayChildren: 1000,
        staggerChildren: 200,
        delay: 50
    },
    hidden: {x: '100%'}
});

const Item = posed.div({
    visible: {
        scale: 1,
    },
    hidden: {scale: 0}
});

const ItemHover = posed.div({
    hoverable: true,
    init: {
        scale: 1,
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        // boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
});

/////////////////////////

class PageFavorites extends React.Component {

    constructor(props) {
        super(props);
        this.animation = React.createRef();
        const {getPublicationFavoriteRequesting, client: {token}} = this.props;
        getPublicationFavoriteRequesting(token);
    };

    state = {
        isVisible: false,
        isOpen: false,
        value: 0,
        isOpenProduct: false,
        isOpenFilter: false,
        productPost: [1, 2, 3, 4, 5, 6],
        focusFilter1: '',
        focusFilter2: '',
    };

    componentDidMount() {
        this.setState({
            isVisible: true
        });
        lottie.loadAnimation({
            container: this.animation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/review.json'
        });
    }

    handleRemovePublication = (publication) => {
        const {removeFavoriteRequesting, client: {client}} = this.props;
        removeFavoriteRequesting(client.id, publication)
    };

// control de modal
    handleControlModal = (modal) => {
        this.setState({
            isOpenProduct: modal === 'modal product',
            isOpenFilter: modal === 'modal filter',
        });
    };

    render() {
        const {publicationFavorite: {publicationFavorites}} = this.props;

        return (
            <MuiThemeProvider theme={theme}>
                <Box pose={this.state.isVisible ? 'visible' : 'hidden'}>
                    {/*<ProductModalComponent*/}
                    {/*visible={isOpenProduct}*/}
                    {/*handleControl={this.handleControlModal.bind(this)}*/}
                    {/*/>*/}
                    {/*<FilterProductModalComponent*/}
                    {/*visible={isOpenFilter}*/}
                    {/*handleControl={this.handleControlModal.bind(this)}*/}
                    {/*/>*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<PageProfile/>*/}
                    {/*<div className="container-item-start">*/}
                    {/*<FilterUserPrincipalComponent/>*/}
                    {/*</div>*/}
                    <div className="container-item-start">
                        <div className="line"/>
                        <div className="container-sub-text">
                            <h2>Productos favoritos</h2>
                            <p>Estos son los productos que más te gustan</p>
                        </div>
                        {publicationFavorites.length === 0 ?
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div className="container-animations-empty-one">
                                    <div className={'animation'} ref={this.animation}/>
                                </div>
                                <p className="p-animation-empty-one" style={{fontSize: 22, color: 'rgba(0,0,0,.3)'}}>No
                                    cuentas con
                                    productos favoritos ...</p>
                            </div>

                            :
                            publicationFavorites.length > 0 && publicationFavorites.map(publicationFavorites => (
                                <div>
                                    <div className="container-profile-product">
                                        <div className="profile-product-item1">
                                            <img
                                                src={publicationFavorites.fotos.length > 0 ? publicationFavorites.fotos[0].url : 'https://d3t4nwcgmfrp9x.cloudfront.net/upload/construccion-acelera-zona-euro-maximos-dos-anos-se-frena-espana.jpg'}
                                                alt="Logo"/>
                                            <div className="container-sub-text">
                                                <h2>$ {publicationFavorites.precio}</h2>
                                                <p>{publicationFavorites.nombre}</p>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                    <p style={{marginRight: 5, fontWeight: 'bold'}}>Publicado : </p>
                                                    <p>{publicationFavorites.actualizado}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="profile-product-favorites-item2 container-sub-text">
                                            <div>
                                                <Button variant="contained" color="secondary"
                                                        onClick={this.handleRemovePublication.bind(this, publicationFavorites.id)}
                                                >
                                                    <Icon style={{fontSize: 22}}>close</Icon>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </Box>
            </MuiThemeProvider>
        )
    }
}


const mapStateToProps = state => ({
    client: state.client,
    publicationFavorite: state.publicationFavorite
});

const connected = connect(mapStateToProps, {
    getPublicationFavoriteRequesting,
    removeFavoriteRequesting
})(PageFavorites);

export default connected;
