import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {MenuItem} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import {connect} from "react-redux";
import {Field, Form, reduxForm} from "redux-form";
import {createUserRequesting, errorControlCreateUser} from "../../../redux/user/actions";
import {filterGender, getGenderRequesting} from "../../../redux/gender/actions";
import {getTypeProfessionalRequesting, visibleTypeProfessional} from "../../../redux/typeProfessional/actions";
import {citiesGetRequesting} from "../../../redux/city/actions";
import {getTypePeopleRequesting} from "../../../redux/typePeople/actions";
import {getTypeProfileRequesting} from "../../../redux/typeProfile/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {MuiThemeProvider} from "@material-ui/core";
import {materialTheme} from "../../../commons/theme/theme";
import ButtonFacebook from "./quality-politics";


const descriptionProfile = [
    {
        name: 'Público',
        description: 'Seleccionado este perfil las personas lo puden buscar en el directorio para ver sus productos'
    },
    {name: 'Privado', description: 'Seleccionado este perfil, las personas no lo podran buscar en el directorio'},
];


const inputTextField = ({input, name, label, type, id, placeholder, meta: {touched, error}, ...rest}) => {
    return (
        <MuiThemeProvider theme={materialTheme}>
            <TextField
                {...input}
                {...rest}
                label={label}
                type={type}
                value={input.value}
                name={input.name}
                placeholder={placeholder ? placeholder : ''}
                margin="normal"
                variant="outlined"
                fullWidth
                multiline={type === "text"}
                rowsMax={'10'}
            />
        </MuiThemeProvider>
    )
};

class ModalTermsConditionsComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            citiesGetRequesting,
            getTypeProfileRequesting,
            getTypePeopleRequesting,
            getGenderRequesting,
            getTypeProfessionalRequesting,

        } = this.props;
        citiesGetRequesting();
        getTypeProfileRequesting();
        getTypePeopleRequesting();
        getGenderRequesting();
        getTypeProfessionalRequesting();
    }

    state = {
        messageTypeProfile: null,
    };

    handleAction = (values) => {
        const {createUserRequesting, user: {phone}} = this.props;
        createUserRequesting({
            ...values,
            password: phone === 0 ? values.password : Math.random(),
            phone: phone === 0 ? values.phone : phone
        })
    };

    handleError = () => {
        const {user: {error}, errorControlCreateUser} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlCreateUser();
    };

    handleShowMessageTypeProfile = (value) => {
        const {
            typeProfile: {typeProfiles},
        } = this.props;
        let typeProfile = typeProfiles.find((typeProfile) => typeProfile.id === value);
        this.setState({
            messageTypeProfile: descriptionProfile.find((description) => description.name === typeProfile.nombre).description,
        });
    };

    render() {
        const {
            handleControl,
            visible,
            handleSubmit,
            city: {cities},
            typePeople: {typePeoples},
            typeProfessional,
            gender: {gendersFilter},
            typeProfile: {typeProfiles},
            filterGender,
            visibleTypeProfessional,
            user: {errorControlCreateUser, phone},
        } = this.props;
        return (

            <Dialog
                onClose={() => handleControl('')} aria-labelledby="customized-dialog-title"
                open={visible}>

                <DialogTitle id="customized-dialog-title">
                    TÉRMINOS Y CONDICIONES GENERALES PARA USO DE LAS PLATAFORMAS DE NOVA INC TECNOLOGY S. A.
                    S.
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={() => handleControl('')}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent>


                    {errorControlCreateUser && this.handleError()}
                    <Form onSubmit={handleSubmit(this.handleAction)}>

                        <div className="container-sub-text">
                            <br/><br/>
                            <p>
                                Este documento contiene los términos y condiciones generales que serán aplicables al
                                hacer uso de los servicios ofrecidos por NOVA INC TECNOLOGY S. A. S., sociedad comercial
                                con domicilio principal en Bogotá Distrito Capital, República de Colombia, identificada
                                con número de identificación tributaria 900.938.821-1, en adelante “La Compañía”, dentro
                                del sitio www.tuconstruccion.com.co y dentro de la aplicación móvil “Tu Construcción”,
                                disponible en los sistemas operativos Android y iOS, que en conjunto se denominarán “La
                                Plataforma” o en cualquier otro portal web, aplicación o herramienta tecnológica que sea
                                propiedad de La Compañía. El presente documento también establece el marco de los
                                derechos y obligaciones que surgen para La Compañía y los Usuarios, de manera que tiene
                                carácter vinculante y es de obligatorio cumplimiento para todo aquel que desee hacer uso
                                de La Plataforma y de los servicios que se prestan a través de ella.   <br/><br/>

                                Al acceder, navegar o usar el sitio web www.tuconstruccion.com.co o la aplicación móvil
                                “Tu Construcción”, el Usuario declara y reconoce que ha leído y entendido y se obliga a
                                cumplir con los términos y condiciones aquí establecidos, además de observar y cumplir
                                con toda la normativa colombiana e internacional relacionada con el uso de sitios en
                                internet, entrega de datos personales, cookies, protección de la propiedad intelectual,
                                protección del consumidor, derecho de la competencia y comercio electrónico que se
                                especifica más adelante. Los Usuarios que no acepten estos términos y condiciones
                                deberán abstenerse de hacer uso de La Plataforma.   <br/><br/>

                                Antes de inscribirse, Los Usuarios deberán leer, entender y aceptar los preceptos que
                                contiene este documento y sus anexos los cuales hacen parte integral del mismo.   <br/><br/>

                                I. DEFINICIONES
                                Para efecto de interpretación del presente documento, los siguientes términos se definen
                                de la siguiente manera:   <br/><br/>
                                Alquiler o arrendamiento: dar a alguien algo, sea un bien mueble o inmueble, para que
                                use de ello por el tiempo que se determine y mediante el pago de la cantidad convenida.
                                Calidad: condición en que un producto cumple con las características inherentes y las
                                atribuidas por la información que se suministre sobre él.
                                Categoría: cada una de las clases o divisiones establecidas al clasificar algo.
                                Comercio: entiéndase como comercios, las empresas o personas que utilizan payu como
                                plataforma de pagos.   <br/><br/>
                                Compra: entiéndase como la acción y efecto de obtener algo a cambio de un precio.
                                Consumidor: toda persona natural o jurídica que, como destinatario final, adquiera,
                                disfrute o utilice un determinado producto, cualquiera que sea su naturaleza para la
                                satisfacción de una necesidad propia, privada, familiar o doméstica y empresarial cuando
                                no esté ligada intrínsecamente a su actividad económica.
                                Contrato de adhesión: aquel en el que las cláusulas son dispuestas por el productor o
                                proveedor, de manera que el consumidor no puede modificarlas, ni puede hacer otra cosa
                                que aceptarlas o rechazarlas.   <br/><br/>
                                Demanda: en economía, la demanda es la cantidad total de un bien o servicio que la gente
                                desea adquirir.   <br/><br/>
                                Idoneidad o eficiencia: aptitud del producto para satisfacer la necesidad o necesidades
                                para las cuales ha sido producido o comercializado.
                                Información: todo contenido y forma de dar a conocer la naturaleza, el origen, el modo
                                de fabricación, los componentes, los usos, el volumen, peso o medida, los precios, la
                                forma de empleo, las propiedades, la calidad, la idoneidad o la cantidad, y toda otra
                                característica o referencia relevante respecto de los productos que se ofrezcan o pongan
                                en circulación, así como los riesgos que puedan derivarse de su consumo o utilización.
                                Negocio: acción y efecto de tratar y comerciar, comprando y vendiendo o cambiando
                                géneros, mercancías o valores.
                                Oferta: en economía, es la cantidad de bienes y servicios que los oferentes están
                                dispuestos a poner a la venta en el mercado a unos precios concretos.
                                Pagador: entiéndase como la persona que realiza un pago a través de payu como pasarela
                                de pago
                                Producto: todo bien o servicio.   <br/><br/>
                                Producto defectuoso: es aquel bien mueble o inmueble que debido a un error el diseño,
                                fabricación, construcción, embalaje o información, no ofrezca la razonable seguridad a
                                la que toda persona tiene derecho.   <br/><br/>
                                Productor: quien de manera habitual, directa o indirectamente, diseñe, produzca,
                                fabrique, ensamble o importe productos. también se reputa productor, quien diseñe,
                                produzca, fabrique, ensamble, o importe productos sujetos a reglamento técnico o medida
                                sanitaria o fitosanitaria.   <br/><br/>
                                Proveedor o expendedor: quien de manera habitual, directa o indirectamente, ofrezca,
                                suministre, distribuya o comercialice productos con o sin ánimo de lucro.
                                Publicidad: toda forma y contenido de comunicación que tenga como finalidad influir en
                                las decisiones de consumo.   <br/><br/>
                                Publicidad engañosa: aquella cuyo mensaje no corresponda a la realidad o sea
                                insuficiente, de manera que induzca o pueda inducir a error, engaño o confusión.   <br/><br/>

                                Seguridad: condición del producto conforme con la cual en situaciones normales de
                                utilización, teniendo en cuenta la duración, la información suministrada en los términos
                                de la presente ley y si procede, la puesta en servicio, instalación y mantenimiento no
                                presenta riesgos irrazonables para la salud o integridad de los consumidores. en caso de
                                que el producto no cumpla con requisitos de seguridad establecidos en reglamentos
                                técnicos o medidas sanitarias, se presumirá inseguro.   <br/><br/>
                                Registro: entiéndase como la acción de ingresar un conjunto de datos que constituyen una
                                unidad de información que hará parte de una base de datos.   <br/><br/>
                                Usuario: se considera usuario toda persona natural o jurídica que se registre en la
                                plataforma e interactúe en ella ya sea mediante compra, alquiler, venta, publicación de
                                productos o se contacte con otros usuarios por medio de ella.
                                Venta: acción y efecto de exponer u ofrecer al público los géneros o mercancías para
                                quien las quiera comprar.   <br/><br/>
                                Ventas con utilización de métodos no tradicionales: son aquellas que se celebran sin que
                                el consumidor las haya buscado, tales como las que se hacen en el lugar de residencia
                                del consumidor o por fuera del establecimiento de comercio. se entenderá por tales,
                                entre otras, las ofertas realizadas y aceptadas personalmente en el lugar de residencia
                                del consumidor, en las que el consumidor es abordado por quien le ofrece los productos
                                de forma intempestiva por fuera del establecimiento de comercio o es llevado a
                                escenarios dispuestos especialmente para aminorar su capacidad de discernimiento.
                                Ventas a distancia: son las realizadas sin que el consumidor tenga contacto directo con
                                el producto que adquiere, que se dan por medios, tales como correo, teléfono, catálogo o
                                vía comercio electrónico.   <br/><br/>
                                II. ALCANCE

                                A través de este documento no se crea ningún contrato de sociedad, de franquicia, de
                                mandato o laboral entre La Compañía y Los Usuarios. Los Usuarios reconocen y aceptan que
                                La Compañía no es parte en ningún negocio ni tiene control alguno sobre la veracidad o
                                exactitud de los anuncios ni sobre la calidad, seguridad o legalidad de los productos
                                anunciados. Tampoco es responsable de la capacidad de los Usuarios para vender, comprar
                                o alquilar productos. La Compañía no puede asegurar que Los Usuarios completen un
                                negocio ni puede verificar los Datos Personales ingresados por Los Usuarios. La Compañía
                                no tiene ninguna injerencia en la publicidad de terceros que aparezca en La Plataforma y
                                no será responsable por los acuerdos, convenios o contratos que Los Usuarios celebren
                                con dichos terceros o con otros Usuarios.   <br/><br/>
                                III. CAPACIDAD

                                De conformidad con la ley, la capacidad legal de una persona consiste en que puede
                                obligarse por sí misma sin la intervención o autorización de otra. Se presumen como
                                capaces aquellas personas que hayan alcanzado la mayoría de edad que en Colombia
                                corresponde a dieciocho (18) años. En este sentido, La Plataforma y Los servicios que en
                                ella se ofrecen están destinados a quienes cuenten con capacidad para contratar. Si Los
                                Usuarios no cuentan con capacidad para contratar se les conmina a abstenerse de hacer
                                uso de La Plataforma.    <br/><br/>

                                IV. CONDICIONES DE REGISTRO
                                a. Los recursos ofrecidos en La Plataforma van dirigidos a personas mayores de 18 años.
                                Al aceptar los términos y condiciones, el usuario confirma que ya cumplió 18 años.
                                <br/><br/>
                                b. El idioma oficial de La Plataforma para todos los efectos legales, publicitarios y
                                comerciales es el español.
                                <br/><br/>
                                c. Los visitantes tienen la posibilidad de registrarse de manera gratuita en La
                                Plataforma. Durante el proceso de registro se le pedirá que proporcione la siguiente
                                información:
                                <br/><br/>
                                • Nombre    <br/><br/>
                                • Tipo de persona   <br/><br/>
                                • Ciudad   <br/><br/>
                                • Tipo de Perfil   <br/><br/>
                                • Número de Identificación (Cédula o NIT)   <br/><br/>
                                • Teléfono   <br/><br/>
                                • Correo electrónico y la confirmación del correo electrónico   <br/><br/>
                                • Dirección física de correspondencia   <br/><br/>
                                • Un nombre de usuario   <br/><br/>
                                • Un teléfono alternativo   <br/><br/>
                                • Una contraseña y la confirmación de la contraseña.   <br/><br/>
                                <br/><br/>

                                d. Los datos entregados por el usuario serán protegidos por La Compañía conforme se
                                indica en la política de tratamiento de datos personales.   <br/><br/>

                                e. El registro del usuario en La Plataforma implicará que conoce y acepta los términos y
                                condiciones, la política de tratamiento de datos personales y la política de calidad e
                                idoneidad de los productos a ofrecer y productos prohibidos.   <br/><br/>

                                f. Los Usuarios deberán proveerse, por su propia cuenta, el equipo de cómputo o
                                dispositivo electrónico, software, navegador y conexión a internet que requiera para
                                poder acceder a La Plataforma y al contenido digital allí publicado. La Compañía no
                                tendrá ninguna responsabilidad respecto de la imposibilidad o problemas que ofrezca el
                                equipo de cómputo, dispositivo electrónico, software, navegador o conexión a internet
                                utilizado por Los Usuarios.   <br/><br/>

                                g. Cuando Los Usuarios se registran, podrá acceder con su email y contraseña a los
                                contenidos de La Plataforma. Los Usuarios se obligan a no compartir su usuario y
                                contraseña con terceras personas, toda vez que son de uso exclusivo.   <br/><br/>
                                h. Los Usuarios deberán completar la información con datos exactos, precisos y verídicos
                                y así mismo deberán actualizarlos cuando sea necesario. La Compañía como propietaria de
                                La Plataforma no será responsable en ningún caso por la certeza de los Datos consignados
                                por Los Usuarios en el formulario de registro. Por lo tanto, Los Usuarios responderán
                                por la exactitud, autenticidad y veracidad de los datos registrados.   <br/><br/>
                                También podrá efectuarse el registro haciendo uso de la cuenta de correo electrónico
                                utilizado para acceder al perfil de Facebook. En caso de efectuarse el registro por ese
                                medio, Los Usuarios serán exclusivamente responsables de los datos contenidos en sus
                                perfiles y a los que se pueda tener acceso por medio de esa red social. La Compañía como
                                propietaria de La Plataforma no será responsable en ningún caso por fallas que presente
                                la red social Facebook y que impida a Los Usuarios acceder a La Plataforma.
                                Para ingresar a La Plataforma se deberá insertar el correo electrónico y la contraseña
                                proporcionada. La cuenta que crea un usuario es personal e intransferible. Por
                                seguridad, no se permitirá el registro de más de una cuenta por Usuario. La contraseña
                                será siempre responsabilidad de Los Usuarios, por lo tanto, son estos los únicos
                                responsables de la confidencialidad con la que la conservan.

                                V. PRIVACIDAD DE LA INFORMACIÓN   <br/><br/>

                                Los Usuarios deberán entregar algunos datos de carácter personal al hacer uso de La
                                Plataforma. Los datos recolectados se procesarán por La Compañía conforme las
                                finalidades para los que son solicitados y se almacenarán propendiendo siempre por la
                                seguridad y protección de la información de acuerdo con los términos señalados en la
                                Política de Tratamiento de Datos Personales. Puede consultar el aviso de privacidad y la
                                política de tratamiento de datos personales en la sección “Políticas de Privacidad” de
                                La Plataforma.   <br/><br/>
                                Los Usuarios dejan claramente establecido que La Compañía no tiene responsabilidad
                                alguna respecto de la información, productos incluidos u ofrecidos a través La
                                Plataforma por ellos mismos o por otros usuarios o por terceros. Parágrafo 1. El hecho
                                de establecerse un link al sitio web de alguno de Los Usuarios, no implica de manera
                                alguna que La Compañía recomiende los productos ofrecidos en dichos sitios, ni que
                                adquiera responsabilidad por la cantidad y calidad de los mismos, por eventuales fallas
                                en ellos o por la ulterior atención que ellos demanden. Parágrafo 2. El(los) sitio(s)
                                web de Los Usuarios pueden contener políticas de privacidad y de seguridad distintas a
                                las de La Compañía.   <br/><br/>
                                VI. COOKIES
                                La Plataforma hace uso de cookies propias y de terceros. Los Usuarios deben tener en
                                cuenta que el uso de las cookies va a permitir optimizar su experiencia en La
                                Plataforma.   <br/><br/>
                                1. Qué son las cookies: Una cookie es un fichero que se descarga en el
                                ordenador/teléfono inteligente/tableta de Los Usuarios al acceder a determinadas páginas
                                web.   <br/><br/>
                                2. Finalidades de las cookies: La Compañía como propietaria de La Plataforma hará uso de
                                las cookies para: a) Determinar sus preferencias de navegación; b) Para efectos
                                promocionales, comerciales y publicitarios; y, c) Para efectos estadísticos, entre otros
                                fines.   <br/><br/>
                                3. Aceptación de uso de cookies: Al aceptar estos términos y condiciones, Los Usuarios
                                aceptan que La Compañía como propietaria de La Plataforma utilice cookies para los fines
                                aquí señalados. El uso continuo de La Plataforma se entenderá como aceptación de los
                                términos y condiciones y como consecuencia, del uso de las cookies.
                                4. Configuración de Cookies: Los Usuarios podrán configurar en su navegador de
                                preferencia para que notifique y rechace la instalación de las cookies enviadas por La
                                Plataforma, sin que ello impida su acceso a los contenidos. Sin embargo, Los Usuarios
                                deben tener en cuenta que al desactivar el uso de cookies podrán experimentar una
                                disminución en la calidad de funcionamiento de La Plataforma.   <br/><br/>

                                VII. OBLIGACIONES DE LOS USUARIOS    <br/><br/>
                                Los Usuarios podrán ostentar la calidad de vendedor o de comprador al hacer uso de La
                                Plataforma. Con base en ello, las obligaciones de Los Usuarios serán las siguientes:
                                1. OBLIGACIONES DEL VENDEDOR:   <br/><br/>
                                Cuando un usuario publique un producto para la venta en calidad de vendedor adquiere las
                                siguientes obligaciones:   <br/><br/>
                                a) Previo a enajenar el producto, El Usuario deberá contar con las licencias, permisos
                                y/o autorizaciones que expidan las autoridades pertinentes y que sean necesarias para
                                transferir un bien o prestar un servicio a otro Usuario.   <br/><br/>
                                b) Actualizar la información del producto ofrecido cuando fuere pertinente con relación
                                a la cantidad de existencias, modificaciones sobre las características del producto o
                                las demás que sean necesarias.   <br/><br/>
                                c) De conformidad con el literal anterior, informar de las modificaciones efectuadas a
                                la publicación a los Usuarios que hayan presentado interés sobre el producto.   <br/><br/>
                                d) Cuando se trate de productos que se ofertan en alquiler, el Usuario oferente deberá
                                especificar el estado en el que se encuentra el bien para su uso, características y
                                demás información que sea pertinente.   <br/><br/>
                                e) Los Usuarios se obligan a no vender, promover, comercializar o a realizar cualquier
                                otra actividad relacionada con productos que atenten contra la moral, la ley o las
                                buenas costumbres, como cualquiera de los bienes y/o servicios que se referencia en la
                                Política de Calidad e Idoneidad de los Productos a Ofrecer y Productos Prohibidos de La
                                Compañía o en las normas vigentes.   <br/><br/>
                                f) Comunicarse o permitir la comunicación con Los Usuarios que hayan ofertado para
                                adquirir el bien o servicio que se ha publicado, para aclarar o resolver cualquier duda
                                o inquietud que se presente.   <br/><br/>
                                g) Los Usuarios se obligan a registrar en La Plataforma la información veraz y oportuna
                                respecto a la naturaleza de sus perfiles, clases y categorías de productos que a través
                                del mismo se ofrezcan o comercialicen y cualquier otra clase de información relativa al
                                referido perfil o al Usuario que La Compañía pueda requerir. Así mismo, Los Usuarios
                                deberán mantener actualizada la información que publiquen en La Plataforma en relación
                                con los productos ofrecidos y las características de los mismos.   <br/><br/>
                                h) Si se logra concretar una compraventa entre usuarios, EL VENDEDOR se obliga a
                                trasferir o entregar el (los) producto(s) que haya(n) sido requerido(s) por EL COMPRADOR
                                de acuerdo con la(s) cantidad(es), características físicas y funcionales, calidad e
                                idoneidad que hayan pactado y que correspondan a las indicadas en la publicación
                                existente en La Plataforma. El no cumplimiento de esta obligación por parte del Usuario
                                vendedor o de cualquiera de las obligaciones previstas en la Ley 1480 de 2011 conocida
                                como “estatuto del consumidor”, autoriza para que La Compañía de forma unilateral
                                suspenda o cancele el perfil del Usuario en La Plataforma sin que se cause ningún tipo
                                de indemnización, derecho o prestación a favor del Usuario incumplido.
                                i) Entregar el (los) producto(s) solicitado(s) en la fecha, lugar de destino y dentro
                                del plazo ofertado o pactado con el Usuario comprador.   <br/><br/>
                                j) Obrar con buena fe, lealtad, diligencia y cuidado en la realización del negocio con
                                el comprador.   <br/><br/>
                                k) Informar oportunamente a EL COMPRADOR sobre cualquier problema, situación o riesgo
                                que advierta antes o durante la ejecución del negocio que impida la ejecución de este o
                                que puedan causar un daño a EL COMPRADOR.   <br/><br/>
                                l) Guardar absoluta confidencialidad sobre toda la información que reciba de EL
                                COMPRADOR en desarrollo del negocio, de manera que le queda expresamente prohibida la
                                divulgación de todo tipo datos a terceros ajenos a la relación contractual.
                                m) Cumplir con las demás obligaciones que sean inherentes al negocio según la ley y la
                                costumbre mercantil.   <br/><br/>
                                La Compañía NO tiene participación o injerencia en las negociaciones que realicen Los
                                Usuarios en calidad de vendedores. La Compañía como propietaria de La Plataforma en
                                ningún caso será responsable por el cumplimiento de las obligaciones que recaen sobre
                                Los Usuarios vendedores ni de los acuerdos, contratos o convenios que llegasen a
                                celebrar con otros usuarios con ocasión de la venta de un artículo o servicio. En caso
                                de incumplimiento, Los Usuarios vendedores serán los únicos responsables frente a Los
                                Usurarios compradores.   <br/><br/>
                                2. OBLIGACIONES DEL COMPRADOR:   <br/><br/>
                                Son obligaciones de Los Usuarios compradores:   <br/><br/>
                                a) Si un usuario requiere un bien y/o servicio y recibe alguna oferta de otro usuario,
                                deberá comunicarse con el Usuario oferente para que precise las inquietudes o dudas que
                                tenga frente al producto. No es obligatorio para el usuario concretar el negocio con el
                                usuario oferente.   <br/><br/>
                                b) Los Usuarios que deseen adquirir un producto deberán esforzarse por comprender las
                                características del producto ofertado en La Plataforma para establecer si se ajusta a
                                sus necesidades.   <br/><br/>
                                c) Suministrar al VENDEDOR la información necesaria para la ejecución del negocio.
                                d) Pagar el valor que pacte con el usuario vendedor y de la forma convenida con aquél.
                                e) Será responsabilidad de Los Usuarios exigir la respectiva factura de venta o
                                documento equivalente cuando fuere el caso.   <br/><br/>
                                f) Cumplir de buena fe con las demás obligaciones que se deriven de la relación que se
                                crean entre el vendedor y el comprador.   <br/><br/>
                                La Compañía NO tiene participación o injerencia en las negociaciones que realicen Los
                                Usuarios en calidad de compradores. La Compañía como propietaria de La Plataforma en
                                ningún caso será responsable por el cumplimiento de las obligaciones que recaen sobre
                                los usuarios ni de los acuerdos, contratos o convenios que llegasen a celebrar con otros
                                usuarios con ocasión de la venta, compra o alquiler de un artículo o servicio. En caso
                                de incumplimiento, Los Usuarios compradores deberán reclamar únicamente a Los Usuarios
                                con quienes realizaron el negocio. Con la aceptación de los términos y condiciones el
                                Usuario comprador exonera de toda responsabilidad a La Compañía y renuncia
                                irrevocablemente a demandarla o a exigir de esta indemnización o compensación por hechos
                                imputables a los Usuarios oferentes o vendedores.
                                3. OTRAS OBLIGACIONES DE LOS USUARIOS:   <br/><br/>
                                a) Los Usuarios son los únicos responsables del uso adecuado y seguro de la cuenta de
                                usuario y contraseña registrada en La Plataforma razón por la cual, desde la firma del
                                presente documento, Los Usuarios exoneran de toda responsabilidad a La Compañía por el
                                uso indebido o fraudulento que se le dé a la cuenta de usuario y la contraseña. Los
                                Usuarios deberán mantener en estricta confidencialidad el usuario y la contraseña
                                registrados en La Plataforma y serán responsables por todos los daños y perjuicios que
                                se ocasionen a terceros por el uso indebido de las mismas.   <br/><br/>

                                b) Los Usuarios deberán proveerse, por su propia cuenta, de los equipos, sistemas y
                                conexiones a Internet que sean necesarios para acceder a la cuenta de usuario creada en
                                La Plataforma.   <br/><br/>

                                c) Los Usuarios que se registren en La Plataforma, ya sean personas naturales o
                                jurídicas, son los autorizadas para utilizar el servicio ofrecido por La Compañía a
                                través de La Plataforma. Esto implica que Los Usuarios no podrán prestar el servicio a
                                terceros, ni servir como puente, facilitador, mandatario, arrendador o cualquier otro
                                esquema comercial o jurídico que tenga como objeto prestar La Plataforma a empresas o
                                personas diferentes a ellos mismos, sin previa autorización por escrito de parte de La
                                Compañía.
                                <br/><br/>
                                d) Los Usuarios se comprometen a indemnizar a La Compañía por cualquier demanda o
                                reclamo contra ésta: i) Por el uso indebido o fraudulento de La Plataforma por parte de
                                estos; y, ii) Por el incumplimiento de las obligaciones estipuladas en estos términos y
                                condiciones cuando el incumplimiento genere algún daño y/o perjuicio a La Compañía o a
                                terceros.   <br/><br/>

                                e) Los Usuarios se obligan a cumplir con las disposiciones constitucionales, legales y
                                comunitarias relacionadas con la protección de la propiedad intelectual, especialmente
                                las contenidas en la Constitución Nacional, ley 23 de 1982, Ley 33 de 1987, Ley 44 de
                                1993, Ley 178 de 1994, Ley 545 de 1999, Ley 565 de 2000, Decisión 486 de 2000 de la
                                Comunidad Andina de Naciones (régimen común sobre propiedad intelectual) y las
                                posteriores normas que las deroguen, modifiquen o adicionen. El incumplimiento a esta
                                obligación facultará a La Compañía a dar de baja el perfil de Los Usuarios infractores,
                                sin aviso alguno y sin perjuicio de las acciones legales a que haya lugar por el
                                incumplimiento de las normas sobre protección a la propiedad intelectual.   <br/><br/>

                                f) Los Usuarios se obligan a cumplir con las disposiciones constitucionales y legales en
                                materia de competencia comercial, especialmente las contenidas en la Constitución
                                Nacional y la Ley 256 de 1996, y las posteriores normas que las deroguen, modifiquen o
                                adicionen. El incumplimiento a esta obligación facultará a La Compañía para dar de baja
                                el perfil de Los Usuarios infractores en cualquier momento, sin aviso alguno y sin
                                perjuicio de las acciones legales a que haya lugar por el incumplimiento de las normas
                                sobre competencia desleal.   <br/><br/>
                                VIII. PROHIBICIONES   <br/><br/>
                                Se prohíbe a Los Usuarios:   <br/><br/>
                                a) Publicar datos personales de otros usuarios o de terceros en los comentarios o a
                                través del chat disponible en La Plataforma sin que medie la autorización expresa del
                                titular. Los datos que compartan Los Usuarios por fuera de La Plataforma, con otros
                                usuarios o con terceros, será bajo su propia cuenta y riesgo.
                                b) Hacer uso de los datos personales proporcionados por otros usuarios a través de los
                                comentarios o del chat disponible en La Plataforma para fines distintos a los relativos
                                al negocio celebrado entre las partes.   <br/><br/>
                                c) Revelar, divulgar, copiar, transmitir por cualquier medio oral o escrito, físico o
                                electrónico, datos o informaciones de Los Usuarios, que deban permanecer en reserva, que
                                no se encuentren visibles en los perfiles y/o respecto de los cuales se haya solicitado
                                por escrito o verbalmente por parte del titular mantener en estricta confidencialidad.
                                d) Publicar productos cuyo ofrecimiento, solicitud, publicación, compra, venta y/o
                                alquiler se encuentren referenciados como artículos prohibidos en la Política de Calidad
                                e Idoneidad de los Productos a Ofrecer y Productos Prohibidos de La Compañía o en las
                                normas vigentes.   <br/><br/>
                                e) Hacer uso de lenguaje vulgar, agresivo, ofensivo o denigrante en los comentarios o en
                                el chat disponible en La Plataforma.   <br/><br/>
                                f) Colocar en el perfil hipervínculos que redireccionen a Los Usuarios a redes sociales
                                (incluyendo pero sin limitar a facebook, instagram, pinterest, linkedIn y/ o cualquier
                                otra red social).   <br/><br/>
                                g) Manipular o modificar de manera fraudulenta los precios de los productos ofrecidos
                                por otros Usuarios.   <br/><br/>
                                h) Acosar, amenazar y/o constreñir a otros usuarios para la adquisición, venta o
                                alquiler de algún bien o servicio.
                                i) Ejecutar acciones o hacer uso de dispositivos, software u otro medio para interferir
                                y/o afectar la operación de La Plataforma.
                                Los Usuarios que incurran en alguna de las prohibiciones señaladas en este acápite
                                podrán ser sancionados conforme lo dispuesto en este documento, sin perjuicio de las
                                acciones legales a que haya lugar si tales conductas llegasen a causar algún daño a
                                otros Usuarios, a terceros o a La Compañía como propietaria de La Plataforma.   <br/><br/>

                                IX. RESPONSABILIDAD   <br/><br/>

                                La Compañía pone La Plataforma a disposición de Los Usuarios como un medio virtual de
                                intercambio o comercio en el que estos puedan ofrecer o demandar productos (bienes y/o
                                servicios) de utilidad para el sector de la construcción. La Compañía no es propietaria
                                de ningún producto publicado por Los Usuarios. Los Usuarios oferentes o vendedores serán
                                los únicos responsables de las existencias, características, precios, envíos, garantías
                                y demás acciones relacionadas con el producto que ofrezcan.   <br/><br/>
                                Los Usuarios serán los únicos responsables del uso que realicen de La Plataforma. La
                                Compañía no tiene participación en las operaciones que estos realicen a través de La
                                Plataforma y tampoco interfiere en los acuerdos, convenios o contratos que se celebren
                                entre Los Usuarios con ocasión de los productos que estos publican u ofertan. Por lo
                                anterior, La Compañía como propietaria de La Plataforma no será responsable, en ningún
                                caso, por el cumplimiento de las obligaciones que adquieren Los Usuarios respecto de
                                otros usuarios o terceros al momento de concretar un negocio. La Compañía tampoco será
                                responsable por los daños y perjuicios directos o indirectos que se llegasen a causar
                                por el uso indebido que realicen Los Usuarios de La Plataforma y/o por el incumplimiento
                                de las oblaciones adquiridas por estos respecto de otros usuarios o de terceros.   <br/><br/>

                                Los Usuarios entienden y aceptan que el uso de La Plataforma es bajo su propia cuenta y
                                riesgo. La Compañía como propietaria de La Plataforma no será responsable, en ninguna
                                circunstancia, de daños o perjuicios que se llegaren a causar a Los Usuarios con ocasión
                                de los negocios realizados o no realizados respecto de los productos publicados en La
                                Plataforma.   <br/><br/>
                                Desde el registro, Los Usuarios son los únicos responsables de la veracidad,
                                autenticidad, exactitud de la información consignada en La Plataforma. La Compañía no
                                asumirá ninguna responsabilidad por los daños o perjuicios que se llegaren a causar a
                                Los Usuarios con ocasión de la falta de veracidad, autenticidad y/o exactitud de los
                                datos ingresados.   <br/><br/>
                                Los Usuarios entienden y aceptan que ni La Compañía ni sus socios, funcionarios,
                                empleados, agentes, mandatarios o proveedores del contenido publicado en La Plataforma,
                                será responsable de daños directos, indirectos, especiales, incidentales, consecuentes,
                                como resultado de, o directa o indirectamente relacionado con, el uso de, o la
                                incapacidad de usar La Plataforma o los contenidos, materiales y funciones relacionados
                                con la misma. Los Usuarios son conscientes de esta limitación de responsabilidad y, por
                                tanto, renuncian a iniciar cualquier acción de reclamación judicial o arbitral que tenga
                                por objeto demandar indemnizaciones a La Compañía o a alguno de sus socios,
                                funcionarios, empleados, agentes, mandatarios o proveedores del contenido publicado en
                                La Plataforma.   <br/><br/>

                                X. SANCIONES A LOS USUARIOS    <br/><br/>

                                Cuando Los Usuarios realicen publicaciones que van en contra de los términos y
                                condiciones generales y/o de las políticas anexas a estos, La Compañía como propietaria
                                de La Plataforma podrá advertir al Usuario que realizó la publicación que esta no es
                                acorde con la política, objetivos o preceptos establecidos en el presente documento y,
                                por tanto, deberá modificarla, ajustarla o eliminarla de manera inmediata, según el
                                caso. En el evento en que la publicación no sea modificada, ajustada o eliminada por El
                                Usuario, La Compañía podrá aplicar como sanción la suspensión de publicación por un
                                lapso de diez (10) días hábiles contados a partir del momento en el que se evidencie la
                                contravención. Si el Usuario es reincidente en la comisión de la conducta antes
                                señalada, La Compañía se reserva el derecho de suspender indefinidamente el perfil del
                                Usuario o eliminarlo.   <br/><br/>

                                Así mismo, La Compañía como propietaria de La Plataforma podrá aplicar como sanción la
                                suspensión o la eliminación del perfil de un Usuario de La Plataforma, cuando este
                                incurra en alguna conducta que a criterio de La Compañía es contraria a la ley, a los
                                términos y condiciones o a las políticas anexas a ésta; cuando el uso del perfil esté
                                encaminado a actos ilícitos o cuando se compruebe que la información proporcionada por
                                El Usuario fue errónea. Al aplicar la suspensión o la eliminación del perfil del Usuario
                                infractor, todas las publicaciones que se encuentren en el perfil dejarán de ser
                                visibles o serán eliminadas, según corresponda.   <br/><br/>

                                XI. SISTEMA DE REPUTACIÓN   <br/><br/>

                                Al registrarse en La Plataforma, Los Usuarios serán incluidos en el sistema de
                                reputación que comprenderá la calificación y comentarios que asignen otros usuarios con
                                los que haya realizado algún negocio a través de La Plataforma. Tanto los usuarios
                                oferentes como los usuarios demandantes podrán recibir calificaciones y comentarios
                                respecto del desarrollo del negocio.   <br/><br/>
                                La calificación se realizará de manera numérica mediante la asignación de estrellas en
                                un rango de uno (1) a cinco (5), donde uno (1) es la mínima calificación y cinco (5) la
                                máxima calificación, entre más estrellas obtenga un usuario mejor será su reputación en
                                la plataforma. Los Usuarios no podrán autocalificarse. Los Usuarios solo podrán
                                calificar y/o recibir calificaciones una sola vez por cada negocio concluido.   <br/><br/>

                                Los Usuarios deberán calificar al Usuario con el que hayan concretado y finalizado un
                                negocio dentro de los quince (15) días siguientes a la transacción. Así mismo, podrán
                                dejar un comentario sobre su experiencia al efectuar el negocio. Los comentarios deberán
                                estar relacionados únicamente con el desarrollo del negocio. Los comentarios deberán ser
                                objetivos, respetuosos, evitando el uso de expresiones de carácter personal.   <br/><br/>

                                Para efectos de establecer la reputación de un usuario, se tendrán en cuenta hasta tres
                                calificaciones recibidas por la misma contraparte en un período de 90 días, las cuales
                                serán promediadas y de su resultado se asignará el número de estrellas que corresponda.
                                La calificación general del Usuario aparecerá bajo el nombre del perfil y estará visible
                                para todos los Usuarios.   <br/><br/>

                                La Compañía como propietaria de La Plataforma, no verifica si el contenido de los
                                comentarios realizados por Los Usuarios carece de veracidad o exactitud. Por lo tanto,
                                La Compañía no será responsable, en ningún caso, por los comentarios publicados por Los
                                Usuarios dentro del sitio o en cualquier otro medio. Todos los comentarios publicados
                                por Los Usuarios en La Plataforma son de su exclusiva responsabilidad y en consecuencia,
                                Los Usuarios podrán responder por las acciones que puedan adelantar otros usuarios, que
                                consideren lesionado algún derecho o infringida alguna ley por el contenido de los
                                comentarios expuestos.   <br/><br/>
                                Bajo circunstancias especiales, La Compañía como propietaria de La Plataforma, de oficio
                                o por solicitud del Usuario afectado, podrá modificar o eliminar los comentarios. Tales
                                circunstancias son:   <br/><br/>
                                • Cuando se evidencie que el comentario no tiene relación con un negocio concertado en
                                La Plataforma.   <br/><br/>
                                • Cuando se publiquen datos personales de Los Usuarios sin la autorización de los
                                titulares de la información.   <br/><br/>
                                • Cuando se utilice lenguaje vulgar, ofensivo o denigrante en los comentarios.
                                • Si la calificación o comentario va contra de los preceptos contenidos en los Términos
                                y Condiciones Generales.   <br/><br/>
                                Con base en lo anterior, La Compañía como propietaria de La Plataforma se reserva la
                                facultad de modificar o eliminar los comentarios que considere contrarios a sus
                                Políticas debido a su contenido inadecuado u ofensivo.

                                XII. TARIFAS Y FACTURACIÓN    <br/><br/>
                                La Compañía como propietaria de La Plataforma ha diseñado los planes que se describen a
                                continuación:   <br/><br/>
                                NOMBRE DEL PLAN DESCRIPCIÓN VIGENCIA VALOR   <br/><br/>

                                Plan Free El Usuario podrá hacer hasta cinco (5) publicaciones de productos en
                                diferentes categorías. Treinta (30) días calendario contados a partir de la fecha de
                                registro. Vencido este término, el usuario deberá adquirir un plan de pago para poder
                                seguir haciendo publicaciones. SIN COSTO   <br/><br/>
                                Plan Experto El Usuario podrá publicar hasta veinte (20) publicaciones de productos en
                                diferentes categorías. Treinta (30) días calendarios contados a partir de la fecha de
                                suscripción. El pago será recurrente cada 30 días calendario hasta el momento en que el
                                Usuario lo cancele. $30.000   <br/><br/>
                                Plan TuConstrucción El Usuario podrá publicar hasta cien (100) publicaciones de
                                productos en diferentes categorías. Treinta (30) días calendarios contados a partir de
                                la fecha de suscripción. El pago será recurrente cada 30 días calendario hasta el
                                momento en que el Usuario lo cancele. $80.000   <br/><br/>

                                Al registrarse en La Plataforma, Los Usuarios quedan vinculados inmediatamente en el
                                Plan Free. Vencido el término de vigencia del plan Free, Los Usuarios podrán suscribirse
                                a cualquiera de los demás planes disponibles, es decir, podrán suscribirse bien sea al
                                “Plan Experto” o al “Plan TuConstrucción”.   <br/><br/>
                                Los pagos por concepto de suscripción a alguno de los planes podrán realizarse en
                                efectivo, transferencia bancaria (PSE) o a través de tarjeta de crédito.   <br/><br/>
                                Los pagos en efectivo cuentan con las siguientes opciones: “Baloto”, “Efecty”, “Pagar en
                                Banco” u “Otros puntos de pago” (que hace referencia a puntos Su Red). Al escoger
                                cualquiera de las opciones se remitirá un recibo de pago al correo electrónico con el
                                cual Usuario realizó el registro en La Plataforma La pasarela de pagos remitirá al
                                pagador el recibo de pago que será gestionado por PayU como la pasarela de pagos con la
                                que cuenta La Compañía. En el recibo se identificará a La Compañía y/o a La Plataforma
                                como “El Comercio”   <br/><br/>
                                La Plataforma NO cuenta con pasarela de pagos propia, por lo que se utilizará para el
                                efecto de los pagos en efectivo o a través de tarjeta de crédito o débito la pasarela de
                                pagos de PayU. Previo a realizar el pago, le recomendamos a Los Usuarios revisar las
                                políticas de PayU en el siguiente enlace:   <br/><br/>
                                https://colombia.payu.com/portal-de-privacidad-payu/ .   <br/><br/>
                                Las reclamaciones de Los Usuarios relacionadas con la pasarela de pagos deberán
                                dirigirse a PayU o a la plataforma de pagos vinculada en el momento de efectuar la
                                transacción.   <br/><br/>
                                Una vez adquirido el Plan, este estará vigente por el término correspondiente.
                                Finalizada la vigencia, Los Usuarios podrán optar por mantener el mismo plan o
                                cambiarlo, para lo cual seguirán el procedimiento descrito anteriormente.   <br/><br/>
                                La Compañía como propietaria de La Plataforma podrá modificar, adicionar o eliminar los
                                planes y las tarifas vigentes en cualquier momento. Las modificaciones efectuadas serán
                                notificadas a Los Usuarios a través de La Plataforma y/o a través de correo electrónico
                                con el que se realizó el registro en La Plataforma.   <br/><br/>

                                XIII. PUBLICACIÓN DE BIENES Y/O SERVICIOS   <br/><br/>

                                Para facilitar la clasificación de los bienes y servicios ofrecidos o demandados por Los
                                Usuarios de La Plataforma, La Compañía dispone de las siguientes categorías:

                                CATEGORÍA DESCRIPCIÓN    <br/><br/>

                                Materiales y suministros En la presente categoría se podrán publicar todos los insumos
                                y/o materias primas relacionadas con la industria de la construcción.   <br/><br/>

                                EJEMPLO: Cemento, Arena, gravilla, varillas, bloques, ladrillos, arcillas, pinturas,
                                yesos, estucos.   <br/><br/>
                                Artículos y accesorios: Dentro de esta categoría se podrán publicar todos los artículos
                                terminados que puedan ser utilizados en la industria de la construcción.   <br/><br/>

                                EJEMPLO: Ventanas, puertas, inodoros, plateros, vidrios, lámparas, cables, griferías,
                                tubería.   <br/><br/>
                                Herramientas En la presente categoría se podrán publicar todas las herramientas
                                eléctricas o de funcionamiento manual, utensilios, que puedan ser portátiles.   <br/><br/>

                                Ejemplo: Taladros, destornilladores, martillos, compresores, rodillos, pulidoras,
                                cortadoras, palas.   <br/><br/>
                                Maquinaria y equipos: En la presente categoría se podrán publicar todas las máquinas y
                                equipos no portables y pesados utilizados en la industria de la construcción.   <br/><br/>

                                EJEMPLO: motobombas, plantas eléctricas, mezcladoras de concreto, plumas, andamios.   <br/><br/>
                                Maquinaria pesada: En la presente categoría se podrán publicar todas las máquinas y
                                equipos para obras civiles de gran calado.   <br/><br/>
                                Seguridad Industrial En la presente categoría se podrán publicar todos los productos
                                relacionados con la seguridad industrial.   <br/><br/>

                                EJEMPLO: chalecos, cascos, gafas. Guantes, arneses, serialización.   <br/><br/>

                                Predios En la presente categoría se podrán publicar todos los inmuebles en venta de
                                proyectos de construcción.   <br/><br/>
                                Vehículos En la presente categoría se podrán publicar todo tipo de vehículos que puedan
                                utilizarse en la construcción (venta y/o alquiler)   <br/><br/>

                                Ejemplo. Volquetas, mezcladoras, camiones etc.   <br/><br/>
                                Ferreterías y depósitos En la presente categoría se podrán publicar todos los
                                establecimientos comerciales dedicados a la venta al público de insumos y herramientas
                                para la construcción.   <br/><br/>
                                Constructoras En la presente categoría se podrán publicar todas las compañías y firmas
                                dedicadas a la construcción como de vivienda como de proyectos civiles
                                Profesionales En la presente categoría se podrán publicar todas las personas naturales o
                                jurídicas que presten servicios profesionales en el ramo de la construcción.

                                EJEMPLO: Ingenieros civiles, arquitectos, diseñadores, calculistas, topógrafos,
                                ingenieros eléctricos.   <br/><br/>
                                Contratistas En la presente categoría se podrán publicar todas las personas naturales o
                                jurídicas, que presten servicios técnicos en el ramo de la construcción.   <br/><br/>

                                EJEMPLO: electricista, enchapadores, carpinteros, albañiles.
                                Logística En la presente categoría se podrán publicar todas las personas naturales o
                                jurídicas, que presten servicios de logística enfocada en el ramo de la construcción.

                                Ejemplo: baños portátiles, alimentación, transporte.   <br/><br/>
                                Software especializado En la presente categoría se podrán publicar todos los softwares
                                utilizados en el ramo de la construcción, se podrá ofrecer licenciamiento o prestación
                                de servicios con dichos softwares.   <br/><br/>

                                Ejemplo: AutoCAD, Revit.   <br/><br/>
                                Curadurías En la presente categoría se podrán publicar todas las curadurías del país.
                                Eventos, ferias y conferencias En la presente categoría se podrán publicar todos los
                                eventos relacionados con la construcción.   <br/><br/>
                                Decoración En la presente categoría se podrán publicar todas las personas naturales o
                                jurídicas, que presten servicios decoración de interiores y exteriores.   <br/><br/>

                                Los Usuarios podrán publicar en La Plataforma ya sea para adquirir un bien y/o servicio
                                o para ofrecerlos en venta o alquiler, según sea el caso. Las publicaciones deberán
                                contener como mínimo la siguiente información:   <br/><br/>
                                • Fotografías relacionadas con el producto que se ofrece o se solicita.   <br/><br/>

                                • Título de la publicación   <br/><br/>
                                • Descripción   <br/><br/>
                                • Precio   <br/><br/>
                                • Marca   <br/><br/>
                                • Cantidad de existencias disponibles (si aplica)   <br/><br/>
                                • Categoría   <br/><br/>
                                • Garantía   <br/><br/>
                                Como datos adicionales, las publicaciones pueden contener:   <br/><br/>
                                • Precio con descuento   <br/><br/>
                                • Peso aproximado en kilogramos (Kg) del producto   <br/><br/>
                                • Medidas aproximadas en centímetros (cm) del producto   <br/><br/>
                                • Precio de envío   <br/><br/>
                                Los Usuarios podrán colocar un máximo de cuatro (4) fotos de máximo cinco megabytes (5
                                MB). La descripción podrá contener un máximo de 65,535 caracteres incluido espacios. Las
                                imágenes o fotografías deben corresponder al producto ofrecido. El título de la
                                publicación y la descripción no deberán contener datos personales de Los Usuarios o de
                                terceros. En la descripción del bien y/o servicio deberán especificarse las
                                características, estado del mismo, plazo de entrega y demás condiciones relevantes. El
                                precio deberá ser publicado en moneda legal (pesos colombianos –COP–) y deberá incluir
                                los impuestos a los que está sujeto el producto (IVA). Las publicaciones deben ajustarse
                                a algunas de las categorías enunciadas. Se recomienda a Los Usuarios que, previo a
                                realizar una publicación, revisen la Política de Calidad e Idoneidad de los Productos a
                                Ofrecer y Productos Prohibidos disponible en www.tuconstruccion.com.co. No se podrán
                                ofrecer o solicitar productos que se encuentren señalados como prohibidos o que tengan
                                alguna relación con estos.   <br/><br/>
                                Los Usuarios entienden y aceptan que son los únicos responsables sobre las publicaciones
                                que realicen. La Compañía no asumirá ninguna responsabilidad por la falta precisión,
                                veracidad, exactitud, vigencia y/o autenticidad de los productos que se ofrezcan a
                                través de La Plataforma. En el evento que se presente algún reclamo por publicidad
                                engañosa ya sea por parte de otros usuarios o de terceros, Los Usuarios dueños de la
                                publicación serán los únicos responsables del reconocimiento de daños y perjuicios que
                                se llegaren a causar.   <br/><br/>

                                XIV. ATAQUES FOTOSENSIBLES.   <br/><br/>

                                Un muy pequeño porcentaje de personas pueden experimentar un ataque al ser expuestas a
                                ciertas imágenes visuales, como luces o dibujos intermitentes que pueden aparecer en los
                                banners publicitarios o presentaciones visuales incluidas en La Plataforma. Aún personas
                                que no tienen historia de ataques o de epilepsia pueden tener una condición no
                                diagnosticada que puede causar estos "ataques epilépticos fotosensibles" al estar
                                expuestos a este tipo de contenidos electrónicos. Estos ataques tienen una variedad de
                                síntomas, incluyendo mareos, desorientación, confusión, pérdida momentánea de
                                conciencia, crispación de ojos o cara, visión alterada o tirones o sacudidas de brazos o
                                piernas. Si Los Usuarios o sus familiares experimentan cualquiera de los síntomas
                                anteriormente mencionados o si han tenido historia de ataques o epilepsia,
                                inmediatamente deben dejar de usar La Plataforma y consultar a un médico.   <br/><br/>

                                XV. PROPIEDAD INTELECTUAL    <br/><br/>

                                La Compañía como propietaria de La Plataforma ostenta todos los derechos sobre el
                                software, textos, gráficos, funciones, bases de datos, códigos, logotipos, marcas y
                                demás contenidos relacionados con La Plataforma. Los derechos de La Compañía sobre La
                                Plataforma se regirán por las normas vigentes sobre propiedad intelectual. Los Usuarios
                                no adquieren por el hecho de registrarse ningún derecho sobre La Plataforma, excepto
                                hacer uso de ella para publicar la oferta y/o solicitud de algún producto relacionado
                                con el sector de la construcción. Los Usuarios no podrán explotar para su beneficio o el
                                de terceros ninguna información contenida en La Plataforma o relacionada con esta, salvo
                                que cuenten con la autorización expresa de La Compañía como propietaria de La
                                Plataforma.    <br/><br/>
                                Se presume que Los Usuarios son titulares y/o cuentan con las licencias pertinentes
                                respecto de los derechos sobre las fotografías y/o imágenes que insertan en las
                                publicaciones, así como de la información que incluyen en la descripción del producto.
                                Los Usuarios autorizan a La Compañía como propietaria de La Plataforma para hacer uso de
                                las imágenes, fotografías y/o información del producto publicado en La Plataforma y
                                reproducirlos en otros sitios web, redes sociales y/o cualquier medio de comunicación
                                físico y/o digital en el que La Compañía considere pertinente para identificar ofertas,
                                clasificar productos, crear catálogos, realizar acciones publicitarias y de marketing y
                                demás actividades relacionadas. La Compañía no es responsable en ninguna circunstancia
                                por la infracción que, con el uso de las imágenes, fotografías y/o información contenida
                                en las publicaciones efectuadas por Los Usuarios, pueda causarse a derechos de otros
                                usuarios y/o a terceros.   <br/><br/>

                                XVI. USOS PROHIBIDOS   <br/><br/>
                                Cualquier distribución, publicación o explotación comercial o promocional de La
                                Plataforma, o de cualquiera de los contenidos, códigos, recursos, datos o materiales
                                publicados en La Plataforma, está estrictamente prohibido, a menos de que Los Usuarios
                                hayan recibido el previo permiso expreso y por escrito de La Compañía. A no ser que esté
                                expresamente permitido en el presente documento, Los Usuarios no pueden descargar,
                                informar, exponer, publicar, copiar, reproducir, distribuir, transmitir, modificar,
                                ejecutar, difundir, transferir, crear trabajos derivados de, vender o de cualquier otra
                                manera explotar cualquiera de los contenidos, códigos, recursos, datos o materiales en o
                                disponibles a través de La Plataforma. Los Usuarios se obligan además a no alterar,
                                editar, borrar, quitar, o de otra manera cambiar el significado o la apariencia de, o
                                cambiar el propósito de, cualquiera de los contenidos, códigos, datos o materiales en o
                                disponibles a través de La Plataforma, incluyendo, sin limitación, la alteración o
                                retiro de cualquier marca comercial, marca registrada, logo, marca de servicios o
                                cualquier otro contenido de propiedad o notificación de derechos de propiedad. Los
                                Usuarios reconocen que no adquieren ningún derecho de propiedad al descargar algún
                                material con derechos de autor de o a través de La Plataforma.   <br/><br/>

                                XVII. RENUNCIA DE GARANTÍAS.   <br/><br/>
                                Los Usuarios entienden y aceptan que toda la información, recursos, contenidos,
                                funciones y materiales proporcionados a través de La Plataforma, se presentan “tal como
                                estén”, “como se ponen a su disposición”, sin garantía de ningún tipo, ya sea expresa o
                                implícita, incluyendo, sin limitación, cualquier garantía de información, datos,
                                servicios de procesamiento de datos, acceso ilimitado o ininterrumpido, cualquier
                                garantía con respecto a la disponibilidad, exactitud, precisión, rectitud, perfección,
                                conclusión, utilidad o contenido de la información, y cualquier garantía de título,
                                no-infracción, comerciabilidad o aptitud para un propósito en particular. En
                                consecuencia, Los Usuarios renuncian a cualquiera y a todas dichas garantías, expresas e
                                implícitas. La Compañía no garantiza que La Plataforma o los servicios, contenidos,
                                funciones o materiales proporcionados a través de La Plataforma serán oportunos,
                                seguros, ininterrumpidos o libres de error, o que los defectos serán corregidos. Tampoco
                                se da garantía de que La Plataforma o de que los servicios proporcionados cumplirán con
                                los requerimientos de Los Usuarios. Ningún consejo, sugerencia, recomendación o
                                información, ya sea escrita o audiovisual, que Los Usuarios obtengan a través de La
                                Plataforma creará alguna garantía que no haya sido establecida expresamente en el
                                presente documento. La Compañía tampoco asume ninguna responsabilidad y no será
                                responsable de cualquier daño o de cualquier virus que pudiera infectar su equipo de
                                cómputo o dispositivo electrónico a causa de su acceso a, uso de, o navegación en La
                                Plataforma o por descargar cualquiera de los materiales, datos, textos, imágenes,
                                contenidos de video o contenidos de audio de La Plataforma. Si Los Usuarios no están
                                satisfechos con la información o publicaciones allí contenidas, se les recomienda
                                informar a La Compañía para efecto de evaluar las sugerencias propuestas. En todo caso,
                                Los Usuarios podrán dejar de acceder o utilizar los servicios ofrecidos en La Plataforma
                                cuando lo estime conveniente.   <br/><br/>
                                La Compañía procura que la información publicada en La Plataforma sea correcta y
                                actualizada, sin embargo, se reserva el derecho de cambiar o hacer correcciones a
                                cualquier información proporcionada en La Plataforma en cualquier momento y sin ningún
                                aviso previo. La Compañía no avala ni es responsable de la exactitud o veracidad de
                                cualquier opinión, recomendación, sugerencia, consejo o declaración en La Plataforma, ni
                                de cualquier publicación ofensiva, difamatoria, obscena, indecente, ilegal o violatoria
                                hecha en la misma por otros usuarios de La Plataforma. Es responsabilidad exclusiva de
                                Los Usuarios evaluar la exactitud, conclusión o utilidad de cualquier información,
                                opinión, recomendación, sugerencia, consejo u otro contenido disponible a través de La
                                Plataforma. En caso de que Los Usuarios presenten dudas o inquietudes frente a la
                                información contenida en La Plataforma, se les recomienda informar a La Compañía para
                                efecto de evaluarlas. En todo caso, Los Usuarios deberán buscar la asesoría profesional
                                que corresponda con el fin de que resuelva las dudas o inquietudes.
                                Previo a la realización de una compra o venta de cualquier bien y/o servicio, se
                                aconseja a Los Usuarios revisar la reputación del Usuario oferente, verificar precios,
                                características de del o los productos y cualquier información relevante para que puedan
                                tomar decisiones suficientemente informadas. La Compañía no tendrá ninguna
                                responsabilidad de decisiones de inversión basadas en, o de resultados obtenidos de, el
                                contenido proporcionado en La Plataforma. Ningún contenido en La Plataforma podrá ser
                                interpretado como consejo o asesoría para el consumidor.   <br/><br/>

                                XVIII. INDEMNIDAD   <br/><br/>
                                Los Usuarios mantendrán indemne a La Compañía, sus socios, representantes legales,
                                administradores, empleados, subcontratistas o proveedores, por cualquier acción,
                                reclamo, demanda, pérdida, obligación, daño, costos y/o costas, que puedan sufrir como
                                resultado de algún incumplimiento de las obligaciones surgidas en virtud de los
                                acuerdos, convenios o contratos que celebren con otros Usuarios registrados en La
                                Plataforma, o por cualquier reclamación judicial o extrajudicial por razón de actos u
                                obligaciones que son de su responsabilidad de conformidad con lo dispuesto en este
                                documento.   <br/><br/>
                                En caso de que se entable un reclamo o acción legal por parte de Los Usuarios o de
                                terceros contra La Compañía por asuntos relacionados con el uso indebido de La
                                Plataforma, el incumplimiento de los términos y condiciones o de las políticas anexas,
                                la violación de leyes, lesiones o daños a terceros se notificará de la existencia de
                                tales acciones al Usuario infractor, para que por su cuenta adopte oportunamente las
                                medidas pertinentes para mantener indemne a La Compañía.   <br/><br/>
                                La Compañía podrá defenderse directamente cuando evidencie que el Usuario Infractor no
                                asume debida y oportunamente la defensa. El Usuario infractor se obliga a pagar todos
                                los costos en que incurra La Compañía como, por ejemplo, honorarios de abogado, peritos,
                                viáticos, gastos judiciales, etc.   <br/><br/>
                                Este documento prestará mérito ejecutivo para requerir el reembolso de los gastos en que
                                haya incurrido La Compañía con ocasión de su defensa, junto con las facturas, contratos
                                o recibos en donde consten las erogaciones hechas por La Compañía. La fecha de
                                exigibilidad del pago a cargo del Usuario infractor será el día siguiente a la fecha
                                indicada en cada una de las facturas, contratos y recibos expedidos a nombre de La
                                Compañía.   <br/><br/>


                                XIX. MODIFICACIONES A LA PLATAFORMA    <br/><br/>
                                La Compañía puede terminar, cambiar, suspender o descontinuar cualquier aspecto de La
                                Plataforma o de sus contenidos en cualquier momento. La Compañía también puede
                                restringir, suspender o terminar el acceso que tienen Los Usuarios a La Plataforma y/o a
                                sus contenidos si considera que Los Usuarios están incumpliendo los términos y
                                condiciones o la ley aplicable, o por cualquier otra razón justificada sin notificación
                                o responsabilidad.   <br/><br/>
                                XX. MODIFICACIONES DE LOS TÉRMINOS Y CONDICIONES   <br/><br/>
                                La Compañía como propietaria de La Plataforma, podrá modificar en cualquier momento los
                                Términos y Condiciones señalados en este documento y en sus anexos, los cuales hacen
                                parte integral de este. Dichas modificaciones serán notificadas a Los Usuarios a través
                                de La Plataforma y/o a través de correo electrónico con el que se realizó el registro en
                                La Plataforma.   <br/><br/>
                                En el evento que Los Usuarios no se encuentren de acuerdo con las modificaciones
                                efectuadas sobre los términos y condiciones y sus anexos, podrán solicitar la
                                eliminación de su perfil.   <br/><br/>
                                XXI. CLÁUSULA ARBITRAL PARA LA SOLUCIÓN DE CONTROVERSIAS.   <br/><br/>
                                Toda disputa, diferencia o controversia relativa a estos términos y condiciones o a sus
                                políticas anexas o que derive del mismo, se resolverá por un tribunal arbitral que
                                funcionará en el Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá,
                                de conformidad con las siguientes reglas:   <br/><br/>
                                1) El tribunal se sujetará al Reglamento de Arbitraje Comercial Internacional del Centro
                                de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá.   <br/><br/>
                                2) El tribunal estará integrado por un (1) árbitro(s) designado(s) por las partes, para
                                lo cual tendrán un plazo máximo de cinco (5) días hábiles para nombrarlo. En caso de que
                                no fuere posible, el árbitro será designado por el Centro de Arbitraje y Conciliación de
                                la Cámara de Comercio de Bogotá a solicitud de cualquiera de las partes.
                                3) El tribunal decidirá en derecho.   <br/><br/>
                                4) El idioma del arbitraje será el español. La sede del arbitraje será la ubicada en la
                                Calle 67 No. 8-32 de Bogotá D.C.   <br/><br/>
                                5) La ley del fondo de la controversia será la indicada en estos términos y condiciones.

                                XXII. LEY APLICABLE   <br/><br/>
                                La relación jurídica originada entre Los Usuarios y La Compañía como propietaria de La
                                Plataforma se regirá por las disposiciones contenidas en las leyes de la República de
                                Colombia.   <br/><br/>

                                XXIII. DOMICILIO
                                Para los efectos del presente documento Los Usuarios aceptan que el domicilio
                                contractual es la ciudad de Bogotá D. C., Colombia.

                            </p>
                        </div>


                        <DialogActions>
                            <ButtonFacebook/>
                            <Button aria-label="Close" onClick={() => handleControl('')} variant="contained"
                                    color="secondary">Cerrar</Button>
                        </DialogActions>


                    </Form>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    city: state.city,
    typePeople: state.typePeople,
    typeProfessional: state.typeProfessional,
    gender: state.gender,
    typeProfile: state.typeProfile,
});
const connected = connect(mapStateToProps, {
    citiesGetRequesting,
    getTypeProfileRequesting,
    getTypePeopleRequesting,
    getGenderRequesting,
    getTypeProfessionalRequesting,
    filterGender,
    visibleTypeProfessional,
    createUserRequesting,
    errorControlCreateUser
})(ModalTermsConditionsComponent);

const formed = reduxForm({
    form: 'frequencyUser',
})(connected);

export default formed;

