export const CATEGORIES_GET_REQUESTING = 'CATEGORIES_GET_REQUESTING';
export const CATEGORIES_GET_SUCCESS = 'CATEGORIES_GET_SUCCESS';
export const CATEGORIES_GET_ERROR = 'CATEGORIES_GET_ERROR';

export const CATEGORIES_MODULE_GET_REQUESTING = 'CATEGORIES_MODULE_GET_REQUESTING';
export const CATEGORIES_MODULE_GET_SUCCESS = 'CATEGORIES_MODULE_GET_SUCCESS';
export const CATEGORIES_MODULE_GET_ERROR = 'CATEGORIES_MODULE_GET_ERROR';

export const CATEGORY_FOCUS = 'CATEGORY_FOCUS';
export const SUBCATEGORY_FOCUS = 'SUBCATEGORY_FOCUS';
export const MODULE_TYPE_FOCUS = 'MODULE_TYPE_FOCUS';

export const TYPE_MODULE_FOCUS = 'TYPE_MODULE_FOCUS';

export const CATEGORY_SHOW_MODAL = 'CATEGORY_SHOW_MODAL';
export const CATEGORY_HIDDEN_MODAL = 'CATEGORY_HIDDEN_MODAL';

export const CATEGORY_RESET_STATES = 'CATEGORY_RESET_STATES';
