import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {MuiThemeProvider} from "@material-ui/core";
import {theme} from "../../../theme/theme";
import ModalForgotPassword from "./modal-forgot password";
import ModalPhone from './../modals/phone';
import ButtonFacebook from '../../button-facebook';
import {showModalResetPassword} from "../../../redux/reset_password/actions";
import {showModalUser} from "../../../redux/user/actions";


import TextField from '@material-ui/core/TextField';
import {Field, Form, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {errorControlLogin, loginRequesting} from "../../../redux/login/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";


const inputTextField = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {
    return (
        <TextField
            {...input}
            {...rest}
            label={label}
            type={type}
            value={input.value}
            name={input.name}
            margin="normal"
            variant="outlined"
            fullWidth
            multiline={type === "text"}
            rowsMax={'10'}
        />
    )
};


class LoginModalComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        isForgotPassword: false,
        isLoginPhone: false
    };
    handleAction = (values) => {
        const {loginRequesting} = this.props;
        loginRequesting(values);
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    handleHiddenModal = () => {
        this.setState({
            isForgotPassword: false,
            isLoginPhone : false
        })
    };

    handleError = () => {
        const {login: {error}, errorControlLogin} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlLogin();
    };

    handleShowModal = (modal) => {
        this.setState({
            isForgotPassword: modal === 'olvido contraseña',
            isLoginPhone: modal === 'phone'
        })
    };

    render() {
        const {handleControl, visible, handleSubmit, login: {requesting, errorLogin}, showModalResetPassword, resetPassword: {isForgotPassword}, showModalUser} = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                {errorLogin && this.handleError()}
                <Dialog
                    onClose={() => handleControl('')} aria-labelledby="customized-dialog-title"
                    open={visible}>

                    <DialogTitle id="customized-dialog-title">
                        {!isForgotPassword ? 'Iniciar sesión' : 'Restablecer contraseña'}
                        <div style={{position: 'absolute', right: 10, top: 10}}>
                            <IconButton aria-label="Close" onClick={() => handleControl('')}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <ModalPhone
                            open={this.state.isLoginPhone}
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal}/>
                        {isForgotPassword ? (
                                <ModalForgotPassword stop={this.stopPropagation.bind(this)}
                                                     hidden={showModalResetPassword}/>
                            )
                            :
                            <div>
                                <ButtonFacebook/>
                                <Form onSubmit={handleSubmit(this.handleAction)}>
                                    <Field name={'email'} type="email" label={'Correo'} component={inputTextField}/>
                                    <Field name={'password'} type="password" autoComplete="current-password"
                                           label={'Contraseña'} component={inputTextField}/>
                                    <Button onClick={() => this.handleShowModal('phone')}>
                                        Ingresar con celular
                                    </Button>
                                    <DialogActions>
                                        <Button variant="contained" disabled={requesting} type={'submit'}
                                                color='secondary'>
                                            Entrar
                                        </Button>
                                        <Button variant="contained" onClick={() => handleControl('')}
                                                color="secondary">
                                            Cancelar
                                        </Button>
                                    </DialogActions>
                                </Form>

                                <Button onClick={() => showModalResetPassword('olvido contraseña')}>
                                    ¿Olvidaste tu contraseña?
                                </Button>
                                <Button onClick={() => showModalUser('modal register')}>
                                    Deseas Registrarte
                                </Button>
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            </MuiThemeProvider>
        );
    }
}

const
    mapStateToProps = state => ({
        login: state.login,
        resetPassword: state.resetPassword
    });
const
    connected = connect(mapStateToProps, {
        loginRequesting,
        errorControlLogin,
        showModalResetPassword,
        showModalUser
    })(LoginModalComponent);

const
    formed = reduxForm({
        form: 'loginUser',
    })(connected);

export default formed;
