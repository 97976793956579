import {ACTUAL_PAGE, FILTER_CHANGE_PRIMARY_PROFILE, FILTER_CHANGE_SECONDARY_PROFILE} from "./constants";

const initialState = {
    filtersPrimary: [
        'Productos', 'Favoritos', 'Mensajes', 'Comentarios', 'Suscripciones'
    ],
    filtersSecondary: [
        'Publicados', 'Vendidos'
    ],
    filterPrimary: 'Productos',
    filterSecondary: 'Publicados',
    url: '/',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTER_CHANGE_PRIMARY_PROFILE:
            return {
                ...state,
                filterPrimary: action.target,
            };
        case FILTER_CHANGE_SECONDARY_PROFILE:
            return {
                ...state,
                filterSecondary: action.target,
            };
        case ACTUAL_PAGE:
            return {
                ...state,
                url: action.url,
            };
        default:
            return state;
    }
};

export default reducer;