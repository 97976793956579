import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {publicationsTalkResetStates, publicationsTalksMeGetError, publicationsTalksMeGetSuccess} from "./actions";
import {PUBLICATIONS_TALKS_ME_GET_REQUESTING} from "./constants";

const publicationTalkURL = `${process.env.REACT_APP_API_URL}/publicationsTalks`;

const publicationsTalksMeGetApi = (token, paginate) => {
    return fetch(`${publicationTalkURL}Me?page=${paginate}`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200 || json.data.data.length > 0)
                return json.data.data;
            throw json.data;

        }).catch((error) => {
            throw error;
        })
};

function* publicationsTalksMeGetFlow(action) {
    try {
        const {token, paginate} = action;
        const publicationsTalks = yield call(publicationsTalksMeGetApi, token, paginate);
        yield put(publicationsTalksMeGetSuccess(publicationsTalks));
        yield put(publicationsTalkResetStates());
    } catch (error) {
        yield put(publicationsTalksMeGetError(error));
    }
}

function* publicationsTalkWatcher() {
    yield all([
        takeEvery(PUBLICATIONS_TALKS_ME_GET_REQUESTING, publicationsTalksMeGetFlow),
    ])
}

export default publicationsTalkWatcher;