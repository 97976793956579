import {ACTUAL_PAGE, FILTER_CHANGE_PRIMARY_PROFILE, FILTER_CHANGE_SECONDARY_PROFILE} from "./constants";

export const filterChangePrimaryProfile = (target) => ({
    type: FILTER_CHANGE_PRIMARY_PROFILE,
    target,
});

export const filterChangeSecondaryProfile = (target) => ({
    type: FILTER_CHANGE_SECONDARY_PROFILE,
    target,
});

export const actualPage = (url) => ({
    type: ACTUAL_PAGE,
    url,
});
