import React from 'react';
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {phoneVerifyRequesting} from "../../../redux/phone/actions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/core/SvgIcon/SvgIcon";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogContent} from "@material-ui/core";
import firebase from 'firebase';
import CircularProgress from '@material-ui/core/CircularProgress';

const inputTextField = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {
    return (
        <TextField
            {...input}
            {...rest}
            label={label}
            type={type}
            value={input.value}
            name={input.name}
            margin="normal"
            variant="outlined"
            fullWidth
            multiline={type === "text"}
            rowsMax={'10'}
        />
    )
};

class ModalPhone extends React.Component {

    constructor(props) {
        super(props);
        this.loginAuth = null;
        this.confirmationResult = null;
    }

    state = {
        phoneNumber: '',
        error: null,
        isInputPhone: true,
        isValidCode: false,
        requesting: false
    };


    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.open && (nextProps.open !== this.props.open))
            if (!this.loginAuth)
                setTimeout(() => {
                    this.loginAuth = new firebase.auth.RecaptchaVerifier("recaptcha-container",
                        {
                            size: "invisible",
                        });
                }, 1000);

    }

    handleSendCode = (code) => {
        const {phoneVerifyRequesting, hidden} = this.props;
        this.setState({requesting: true});
        this.confirmationResult.confirm(code).then(result => {
            if (result.operationType === 'signIn') {
                phoneVerifyRequesting(this.state.phoneNumber);
            }
            this.setState({requesting: false}, () => hidden);
        }).catch(error => {
            this.setState({requesting: false});
            console.log(error);
        });
    };

    handleSignupPhoneNumber = (phone) => {
        this.setState({
            requesting: true
        });
        firebase.auth().signInWithPhoneNumber('+57' + phone, this.loginAuth)
            .then(result => {
                this.confirmationResult = result;
                this.setState({isValidCode: true, requesting: false, phoneNumber: phone})
                // this.handleOpen('code');
            })
            .catch(error => this.setState({requesting: false}));
    };


    handleLoginPhone = (values) => {
        const {isInputPhone, isValidCode} = this.state;
        if (isInputPhone && !isValidCode)
            this.handleSignupPhoneNumber(values.phone);
        if (isValidCode)
            this.handleSendCode(values.code, values.phone);
    };

    handleVerifyToken = (values) => {
        const {verifyTokenResetPasswordPostRequesting} = this.props;
        verifyTokenResetPasswordPostRequesting(values);
    };

    handleResetPassword = (values) => {
        const {resetPasswordPutRequesting} = this.props;
        resetPasswordPutRequesting(values);
    };

    render() {
        const {
            handleSubmit,
            phone,
            open,
            hidden
        } = this.props;
        const {isValidCode, requesting} = this.state;
        return (
            <Dialog
                onClose={hidden}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <DialogTitle id="customized-dialog-title">
                    {isValidCode ? 'Ingresa el código enviado' : 'Ingresa tu número celular'}
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={hidden}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div id="recaptcha-container"/>
                    </div>

                    <form onSubmit={handleSubmit(this.handleLoginPhone)}>
                        <div className="item-modal">
                            {!isValidCode ? <Field className="input input-phone"
                                                   type="number"
                                                   name={'phone'}
                                                   placeholder={'Escribe tu número celular'}
                                                   component={inputTextField}
                            /> : <Field className="input input-phone"
                                        type="number"
                                        name={'code'}
                                        placeholder={'Escribe tu código'}
                                        component={inputTextField}
                            />}

                        </div>
                        <div className="button-container-modal1">
                            {(requesting || phone.requesting) ? <CircularProgress color="secondary"/> :
                                <Button data-color="green" color="secondary" type={'submit'}
                                        variant="contained">
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                        {isValidCode ? 'Validar código' : 'Obtener código'}
                                        <i className="icon-ok styles-icons"/>
                                    </div>
                                </Button>}

                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

const formed = reduxForm({
    form: 'ModalPhone',
})(ModalPhone);

const mapStateToProps = state => ({
    phone: state.phone,
});

const connected = connect(mapStateToProps, {
    phoneVerifyRequesting
})(formed);

export default connected;
