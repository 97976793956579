import {
    TIME_FOCUS,
    TIME_RESET_STATES,
    TIMES_GET_ERROR,
    TIMES_GET_REQUESTING,
    TIMES_GET_SUCCESS
} from "./constants";

export const timesGetRequesting = () => ({
    type: TIMES_GET_REQUESTING,
});

export const timesGetSuccess = (times) => ({
    type: TIMES_GET_SUCCESS,
    times,
});

export const timesGetError = (error) => ({
    type: TIMES_GET_ERROR,
    error,
});

export const timeFocus = (time) => ({
    type: TIME_FOCUS,
    time,
});

export const timeResetStates = () => ({
    type: TIME_RESET_STATES,
});