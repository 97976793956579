import {
    GET_TYPE_PROFESSIONAL_ERROR,
    GET_TYPE_PROFESSIONAL_REQUESTING,
    GET_TYPE_PROFESSIONAL_SUCCESS,
    VISIBLE_TYPE_PROFESSIONAL
} from "./constants";

export const getTypeProfessionalRequesting = () => ({
    type: GET_TYPE_PROFESSIONAL_REQUESTING
});

export const getTypeProfessionalSuccess = (typeProfessional) => ({
    type: GET_TYPE_PROFESSIONAL_SUCCESS,
    typeProfessional
});

export const getTypeProfessionalError = (error) => ({
    type: GET_TYPE_PROFESSIONAL_ERROR,
    error
});

export const visibleTypeProfessional = (typePeopleFocus) => ({
    type: VISIBLE_TYPE_PROFESSIONAL,
    typePeopleFocus
});