import {
    CREATE_USER_ERROR,
    CREATE_USER_REQUESTING,
    CREATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_REQUESTING,
    UPDATE_USER_SUCCESS,
    SHOW_MODAL_USER,
    ERROR_CONTROL_CREATE_USER,
    ERROR_CONTROL_UPDATE_USER,
    GET_USER_REQUESTING,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    FILTER_TYPE_PEOPLE_REQUESTING,
    FILTER_TYPE_PEOPLE_SUCCESS,
    FILTER_TYPE_PEOPLE_ERROR,
    SET_FOCUS_USER,
    SHOW_MODAL_CATALOG,
    SHOW_USER_REQUESTING,
    SHOW_USER_SUCCESS, SHOW_USER_ERROR,
} from "./constants";

export const createUserRequesting = (user) => ({
    type: CREATE_USER_REQUESTING,
    user
});

export const createUserSuccess = () => ({
    type: CREATE_USER_SUCCESS
});

export const createUserError = (error) => ({
    type: CREATE_USER_ERROR,
    error
});

export const updateUserRequesting = (values, token, user, typePeoples) => ({
    type: UPDATE_USER_REQUESTING,
    values,
    token,
    user,
    typePeoples
});

export const updateUserSuccess = () => ({
    type: UPDATE_USER_SUCCESS
});

export const updateUserError = (error) => ({
    type: UPDATE_USER_ERROR,
    error
});

export const showModalUser = (modal, phone = 0) => ({
    type: SHOW_MODAL_USER,
    modal,
    phone
});

export const errorControlCreateUser = () => ({
    type: ERROR_CONTROL_CREATE_USER
});
export const errorControlUpdateUser = () => ({
    type: ERROR_CONTROL_UPDATE_USER
});

export const getUserRequesting = (token) => ({
    type: GET_USER_REQUESTING,
    token
});

export const getUserSuccess = (users) => ({
    type: GET_USER_SUCCESS,
    users
});

export const getUserError = (error) => ({
    type: GET_USER_ERROR,
    error
});

export const filterTypePeopleRequesting = (typePeople, token) => ({
    type: FILTER_TYPE_PEOPLE_REQUESTING,
    typePeople,
    token
});

export const filterTypePeopleSuccess = (users) => ({
    type: FILTER_TYPE_PEOPLE_SUCCESS,
    users
});

export const filterTypePeopleError = (error) => ({
    type: FILTER_TYPE_PEOPLE_ERROR,
    error
});

export const setFocusUser = (id = 0, user = {}) => ({
    type: SET_FOCUS_USER,
    id,
    user
});

export const showModalCatalog = (modal) => ({
    type: SHOW_MODAL_CATALOG,
    modal
});

export const showUserRequesting = (id) => ({
    type: SHOW_USER_REQUESTING,
    id
});

export const showUserSuccess = (user) => ({
    type: SHOW_USER_SUCCESS,
    user
});

export const showUserError = (error) => ({
    type: SHOW_USER_ERROR,
    error
});
