import {createMuiTheme} from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";

export const materialTheme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                height: 40
            },
        },
        MuiInputLabel : {
            outlined : {
                transform: 'translate(10px, 12px) scale(1)'
            }
        }
    }
});
