import {all, call, put, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {toastr} from "react-redux-toastr";
import {
    publicationChangeStatePutError,
    publicationChangeStatePutSuccess,
    publicationClearForm,
    publicationCreatePostError,
    publicationCreatePostSuccess,
    publicationGetError,
    publicationGetSuccess,
    publicationHiddenModal, publicationPhotoDeleteError,
    publicationPhotoDeleteSuccess,
    publicationResetStates,
    publicationsGetError,
    publicationsGetSuccess,
    publicationsMePostError,
    publicationsMePostSuccess,
    publicationsOwnerUserPostError,
    publicationsOwnerUserPostSuccess,
    publicationsSearchPostError,
    publicationsSearchPostSuccess,
    publicationUpdatePutError,
    publicationUpdatePutSuccess,
    cleanPublication
} from "./actions";
import {
    PUBLICATION_CHANGE_STATE_PUT_REQUESTING,
    PUBLICATION_CREATE_POST_REQUESTING,
    PUBLICATION_GET_REQUESTING,
    PUBLICATION_PHOTO_DELETE_REQUESTING,
    PUBLICATION_UPDATE_PUT_REQUESTING,
    PUBLICATIONS_GET_REQUESTING,
    PUBLICATIONS_ME_POST_REQUESTING,
    PUBLICATIONS_OWNER_USER_POST_REQUESTING,
    PUBLICATIONS_SEARCH_POST_REQUESTING
} from "./constants";

const publicationURL = `${process.env.REACT_APP_API_URL}/publications`;
const photoUrl = `${process.env.REACT_APP_API_URL}/photos`;

const publicationsGetApi = (publicationsIds, values) => {
    let body = {
        publications: publicationsIds,
        city: values.ciudad,
        priceMin: values.prices.min,
        priceMax: values.prices.max,
        date: values.fecha,
        category: values.categoria,
        subcategory: values.subcategoria,
        column: values.column,
        orderBy: values.ordenar,
        typeModule : values.typeModule
    };
    return fetch(`${publicationURL}Index`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200 || json.data.data.length > 0)
                return json.data.data;
            throw json.data;

        }).catch((error) => {
            throw error;
        })
};

function* publicationsGetFlow(action) {
    try {
        const {publicationsIds, values} = action;
        const publications = yield call(publicationsGetApi, publicationsIds, values);
        yield put(publicationsGetSuccess(publications));
        yield put(publicationResetStates());
    } catch (error) {
        yield put(publicationsGetError(error));
    }
}

const publicationGetApi = (publication, token) => {
    return fetch(`${publicationURL}/${publication.id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;

        }).catch((error) => {
            throw error;
        })
};

function* publicationGetFlow(action) {
    try {
        const {publication, token} = action;
        const getPublication = yield call(publicationGetApi, publication, token);
        yield put(publicationGetSuccess(getPublication));
        yield put(publicationResetStates());
    } catch (error) {
        yield put(publicationGetError(error));
    }
}

const publicationsSearchPostApi = (search, cityId) => {
    let body = {
        search: search,
        city: cityId,
    };
    return fetch(`${publicationURL}/search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200 || json.data.data.length > 0)
                return json.data.data;
            throw json.data;

        }).catch((error) => {
            throw error;
        })
};

function* publicationsSearchPostFlow(action) {
    try {
        const {search, cityId} = action;
        const publications = yield call(publicationsSearchPostApi, search, cityId);
        yield put(publicationsSearchPostSuccess(publications));
        yield put(publicationResetStates());
    } catch (error) {
        yield put(publicationsSearchPostError(error));
    }
}

const publicationCreatePostApi = (token, values) => {
    let body = new FormData();
    body.append('name', values.name || '');
    body.append('price', values.hasOwnProperty('price') ? values.price.target.value : '');
    body.append('discountPrice', values.hasOwnProperty('discountPrice') ? values.discountPrice.target.value : 0);
    body.append('description', values.description || '');
    body.append('brand', values.brand || '');
    body.append('dimensions', values.dimensions || '');
    body.append('weight', values.weight || '');
    body.append('shippingValue', values.hasOwnProperty('shippingValue') ? values.shippingValue.target.value : 0);
    body.append('quantity', values.hasOwnProperty('quantity') ? values.quantity.target.value : 1);
    body.append('subcategory', values.subcategory || '');
    body.append('typeModule', values.typeModule || 1);
    if (values.hasOwnProperty('photos') && values.photos.length > 0) {
        values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('photos_length', values.photos.length || '');
    }
    if (values.hasOwnProperty('times') && values.times.length > 0) {
        values.times.map((time, index) => body.append(`time_${index}`, time));
        body.append('times_length', values.times.length || '');
    }

    return fetch(`${publicationURL}`, {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: body,
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* publicationCreatePostFlow(action) {
    try {
        const {token, values} = action;
        const publication = yield call(publicationCreatePostApi, token, values);
        toastr.success('Publicación creada', 'La publicación fue creada con exito.');
        yield put(publicationCreatePostSuccess(publication));
        yield put(publicationHiddenModal('createPublication'));
        yield put(publicationResetStates());
    } catch (error) {
        if (error === 'Debe iniciar sesión')
            toastr.error('Error', error);
        yield put(publicationCreatePostError(error));
    }
}

const publicationUpdatePutApi = (token, values) => {
    let body = new FormData();
    body.append('_method', 'PUT');
    body.append('name', values.nombre || '');
    // body.append('price', values.precio || '');
    // body.append('discountPrice', values.precio_descuento || '');
    body.append('price', values.precio.hasOwnProperty('target') ? values.precio.target.value : values.precio);
    body.append('discountPrice', values.precio_descuento.hasOwnProperty('target') ? values.precio_descuento.target.value : values.precio_descuento);
    body.append('description', values.descripcion || '');
    body.append('brand', values.marca || '');
    body.append('dimensions', values.dimensiones || '');
    body.append('weight', values.peso || '');
    // body.append('shippingValue', values.valor_envio || 0);
    body.append('shippingValue', values.valor_envio.hasOwnProperty('target') ? values.valor_envio.target.value : values.valor_envio);
    body.append('quantity', values.cantidad.hasOwnProperty('target') ? values.cantidad.target.value : values.cantidad);
    body.append('subcategory', values.subcategoria.id || values.subcategoria);
    body.append('typeModule', values.tipo_modulo.id || values.tipo_modulo);

    if (values.hasOwnProperty('photos') && values.photos.length > 0) {
        values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('photos_length', values.photos.length || '');
    }
    if (values.hasOwnProperty('times') && values.times.length > 0) {
        values.times.map((time, index) => body.append(`time_${index}`, time.id));
        body.append('times_length', values.times.length || '');
    }
    return fetch(`${publicationURL}/update/${values.id}`, {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: body,
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.data.code === 200 || json.data.data.hasOwnProperty('id'))
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* publicationUpdatePutFlow(action) {
    try {
        const {token, values} = action;
        const publication = yield call(publicationUpdatePutApi, token, values);
        toastr.success('Publicación actualizada', 'La publicación fue actualizada con exito.');
        yield put(publicationUpdatePutSuccess(publication));
        yield put(publicationClearForm());
        yield put(publicationResetStates());
        yield put(publicationHiddenModal('editPublication'));
    } catch (error) {
        yield put(publicationUpdatePutError(error));
    }
}

const publicationChangeStateApi = (token, publicationId) => {
    return fetch(`${publicationURL}/state/${publicationId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;

        }).catch((error) => {
            throw error;
        })
};

function* publicationChangeStateFlow(action) {
    try {
        const {token, publicationId} = action;
        const publication = yield call(publicationChangeStateApi, token, publicationId);
        toastr.success(`Publicación ${publication.estado.nombre}`, `La publicación fue ${publication.estado.nombre} con exito.`);
        yield put(publicationChangeStatePutSuccess(publication));
        yield put(publicationResetStates());
    } catch (error) {
        yield put(publicationChangeStatePutError(error));
    }
}

const publicationsOwnerUserPostApi = (userId, publicationsIds, paginate) => {
    let body = {
        publications: publicationsIds,
    };

    return fetch(`${publicationURL}/ownerUser/${userId}?page=${paginate}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200 || json.data.data.length > 0)
                return json.data.data;
            throw json.data;

        }).catch((error) => {
            throw error;
        })
};

function* publicationsOwnerUserPostFlow(action) {
    try {
        const {userId, publicationsIds, paginate} = action;
        const publications = yield call(publicationsOwnerUserPostApi, userId, publicationsIds, paginate);
        yield put(publicationsOwnerUserPostSuccess(publications));
    } catch (error) {
        yield put(publicationsOwnerUserPostError(error));
    }
}

const publicationsMePostApi = (token, publicationsIds, paginate) => {
    let body = {
        publications: publicationsIds,
    };
    return fetch(`${publicationURL}/ownerMe?page=${paginate}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200 || json.data.data.length > 0)
                return json.data.data;
            throw json.data;

        }).catch((error) => {
            throw error;
        })
};

function* publicationsMePostFlow(action) {
    try {
        const {token, publicationsIds, paginate} = action;
        const publications = yield call(publicationsMePostApi, token, publicationsIds, paginate);
        yield put(publicationsMePostSuccess(publications));
    } catch (error) {
        yield put(publicationsMePostError(error));
    }
}

const publicationPhotoDeleteApi = (token, photoId) => {
    return fetch(`${photoUrl}/${photoId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data.data;
            if (json.data.code === 200)
                return json.data.data;
            throw json.data
        }).catch((error) => {
            throw error;
        })
};

function* publicationPhotoDeleteFlow(action) {
    try {
        const {token, photoId} = action;
        const message = yield call(publicationPhotoDeleteApi, token, photoId);
        yield put(publicationPhotoDeleteSuccess(message));
        toastr.success('Foto eliminada', message);
        yield put(publicationResetStates());
    } catch (error) {
        yield put(publicationPhotoDeleteError(error));
    }
}

function* publicationWatcher() {
    yield all([
        takeEvery(PUBLICATIONS_GET_REQUESTING, publicationsGetFlow),
        takeEvery(PUBLICATION_GET_REQUESTING, publicationGetFlow),
        takeEvery(PUBLICATIONS_SEARCH_POST_REQUESTING, publicationsSearchPostFlow),
        takeEvery(PUBLICATION_CREATE_POST_REQUESTING, publicationCreatePostFlow),
        takeEvery(PUBLICATION_UPDATE_PUT_REQUESTING, publicationUpdatePutFlow),
        takeEvery(PUBLICATION_CHANGE_STATE_PUT_REQUESTING, publicationChangeStateFlow),
        takeEvery(PUBLICATIONS_OWNER_USER_POST_REQUESTING, publicationsOwnerUserPostFlow),
        takeEvery(PUBLICATIONS_ME_POST_REQUESTING, publicationsMePostFlow),
        takeEvery(PUBLICATION_PHOTO_DELETE_REQUESTING, publicationPhotoDeleteFlow),
    ])
}

export default publicationWatcher;
