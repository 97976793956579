import React, {Component} from 'react';

import {connect} from "react-redux";
import {change, Field, Form, reduxForm, reset} from "redux-form";
import {MessageList} from 'react-chat-elements'
import {
    changeStateToSoldRequesting,
    createMessageRequesting,
    focusChat,
    getListMessagingRequesting,
    getMessageRequesting
} from "../../../../redux/chat/actions";
import {actualPage, filterChangePrimaryProfile} from "../../../../redux/iu/actions";
import lottie from "lottie-web";
import {COLORS} from "../../../../theme/colors";
import CircularProgress from '@material-ui/core/CircularProgress';
import {controlModalReview} from "../../../../redux/review/actions";
import ReviewModal from './../../modals/review';
import {Button, Input} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import 'react-chat-elements/dist/main.css';

const inputTextField = ({input, name, placeholder, label, type, id, ref, meta: {touched, error}, ...rest}) => {
    return (
        <Input
            {...input}
            {...rest}
            style={{width: '70%'}}
            inputProps={{
                'aria-label': 'description',
            }}
            placeholder={'Escribe tu mensaje'}
        />
    )
};

class PageChat extends Component {

    componentDidMount() {
        this.setState({
            isVisible: true
        });
        lottie.loadAnimation({
            container: this.animation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/review.json'
        });
        this.message = React.createRef();
    }


    constructor(props) {
        super(props);
        const {
            // client: {token},
            getListMessagingRequesting,
            actualPage,
            filterChangePrimaryProfile,
        } = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        getListMessagingRequesting(token);
        filterChangePrimaryProfile('Mensajes');
        this.animation = React.createRef();
    }

    state = {
        isVisible: false,
    };

    handleFocus = (chat) => {
        const {
            focusChat,
        } = this.props;
        focusChat(!chat.es_comprador ? chat.comprador.nombre : chat.pagador.nombre, chat, !chat.es_comprador ? chat.comprador.id : chat.pagador.id, chat.producto.nombre, chat.producto.foto, chat.comprador.id, chat.pagador.id, chat.calificacion);

        this.handleGetMessages(chat);
        this.animation = React.createRef();
    };

    handleGetMessages = (chat) => {
        const {
            // client: {token},
            chat: {cursor, focus},
            getMessageRequesting,
        } = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        getMessageRequesting(chat.id, token, 1);

    };

    handleMessages = () => {
        const {
            client: {token},
            chat: {cursor, focus},
            getMessageRequesting,
        } = this.props;
        getMessageRequesting(focus, token, cursor + 1);
    };

    handleSendMessage = (values) => {
        const {
            // client: {token},
            chat: {focus, to},
            createMessageRequesting,
            change
        } = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        createMessageRequesting(focus, token, to, values.message);
        change('roomChat', 'message', '');
        // console.log(this.refs.input.ref.current.props._reduxForm.change('message', ''))
        // this.props.resetForm();
        // this.message.ref.current.clear();
        // this.props.reset();
        // this.message.clear();
    };


    render() {
        const {
            handleSubmit,
            changeStateToSoldRequesting,
            chat: {listMessaging, titleChat, focus, messages, titleProduct, cursor, photoProduct, soldFocus, requesting, userBuyer, userPayer, score},
            iu: {url},
            client: {token, client},
            controlModalReview
        } = this.props;
        return (
            <div className="container-item-start">
                <div className="line"/>
                <div className="container-sub-text">
                    <p>Aquí podrás encontrar los mensajes</p>
                </div>
                {listMessaging.length === 0 &&
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="container-animations-empty-one">
                        <div className={'animation'} ref={this.animation}/>
                    </div>
                    <p className="p-animation-empty-one" style={{fontSize: 22, color: 'rgba(0,0,0,.3)'}}>No cuentas con
                        mensajes ...</p>
                </div>
                }


                <div className="chat-list">
                    {
                        listMessaging.length > 0 && listMessaging.map((chat, index) => {
                            let comparator = !chat.es_comprador ? chat.comprador.nombre : chat.pagador.nombre;
                            return (
                                <div key={index} onClick={() => this.handleFocus(chat)}
                                     className={"container-profile-product"}
                                     style={focus === chat.id ? {
                                         backgroundColor: '#fff159',
                                         display: 'flex',
                                         flexWrap: 'nowrap',
                                         padding: '5px'
                                     } : {
                                         backgroundColor: 'white',
                                         display: 'flex',
                                         flexWrap: 'nowrap',
                                         padding: '5px'
                                     }}
                                >
                                    <div className="chat-item1">
                                        <img src={chat.producto.foto}
                                             alt={comparator}/>
                                    </div>
                                    <div className="container-sub-text" style={{margin: '0 20px'}}>
                                        <h2 style={{fontSize: '12px', width : '110px'}}>{chat.producto.nombre}</h2>
                                        <p style={{
                                            fontSize: '12px'
                                        }}>{chat.ultimo_mensaje && chat.ultimo_mensaje.length > comparator.length ? `${chat.ultimo_mensaje.substring(0, comparator.length)}...` : chat.ultimo_mensaje}</p>
                                    </div>
                                </div>
                            );
                        })}
                </div>

                {focus ? (
                    <div className='container' style={{marginTop: 10, height: 500}}>
                        <div className='right-panel'>
                            {messages.length >= 20 && (
                                <div style={{position: 'absolute', zIndex: 1, top: 10, left: '40%'}}>
                                    <Button variant="outlined" color="secondary" onClick={() => this.handleMessages()}
                                            style={{color: COLORS.PRIMARY, border: '1px solid ' + COLORS.PRIMARY}}>
                                        Anteriores
                                    </Button>
                                </div>

                            )}
                            <MessageList
                                className='message-list content-chat'
                                lockable={true}
                                downButtonBadge={10}
                                dataSource={messages}/>
                            {score !== 'calificado' && (
                                <div>
                                    <Form onSubmit={handleSubmit(this.handleSendMessage)}
                                          style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                        <Field name={'message'} type={'text'} placeholder={'Escribe tu mensaje'}
                                               ref={this.message}
                                               component={inputTextField}/>
                                        <Button
                                            type={'submit'}
                                            variant="contained"
                                            style={{backgroundColor: COLORS.PRIMARY}}
                                            endIcon={<Icon>send</Icon>}
                                        >
                                            Enviar
                                        </Button>
                                        {/*{!requesting && !soldFocus && userBuyer !== userPayer &&*/}
                                        {/* <Button*/}
                                        {/*    onClick={() => controlModalReview()}*/}
                                        {/*    variant="contained"*/}
                                        {/*    disabled={requesting}*/}
                                        {/*    style={{*/}
                                        {/*        color: 'white',*/}
                                        {/*        border: '1px solid ' + COLORS.SECONDARY,*/}
                                        {/*        backgroundColor: COLORS.SECONDARY*/}
                                        {/*    }}>*/}
                                        {/*    Calificar*/}
                                        {/*</Button>}*/}
                                        {!requesting && soldFocus &&

                                        <Button
                                            onClick={() => controlModalReview()}
                                            variant="contained"
                                            disabled={requesting}
                                            style={{
                                                color: 'white',
                                                border: '1px solid ' + COLORS.SECONDARY,
                                                backgroundColor: COLORS.SECONDARY
                                            }}>
                                            Calificar
                                        </Button>}
                                        {!requesting && !soldFocus && client.id === userPayer &&
                                        <Button
                                            onClick={() => changeStateToSoldRequesting(token, focus)}
                                            variant="contained"
                                            disabled={requesting}
                                            style={{
                                                color: 'white',
                                                border: '1px solid ' + COLORS.SECONDARY,
                                                backgroundColor: COLORS.SECONDARY
                                            }}>
                                            {requesting && (<CircularProgress style={{color: 'white'}} size={22}/>)}
                                            Vendido
                                        </Button>}

                                    </Form>
                                </div>
                            )}

                        </div>
                    </div>
                ) : null}
                <ReviewModal/>
            </div>
        );
    }
}

const afterSubmit = (result, dispatch) => {
    // console.log(result);
    // dispatch(change('roomChat','message',''));
    dispatch(reset('roomChat'));
};

const formed = reduxForm({
    form: 'roomChat',
    onSubmitSuccess: afterSubmit,
})(PageChat);

const mapStateToProps = state => ({
    client: state.client,
    chat: state.chat,
    iu: state.iu,
});

const connected = connect(mapStateToProps, {
    getListMessagingRequesting,
    focusChat,
    getMessageRequesting,
    createMessageRequesting,
    actualPage,
    filterChangePrimaryProfile,
    changeStateToSoldRequesting,
    controlModalReview,
    change
})(formed);

export default connected;
