import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {getGenderError, getGenderSuccess} from "./actions";
import {GET_GENDER_REQUESTING} from "./constants";

const GENDER_URL = `${process.env.REACT_APP_API_URL}/genders`;


const getCityAPI = () => {

    return fetch(GENDER_URL, {
        method: 'GET'
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getGenderFlow(action) {
    try{
        const {} = action;
        const genders = yield call(getCityAPI);
        yield put(getGenderSuccess(genders));
    }catch (error) {
        yield put(getGenderError(error));
    }
}

function* genderWatcher() {
    yield all([
        takeEvery(GET_GENDER_REQUESTING, getGenderFlow)
    ])
}

export default genderWatcher;