export const GET_LIST_MESSAGING_REQUESTING = 'GET_LIST_MESSAGING_REQUESTING';
export const GET_LIST_MESSAGING_SUCCESS = 'GET_LIST_MESSAGING_SUCCESS';
export const GET_LIST_MESSAGING_ERROR = 'GET_LIST_MESSAGING_ERROR';

export const GET_MESSAGES_REQUESTING = 'GET_MESSAGES_REQUESTING';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';


export const CREATE_MESSAGE_REQUESTING = 'CREATE_MESSAGE_REQUESTING';
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';

export const CREATE_ROOM_CHAT_REQUESTING = 'CREATE_ROOM_CHAT_REQUESTING';
export const CREATE_ROOM_CHAT_SUCCESS = 'CREATE_ROOM_CHAT_SUCCESS';
export const CREATE_ROOM_CHAT_ERROR = 'CREATE_ROOM_CHAT_ERROR';


export const FOCUS_CHAT = 'FOCUS_CHAT';

export const ENTRY_MESSAGE = 'ENTRY_MESSAGE';

export const CONNECT_TO_SOCKET = 'CONNECT_TO_SOCKET';

export const CHANGE_STATE_TO_SOLD_REQUESTING = 'CHANGE_STATE_TO_SOLD_REQUESTING';
export const CHANGE_STATE_TO_SOLD_SUCCESS = 'CHANGE_STATE_TO_SOLD_SUCCESS';
export const CHANGE_STATE_TO_SOLD_ERROR = 'CHANGE_STATE_TO_SOLD_ERROR';
