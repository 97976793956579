import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {PaymentInputsContainer, PaymentInputsWrapper} from 'react-payment-inputs';
import InputLabel from '@material-ui/core/InputLabel';
//input
// payment component
import images from 'react-payment-inputs/images';
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from "@material-ui/core/NativeSelect";

const TYPES_IDENTIFICATION = [
    {value: 'CC', item: 'Cédula de ciudadanía'}, {value: 'CE', item: 'Cédula de extranjería'},
    {value: 'NIT', item: 'Número de Identificación Tributario'}, {value: 'TI', item: 'Tarjeta de Identidad'},
    {value: 'PP', item: 'Pasaporte'}, {
        value: 'IDC',
        item: 'Identificador único de cliente, para el caso de ID’s únicos de clientes/usuarios de servicios públicos'
    },
    {value: 'CEL', item: 'En caso de identificarse a través de la línea del móvil'},
    {value: 'RC', item: 'Registro civil de nacimiento'},
    {value: 'DE', item: 'Documento de identificación extranjero'}
];


const USER_TYPES = [{value: 'N', item: 'Persona natural'}, {value: 'J', item: 'Persona jurídica'}];

export class SubscriptionPaymentModalComponent extends React.Component {


    state = {
        typeFranchise: '',
        typePayment: 'cash',
        bank: '',
        PSE_REFERENCE2: '',
        USER_TYPE: '',
        PSE_REFERENCE3: '',
        contactPhone: '',
        name: '',
        phone: '',
        email: '',
        identification: '',
        creditCard: {
            number: '',
            expiry: '',
            cvc: '',
            type: '',
            name: ''
        }
    };

    componentDidMount() {
        const {client: {client}} = this.props;
        this.setState({
            email: client.correo,
            identification: client.identificacion,
            phone: client.telefono,
            name: client.nombre
        })
    }

    handlePaySubscriptionCardCredit = () => {
        const {
            subscription,
            handlePaySubscriptionCardCredit,
            client: {client, token},
            payments,
            handlePaySubscriptionCash,
            handlePayPse
        } = this.props;
        if (this.state.typePayment === 'targetCredit') {
            let pay = payments.filter(payment => (payment.nombre === 'Tarjeta de crédito'));
            handlePaySubscriptionCardCredit(token, this.state.creditCard, subscription.precio, client.id, subscription.id, pay[0].id);
        }
        if (this.state.typePayment === 'typePayment') {
            let pay = payments.filter(payment => (payment.nombre === 'Efectivo'));
            handlePaySubscriptionCash(token, this.state.typeFranchise, subscription.precio, client.id, subscription.id, pay[0].id);
        }

        if (this.state.typePayment === 'pse') {
            let pay = payments.filter(payment => (payment.nombre === 'PSE'));
            let values = {
                name: this.state.name,
                client: this.state.USER_TYPE,
                typeDocument: this.state.PSE_REFERENCE2,
                phone: this.state.phone,
                bank: this.state.bank,
                document: this.state.identification,
                email: this.state.email,
                total: subscription.precio,
                payment_id: pay[0].id,
                subscription_id: subscription.id
            };
            handlePayPse(token, values);
        }
    };

    handleRedirectToBank = () => {
        const {response} = this.props;
        window.open(response.transactionResponse.extraParameters.BANK_URL, '_blank');
    };

    render() {
        const {handleControl, visible, subscription, banks, requesting, response, clearPSE} = this.props;
        return (
            <Dialog
                onClose={() => handleControl('')} aria-labelledby="customized-dialog-title"
                open={visible}>
                <DialogTitle id="customized-dialog-title">
                    Metodo de pago
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={() => handleControl('')}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="container-subscription-payment container-text">
                        <div className="line"/>
                        <div className="subscription-payment-item1">
                            <div onClick={() => {
                                this.setState({typePayment: 'cash'});
                                clearPSE();
                            }}
                                 className={this.state.typePayment === 'cash' ? "payment-filter payment-filter-active" : "payment-filter"}>
                                <h1>Efectivo</h1>
                                <div className="line"/>
                                <img style={{width: 260, height: 60}}
                                     src={'https://ecommerce.payulatam.com/logos/co-efectivo.png'} alt={'PayU'}/>
                                <br/>
                            </div>
                            <div onClick={() => this.setState({typePayment: 'pse'})}
                                 className={this.state.typePayment === 'pse' ? "payment-filter payment-filter-active" : "payment-filter"}>
                                <h1>PSE</h1>
                                <div className="line"/>
                                <img style={{width: 60, height: 60}}
                                     src={require('./../../../assets/Modal/pse.jpeg')} alt={'PayU'}/>
                                <br/>
                            </div>
                        </div>
                        <div onClick={() => {
                            this.setState({typePayment: 'targetCredit'});
                            clearPSE();
                        }}
                             className={this.state.typePayment === 'targetCredit' ? "payment-filter payment-filter-active" : "payment-filter"}>
                            <h1>Tarjeta de credito </h1>
                            <div className="line"/>
                            <img style={{width: '70%', height: 60}}
                                 src={'https://ecommerce.payulatam.com/logos/co-credito.png'} alt={'PayU'}/>
                            <br/>
                        </div>
                        <div className="subscription-payment-item2">
                            <h1>Precio total</h1>
                            <h2>${subscription.precio}</h2>
                            {this.state.typePayment === 'targetCredit' && <PaymentInputsContainer
                                errorMessages={{
                                    emptyCardNumber: 'El número de la tarjeta es inválido',
                                    invalidCardNumber: 'El número de la tarjeta es inválido',
                                    emptyExpiryDate: 'La fecha de expiración es inválida',
                                    monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
                                    yearOutOfRange: 'El año de expiración no puede estar en el pasado',
                                    dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
                                    invalidExpiryDate: 'La fecha de expiración es inválida',
                                    emptyCVC: 'El código de seguridad es inválido',
                                    invalidCVC: 'El código de seguridad es inválido'
                                }}
                            >
                                {({getCardNumberProps, getExpiryDateProps, getCardImageProps, getCVCProps, wrapperProps, meta}) => (
                                    <PaymentInputsWrapper {...wrapperProps}>
                                        <svg {...getCardImageProps({images})} />
                                        <input {...getCardNumberProps({
                                            onChange: e =>
                                                this.setState({
                                                    creditCard: {
                                                        ...this.state.creditCard,
                                                        number: e.target.value,
                                                        type: meta.cardType
                                                    }
                                                })
                                        })}
                                               placeholder="Tarjeta de credito"
                                        />

                                        <input onChange={(e) => this.setState({
                                            creditCard: {
                                                ...this.state.creditCard,
                                                name: e.target.value
                                            }
                                        })}
                                               placeholder="Nombre"
                                        />

                                        <input {...getExpiryDateProps({
                                            onChange: e =>
                                                this.setState({
                                                    creditCard: {
                                                        ...this.state.creditCard,
                                                        expiry: e.target.value
                                                    }
                                                })
                                        })} />
                                        <input {...getCVCProps({
                                            onChange: e =>
                                                this.setState({
                                                    creditCard: {
                                                        ...this.state.creditCard,
                                                        cvc: e.target.value
                                                    }
                                                })
                                        })} />
                                    </PaymentInputsWrapper>
                                )}
                            </PaymentInputsContainer>}
                            {this.state.typePayment === 'cash' && <>
                                <InputLabel shrink id="select-label-placeholder">Opciones de pago</InputLabel>
                                <NativeSelect
                                    labelId="select-label-placeholder"
                                    id="select-payments"
                                    value={this.state.typeFranchise ? this.state.typeFranchise : 'Opciones de pago'}
                                    style={{color: this.state.typeFranchise ? '#000' : 'gray'}}
                                    onChange={e => this.setState({
                                        typeFranchise: e.target.value
                                    })}
                                >
                                    <option value={'Opciones de pago'} style={{'display': 'none'}}>Opciones de
                                        pago
                                    </option>
                                    <option value={'BALOTO'}>Baloto</option>
                                    <option value={'EFECTY'}>Efecty</option>
                                    <option value={'BANK_REFERENCED'}>Pagar en banco</option>
                                    <option value={'OTHERS_CASH'}>Otros puntos de pago</option>
                                </NativeSelect>
                            </>}
                            {this.state.typePayment === 'pse' && <div>
                                <InputLabel shrink id="select-label-placeholder">Bancos</InputLabel>
                                <NativeSelect
                                    labelId="select-label-placeholder"
                                    id="select-payments"
                                    value={this.state.bank ? this.state.bank : 'Seleccione banco'}
                                    style={{color: this.state.typeFranchise ? '#000' : 'gray', marginBottom: '1.2rem'}}
                                    onChange={e => this.setState({
                                        bank: e.target.value
                                    })}
                                >
                                    {banks.map(bank => <option
                                        style={{width: '100%'}}
                                        value={bank.pseCode}>{bank.description}</option>)}

                                </NativeSelect>
                                <InputLabel shrink id="select-label-placeholder">Nombre del titular</InputLabel>
                                <InputBase inputProps={{value: this.state.name}}
                                           onChange={(e) => this.setState({name: e.target.value})}
                                           style={{marginBottom: '1.2rem', borderBottom: '1px solid #cececece'}}
                                           placeholder="Nombre del titular"/>

                                <InputLabel shrink id="select-label-placeholder">Tipo de identificación</InputLabel>
                                <NativeSelect
                                    labelId="select-label-placeholder"
                                    id="select-payments"
                                    value={this.state.PSE_REFERENCE2 ? this.state.PSE_REFERENCE2 : 'Seleccione tipo de identificación'}
                                    style={{color: this.state.PSE_REFERENCE2 ? '#000' : 'gray', marginBottom: '1.2rem'}}
                                    onChange={e => this.setState({
                                        PSE_REFERENCE2: e.target.value
                                    })}
                                >
                                    <option
                                        style={{width: '100%'}}
                                        value={'type'}>Seleccione tipo de identificación
                                    </option>
                                    {TYPES_IDENTIFICATION.map(bank => <option
                                        style={{width: '100%'}}
                                        value={bank.value}>{bank.item}</option>)}

                                </NativeSelect>

                                <InputLabel shrink id="select-label-placeholder">Identificación</InputLabel>
                                <InputBase
                                    value={this.state.identification}
                                    onChange={(e) => this.setState({identification: e.target.value})}
                                    style={{marginBottom: '1.2rem', borderBottom: '1px solid #cececece'}}
                                    placeholder="Identificación"/>

                                <InputLabel shrink id="select-label-placeholder">Tipo de cliente</InputLabel>
                                <NativeSelect
                                    labelId="select-label-placeholder"
                                    id="select-payments"
                                    value={this.state.USER_TYPE ? this.state.USER_TYPE : 'Seleccione tipo de cliente'}
                                    style={{color: this.state.USER_TYPE ? '#000' : 'gray', marginBottom: '1.2rem'}}
                                    onChange={e => this.setState({
                                        USER_TYPE: e.target.value
                                    })}
                                >
                                    <option
                                        style={{width: '100%'}}
                                        value={'Seleccione tipo de cliente'}>Seleccione tipo de cliente
                                    </option>
                                    {USER_TYPES.map(user => <option
                                        style={{width: '100%'}}
                                        value={user.value}>{user.item}</option>)}
                                </NativeSelect>

                                <InputLabel shrink id="select-label-placeholder">Telefono</InputLabel>
                                <InputBase
                                    value={this.state.phone}
                                    onChange={(e) => this.setState({phone: e.target.value})}
                                    style={{marginBottom: '1.2rem', borderBottom: '1px solid #cececece'}}
                                    placeholder="Telefono"/>

                            </div>}

                            {requesting ? <CircularProgress
                                color={'primary'}/> : <Button
                                onClick={() => (response.hasOwnProperty('transactionResponse') && response.transactionResponse.state === 'PENDING') ? this.handleRedirectToBank() : this.handlePaySubscriptionCardCredit()}
                                variant="contained" color="secondary">
                                {(response.hasOwnProperty('transactionResponse') && response.transactionResponse.state === 'PENDING') ? 'Ir la banco' : 'Comprar'}
                            </Button>}

                            <br/>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}

