import {
    CATEGORIES_GET_ERROR,
    CATEGORIES_GET_REQUESTING,
    CATEGORIES_GET_SUCCESS, CATEGORIES_MODULE_GET_ERROR,
    CATEGORIES_MODULE_GET_REQUESTING,
    CATEGORIES_MODULE_GET_SUCCESS,
    CATEGORY_FOCUS,
    CATEGORY_HIDDEN_MODAL,
    CATEGORY_RESET_STATES,
    CATEGORY_SHOW_MODAL, MODULE_TYPE_FOCUS,
    SUBCATEGORY_FOCUS,
    TYPE_MODULE_FOCUS
} from "./constants";

export const categoriesGetRequesting = () => ({
    type: CATEGORIES_GET_REQUESTING,
});

export const categoriesGetSuccess = (categories) => ({
    type: CATEGORIES_GET_SUCCESS,
    categories,
});

export const categoriesGetError = (error) => ({
    type: CATEGORIES_GET_ERROR,
    error,
});

export const moduleFocus = (typeModule) => ({
    type: TYPE_MODULE_FOCUS,
    typeModule,
});

export const categoriesModuleGetRequesting = (moduleId) => ({
    type: CATEGORIES_MODULE_GET_REQUESTING,
    moduleId,
});

export const categoriesModuleGetSuccess = (categories) => ({
    type: CATEGORIES_MODULE_GET_SUCCESS,
    categories,
});

export const categoriesModuleGetError = (error) => ({
    type: CATEGORIES_MODULE_GET_ERROR,
    error,
});

export const categoryFocus = (category) => ({
    type: CATEGORY_FOCUS,
    category,
});

export const subcategoryFocus = (subcategory) => ({
    type: SUBCATEGORY_FOCUS,
    subcategory,
});

export const moduleTypeFocus = (moduleType) => ({
    type: MODULE_TYPE_FOCUS,
    moduleType
});

export const categoryShowModal = (modal) => ({
    type: CATEGORY_SHOW_MODAL,
    modal,
});

export const categoryHiddenModal = (modal) => ({
    type: CATEGORY_HIDDEN_MODAL,
    modal,
});

export const categoryResetStates = () => ({
    type: CATEGORY_RESET_STATES,
});

