import {call, put, all, takeEvery} from 'redux-saga/effects'
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    paySubscriptionCreditCardError,
    paySubscriptionCreditCardSuccess,
    paySubscriptionCashSuccess,
    paySubscriptionCashError,
    getPaymentError,
    getPaymentSuccess, showModalPayment, getListBanksSuccess, getListBanksError, paymentPseError, paymentPseSuccess
} from "./actions";
import {toastr} from "react-redux-toastr";
import {createSubscriptionUserRequesting} from "../subscription/user/actions";

import {
    PAY_SUBSCRIPTION_CREDIT_CARD_REQUESTING,
    PAY_SUBSCRIPTION_CASH_REQUESTING,
    GET_PAYMENT_REQUESTING, GET_LIST_BANKS_REQUESTING, PAY_SUBSCRIPTION_PSE_REQUESTING
} from "./constants";
import {showModalSubscription} from "../subscription/actions";

const PAYMENT_URL = `${process.env.REACT_APP_API_URL}/paymentMethods`;
const PAY_CREDIT_CARD_URL = `${process.env.REACT_APP_API_URL}/creditCardPayment`;
const PAY_CASH_URL = `${process.env.REACT_APP_API_URL}/cashPayment`;
const BANKS_URL = `${process.env.REACT_APP_API_URL}/getBanks`;
const PAY_URL = `${process.env.REACT_APP_API_URL}/pay/pse`;

const postPseAPI = (token, values) => {
    return fetch(PAY_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(values)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422) {
                toastr.error('Suscripción', 'Ingresa todos los datos del formulario');
                throw json.data;
            }
            if (json.code === 400) {
                toastr.error('Suscripción', json.data.data);
                throw json.data;
            }
            if (json.code === 200) {
                toastr.info('Suscripción', 'Se ha generado un link de pago, da click en "Ir al banco"');
                return json.data.data;
            }

        }).catch((error) => {
            throw error;
        })
};

function* postPseFlow(action) {
    try {
        const {token, values} = action;
        const response = yield call(postPseAPI, token, values);
        yield put(paymentPseSuccess(response));
    } catch (error) {
        yield put(paymentPseError(error));
    }
}


const getBanksAPI = () => {
    return fetch(BANKS_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.banks.length > 0)
                return json.data.data.banks;
            throw []
        }).catch((error) => {
            throw error;
        })
};

function* getBanksFlow(action) {
    try {
        const banks = yield call(getBanksAPI);
        yield put(getListBanksSuccess(banks));
    } catch (error) {
        yield put(getListBanksError(error));
    }
}


const getPaymentAPI = (token) => {
    return fetch(PAYMENT_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw []
        }).catch((error) => {
            throw error;
        })
};

function* getPaymentFlow(action) {
    try {
        const {token} = action;
        const payments = yield call(getPaymentAPI, token);
        yield put(getPaymentSuccess(payments));
    } catch (error) {
        yield put(getPaymentError(error));
    }
}

const paySubscriptionCashApi = (token, franchise, total, user, subscription, payment) => {

    let body = {
        'franchise': franchise,
        'price': total,
        'subscription_id': subscription,
        'payment_id': payment
    };

    return fetch(PAY_CASH_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            throw ''
        }).catch((error) => {
            throw error;
        })
};


function* paySubscriptionCashFlow(action) {
    try {
        const {token, franchise, total, user, subscription, payment} = action;
        const payCash = yield call(paySubscriptionCashApi, token, franchise, total, user, subscription, payment);
        yield put(paySubscriptionCashSuccess(payCash));
        toastr.info('Suscripción', 'Se ha enviado el recibo de pago a tu correo electrónico');
        yield put(showModalPayment(''));
        yield put(showModalSubscription(''));
        // if(payCash.hasOwnProperty('transactionResponse')){
        //     if(payCash.error===null && payCash.transactionResponse.responseCode==='PENDING_TRANSACTION_CONFIRMATION')
        //         yield put(createSubscriptionUserRequesting(user,subscription,payment,token,payCash.transactionResponse.responseCode,payCash.transactionResponse.orderId));
        //     else
        //         toastr.error('Ups! hubo un error', 'Datos de la tarjeta incorrectos');
        // }
    } catch (error) {
        yield put(paySubscriptionCashError(error));
    }
}


const paySubscriptionCreditCardApi = (token, dataCreditCard, total, user, subscription, payment) => {

    let date = dataCreditCard.expiry ? dataCreditCard.expiry.split('/') : '';
    let month = date ? date[0].replace(/\s+/g, '') : '';
    let year = date ? ' 20' + date[1].replace(/\s+/g, '') : '';
    let type = dataCreditCard.type.type ? (dataCreditCard.type.type).toUpperCase() : '';

    let body = {
        'number': dataCreditCard.number ? dataCreditCard.number.replace(/\s+/g, '') : '',
        'exp_year': year ? year : '',
        'exp_month': month ? month : '',
        'cvc': dataCreditCard.cvc ? dataCreditCard.cvc : '',
        'price': total ? total : '',
        'type': type ? type : '',
        'name': dataCreditCard.name,
        'subscription_id': subscription,
        'payment_id': payment
    };

    return fetch(PAY_CREDIT_CARD_URL, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422) {
                toastr.error('Suscripción', 'Ingresa todos los datos');
                throw json.data;
            }
            if (json.code === 400) {
                toastr.error('Suscripción', json.data);
                throw json.data;
            }
            if (json.code === 200)
                return json.data.data;
            throw ''
        }).catch((error) => {
            throw error;
        })
};

function* paySubscriptionCreditCardFlow(action) {
    try {
        const {token, dataCreditCard, total, user, subscription, payment} = action;
        const pay = yield call(paySubscriptionCreditCardApi, token, dataCreditCard, total, user, subscription, payment);
        yield put(paySubscriptionCreditCardSuccess(pay));
        // if(pay.hasOwnProperty('transactionResponse')){
        //     if(pay.error===null && pay.transactionResponse.responseCode==='APPROVED')
        //         yield put(createSubscriptionUserRequesting(user,subscription,payment,token,pay.transactionResponse.responseCode,pay.transactionResponse.orderId));
        //     else
        //         toastr.error('Ups! hubo un error', 'Datos de la tarjeta incorrectos');
        // }
        toastr.warning('Suscripcion', 'Estamos validando la transacción en breve PayU enviara un correo con el estado de su transacción');
        yield put(showModalPayment(''));
        yield put(showModalSubscription(''));
    } catch (error) {
        yield put(paySubscriptionCreditCardError(error));
        // DropDownHolder.alert('error', 'Ups! hubo un error', 'Al parecer hubo un error, por favor intente de nuevo');
    }
}

function* paymentWatcher() {
    yield all([
        takeEvery(PAY_SUBSCRIPTION_CREDIT_CARD_REQUESTING, paySubscriptionCreditCardFlow),
        takeEvery(PAY_SUBSCRIPTION_CASH_REQUESTING, paySubscriptionCashFlow),
        takeEvery(GET_PAYMENT_REQUESTING, getPaymentFlow),
        takeEvery(GET_LIST_BANKS_REQUESTING, getBanksFlow),
        takeEvery(PAY_SUBSCRIPTION_PSE_REQUESTING, postPseFlow)
    ]);
}

export default paymentWatcher;
