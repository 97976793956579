import {
    GET_SUBSCRIPTION_USER_ERROR,
    GET_SUBSCRIPTION_USER_REQUESTING,
    GET_SUBSCRIPTION_USER_SUCCESS,
    CREATE_SUBSCRIPTION_USER_ERROR,
    CREATE_SUBSCRIPTION_USER_REQUESTING,
    CREATE_SUBSCRIPTION_USER_SUCCESS,
} from './constants';

const initialState = {
    requesting: false,
    success: false,
    error: '',
    userSubscriptions: [],
    userSubscription :{}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTION_USER_REQUESTING:
            return {
                ...state,
                error: '',
                success: false,
                requesting: true
            };
        case GET_SUBSCRIPTION_USER_SUCCESS:
            return {
                ...state,
                userSubscriptions: action.subscriptions,
                error: '',
                success: true,
                requesting: false
            };
        case GET_SUBSCRIPTION_USER_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                userSubscriptions: []
            };
        case CREATE_SUBSCRIPTION_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case CREATE_SUBSCRIPTION_USER_SUCCESS:
            return {
                ...state,
                userSubscription: action.subscription,
                requesting: false,
                success: true,
                error: ''
            };
        case CREATE_SUBSCRIPTION_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };

        default :
            return state;
    }
};

export default reducer;
