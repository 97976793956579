import {
    CONTROL_MODAL_REVIEW,
    CREATE_REVIEW_ERROR,
    CREATE_REVIEW_REQUESTING,
    CREATE_REVIEW_SUCCESS,
    GET_REVIEW_ERROR,
    GET_REVIEW_REQUESTING,
    GET_REVIEW_SUCCESS, GET_SEND_REVIEW_SUCCESS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    reviews: [],
    modal: false,
    reviewsSend : []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REVIEW_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_REVIEW_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                reviews: action.reviews
            };
        case GET_REVIEW_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                reviews: []
            };
        case CONTROL_MODAL_REVIEW :
            return {
                ...state,
                modal: !state.modal
            };
        case  CREATE_REVIEW_REQUESTING:
            return {
                ...state,
                requesting: true
            };

        case CREATE_REVIEW_SUCCESS :
            return {
                ...state,
                requesting: false,
                modal: false
            };

        case CREATE_REVIEW_ERROR :
            return {
                ...state,
                requesting: false
            };
        case GET_SEND_REVIEW_SUCCESS:
            return {
              ...state,
                reviewsSend : action.reviews
            };
        default:
            return state;
    }
};

export default reducer;