export const LOGIN_REQUESTING = 'LOGIN_REQUESTING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_REQUESTING = 'LOGOUT_REQUESTING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const ERROR_CONTROL_LOGIN='ERROR_CONTROL_LOGIN';

export const LOGIN_FACEBOOK_REQUESTING = 'LOGIN_FACEBOOK_REQUESTING';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';
export const LOGIN_FACEBOOK_ERROR = 'LOGIN_FACEBOOK_ERROR';