import {
    CONTROL_MODAL_REVIEW,
    CREATE_REVIEW_ERROR,
    CREATE_REVIEW_REQUESTING,
    CREATE_REVIEW_SUCCESS,
    GET_REVIEW_ERROR,
    GET_REVIEW_REQUESTING,
    GET_REVIEW_SUCCESS,
    GET_SEND_REVIEW_ERROR,
    GET_SEND_REVIEW_REQUESTING,
    GET_SEND_REVIEW_SUCCESS
} from "./constants";

export const getReviewRequesting = (token, id) => ({
    type: GET_REVIEW_REQUESTING,
    token,
    id
});

export const getReviewSuccess = (reviews) => ({
    type: GET_REVIEW_SUCCESS,
    reviews
});

export const getReviewError = (error) => ({
    type: GET_REVIEW_ERROR,
    error
});

export const controlModalReview = () => ({
    type: CONTROL_MODAL_REVIEW
});

export const createReviewRequesting = (token, room, values) => ({
    type: CREATE_REVIEW_REQUESTING,
    token,
    room,
    values
});

export const createReviewSuccess = (review) => ({
    type: CREATE_REVIEW_SUCCESS,
    review
});

export const createReviewError = () => ({
    type: CREATE_REVIEW_ERROR
});


export const getSendReviewRequesting = (token, id) => ({
    type: GET_SEND_REVIEW_REQUESTING,
    token,
    id
});

export const getSendReviewSuccess = (reviews) => ({
    type: GET_SEND_REVIEW_SUCCESS,
    reviews
});

export const getSendReviewError = (error) => ({
    type: GET_SEND_REVIEW_ERROR,
    error
});