import {
    GET_TYPE_PEOPLE_ERROR,
    GET_TYPE_PEOPLE_REQUESTING,
    GET_TYPE_PEOPLE_SUCCESS,

} from "./constants";

export const getTypePeopleRequesting = () => ({
    type: GET_TYPE_PEOPLE_REQUESTING
});

export const getTypePeopleSuccess = (typePeoples) => ({
    type: GET_TYPE_PEOPLE_SUCCESS,
    typePeoples
});

export const getTypePeopleError = (error) => ({
    type: GET_TYPE_PEOPLE_ERROR,
    error
});

