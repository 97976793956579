import React from 'react';
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {
    forgotPasswordPostRequesting,
    verifyTokenResetPasswordPostRequesting,
    resetPasswordPutRequesting,
    resetStates,
} from "../../../redux/reset_password/actions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/es/TextField";


const inputTextField = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {
    return (
        <TextField
            {...input}
            {...rest}
            label={label}
            type={type}
            value={input.value}
            name={input.name}
            margin="normal"
            variant="outlined"
            fullWidth
            multiline={type === "text"}
            rowsMax={'10'}
        />
    )
};
class ModalForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.buttonLadda = React.createRef();
    }

    // componentWillUpdate(nextProps, nextState, nextContext) {
    //     const {
    //         resetPassword: {
    //             requestingForgot, requestingVerify, requestingReset,
    //             successForgot, successVerify, successReset,
    //             error,
    //         }
    //     } = this.props;
    //     let button = null;
    //
    //     if (this.buttonLadda.current !== null)
    //         button = Ladda.create(this.buttonLadda.current);
    //
    //     if (nextProps.resetPassword.requestingForgot !== requestingForgot
    //         || nextProps.resetPassword.requestingVerify !== requestingVerify
    //         || nextProps.resetPassword.requestingReset !== requestingReset)
    //         button.start();
    //
    //     if (nextProps.resetPassword.successForgot !== successForgot
    //         || nextProps.resetPassword.successVerify !== successVerify
    //         || nextProps.resetPassword.successReset !== successReset)
    //         button.stop();
    //
    //     if (nextProps.resetPassword.error !== error && nextProps.resetPassword.error !== '') {
    //         let toastOptions = {
    //             component: (
    //                 <ErrorComponent message={nextProps.resetPassword.error}/>
    //             )
    //         };
    //         toastr.error('Error', toastOptions);
    //         button.stop();
    //     }
    //     if (nextProps.resetPassword.successReset !== successReset && nextProps.resetPassword.successReset === true){
    //         this.props.resetStates();
    //         this.props.hidden();
    //     }
    // }

    handleForgotPassword = (values) => {
        const {forgotPasswordPostRequesting} = this.props;
        forgotPasswordPostRequesting(values);
    };

    handleVerifyToken = (values) => {
        const {verifyTokenResetPasswordPostRequesting} = this.props;
        verifyTokenResetPasswordPostRequesting(values);
    };

    handleResetPassword = (values) => {
        const {resetPasswordPutRequesting} = this.props;
        resetPasswordPutRequesting(values);
    };

    render() {
        const {
            handleSubmit,
            resetPassword,
            hidden
        } = this.props;
            return (
                <div className={'body-blur'} onClick={()=>hidden('')}>
                    <div className="register-modal" onClick={this.props.stop.bind(this)}>
                        {/*<div className="button-container-close">*/}
                        {/*    <button className="Btn-modal-cancel" onClick={()=>hidden('')}>*/}
                        {/*        <i className="icon-cancel"/>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        {resetPassword.showForget && (
                         <div>
                             <h2 style={{color: 'black'}}>Ingresa tu email para recuperar tu cuenta</h2>
                             <form onSubmit={handleSubmit(this.handleForgotPassword)}>
                                 <div className="item-modal">
                                     <Field className="input input-phone"
                                            type="text"
                                            name={'email'}
                                            placeholder={'Escribe un correo electronico'}
                                            component={inputTextField}
                                     />
                                 </div>
                                 <div className="button-container-modal1">
                                     <Button ref={this.buttonLadda} data-color="green" color="secondary"  type={'submit'} variant="contained">
                                         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                             Recuperar contraseña
                                             <i className="icon-ok styles-icons"/>
                                         </div>
                                     </Button>
                                 </div>
                             </form>
                         </div>
                        )
                        }
                        {resetPassword.showVerify &&
                        (
                         <div>
                             <h2 style={{color: 'black'}}>Ingresa el token que hemos enviado al correo electronico indicado</h2>
                             <form onSubmit={handleSubmit(this.handleVerifyToken)}>
                                 <div className="item-modal">
                                     <label style={{color: 'black'}}>Token:</label>
                                     <Field className="input input-phone"
                                            type="text"
                                            component={inputTextField}
                                            name={'token'}
                                            placeholder={'Escribe el token enviado al correo electronico indicado'}
                                     />
                                 </div>
                                 <div className="button-container-modal1">
                                     <Button ref={this.buttonLadda} data-color="green" color="secondary"  type={'submit'} variant="contained">
                                         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                             Enviar token
                                             <i className="icon-ok styles-icons"/>
                                         </div>
                                     </Button>
                                 </div>
                             </form>
                         </div>
                        )
                        }
                        {resetPassword.showReset && (
                            <>
                                <h2 style={{color: 'black'}}>Nueva contraseña</h2>
                                <p style={{color: 'black'}}>La contraseña debe ser minimo de 6 caracteres.</p>
                                <form onSubmit={handleSubmit(this.handleResetPassword)}>
                                    <div className="item-modal">
                                        <label style={{color: 'black'}}>Contraseña:</label>
                                        <Field className="input input-phone"
                                               type="password"
                                               component={inputTextField}
                                               name={'contraseña'}
                                               placeholder={'Contraseña'}
                                        />
                                        <label style={{color: 'black'}}>Confirmar contraseña:</label>
                                        <Field className="input input-phone"
                                               type="password"
                                               component={inputTextField}
                                               name={'re_contraseña'}
                                               placeholder={'Confirma la contraseña'}
                                        />
                                    </div>
                                    <div className="button-container-modal1">
                                        <Button ref={this.buttonLadda} data-color="green" color="secondary"  type={'submit'} variant="contained">
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                                Cambiar contraseña
                                                <i className="icon-ok styles-icons"/>
                                            </div>
                                        </Button>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            );
    }
}

const formed = reduxForm({
    form: 'ForgotPassword',
})(ModalForgotPassword);

const mapStateToProps = state => ({
    resetPassword: state.resetPassword,
});

const connected = connect(mapStateToProps, {
    forgotPasswordPostRequesting,
    verifyTokenResetPasswordPostRequesting,
    resetPasswordPutRequesting,
    resetStates
})(formed);

export default connected;
