import React from 'react';
//Material-ui
import {MuiThemeProvider} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
//button
import Button from '@material-ui/core/Button';

import "react-id-swiper/lib/styles/css/swiper.css";
import posed from "react-pose";
import {theme} from "../../../../theme/theme";
import {FilterProfileSecundaryComponent} from "../../../filter/filter_profile_secundary";
import {ProductModalComponent} from "../../../2-product/modals/detail-product";
//img
import {FormattedNumber} from "react-intl";
import {ModalPublicationTalkForSeller} from "../../../publicationsTalk/modals/ModalPublicationTalkForSeller";
import ModalEditPublication from "../../../2-product/modals/edit-publication";
import lottie from "lottie-web";
import {COLORS} from "../../../../theme/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
////////////////effect////////////////
const Box = posed.div({
    visible: {
        x: '0%',
        delayChildren: 1000,
        staggerChildren: 200,
        delay: 50
    },
    hidden: {x: '100%'}
});
const Item = posed.div({
    visible: {
        scale: 1,
    },
    hidden: {scale: 0}
});
const ItemHover = posed.div({
    hoverable: true,
    init: {
        scale: 1,
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        // boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
});

/////////////////////////


export class PageProduct extends React.Component {

    constructor(props) {
        super(props);
        this.animation = React.createRef();
        this.animationTwo = React.createRef();
    }

    componentDidMount() {
        lottie.loadAnimation({
            container: this.animation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/review.json'
        });
        lottie.loadAnimation({
            container: this.animationTwo.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/review.json'
        });
    }

    render() {
        const {
            visible,
            filters,
            filter,
            publications,
            publicationsTalks,
            publication,
            publicationsTalk,
            viewPublication,
            viewPublicationsTalk,
            editPublication,
            focusFilter,
            focus,
            showModal,
            hiddenModal,
            changeState,
            enabled,
            client,
            addFavorite,
            focusId,
            requesting
        } = this.props;

        const enabledPublication = publications.filter((publication, index) => publication.estado.nombre === 'Habilitado');
        const disbaledPublication = publications.filter((publication, index) => publication.estado.nombre === 'Inhabilitado');

        return (
            <MuiThemeProvider theme={theme}>
                <Box pose={visible ? 'visible' : 'hidden'}>
                    {viewPublication && (
                        <ProductModalComponent
                            addFavorite={addFavorite}
                            client={client}
                            enabled={enabled}
                            visible={viewPublication}
                            publication={publication}
                            showModal={showModal}
                            hiddenModal={hiddenModal}
                            type={'publication'}
                            viewChat={false}
                        />
                    )}
                    {viewPublicationsTalk && (
                        <ModalPublicationTalkForSeller
                            visible={viewPublicationsTalk}
                            publicationsTalk={publicationsTalk}
                            showModal={showModal}
                            hiddenModal={hiddenModal}
                            getPublication={focus}
                            type={'publicationsTalk'}
                        />
                    )}
                    {editPublication && (
                        <ModalEditPublication
                            visible={editPublication}
                            hiddenModal={hiddenModal}
                            type={'publication'}
                        />
                    )}
                    <div className="container-item-start">

                        <div className="line"/>
                        <div className="container-sub-text">
                            <h2>Productos</h2>
                            <p>Aquí podrás subir productos, gestionar los que ya tienes y destacarlos para venderlos
                                antes</p>
                        </div>
                        <FilterProfileSecundaryComponent filters={filters} filter={filter} focus={focusFilter}/>


                        <div style={filter === 'Publicados' && publications.length === 0 ? {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        } : {display: 'none'}}>
                            <div className="container-animations-empty-one">
                                <div className={'animation'} ref={this.animation}/>
                            </div>
                            <p className="p-animation-empty-one"
                               style={{fontSize: 22, color: 'rgba(0,0,0,.3)'}}>No cuentas con
                                productos publicados ...</p>
                        </div>


                        {filter === 'Publicados' && (
                            publications.length > 0 && (
                                <>
                                    {enabledPublication.length > 0 && <div className="container-sub-text">
                                        <h2 style={{margin: '10px 0'}}>Habilitados</h2>
                                    </div>}

                                    {enabledPublication.map((publication, index) => {
                                        if (publication.estado.nombre === 'Habilitado')
                                            return <div className={"container-profile-product"} key={publication.id}>
                                                <div className="profile-product-item1">
                                                    <img src={publication.foto ? publication.foto : ''}
                                                         alt={publication.nombre}/>
                                                    <div className="container-sub-text">
                                                        <h2><FormattedNumber value={publication.precio}
                                                                             style={'currency'}
                                                                             currency={'USD'}
                                                                             minimumFractionDigits={0}
                                                                             maximumFractionDigits={0}/>
                                                        </h2>
                                                        <p>{publication.nombre}</p>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center'
                                                        }}>
                                                            <p style={{
                                                                marginRight: 5,
                                                                fontWeight: 'bold'
                                                            }}>Publicado
                                                                : </p>
                                                            <p>{publication.actualizado}</p>
                                                        </div>


                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center'
                                                        }}>
                                                            <p style={{marginRight: 5, fontWeight: 'bold'}}>Estado
                                                                : </p>
                                                            <p style={publication.estado.nombre === 'Habilitado' ? {color: COLORS.PRIMARY} : {color: 'red'}}>{publication.estado.nombre}</p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="profile-product-item2 container-sub-text">
                                                    <div style={{margin: '5px'}}>
                                                        <Button variant="contained" color="secondary"
                                                                onClick={() => {
                                                                    focus(publication, 'publication');
                                                                    showModal('viewPublication', 'publication');
                                                                }}>
                                                            <Icon>remove_red_eye</Icon>
                                                        </Button>
                                                    </div>
                                                    <div style={{margin: '5px'}}>
                                                        <Button variant="contained" color="secondary"
                                                                onClick={() => {
                                                                    focus(publication, 'publication');
                                                                    showModal('editPublication', 'publication');
                                                                }}>
                                                            <Icon>create</Icon>
                                                        </Button>
                                                    </div>
                                                    <div style={{margin: '5px'}}>
                                                        <Button disabled={requesting} variant="contained"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    changeState(publication)
                                                                }}>
                                                            {requesting && focusId === publication.id ?
                                                                <CircularProgress style={{color: COLORS.PRIMARY}}
                                                                                  size={22}/> :
                                                                <Icon>delete_sweep</Icon>}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>;
                                    })}
                                    {disbaledPublication.length > 0 && <div className="container-sub-text">
                                        <h2 style={{margin: '10px 0'}}>Inhabilitados</h2>
                                    </div>}

                                    {disbaledPublication.map((publication, index) => {
                                        if (publication.estado.nombre === 'Inhabilitado')
                                            return <div className={"container-profile-product"} key={publication.id}>
                                                <div className="profile-product-item1">
                                                    <img src={publication.foto ? publication.foto : ''}
                                                         alt={publication.nombre}/>
                                                    <div className="container-sub-text">
                                                        <h2><FormattedNumber value={publication.precio}
                                                                             style={'currency'}
                                                                             currency={'USD'}
                                                                             minimumFractionDigits={0}
                                                                             maximumFractionDigits={0}/>
                                                        </h2>
                                                        <p>{publication.nombre}</p>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center'
                                                        }}>
                                                            <p style={{
                                                                marginRight: 5,
                                                                fontWeight: 'bold'
                                                            }}>Publicado
                                                                : </p>
                                                            <p>{publication.actualizado}</p>
                                                        </div>


                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center'
                                                        }}>
                                                            <p style={{marginRight: 5, fontWeight: 'bold'}}>Estado
                                                                : </p>
                                                            <p style={publication.estado.nombre === 'Habilitado' ? {color: COLORS.PRIMARY} : {color: 'red'}}>{publication.estado.nombre}</p>
                                                        </div>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center'
                                                        }}>
                                                            <p style={{marginRight: 5, fontWeight: 'bold'}}>Razón :</p>
                                                            <p style={{}}>{publication.observacion}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="profile-product-item2 container-sub-text">
                                                    <div style={{margin: '5px'}}>
                                                        <Button variant="contained" color="secondary"
                                                                onClick={() => {
                                                                    focus(publication, 'publication');
                                                                    showModal('viewPublication', 'publication');
                                                                }}>
                                                            <Icon>remove_red_eye</Icon>
                                                        </Button>
                                                    </div>
                                                    <div style={{margin: '5px'}}>
                                                        <Button variant="contained" color="secondary"
                                                                onClick={() => {
                                                                    focus(publication, 'publication');
                                                                    showModal('editPublication', 'publication');
                                                                }}>
                                                            <Icon>create</Icon>
                                                        </Button>
                                                    </div>
                                                    <div style={{margin: '5px'}}>
                                                        <Button disabled={requesting} variant="contained"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    changeState(publication)
                                                                }}>
                                                            {requesting && focusId === publication.id ?
                                                                <CircularProgress style={{color: COLORS.PRIMARY}}
                                                                                  size={22}/> :
                                                                <Icon>delete_sweep</Icon>}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>;
                                    })}
                                </>
                            )
                        )}

                        <div style={filter === 'Vendidos' && publicationsTalks.length === 0 ? {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        } : {display: 'none'}}>
                            <div className="container-animations-empty-one">
                                <div className={'animationTwo'} ref={this.animationTwo}/>
                            </div>
                            <p className="p-animation-empty-one"
                               style={{fontSize: 22, color: 'rgba(0,0,0,.3)'}}>No cuentas con
                                productos vendidos ...</p>
                        </div>

                        {filter === 'Vendidos' && (
                            publicationsTalks.length > 0 && (
                                publicationsTalks.map((publicationsTalk, index) => (
                                    <div key={index}>
                                        <div className={"container-profile-product-user"}>
                                            <div className="profile-product-item1">
                                                <img
                                                    src={publicationsTalk.foto ? publicationsTalk.foto : ''}
                                                    alt={publicationsTalk.nombre + index}/>
                                            </div>
                                            <div className="container-sub-text">
                                                <h2><FormattedNumber
                                                    value={publicationsTalk.precio}
                                                    style={'currency'}
                                                    currency={'USD'}
                                                    minimumFractionDigits={0}
                                                    maximumFractionDigits={0}/></h2>
                                                <p>{publicationsTalk.nombre}</p>
                                            </div>
                                            <div className="profile-product-item3 container-sub-text">
                                                <div>
                                                    <Button variant="contained" color="secondary"
                                                            onClick={() => {
                                                                focus(publicationsTalk, 'publicationsTalk');
                                                                showModal('viewPublicationTalk', 'publicationsTalk');
                                                            }}>
                                                        <Icon>remove_red_eye</Icon>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                        )}
                    </div>
                </Box>
            </MuiThemeProvider>
        )
    }
}
