export const ERROR_PAYMENT='ERROR_PAYMENT';

export const SHOW_MODAL_PAYMENT='SHOW_MODAL_PAYMENT';

export const PAY_SUBSCRIPTION_CREDIT_CARD_REQUESTING='PAY_SUBSCRIPTION_CREDIT_CARD_REQUESTING';
export const PAY_SUBSCRIPTION_CREDIT_CARD_SUCCESS='PAY_SUBSCRIPTION_CREDIT_CARD_SUCCESS';
export const PAY_SUBSCRIPTION_CREDIT_CARD_ERROR='PAY_SUBSCRIPTION_CREDIT_CARD_ERROR';

export const PAY_SUBSCRIPTION_CASH_REQUESTING='PAY_SUBSCRIPTION_CASH_REQUESTING';
export const PAY_SUBSCRIPTION_CASH_SUCCESS='PAY_SUBSCRIPTION_CASH_SUCCESS';
export const PAY_SUBSCRIPTION_CASH_ERROR='PAY_SUBSCRIPTION_CASH_ERROR';

export const GET_PAYMENT_REQUESTING = 'GET_PAYMENT_REQUESTING';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR';


export const GET_LIST_BANKS_REQUESTING = 'GET_LIST_BANKS_REQUESTING';
export const GET_LIST_BANKS_SUCCESS = 'GET_LIST_BANKS_SUCCESS';
export const GET_LIST_BANKS_ERROR = 'GET_LIST_BANKS_ERROR';

export const PAY_SUBSCRIPTION_PSE_REQUESTING = 'PAY_SUBSCRIPTION_PSE_REQUESTING';
export const PAY_SUBSCRIPTION_PSE_SUCCESS = 'PAY_SUBSCRIPTION_PSE_SUCCESS';
export const PAY_SUBSCRIPTION_PSE_ERROR = 'PAY_SUBSCRIPTION_PSE_ERROR';
