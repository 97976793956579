import React from 'react';
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import * as firebase from "firebase/app";
import {FirebaseConfig} from '../config/keys';

class onMessageFirebase extends React.Component {

    state = {
        token: ''
    };

    componentWillMount() {
        firebase.initializeApp(FirebaseConfig);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {

        const {token} = this.state;

        // if (nextProps.client.token !== client.token && nextProps.client.token !== '') {
        //     this.setState({
        //         token: nextProps.client.token,
        //     });
        // }

        try {
            const messaging = firebase.messaging();
            messaging.onMessage((payload) => {
                toastr.success(payload.notification.title, payload.notification.body);
            });
        } catch (error) {
            console.error(error);
        }

    }

    render() {
        return null
    }
}

const mapStateToProps = state => ({
    client: state.client,
});

const connected = connect(mapStateToProps, {

})(onMessageFirebase);

export default connected;