import {createMuiTheme} from "@material-ui/core";

export const themeUI = createMuiTheme({
    overrides: {
        MuiOutlinedInput : {
            multiline : {
                height : '100%'
            }
        }
    },
});