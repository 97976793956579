import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    MenuItem,
    MuiThemeProvider
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {theme} from "../../../theme/theme";
import {Field, Form, reduxForm, reset} from "redux-form";
import {connect} from "react-redux";
import {
    publicationPhotoDeleteRequesting,
    publicationResetStates,
    publicationUpdatePutRequesting
} from "../../../redux/publication/actions";
import {
    categoriesGetRequesting,
    categoryFocus,
    moduleTypeFocus,
    subcategoryFocus
} from "../../../redux/category/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import ImagesInput from "../../inputs-file/images";
import TextField from '@material-ui/core/TextField';
import {timesGetRequesting} from "../../../redux/time/actions";
import {themeUI} from "../../../theme/themeMaterialUI";
import NumberFormat from "react-number-format";

const InputQuantity = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {
    return <MuiThemeProvider theme={themeUI}>
        <TextField
            {...input}
            {...rest}
            label={label}
            type={type}
            name={input.name}
            id="outlined-name"
            margin="normal"
            variant="outlined"
            fullWidth
            multiline
            value={!input.value.hasOwnProperty('target') ? parseInt(input.value) : input.value.target.value}
            InputProps={{
                inputComponent: NumberFormatQuantity,
            }}
        />
    </MuiThemeProvider>
};


const NumberFormatQuantity = (props) => {
    const {inputRef, onChange} = props;
    return (
        <NumberFormat
            value={props.value}
            style={{borderWidth: 0, fontSize: '16px'}}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString={true}
        />
    );
};
const NumberFormatCustom = (props) => {
    const {inputRef, onChange} = props;
    return (
        <NumberFormat
            value={props.value}
            style={{borderWidth: 0, fontSize: '16px'}}
            getInputRef={inputRef}
            onValueChange={values => {
                console.log(values);
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString={true}
            prefix="$ "
        />
    );
};

const inputTextField = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {
    return (
        <MuiThemeProvider theme={themeUI}>
            <TextField
                {...input}
                {...rest}
                label={label}
                type={type}
                value={input.value.hasOwnProperty('id') ? input.value.id : input.value}
                name={input.name}
                id="outlined-name"
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                rowsMax={'10'}
            />
        </MuiThemeProvider>
    )
};


const inputPrice = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {

    return (
        <MuiThemeProvider theme={themeUI}>
            <TextField
                {...input}
                {...rest}
                label={label}
                type={type}
                name={input.name}
                value={!input.value.hasOwnProperty('target') ? parseInt(input.value) : input.value.target.value}
                id="outlined-name"
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}
            />
        </MuiThemeProvider>
    )
};

class ModalEditPublication extends React.Component {


    constructor(props) {
        super(props);
        const {
            categoriesGetRequesting,
            timesGetRequesting
        } = this.props;
        categoriesGetRequesting();
        timesGetRequesting();
    }

    handleSubmit = (values) => {
        const {
            client: {token},
            publicationUpdatePutRequesting,
        } = this.props;
        publicationUpdatePutRequesting(token, values);
    };

    handleFocus = (object, type) => {
        const {categoryFocus, subcategoryFocus, moduleTypeFocus} = this.props;
        switch (type) {
            case 'categoria':
                categoryFocus(object);
                break;
            case 'subcategoria':
                subcategoryFocus(object);
                break;
            case 'tipo_modulo':
                moduleTypeFocus(object);
                break;
        }
    };

    handleResetForm = () => {
        reset('editPublication');
    };

    handleError = (error) => {
        const {publicationResetStates} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        publicationResetStates();
    };

    handleDeletePhoto = (photoId) => {
        const {
            client: {token},
            publicationPhotoDeleteRequesting,
        } = this.props;
        publicationPhotoDeleteRequesting(token, photoId);
    };

    render() {
        const {
            initialValues,
            handleSubmit,
            visible,
            hiddenModal,
            type,
            publication: {error},
            category: {categories, subcategories, moduleTypes, moduleType},
            time: {times},
        } = this.props;
        if (categories.length > 0 && initialValues.categoria.hasOwnProperty('id')) {
            this.handleFocus(categories.find((category) => category.id === initialValues.categoria.id), 'category');
        }
        if (subcategories.length > 0 && initialValues.subcategoria.hasOwnProperty('id')) {
            this.handleFocus(subcategories.find((subcategory) => subcategory.id === initialValues.subcategoria.id), 'subcategory');
        }
        // if (moduleTypes.length > 0 && initialValues.tipo_modulo.hasOwnProperty('id')){
        //     this.handleFocus(moduleTypes.find((moduleType) => moduleType.id === initialValues.tipo_modulo.id), 'moduleType');
        // }

        return (
            <MuiThemeProvider theme={theme}>
                {error !== '' && this.handleError(error)}
                <Dialog open={visible} onClose={() => hiddenModal('editPublication', type)}
                        aria-labelledby="customized-dialog-title">
                    <DialogTitle>
                        Editar publicación
                        <div style={{position: 'absolute', right: 10, top: 10}}>
                            <IconButton aria-label={'Close'} onClick={() => hiddenModal('editPublication', type)}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className={'container-sub-text'}>
                        <div style={{flexDirection: 'row', display: 'flex'}}>
                            {initialValues.hasOwnProperty('fotos') && initialValues.fotos.length > 0 && initialValues.fotos.map((photo, index) => (
                                <DialogContentText style={{marginRight: 12}}>
                                    <div key={index} className={'container-images-edit-publication'}
                                         style={{position: 'relative'}}>
                                        <IconButton color={'primary'} aria-label={'Close'}
                                                    style={{position: 'absolute', top: -30, right: -24}}
                                                    onClick={() => this.handleDeletePhoto(photo.id)}
                                        >
                                            <CloseIcon color="primary"/>
                                        </IconButton>
                                        <img src={photo.url} alt={initialValues.nombre + index}/>
                                    </div>
                                </DialogContentText>
                            ))}
                        </div>

                        <Form onSubmit={handleSubmit(this.handleSubmit)}>
                            <div className={'container-post-item'}>
                                <h2>Subir fotos</h2>
                                <div className={'line'}/>
                                <Field name={'photos'} maxFiles={10} component={ImagesInput}/>
                            </div>
                            <h2>Datos de la publicación</h2>
                            <div className={'line'}/>
                            <Field name={'nombre'} type={'text'} component={inputTextField}
                                   label={'Titulo de la publicación *'}/>
                            <Field name={'descripcion'} type={'text'} component={inputTextField}
                                   label={'Descripción *'} style={{height: 'auto'}}/>
                            <Field name={'precio'} type={'number'} component={inputPrice} label={'Precio *'}/>
                            <Field name={'precio_descuento'} type={'number'} component={inputPrice}
                                   label={'Precio con descuento'}/>
                            <Field name={'dimensiones'} type={'text'} component={inputTextField} label={'Medidas'}/>
                            <Field name={'peso'} type={'text'} component={inputTextField} label={'Peso'}/>
                            <Field name={'valor_envio'} type={'number'} component={inputPrice}
                                   label={'Precio de envio'}/>
                            <Field name={'marca'} type={'text'} component={inputTextField} label={'Marca'}/>
                            <Field name={'cantidad'} type={'number'} component={InputQuantity} label={'Cantidad'}/>
                            {categories.length > 0 && (
                                <Field value={initialValues.categoria.id} select name={'categoria'} type={'select'}
                                       component={inputTextField}
                                       label={'Categoria *'}
                                       onChange={(input) => {
                                           this.handleFocus(categories.find((category) => category.id === input.target.value), 'categoria')
                                       }}>

                                    {categories.map((category, index) => {
                                        return (
                                            <MenuItem key={index} className={'menuItemStyle'}
                                                      value={category.id}>
                                                <p className={'menuItemStyleP'}>{category.nombre}</p>
                                            </MenuItem>
                                        );
                                    })}
                                </Field>
                            )}
                            {moduleTypes.length > 0 && (
                                <Field value={initialValues.categoria.id} select name={'tipo_modulo'} type={'select'}
                                       component={inputTextField}
                                       label={'Tipo de modulo *'}
                                       onChange={(input) => {
                                           this.handleFocus(moduleTypes.find((moduleType) => moduleType.id === input.target.value), 'tipo_modulo')
                                       }}>

                                    {moduleTypes.map((moduleType, index) => (
                                        <MenuItem key={index} className={'menuItemStyle'}
                                                  value={moduleType.id}>
                                            <p className={'menuItemStyleP'}>{moduleType.nombre}</p>
                                        </MenuItem>
                                    ))}
                                </Field>
                            )}
                            {moduleType.hasOwnProperty('nombre') && moduleType.nombre === 'Alquiler' && (
                                <Field value={initialValues.tiempos.id}
                                       select multiple name={'tiempos'} type={'select'} component={inputTextField}
                                       label={'Tiempos de alquiler *'}>
                                    {times.length > 0 && times.map((time, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index} value={time.id}>
                                                <p className="menuItemStyleP">{time.nombre}</p>
                                            </MenuItem>
                                        );
                                    })}
                                </Field>
                            )}
                            {subcategories.length > 0 && (
                                <Field value={initialValues.subcategoria.id} select name={'subcategoria'}
                                       type={'select'} component={inputTextField}
                                       label={'Subcategoria *'}
                                       onChange={(input) => {
                                           this.handleFocus(subcategories.find((subcategory) => subcategory.id === input.target.value), 'subcategoria')
                                       }}>
                                    {subcategories.map((subcategory, index) => (
                                        <MenuItem key={index} className={'menuItemStyle'}
                                                  value={subcategory.id}>
                                            <p className={'menuItemStyleP'}>{subcategory.nombre}</p>
                                        </MenuItem>
                                    ))}
                                </Field>
                            )}
                            <DialogActions>
                                <Button variant="contained" color="secondary"
                                        onClick={() => {
                                            this.handleResetForm();
                                            hiddenModal('editPublication', 'publication');
                                        }}>
                                    Cancelar
                                </Button>
                                <Button variant="contained" color='secondary' type={'submit'}>
                                    Actualizar
                                </Button>
                            </DialogActions>
                        </Form>
                    </DialogContent>
                </Dialog>
            </MuiThemeProvider>
        )
    }
}

const formed = reduxForm({
    form: 'editPublication',
    enableReinitialize: true
})(ModalEditPublication);

const mapStateToProps = state => ({
    client: state.client,
    category: state.category,
    publication: state.publication,
    initialValues: state.publication.publication,
    time: state.time,
});

const connected = connect(mapStateToProps, {
    publicationUpdatePutRequesting,
    publicationResetStates,
    publicationPhotoDeleteRequesting,
    categoriesGetRequesting,
    categoryFocus,
    subcategoryFocus,
    moduleTypeFocus,
    timesGetRequesting
})(formed);

export default connected;
