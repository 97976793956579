export const CLIENT_SET = 'CLIENT_SET';
export const CLIENT_UNSET = 'CLIENT_UNSET';

export const CHECK_JWT_REQUESTING = 'CHECK_JWT_REQUESTING';
export const CHECK_JWT_ERROR = 'CHECK_JWT_ERROR';

export const GET_ME_REQUESTING = 'GET_ME_REQUESTING';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_ERROR = 'GET_ME_ERROR';

export const SAVE_DEVICE = 'SAVE_DEVICE';
export const DELETE_DEVICE= 'DELETE_DEVICE';


export const UPDATE_CLIENT='UPDATE_CLIENT';

export const ENABLED = 'ENABLED';

export const DISABLED = 'DISABLED';

