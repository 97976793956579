import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    GET_ALL_SUBSCRIPTION_REQUESTING
} from './constants';

import {
    getAllSubscriptionError,
    getAllSubscriptionSuccess
} from './actions';
import {CONNECT_TO_SOCKET} from "../chat/constants";

const subscriptionsURL = `${process.env.REACT_APP_API_URL}/subscriptions/app`;

//Obtener todas las suscripciones
const getAllSubscriptionsApi = (token) => {

    return fetch(subscriptionsURL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getAllSubscriptionsFLow(action) {
    try {

        const {token} = action;
        const getSubscriptions = yield call(getAllSubscriptionsApi,token);
        yield put(getAllSubscriptionSuccess(getSubscriptions));
    } catch (error) {
        yield put(getAllSubscriptionError(error));
    }
}
function* usersWatcher() {
    yield all([
        takeEvery(GET_ALL_SUBSCRIPTION_REQUESTING,getAllSubscriptionsFLow)
    ])
}
export default usersWatcher