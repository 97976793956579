import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {MenuItem, MuiThemeProvider} from "@material-ui/core";
import {theme} from "../../../theme/theme";
import Icon from '@material-ui/core/Icon';
import Rater from 'react-rater'
//actions
import {filterGender} from "../../../redux/gender/actions";
import {updateUserRequesting, errorControlUpdateUser} from "../../../redux/user/actions";
import {visibleTypeProfessional} from "../../../redux/typeProfessional/actions";
//input
import TextField from '@material-ui/core/TextField';
import {Field, Form, reduxForm} from "redux-form";
import {connect} from "react-redux";
import ImgUser from "../../../assets/img/img-user.jpg";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import $ from 'jquery';
import Paper from "@material-ui/core/Paper";

const inputTextField = ({input, name, disabled,label, type, id, placeholder, meta: {touched, error}, ...rest}) => {
    return (
        <TextField
            {...input}
            {...rest}
            label={label}
            type={type}
            placeholder={placeholder ? placeholder : ''}
            value={input.value.hasOwnProperty('id') ? input.value.id : input.value}
            name={input.name}
            margin="normal"
            disabled={disabled}
            variant="outlined"
            fullWidth
            multiline={type === "text"}
            rowsMax={'10'}
        />
    )
};

class EditProfileComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        photoPreview: '',
        photo:[],
    };
    handleAction = (values) => {
         const {updateUserRequesting, client: {token, client}, typePeople: {typePeoples}} = this.props;
          let val={...values, photos : this.state.photo};
          updateUserRequesting(val,token, client.id, typePeoples);
    };

    handleError = () => {
        const {user: {error}, errorControlUpdateUser} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlUpdateUser();
    };

    render() {
        const {
            handleControl,
            visible,
            handleSubmit,
            city: {cities},
            typePeople: {typePeoples},
            typeProfessional,
            gender: {gendersFilter, genders},
            typeProfile: {typeProfiles},
            filterGender,
            initialValues,
            visibleTypeProfessional,
            user: {errorControlUpdateUser},
        } = this.props;
        if (initialValues.hasOwnProperty('tipo_persona') && gendersFilter.length === 0) {
            filterGender(typePeoples, typePeoples.find(typePeoples => typePeoples.id === initialValues.tipo_persona.id));
        }
        return (
            <MuiThemeProvider theme={theme}>
                {errorControlUpdateUser && this.handleError()}
                <Dialog
                    onClose={() => handleControl('')} aria-labelledby="customized-dialog-title"
                    open={visible}>

                    <DialogTitle id="customized-dialog-title">
                        Editar perfil
                        <div style={{position: 'absolute', right: 10, top: 10}}>
                            <IconButton aria-label="Close" onClick={() => handleControl('')}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent>
                        <div className="avatar-modal container-text ">
                            <img src={!this.state.photoPreview ? initialValues.foto : this.state.photoPreview} alt="Logo"/>
                            <Button variant="contained" color="secondary" onClick={() => {
                                $('#photo_file').click();
                            }}>
                                Editar
                                <Icon>add_a_photo</Icon>
                            </Button>
                            <input type={'file'} id={'photo_file'} style={{display: 'none'}} name={'photo'}
                                   onChange={(e) => {
                                       this.setState({
                                           photo: e.target.files,
                                           photoPreview: URL.createObjectURL(e.target.files[0])
                                       })
                                   }}/>
                            <h2>{initialValues.nombre}</h2>
                            <div className="rater-component" style={{justifyContent: 'center'}}>
                                <Rater total={5} rating={initialValues.calificacion} interactive={false}/>
                                <p>{initialValues.calificacion}</p>
                            </div>
                        </div>

                        <Form onSubmit={handleSubmit(this.handleAction)}>

                            <Field name={'nombre'} type={'text'} component={inputTextField}
                                   label={'Nombre'}/>
                            {typePeoples.length > 0 &&
                            (
                                <Field select  name={'tipo_persona'} type={'select'} component={inputTextField}
                                       label={'Seleccionar Tipo de persona'}
                                       onChange={(e) => {
                                           filterGender(typePeoples, typePeoples.find(typePeoples => typePeoples.id === e.target.value));
                                           visibleTypeProfessional(typePeoples.find(typePeoples => typePeoples.id === e.target.value));
                                       }}
                                >
                                    {typePeoples.map((typePeople, index) => {
                                            return (
                                                <MenuItem className="menuItemStyle" key={index} value={typePeople.id}>
                                                    {typePeople.nombre !== 'Todos' && (
                                                        <p className="menuItemStyleP">{typePeople.nombre}</p>
                                                    )}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Field>
                            )}

                            {initialValues.hasOwnProperty('tipo_profesional') && initialValues.tipo_profesional.nombre !== 'No requerido'
                             && typeProfessional.typeProfessionals.length > 0 &&
                            (
                                <Field select  disabled={true} name={'tipo_profesional'} type={'select'} component={inputTextField}
                                       label={'Seleccionar Tipo Profesional'}
                                >
                                    {typeProfessional.typeProfessionals.map((typeProfessional, index) => {
                                            return (
                                                <MenuItem className="menuItemStyle" key={index}
                                                          value={typeProfessional.id}>
                                                    <p className="menuItemStyleP">{typeProfessional.nombre}</p>
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Field>
                            )}

                            {gendersFilter.length > 0 &&
                                    (
                                        <Field select name={'genero'} type={'select'} component={inputTextField}
                                               label={'Seleccionar Genero'}
                                        >
                                            {gendersFilter.map((gender, index) => {
                                                    return (
                                                        <MenuItem className="menuItemStyle" key={index} value={gender.id}>
                                                            <p className="menuItemStyleP">{gender.nombre}</p>
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Field>
                                    )}

                            {cities.length > 0 &&
                            (
                                <Field select name={'ciudad'} type={'select'} component={inputTextField}
                                       label={'Ciudad'}
                                >
                                    {cities.map((city, index) => {
                                            return (
                                                <MenuItem key={index} value={city.id}>

                                                    <p>{city.nombre}</p>
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Field>
                            )}

                            {typeProfiles.length > 0 &&
                            (
                                <Field select name={'tipo_perfil'} type={'select'} component={inputTextField}
                                       label={'Seleccionar el tipo de perfil'}
                                >
                                    {typeProfiles.map((typeProfile, index) => {
                                            return (
                                                <MenuItem className="menuItemStyle" key={index} value={typeProfile.id}>
                                                    <p className="menuItemStyleP">{typeProfile.nombre}</p>
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Field>
                            )}

                            <Field  name={'identificacion'} type="text" label={'Cedula-Nit'}
                                   component={inputTextField} placeholder={'Sin puntos, simbolos ni codigo de verificación'}/>

                            <Field name={'telefono'} type="number" label={'Telefono'} component={inputTextField}/>

                            <Field disabled={true} name={'correo'} type="email" label={'Correo'} component={inputTextField}/>

                            <Field name={'direccion'} type="text" label={'Direccion'} component={inputTextField}/>

                            <Field name={'persona_contacto'} type="text" label={'Nombre de usuario'}
                                   component={inputTextField}/>

                            <Field name={'telefono_contacto'} type="number" label={'Teléfono alternativo'}
                                   component={inputTextField}/>

                            <DialogActions>
                                <Button variant="contained" color="Secondary" type={'submit'}>Guardar</Button>
                                <Button variant="contained" color="Secondary"
                                        onClick={() => handleControl('')}>Cancelar</Button>
                            </DialogActions>
                        </Form>
                    </DialogContent>
                </Dialog>
            </MuiThemeProvider>
        )
    }
}

const formed = reduxForm({
    form: 'editProfileUser',
    enableReinitialize: true
})(EditProfileComponent);

const mapStateToProps = state => ({
    initialValues: state.client.client,
    client: state.client,
    user: state.user,
    city: state.city,
    typePeople: state.typePeople,
    typeProfessional: state.typeProfessional,
    gender: state.gender,
    typeProfile: state.typeProfile
});

const connected = connect(mapStateToProps, {
    filterGender,
    visibleTypeProfessional,
    updateUserRequesting,
    errorControlUpdateUser
})(formed);

export default connected;
