import {
    CHANGE_STATE_TO_SOLD_ERROR,
    CHANGE_STATE_TO_SOLD_REQUESTING, CHANGE_STATE_TO_SOLD_SUCCESS,
    CREATE_MESSAGE_REQUESTING,
    CREATE_MESSAGE_SUCCESS,
    CREATE_ROOM_CHAT_ERROR,
    CREATE_ROOM_CHAT_REQUESTING,
    CREATE_ROOM_CHAT_SUCCESS,
    ENTRY_MESSAGE,
    FOCUS_CHAT,
    GET_LIST_MESSAGING_ERROR,
    GET_LIST_MESSAGING_REQUESTING,
    GET_LIST_MESSAGING_SUCCESS,
    GET_MESSAGES_ERROR,
    GET_MESSAGES_REQUESTING,
    GET_MESSAGES_SUCCESS
} from "./constants";

import moment from 'moment';

const initialState = {
    requesting: false,
    success: false,
    error: false,
    listMessaging: [],
    titleChat: '',
    titleProduct: '',
    photoProduct: '',
    userBuyer: '',
    userPayer: '',
    score: '',
    focus: 0,
    hasMore: true,
    messages: [],
    cursor: 1,
    to: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MESSAGES_REQUESTING :
            return {
                ...state,
                requesting: true,
                cursor: action.cursor
            };
        case GET_MESSAGES_SUCCESS :
            let newMessages = action.messages.map(message => {
                return {
                    id: message.id,
                    position: message.usuario === message.usuario_desde.id ? 'right' : 'left',
                    date: new Date(message.fecha.date),
                    type: 'text',
                    avatar: message.usuario_desde.foto,
                    text: message.mensaje,
                    dateString: moment(message.fecha.date).format('YYYY-MM-DD')
                    // user: {
                    //     _id: message.usuario_desde.id,
                    //     name: message.usuario_desde.nombre,
                    //     avatar: message.usuario_desde.foto,
                    // },
                }
            });

            return {
                ...state,
                requesting: false,
                messages: [...state.messages, ...newMessages].sort((a, b) => (a.id > b.id) ? 1 : -1),
            };
        case GET_MESSAGES_ERROR :
            return {
                ...state,
                requesting: false,
                hasMore: false
            };
        case GET_LIST_MESSAGING_ERROR:
            return {
                ...state,
                listMessaging: [],
                error: true,
                requesting: false,
                success: false,
            };
        case GET_LIST_MESSAGING_SUCCESS:
            return {
                ...state,
                listMessaging: action.list,
                requesting: false,
                success: true,
                error: false,
            };
        case GET_LIST_MESSAGING_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };

        case FOCUS_CHAT :
            return {
                ...state,
                photoProduct: action.photoProduct,
                titleChat: action.title,
                titleProduct: action.titleProduct,
                focus: action.focus.id,
                messages: [],
                cursor: 1,
                hasMore: true,
                to: action.to,
                soldFocus: action.focus.vendido,
                userBuyer: action.userBuyer,
                userPayer: action.userPayer,
                score: action.score
            };
        case ENTRY_MESSAGE :
            let messagesEnd = state.messages;
            if (action.message.sala === state.focus)
                messagesEnd = [...state.messages, {
                    id: action.message.id,
                    position: 'left',
                    date: new Date(action.message.fecha.date),
                    type: 'text',
                    avatar: action.message.usuario_desde.foto,
                    text: action.message.mensaje,
                    dateString: moment(action.message.fecha.date).format('YYYY-MM-DD')
                }];
            return {
                ...state,
                messages: messagesEnd
            };
        case CREATE_MESSAGE_REQUESTING :
            return {
                ...state,
            };

        case CREATE_MESSAGE_SUCCESS :
            return {
                ...state,
                messages: [...state.messages, {
                    id: action.message.id,
                    position: action.message.usuario === action.message.usuario_desde.id ? 'right' : 'left',
                    date: new Date(action.message.fecha.date),
                    type: 'text',
                    avatar: action.message.usuario_desde.foto,
                    text: action.message.mensaje,
                    dateString: moment(action.message.fecha.date).format('YYYY-MM-DD')
                }]
            };
        case CREATE_ROOM_CHAT_REQUESTING :
            return {
                ...state,
                requesting: true,
            };
        case CREATE_ROOM_CHAT_ERROR :
            return {
                ...state,
                requesting: false
            };
        case CREATE_ROOM_CHAT_SUCCESS :

            return {
                ...state,
                listMessaging: [
                    ...state.listMessaging, action.room
                ],
                titleChat: !action.room.es_comprador ? action.room.comprador.nombre : action.room.pagador.nombre,
                titleProduct: action.room.producto.nombre,
                photoProduct: action.room.producto.foto,
                focus: action.room.id,
                messages: [],
                cursor: 0,
                hasMore: true,
                to: !action.room.es_comprador ? action.room.comprador.id : action.room.pagador.id,
                userBuyer: action.room.comprador.id,
                userPayer: action.room.pagador.id,
                score: action.room.calificacion
            };

        case CHANGE_STATE_TO_SOLD_REQUESTING :
            return {
                ...state,
                requesting: true,
            };
        case CHANGE_STATE_TO_SOLD_SUCCESS :
            return {
                ...state,
                requesting:  false,
                soldFocus : true
            };
        case CHANGE_STATE_TO_SOLD_ERROR :
            return {
                ...state,
                requesting: false
            };
        default :
            return state;
    }
};

export default reducer;