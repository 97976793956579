import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {toastr} from "react-redux-toastr";
import {moduleResetStates, modulesGetError, modulesGetSuccess} from "./actions";
import {MODULES_GET_REQUESTING} from "./constants";

const moduleURL = `${process.env.REACT_APP_API_URL}/modules`;

const modulesGetApi = () => {
    return fetch(`${moduleURL}`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;

        }).catch((error) => {
            throw error;
        })
};

function* modulesGetFlow() {
    try {
        const modules = yield call(modulesGetApi);
        yield put(modulesGetSuccess(modules));
        yield put(moduleResetStates());
    } catch (error) {
        yield put(modulesGetError(error));
    }
}

function* moduleWatcher() {
    yield all([
        takeEvery(MODULES_GET_REQUESTING, modulesGetFlow),
    ])
}

export default moduleWatcher;