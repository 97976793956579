import React from 'react';
//Material-ui
import {MuiThemeProvider} from '@material-ui/core/styles';
import {theme} from "../../../theme/theme";
//input
//radius input checkbox
//button
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
//Buscador
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Select  from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import { Field } from 'redux-form';
//Lista de productos
import Masonry from 'react-masonry-component';
// export default withStyles(styles);
import posed from "react-pose";
//img
import FotoProduct1 from "../../../assets/img/product1.jpg";
import {ProductModalComponent} from "../../2-product/modals/detail-product";
// Need to add Pagination, Navigation modules
//import "react-id-swiper/src/styles/css/swiper.css";
import FilterProductModalComponent from "../../2-product/modals/filter-product";
import {FormattedNumber} from "react-intl";
////////////////////////

const Box = posed.div({
    visible: {
        x: '0%',
        delayChildren: 1000,
        staggerChildren: 200,
        delay: 700
    },
    hidden: {x: '100%'}
});

const Item = posed.div({
    visible: {
        scale: 1,
    },
    hidden: {scale: 0}
});

const ItemHover = posed.div({
    hoverable: true,
    init: {
        scale: 1,
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        // boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
});

////////////////////////

const masonryOptions = {
    transitionDuration: '0.8s',
    itemSelector: '.sub-item-1-supply',
    percentPosition: true,
    columnWidth: '.sub-item-1-supply',

};
const imagesLoadedOptions = {background: '.my-bg-image-el'};


export class PageHome extends React.Component {

    state = {
        isVisible: false,
        categorySelect: ''
    };

    componentDidMount() {
        this.setState({
            isVisible: true
        });
    }


    render() {
        const {
            publications, categories, getPublication, showModal, hiddenModal, viewPublication, type, modalFilter,
            publication, handleNewChat, handleFocus, handleFilterCategory, handleResetStates, handleSearch, handleSearchChangeForm,
            authUser, enabled, handleControl, client, publicationsOwnerUserPostRequesting, cleanPublication, setFocusUser, getReviewRequesting,
            userAuth, paginate, ownerPublicationsIds, publicationShowModal, active, focus, addFavorite
        } = this.props;
        const childElements = publications.length > 0 && publications.map((publication, index) => {
            return (
                <div className=" container-item-start" key={index}>
                    <div className="sub-item-1-supply container-text"
                         onClick={() => {
                             getPublication(publication);
                             showModal('viewPublication', type)
                         }}>
                        <div className="sub-item-2-supply item-1-supply">
                            <h1>{publication.nombre.length > 25 ? `${publication.nombre.substring(0, 25)}...` : publication.nombre}</h1>
                            <p>{publication.subcategoria.nombre}</p>
                        </div>
                        <img src={publication.foto ? publication.foto : FotoProduct1} alt={publication.nombre}/>
                        <div className="sub-item-2-supply">
                            <p>{publication.descripcion.length > 70 ? `${publication.descripcion.substring(0, 70)}...` : publication.descripcion}</p>
                        </div>
                        <div className="sub-item-3-supply item-1-supply">

                            <div>
                                {
                                    publication.precio_descuento !== '0' &&
                                    <h2><FormattedNumber
                                        value={publication.precio_descuento}
                                        style={'currency'} currency={'USD'}
                                        minimumFractionDigits={0} maximumFractionDigits={0}/>
                                    </h2>
                                }
                                <h2 style={publication.precio_descuento !== '0'  ? {color:'red',textDecorationLine: 'line-through'} :{fontWeight:900}}>
                                    <FormattedNumber
                                    value={publication.precio}
                                    style={'currency'} currency={'USD'}
                                    minimumFractionDigits={0} maximumFractionDigits={0}/></h2>
                            </div>

                            <Button variant="contained" color="primary"
                                    style={{backgroundColor: '#fff159',color:"black"}}
                                    onClick={() => {
                                        getPublication(publication);
                                        showModal('viewPublication', type)
                                    }}>
                                Ver

                            </Button>
                        </div>
                    </div>
                </div>
            );
        });
        ////////////////////////////////////////////////////////
        return (
            <MuiThemeProvider theme={theme}>
                <Box pose={this.state.isVisible ? 'visible' : 'hidden'}>
                    {viewPublication && (
                        <ProductModalComponent
                            addFavorite={addFavorite}
                            publicationShowModal={publicationShowModal}
                            paginate={paginate}
                            ownerPublicationsIds={ownerPublicationsIds}
                            token={userAuth}
                            getReviewRequesting={getReviewRequesting}
                            setFocusUser={setFocusUser}
                            publicationsOwnerUserPostRequesting={publicationsOwnerUserPostRequesting}
                            cleanPublication={cleanPublication}
                            client={client}
                            handleControl={handleControl}
                            enabled={enabled}
                            publication={publication}
                            visible={viewPublication}
                            showModal={showModal}
                            hiddenModal={hiddenModal}
                            type={type}
                            viewChat={true}
                            auth={authUser}
                            handleNewChat={handleNewChat}
                        />
                    )}
                    {modalFilter && (
                        <FilterProductModalComponent
                            visible={modalFilter}
                            hiddenModal={hiddenModal}
                            type={'filter'}
                        />
                    )}
                    <div className="container-item-start">
                        <div className="container-btn-filter">
                            <Paper elevation={1}>
                                <InputBase placeholder="Buscar en TuConstrucción"
                                           onKeyPress={(event) => {
                                               if (event.key === 'Enter') {
                                                   handleSearch()
                                               }
                                           }}
                                           onChange={(input) => handleSearchChangeForm('search', input.target.value)}
                                />
                                <IconButton aria-label="Search" onClick={() => handleSearch()}>
                                    <SearchIcon/>
                                </IconButton>
                            </Paper>
                            <Paper elevation={1}>
                                <Select
                                    disableUnderline
                                    value={this.state.categorySelect ? this.state.categorySelect : 'todas-las-categorias'}
                                    onChange={(e) => this.setState({ categorySelect: e.target.value }) }
                                >
                                    <MenuItem value="todas-las-categorias">
                                        <a onClick={() => {
                                            handleResetStates('category');
                                            setTimeout(() => handleFilterCategory(), 100);
                                        }}>
                                            <i className="icon-pin styles-icons"/>
                                            Filtrar: Todas las categorías
                                        </a>
                                    </MenuItem>
                                    {categories.length > 0 && categories.map((category,index) => (
                                        <MenuItem value={category.nombre}>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                handleFocus(category, 'category');
                                                setTimeout(() => handleFilterCategory(), 100);
                                            }} key={index}> { category.nombre }</a>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Paper>
                            <div className="item-btn-fixed">
                                <Button style={{border: "2px solid white", background: "#393939 ", height: "45px"}}
                                        onClick={() => showModal('filter', 'filter')} variant="contained"
                                        color="secondary">
                                    Filtrar
                                </Button>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <div className="container-products">
                        <Masonry
                            className="container-supply"
                            elementType={'div'}
                            options={masonryOptions}
                            disableImagesLoaded={false}
                            updateOnEachImageLoad={false}
                            imagesLoadedOptions={imagesLoadedOptions}
                            enableResizableChildren={true}>
                            {childElements}
                        </Masonry>
                    </div>

                    {/*<div className="container-item-start">*/}
                    {/*<div className="container-sub-text">*/}
                    {/*<h1>¿Quieres ganar dinero extra?</h1>*/}
                    {/*<h2>¡Ingresa tus datos para coducir con nostros!</h2>*/}
                    {/*<p>¡Ingresa tus datos para coducir con nostros!</p>*/}
                    {/*</div>*/}
                    {/*<div className="item-1-informative">*/}
                    {/*<div className="container-form">*/}
                    {/*<div className="item-1-form">*/}
                    {/*<TextField*/}
                    {/*id="outlined-select-currency"*/}
                    {/*select*/}
                    {/*label="Seleccione la ciudad "*/}
                    {/*value={1}*/}
                    {/*margin="normal"*/}
                    {/*variant="outlined">*/}
                    {/*<MenuItem value={1} className="menuItemStyle">*/}
                    {/*<p className="menuItemStyleP"> pop</p>*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem value={2} className="menuItemStyle">*/}
                    {/*<p className="menuItemStyleP"> pop</p>*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem value={2} className="menuItemStyle">*/}
                    {/*<p className="menuItemStyleP"> pop</p>*/}
                    {/*</MenuItem>*/}
                    {/*</TextField>*/}

                    {/*<TextField*/}
                    {/*id="outlined-search"*/}
                    {/*label="Search field"*/}
                    {/*type="search"*/}
                    {/*margin="normal"*/}
                    {/*variant="outlined"*/}
                    {/*/>*/}

                    {/*<TextField*/}
                    {/*id="outlined-select-currency"*/}
                    {/*select*/}
                    {/*label="Select"*/}
                    {/*value={1}*/}
                    {/*helperText="Please select your currency"*/}
                    {/*margin="normal"*/}
                    {/*variant="outlined"*/}
                    {/*>*/}
                    {/*<MenuItem value={1} className="menuItemStyle">*/}
                    {/*<p className="menuItemStyleP"> pop</p>*/}
                    {/*</MenuItem>*/}
                    {/*</TextField>*/}
                    {/*</div>*/}
                    {/*<div className="item-1-form">*/}
                    {/*<TextField*/}
                    {/*id="outlined-name"*/}
                    {/*label="Digite el correo"*/}
                    {/*// className={classes.textField}*/}
                    {/*value={this.state.name}*/}
                    {/*// onChange={this.handleChange('name')}*/}
                    {/*margin="normal"*/}
                    {/*variant="outlined"*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*<div className="item-1-form">*/}
                    {/*<TextField*/}
                    {/*id="outlined-name"*/}
                    {/*label="Digite el correo"*/}
                    {/*// className={classes.textField}*/}
                    {/*value={this.state.name}*/}
                    {/*// onChange={this.handleChange('name')}*/}
                    {/*margin="normal"*/}
                    {/*variant="outlined"*/}
                    {/*/>*/}
                    {/*<FormControlLabel*/}
                    {/*value="start"*/}
                    {/*control={<Radio color="primary"/>}*/}
                    {/*label="Start"*/}
                    {/*labelPlacement="start"*/}
                    {/*/>*/}
                    {/*<FormControlLabel*/}
                    {/*value="start"*/}
                    {/*control={<Radio color="primary"/>}*/}
                    {/*label="Start"*/}
                    {/*labelPlacement="start"*/}
                    {/*/>*/}
                    {/*<FormControlLabel*/}
                    {/*value="start"*/}
                    {/*control={<Radio color="primary"/>}*/}
                    {/*label="Start"*/}
                    {/*labelPlacement="start"*/}
                    {/*/>*/}
                    {/*<TextField*/}
                    {/*id="outlined-adornment-password"*/}
                    {/*// className={classNames(classes.margin, classes.textField)}*/}
                    {/*variant="outlined"*/}
                    {/*type={true ? 'text' : 'password'}*/}
                    {/*label="Password"*/}
                    {/*value={this.state.password}*/}
                    {/*// onChange={this.handleChange('password')}*/}
                    {/*InputProps={{*/}
                    {/*endAdornment: (*/}
                    {/*<InputAdornment position="end">*/}
                    {/*<IconButton*/}
                    {/*aria-label="Toggle password visibility"*/}
                    {/*onClick={this.handleClickShowPassword}*/}
                    {/*>*/}
                    {/*{true ? <VisibilityOff/> : <Visibility/>}*/}
                    {/*</IconButton>*/}
                    {/*</InputAdornment>*/}
                    {/*),*/}
                    {/*}}*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*<div className="item-1-form">*/}
                    {/*<Button variant="contained" color="secondary"*/}
                    {/*onClick={this.handleControlModal}>*/}
                    {/*julian camilo*/}
                    {/*<Icon>favorite_border</Icon>*/}
                    {/*</Button>*/}
                    {/*</div>*/}
                    {/*<div className="item-1-informative">*/}
                    {/*<div className="container-form">*/}
                    {/*<div className="item-1-form">*/}
                    {/*<Button variant="contained" color="secondary"*/}
                    {/*onClick={this.handleControlModal}>*/}
                    {/*julian camilo*/}
                    {/*<Icon>favorite_border</Icon>*/}
                    {/*</Button>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}

                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                </Box>
            </MuiThemeProvider>
        )
    }
}
