import React from 'react';
import {connect} from "react-redux";
import {filterChangePrimaryProfile} from "../../redux/iu/actions";

class FilterUserPrincipalComponent extends React.Component {

    handleFocus = (target) => {
        const {filterChangePrimaryProfile} = this.props;
        switch (target) {
            case 'Productos':

                filterChangePrimaryProfile(target);
                break;
            case 'Favoritos':

                filterChangePrimaryProfile(target);
                break;
            case 'Mensajes':

                filterChangePrimaryProfile(target);
                break;
            case 'Comentarios':

                filterChangePrimaryProfile(target);
                break;
            case 'Suscripciones':

                filterChangePrimaryProfile(target);
                break;
        }
    };

    render() {
        const {
            iu: {filtersPrimary, filterPrimary},
        } = this.props;
        return (
            <div className="container-sub-text">
                <div className="container-profile-menu">
                    {filtersPrimary.map((filter, index) => (
                        <div className={filterPrimary !== filter ? "profile-menu-item1" : "profile-menu-item1 profile-menu-item1-active"}
                            key={index}
                            onClick={() => this.handleFocus(filter)}>
                            <h2>{filter}</h2>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    iu: state.iu,
});

const connected = connect(mapStateToProps, {
    filterChangePrimaryProfile,
})(FilterUserPrincipalComponent);

export default connected;