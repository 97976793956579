import React, {Component} from 'react';
import './App.css';
import MenuHome from "./components/menu-home/menuHome";
import HomeComponent from "./components/1-home-page";
import ProfileComponent from "./components/3-profile-page";
import PageChat from './components/3-profile-page/page/chat_messages/chat';
import ProfileVisitorComponent from "./components/4-profile-page-visitor";
import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {IndexSagas} from './redux/index-sagas';
import IndexReducer from './redux/index-reducer';
import ReduxToastr from 'react-redux-toastr';
import Routes from './commons/routes/routes';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import PageFavorites from "./components/3-profile-page/page/favorites/page-favorites";
import PageComments from "./components/3-profile-page/page/comments/comments";
import PageSubscriptionView from "./components/3-profile-page/page/subscription-view/page-subscription-view";
import MessageFirebase from './pushNotification/message';
import PageLoading from "./components/PageLoading";
import ReactGA from 'react-ga';
import { createBrowserHistory } from "history";


// const history = createHistory();
const history = createBrowserHistory();

history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

// ReactGA.initialize('UA-150826540-1', { debug: true });
//
// ReactGA.set({ page: window.location.pathname + window.location.search }); // Update the user's current page
// ReactGA.pageview(window.location.pathname + window.location.search);


const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    IndexReducer,
    composeSetup(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(IndexSagas);



class App extends Component {


    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <div className="App">
                        <ReduxToastr
                            timeOut={10000}
                            newestOnTop={false}
                            preventDuplicates
                            position="top-right"
                            transitionIn="bounceInDown"
                            transitionOut="bounceOutUp"
                            progressBar
                            closeOnToastrClick
                        />
                        <PageLoading/>
                        <MessageFirebase/>
                        <Routes/>
                        <MenuHome/>
                        <Route exact path={'/'} component={HomeComponent}/>
                        <Route exact path={'/profile'} component={ProfileComponent}/>
                        <Route exact path={'/profile/favorites'} component={PageFavorites}/>
                        <Route exact path={'/profile/chat'} component={PageChat}/>
                        <Route exact path={'/profile/comments'} component={PageComments}/>
                        <Route exact path={'/profile/subscriptions'} component={PageSubscriptionView}/>
                        <Route exact path={'/profileVisitor/:id'} component={ProfileVisitorComponent}/>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;

