import React from 'react';
import connect from "react-redux/es/connect/connect";
import MenuHome from "../../components/menu-home/menuHome";
import {askForPermissioToReceiveNotifications} from "../../pushNotification/push";
import {saveDevice} from "../../redux/client/actions";


class RoutesComponent extends React.Component {

    componentWillMount() {
        const {saveDevice}=this.props;

        let localToken = localStorage.getItem('@user:tuConstruccion');

        if (localToken){
            askForPermissioToReceiveNotifications().then(tokenFirebase =>
            {
                let values = {
                    os: 'web',
                    device: tokenFirebase
                };
                saveDevice(localToken,values);
            });
        }
    }

    render() {
        return (
            <div>
                <MenuHome/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    client: state.client,
});

const connected = connect(mapStateToProps, {
    saveDevice
})(RoutesComponent);

export default connected;