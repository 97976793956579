import React from 'react';
import {connect} from "react-redux";
import {filterChangePrimaryProfile} from "../../redux/iu/actions";

class FilterVisitorPrincipalComponent extends React.Component {

    handleFocus = (target) => {
        const {filterChangePrimaryProfile} = this.props;
        switch (target) {
            case 'Productos':
                filterChangePrimaryProfile(target);
                break;
            case 'Comentarios':
                filterChangePrimaryProfile(target);
                break;
        }
    };

    render() {
        const {
            iu: {filtersPrimary, filterPrimary},
        } = this.props;
        return (
            <div className="container-sub-text">
                <div className="container-profile-menu">
                    {filtersPrimary.map((filter, index) => {
                        if (filter === 'Productos' || filter === 'Comentarios') {
                            return (
                                <div key={index}
                                     className={filterPrimary !== filter ? "profile-menu-item1" : "profile-menu-item1 profile-menu-item1-active"}
                                     onClick={() => this.handleFocus(filter)}>
                                    <h2>{filter}</h2>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    iu: state.iu,
});

const connected = connect(mapStateToProps, {
    filterChangePrimaryProfile,
})(FilterVisitorPrincipalComponent);

export default connected;