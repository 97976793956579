import {
    CATEGORIES_GET_ERROR,
    CATEGORIES_GET_REQUESTING,
    CATEGORIES_GET_SUCCESS, CATEGORIES_MODULE_GET_ERROR,
    CATEGORIES_MODULE_GET_REQUESTING,
    CATEGORIES_MODULE_GET_SUCCESS,
    CATEGORY_FOCUS,
    CATEGORY_HIDDEN_MODAL,
    CATEGORY_RESET_STATES,
    CATEGORY_SHOW_MODAL,
    MODULE_TYPE_FOCUS,
    SUBCATEGORY_FOCUS,
    TYPE_MODULE_FOCUS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    categories: [],
    category: {},
    typeModules: {},
    typeModule:{},
    subcategories: [],
    subcategory: {},
    moduleType: {},
    moduleTypes: [],
    modalCategories: false,
    modalSubcategories: false,
    active: true,
    focus: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORIES_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case CATEGORIES_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                categories: action.categories,
            };
        case CATEGORIES_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case CATEGORIES_MODULE_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case CATEGORIES_MODULE_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                categories: action.categories,
            };
        case CATEGORIES_MODULE_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case CATEGORY_FOCUS:
            return {
                ...state,
                active: false,
                focus: action.category.id,
                category: action.category,
                typeModules: action.category.tipo_modulo,
                moduleTypes: action.category.tipo_modulo,
                subcategories: action.category.subcategorias,
            };
        case SUBCATEGORY_FOCUS:
            return {
                ...state,
                subcategory: action.subcategory,
            };
        case MODULE_TYPE_FOCUS:
            return {
                ...state,
                moduleType: action.moduleType,
            };
        case TYPE_MODULE_FOCUS:
            return {
                ...state,
                typeModule: action.typeModule
            };
        case CATEGORY_SHOW_MODAL:
            return {
                ...state,
                modalCategories: action.modal === 'categories',
                modalSubcategories: action.modal === 'subcategories',
            };
        case CATEGORY_HIDDEN_MODAL:
            return {
                ...state,
                modalCategories: action.modal === 'categories' && false,
                modalSubcategories: action.modal === 'subcategories' && false,
            };
        case CATEGORY_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
                active: true,
                focus: ''
            };
        default:
            return state;
    }
};

export default reducer;
