import {all, call, put, takeEvery} from 'redux-saga/effects';
import {
    createReviewSuccess,
    getReviewError,
    getReviewSuccess,
    getSendReviewError,
    getSendReviewSuccess
} from "./actions";
import {CREATE_REVIEW_REQUESTING, GET_REVIEW_REQUESTING, GET_SEND_REVIEW_REQUESTING} from "./constants";
import {handleShowError} from "../../utils/showError";
import {toastr} from "react-redux-toastr";


const REVIEW_RECEIVED_URL = `${process.env.REACT_APP_API_URL}/reviewsReceived`;
const REVIEW_CREATE_URL = `${process.env.REACT_APP_API_URL}/review`;
const REVIEW_SEND_URL = `${process.env.REACT_APP_API_URL}/reviewsSend`;

const getReviewReceivedAPI = (token, id) => {
    return fetch(`${REVIEW_RECEIVED_URL}/${id}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(response => response.json())
        .then(json => {

            if (json.code === 422) {
                let message = '';
                for (let key in json.data) {
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getReviewReceivedFlow(action) {
    try {
        const {token, id} = action;
        const reviews = yield call(getReviewReceivedAPI, token, id);
        yield put(getReviewSuccess(reviews));
    } catch (error) {

        yield put(getReviewError(error));
    }
}

const getReviewSendAPI = (token, id) => {
    return fetch(`${REVIEW_SEND_URL}/${id}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getReviewSendFlow(action) {
    try {
        const {token, id} = action;
        const reviews = yield call(getReviewSendAPI, token, id);
        yield put(getSendReviewSuccess(reviews));
    } catch (error) {
        yield put(getSendReviewError(error));
    }
}


const createReviewAPI = (token, room, values) => {
    let body = {
        comment: values.comment,
        score: values.rater
    };
    return fetch(`${REVIEW_CREATE_URL}/${room}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw {error: json.data};
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* createReviewFlow(action) {
    try {
        const {token, room, values} = action;
        const review = yield call(createReviewAPI, token, room, values);
        yield put(createReviewSuccess(review));
        toastr.success('Comentario agregado', 'Se ha añadido el comentario');
    } catch (error) {
        
        handleShowError(error);
        yield put(getReviewError(error));
    }
}

function* reviewWatcher() {
    yield all([
        takeEvery(GET_REVIEW_REQUESTING, getReviewReceivedFlow),
        takeEvery(CREATE_REVIEW_REQUESTING, createReviewFlow),
        takeEvery(GET_SEND_REVIEW_REQUESTING, getReviewSendFlow)
    ])
}

export default reviewWatcher;