import {combineReducers} from 'redux';
import {reducer as form} from 'redux-form';
import {reducer as toastrReducer} from 'react-redux-toastr';
import iu from './iu/reducer';
import city from './city/reducer';
import module from './module/reducer';
import category from './category/reducer';
import time from './time/reducer';
import filter from './filter/reducer';
import publication from './publication/reducer';
import search from './search/reducer';
import publicationsTalk from './publicationsTalks/reducer';
import chat from './chat/reducer';
import login from './login/reducer'
import typePeople from './typePeople/reducer';
import typeProfessional from './typeProfessional/reducer';
import typeProfile from './typeProfile/reducer';
import gender from './gender/reducer';
import payment from './payment/reducer';
import user from './user/reducer';
import client from './client/reducer';
import subscription from './subscription/reducer';
import userSubscription from './subscription/user/reducer';
import resetPassword from './reset_password/reducer';
import review from './review/reducer';
import publicationFavorite from './publicationFavorite/reducer';
import phone from './phone/reducer';

const IndexReducer = combineReducers({
    form,
    toastr: toastrReducer,
    iu,
    city,
    module,
    category,
    time,
    filter,
    publication,
    search,
    publicationsTalk,
    chat,
    typePeople,
    typeProfessional,
    typeProfile,
    gender,
    subscription,
    userSubscription,
    payment,
    user,
    client,
    login,
    resetPassword,
    review,
    publicationFavorite,
    phone
});

export default IndexReducer;
