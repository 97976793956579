import {
    ERROR_SUBSCRIPTION,
    GET_ALL_SUBSCRIPTION_SUCCESS,
    GET_ALL_SUBSCRIPTION_REQUESTING,
    GET_ALL_SUBSCRIPTION_ERROR,
    SET_FOCUS_SUBSCRIPTION,
    SHOW_MODAL_SUBSCRIPTION
} from './constants';

const initialState = {
    requesting: false,
    success: false,
    error: '',
    errorSubscription : false,
    subscriptions:[],
    subscriptionFocus :[],
    isOpenSubscription: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL_SUBSCRIPTION:
            return {
                ...state,
                isOpenSubscription: action.modal === 'modal subscripcion',
            };
        case GET_ALL_SUBSCRIPTION_REQUESTING:
            return {
                ...state,
                error: '',
                success: false,
                requesting: false
            };
        case GET_ALL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptions: action.subscriptions,
                error: '',
                success: true,
                requesting: false
            };
        case GET_ALL_SUBSCRIPTION_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                subscriptions: []
            };
        case ERROR_SUBSCRIPTION:
            return {
                ...state,
                errorSubscription: !state.errorSubscription
            };
        case SET_FOCUS_SUBSCRIPTION :
            return {
                ...state,
                subscriptionFocus: action.subscription,
            };

        default :
            return state;
    }
};

export default reducer;
