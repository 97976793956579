import {
    LOGIN_ERROR,
    LOGIN_REQUESTING,
    LOGIN_SUCCESS,
    LOGOUT_ERROR,
    LOGOUT_REQUESTING,
    LOGOUT_SUCCESS,
    ERROR_CONTROL_LOGIN,
    LOGIN_FACEBOOK_ERROR,
    LOGIN_FACEBOOK_REQUESTING,
    LOGIN_FACEBOOK_SUCCESS,
} from "./constants";

export const loginRequesting = (values) => ({
    type: LOGIN_REQUESTING,
    values
});

export const loginSuccess = (token) => ({
    type: LOGIN_SUCCESS,
    token
});

export const loginError = (error) => ({
    type: LOGIN_ERROR,
    error
});

export const logoutRequesting = (token) => ({
    type: LOGOUT_REQUESTING,
    token,
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logoutError = (error) => ({
    type: LOGOUT_ERROR,
    error,
});

export const errorControlLogin = () =>({
   type: ERROR_CONTROL_LOGIN
});

export const loginFacebookRequesting = (response) => ({
    type: LOGIN_FACEBOOK_REQUESTING,
    response,
});

export const loginFacebookSuccess = (token) => ({
    type: LOGIN_FACEBOOK_SUCCESS,
    token
});

export const loginFacebookError = (error) => ({
    type: LOGIN_FACEBOOK_ERROR,
    error,
});

