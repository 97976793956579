export const GET_PUBLICATION_FAVORITES_REQUESTING = 'GET_PUBLICATION_FAVORITES_REQUESTING';
export const GET_PUBLICATION_FAVORITES_SUCCESS = 'GET_PUBLICATION_FAVORITES_SUCCESS';
export const GET_PUBLICATION_FAVORITES_ERROR = 'GET_PUBLICATION_FAVORITES_ERROR';

export const ADD_FAVORITE_REQUESTING = 'ADD_FAVORITE_REQUESTING';
export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS';
export const ADD_FAVORITE_ERROR = 'ADD_FAVORITE_ERROR';

export const REMOVE_FAVORITE_REQUESTING = 'REMOVE_FAVORITE_REQUESTING';
export const REMOVE_FAVORITE_SUCCESS = 'REMOVE_FAVORITE_SUCCESS';
export const REMOVE_FAVORITE_ERROR = 'REMOVE_FAVORITE_ERROR';