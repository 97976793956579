import React, {useEffect, useState} from "react";
import {COLORS} from "../../theme/colors";
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import {checkJWTRequesting} from "../../redux/client/actions";

const PageLoading = (props) => {

    const [loadScreen, setLoadScreen] = useState(true);

    useEffect(() => {
        const {checkJWTRequesting} = props;
        let token = localStorage.getItem('@user:tuConstruccion');
        checkJWTRequesting(token);
        setTimeout(() => {
            setLoadScreen(false);
        }, 1800);
    }, []);

    return <div style={loadScreen ? {
        width: '100%',
        height: '120vh',
        backgroundColor: COLORS.PRIMARY,
        position: 'fixed',
        zIndex: 99999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    } : {display: 'none'}}>
        <img src={require('./../../assets/img/Diseño-header.png')} style={{width: '50%', marginBottom: 60}}/>
        <CircularProgress style={{color: 'white'}}/>

    </div>
};

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {
    checkJWTRequesting,
})(PageLoading);

export default connected;