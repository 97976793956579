import {
    SHOW_MODAL_PAYMENT,
    PAY_SUBSCRIPTION_CASH_ERROR,
    PAY_SUBSCRIPTION_CASH_REQUESTING,
    PAY_SUBSCRIPTION_CASH_SUCCESS,
    PAY_SUBSCRIPTION_CREDIT_CARD_ERROR,
    PAY_SUBSCRIPTION_CREDIT_CARD_REQUESTING,
    PAY_SUBSCRIPTION_CREDIT_CARD_SUCCESS,
    GET_PAYMENT_ERROR,
    GET_PAYMENT_REQUESTING,
    GET_PAYMENT_SUCCESS,
    GET_LIST_BANKS_REQUESTING,
    GET_LIST_BANKS_SUCCESS,
    GET_LIST_BANKS_ERROR,
    PAY_SUBSCRIPTION_PSE_REQUESTING, PAY_SUBSCRIPTION_PSE_SUCCESS, PAY_SUBSCRIPTION_PSE_ERROR,

} from "./constants";

export const paySubscriptionCashRequesting = (token, franchise, total, user, subscription, payment) => ({
    type: PAY_SUBSCRIPTION_CASH_REQUESTING,
    token,
    franchise,
    total,
    user,
    subscription,
    payment
});

export const paySubscriptionCashSuccess = (payCash) => ({
    type: PAY_SUBSCRIPTION_CASH_SUCCESS,
    payCash
});

export const paySubscriptionCashError = (error) => ({
    type: PAY_SUBSCRIPTION_CASH_ERROR,
    error
});

export const paySubscriptionCreditCardRequesting = (token, dataCreditCard, total, user, subscription, payment) => ({
    type: PAY_SUBSCRIPTION_CREDIT_CARD_REQUESTING,
    token,
    dataCreditCard,
    total,
    user,
    subscription,
    payment
});

export const paySubscriptionCreditCardSuccess = (pay) => ({
    type: PAY_SUBSCRIPTION_CREDIT_CARD_SUCCESS,
    pay
});

export const paySubscriptionCreditCardError = (error) => ({
    type: PAY_SUBSCRIPTION_CREDIT_CARD_ERROR,
    error
});

export const getPaymentRequesting = (token) => ({
    type: GET_PAYMENT_REQUESTING,
    token
});

export const getPaymentSuccess = (payments) => ({
    type: GET_PAYMENT_SUCCESS,
    payments
});

export const getPaymentError = (error) => ({
    type: GET_PAYMENT_ERROR,
    error
});

export const showModalPayment = (modal) => ({
    type: SHOW_MODAL_PAYMENT,
    modal
});

export const getListBanksRequesting = () => ({
    type: GET_LIST_BANKS_REQUESTING
});

export const getListBanksSuccess = banks => ({
    type: GET_LIST_BANKS_SUCCESS,
    banks
});

export const getListBanksError = error => ({
    type: GET_LIST_BANKS_ERROR,
    error
});


export const paymentPseRequesting = (token, values) => ({
    type: PAY_SUBSCRIPTION_PSE_REQUESTING,
    token,
    values
});

export const paymentPseSuccess = (response) => ({
    type: PAY_SUBSCRIPTION_PSE_SUCCESS,
    response
});

export const paymentPseError = error => ({
    type: PAY_SUBSCRIPTION_PSE_ERROR,
    error
})
