import {
    CHECK_JWT_ERROR,
    CHECK_JWT_REQUESTING,
    CLIENT_SET,
    CLIENT_UNSET,
    GET_ME_ERROR,
    GET_ME_REQUESTING,
    GET_ME_SUCCESS,
    UPDATE_CLIENT,
    SAVE_DEVICE,
    DELETE_DEVICE, ENABLED, DISABLED,
} from "./constants";


const initialState = {
    logged: false,
    token: null,
    client: {},
    requesting: false,
    success: false,
    error: '',
    enabled: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case CLIENT_SET:
            return{
                ...state,
                token: action.token,
                requesting: true,
                logged: true,
            };
        case CLIENT_UNSET:
            return {
                ...state,
                token: null,
                logged: false,
                client: {}
            };
        case CHECK_JWT_REQUESTING:
            return {
                ...state,
                requesting: true,
                client: {},
                token: null
            };
        case CHECK_JWT_ERROR:
            return {
                ...state,
                requesting: false,
            };
        case GET_ME_REQUESTING:
            return {
                ...state,
                requesting: true
            };
        case GET_ME_SUCCESS:
            return {
                ...state,
                client: action.client,
                requesting: true
            };
        case GET_ME_ERROR:
            return {
                ...state,
                client: {},
                requesting: false
            };

        case UPDATE_CLIENT:
            return {
                ...state,
                client: action.client,
            };
        case ENABLED:
            return {
                ...state,
                enabled: true
            };
        case DISABLED:
            return {
                ...state,
                enabled: false
            };
        default:
            return state;
    }
};

export default reducer;