import React from 'react';
//Material-ui
import {MuiThemeProvider} from '@material-ui/core/styles';
import Rater from 'react-rater'
import "react-id-swiper/lib/styles/css/swiper.css";
import posed from "react-pose";
import {theme} from "../../../../theme/theme";
//img
import {connect} from "react-redux";
import {getReviewRequesting} from "../../../../redux/review/actions";
import {actualPage, filterChangePrimaryProfile} from "../../../../redux/iu/actions";
import lottie from 'lottie-web';
//button

//////////effect/////////

const Box = posed.div({
    visible: {
        x: '0%',
        delayChildren: 1000,
        staggerChildren: 200,
        delay: 50
    },
    hidden: {x: '100%'}
});

const Item = posed.div({
    visible: {
        scale: 1,
    },
    hidden: {scale: 0}
});

const ItemHover = posed.div({
    hoverable: true,
    init: {
        scale: 1,
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        // boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
});

///////////effect///////

// import FilterUserPrincipalComponent from "../../../filter/filter_profile_principal";
// import {PageProfile} from "../profile/profile";

class PageComments extends React.Component {

    constructor(props) {
        super(props);
        this.animation = React.createRef();
    };

    state = {
        isVisible: false,
        isOpen: false,
        value: 0,
        isOpenProduct: false,
        isOpenFilter: false,
        productPost: [1, 2, 3, 4, 5, 6],
        focusFilter: '',
        focusFilter2: '',

    };


    componentDidMount() {
        this.setState({
            isVisible: true
        });
        lottie.loadAnimation({
            container: this.animation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/review.json'
        });
    }

    handleFocusPrimary = (focusFilter1) => {
        this.setState({
            focusFilter1: focusFilter1
        });
    };

    handleFocus = (focusFilter2) => {
        this.setState({
            focusFilter2: focusFilter2
        });
    };

// control de modal
    handleControlModal = (modal) => {
        this.setState({
            isOpenProduct: modal === 'modal product',
            isOpenFilter: modal === 'modal filter',
        });
    };

    render() {
        const {isOpenProduct, isOpenFilter, productPost} = this.state;
        ////////////////////////////////////////////////////////
        const {
            review: {reviews, reviewsSend},
        } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <Box pose={this.state.isVisible ? 'visible' : 'hidden'}>
                    <div className="container-item-start">
                        <div className="line"/>
                            <div className="container-sub-text">
                                <h2>Comentarios</h2>
                                <p>Opiniones de usuarios</p>
                            </div>

                        {(reviews.length === 0 || reviewsSend.length === 0) &&
                        (<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div className="container-animations-empty-one">
                                    <div className={'animation'} ref={this.animation}/>
                                </div>
                                <p className="p-animation-empty-one" style={{fontSize: 22, color: 'rgba(0,0,0,.3)'}}>No
                                    cuentas con
                                    reseñas ...</p>
                            </div>
                        )}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between'
                        }}>
                            {reviewsSend.length > 0 && reviewsSend.map(review => (


                                <div
                                    className={productPost !== review ? "container-profile-product" : "container-profile-product"}
                                    style={{display: 'flex', width: 'auto'}}>
                                    <div className="profile-product-item1"
                                         style={{flexDirection: 'column', alignItems: 'center'}}>
                                        <img src={review.publicacion.foto} alt="Logo"/>
                                        <p style={{marginTop: 8, fontWeight: 'bold'}}>Realizado</p>
                                    </div>
                                    <div className="container-sub-text" style={{marginLeft: 20}}>
                                        <Rater total={5} rating={review.puntuacion} interactive={false}/>
                                        <h2>{review.usuario_emisor.nombre}</h2>
                                        <p style={{maxWidth: 200}}>{review.mensaje}</p>
                                        <p>Publicado : {review.fecha.date.split('.')[0]}</p>
                                    </div>
                                </div>

                            ))}
                            {reviews.length > 0 && reviews.map(review => (
                                <div
                                    className={productPost !== review ? "container-profile-product" : "container-profile-product"}
                                    style={{display: 'flex', width: 'auto'}}>
                                    <div className="profile-product-item1"
                                         style={{flexDirection: 'column', alignItems: 'center'}}>
                                        <img src={review.publicacion.foto} alt="Logo"/>
                                        <p style={{marginTop: 8, fontWeight: 'bold'}}>Recibido</p>
                                    </div>
                                    <div className="container-sub-text" style={{marginLeft: 20}}>
                                        <Rater total={5} rating={review.puntuacion} interactive={false}/>
                                        <h2>{review.usuario_emisor.nombre}</h2>
                                        <p style={{maxWidth: 200}}>{review.mensaje}</p>
                                        <p>Publicado : {review.fecha.date.split('.')[0]}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </Box>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    review: state.review
});

const connected = connect(mapStateToProps, {
    getReviewRequesting,
    actualPage,
    filterChangePrimaryProfile
})(PageComments);

export default connected;
