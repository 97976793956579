import {
    ADD_FAVORITE_ERROR,
    ADD_FAVORITE_REQUESTING,
    ADD_FAVORITE_SUCCESS,
    GET_PUBLICATION_FAVORITES_ERROR,
    GET_PUBLICATION_FAVORITES_REQUESTING,
    GET_PUBLICATION_FAVORITES_SUCCESS,
    REMOVE_FAVORITE_ERROR,
    REMOVE_FAVORITE_REQUESTING,
    REMOVE_FAVORITE_SUCCESS
} from "./constants";


const initialState = {
    requesting: false,
    success: false,
    error: '',
    publicationFavorites: [],
    messages: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PUBLICATION_FAVORITES_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_PUBLICATION_FAVORITES_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                publicationFavorites: action.publicationFavorites
            };
        case GET_PUBLICATION_FAVORITES_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case ADD_FAVORITE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case ADD_FAVORITE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                messages: action.messages
            };
        case ADD_FAVORITE_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case REMOVE_FAVORITE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case REMOVE_FAVORITE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                publicationFavorites: state.publicationFavorites.filter(publication => publication.id_favorito !== action.messages.id_favorito)
            };
        case REMOVE_FAVORITE_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default reducer;