import {
    CHANGE_STATE_TO_SOLD_ERROR,
    CHANGE_STATE_TO_SOLD_REQUESTING,
    CHANGE_STATE_TO_SOLD_SUCCESS,
    CREATE_MESSAGE_ERROR,
    CREATE_MESSAGE_REQUESTING,
    CREATE_MESSAGE_SUCCESS,
    CREATE_ROOM_CHAT_ERROR,
    CREATE_ROOM_CHAT_REQUESTING,
    CREATE_ROOM_CHAT_SUCCESS,
    ENTRY_MESSAGE,
    FOCUS_CHAT,
    GET_LIST_MESSAGING_ERROR,
    GET_LIST_MESSAGING_REQUESTING,
    GET_LIST_MESSAGING_SUCCESS,
    GET_MESSAGES_ERROR,
    GET_MESSAGES_REQUESTING,
    GET_MESSAGES_SUCCESS
} from "./constants";

export const getListMessagingRequesting = (token) => ({
    type: GET_LIST_MESSAGING_REQUESTING,
    token
});

export const getListMessagingSuccess = (list) => ({
    type: GET_LIST_MESSAGING_SUCCESS,
    list
});

export const getListMessagingError = () => ({
    type: GET_LIST_MESSAGING_ERROR
});

export const focusChat = (title, focus, to, titleProduct, photoProduct, userBuyer, userPayer, score) => ({
    type: FOCUS_CHAT,
    title,
    focus,
    to,
    titleProduct,
    photoProduct,
    userBuyer,
    userPayer,
    score
});

export const getMessageRequesting = (room, token, cursor) => ({
    type: GET_MESSAGES_REQUESTING,
    room,
    token,
    cursor
});

export const getMessageSuccess = (messages) => ({
    type: GET_MESSAGES_SUCCESS,
    messages
});

export const getMessageError = () => ({
    type: GET_MESSAGES_ERROR
});

export const entryNewMessage = (message) => ({
    type: ENTRY_MESSAGE,
    message
});

export const createMessageRequesting = (room, token, to, message) => ({
    type: CREATE_MESSAGE_REQUESTING,
    room,
    token,
    to,
    message
});

export const createMessageSuccess = (message) => ({
    type: CREATE_MESSAGE_SUCCESS,
    message
});

export const createMessageError = () => ({
    type: CREATE_MESSAGE_ERROR
});

export const createRoomChatRequesting = (token, to, product) => ({
    type: CREATE_ROOM_CHAT_REQUESTING,
    token,
    to,
    product
});


export const createRoomChatSuccess = (room) => ({
    type: CREATE_ROOM_CHAT_SUCCESS,
    room
});

export const createRoomChatError = (error) => ({
    type: CREATE_ROOM_CHAT_ERROR,
    error
});

export const changeStateToSoldRequesting = (token, roomChat) => ({
    type: CHANGE_STATE_TO_SOLD_REQUESTING,
    token,
    roomChat
});

export const changeStateToSoldSuccess = (roomChat) => ({
    type: CHANGE_STATE_TO_SOLD_SUCCESS,
    roomChat
});

export const changeStateToSoldError = (error) => ({
    type: CHANGE_STATE_TO_SOLD_ERROR,
    error
});