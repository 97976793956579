import {
    SHOW_MODAL_PAYMENT,
    PAY_SUBSCRIPTION_CASH_ERROR,
    PAY_SUBSCRIPTION_CASH_REQUESTING,
    PAY_SUBSCRIPTION_CASH_SUCCESS,
    PAY_SUBSCRIPTION_CREDIT_CARD_ERROR,
    PAY_SUBSCRIPTION_CREDIT_CARD_REQUESTING,
    PAY_SUBSCRIPTION_CREDIT_CARD_SUCCESS,
    GET_PAYMENT_ERROR,
    GET_PAYMENT_REQUESTING,
    GET_PAYMENT_SUCCESS,
    GET_LIST_BANKS_SUCCESS,
    PAY_SUBSCRIPTION_PSE_REQUESTING,
    PAY_SUBSCRIPTION_PSE_ERROR,
    PAY_SUBSCRIPTION_PSE_SUCCESS,
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    isModalPayment: false,
    pay: {},
    payCash: {},
    payments: [],
    banks: [],
    response: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL_PAYMENT:
            return {
                ...state,
                isModalPayment: action.modal === 'modal de pagos',
            };
        case PAY_SUBSCRIPTION_CASH_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case PAY_SUBSCRIPTION_CASH_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
                payCash: action.payCash
            };
        case PAY_SUBSCRIPTION_CASH_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case PAY_SUBSCRIPTION_CREDIT_CARD_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case PAY_SUBSCRIPTION_CREDIT_CARD_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                pay: action.pay
            };
        case PAY_SUBSCRIPTION_CREDIT_CARD_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };
        case GET_PAYMENT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_PAYMENT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                payments: action.payments
            };
        case GET_PAYMENT_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                payments: []
            };

        case PAY_SUBSCRIPTION_PSE_REQUESTING :
            return {
                ...state,
                requesting: true,
                response: {}
            };
        case PAY_SUBSCRIPTION_PSE_ERROR :
            return {
                ...state,
                requesting: false,
                response: {}
            };

        case PAY_SUBSCRIPTION_PSE_SUCCESS:
            return {
                ...state,
                requesting: false,
                response: action.response
            }
        case GET_LIST_BANKS_SUCCESS :
            return {
                ...state,
                banks: action.banks
            };
        default :
            return state;
    }
};

export default reducer;
