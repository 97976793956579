import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';






// ******body******
import './styles/styles-body.css';
import './styles/Styles-header.css';
import './styles/Styles-slider.css';
import './styles/Styles-footer.css';

// ******Estilos de contenedores******
import './styles/Styles-container.css';
import './vendor/css/fontello.css';
import App from './App';

import 'react-input-range/lib/css/index.css';
import {IntlProvider} from "react-intl";
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga';


ReactGA.initialize('UA-150826540-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const advancedMatching = { em: 'tuconstruccioncol@gmail.com' };
const options = {
    autoConfig: true,
    debug: false
};
ReactPixel.init('2452186245056995', advancedMatching, options);

ReactPixel.pageView();
ReactPixel.fbq('track', 'pageView');



ReactDOM.render(
    <div>
    <IntlProvider locale={'es-CO'}>
        <App/>
    </IntlProvider>,
    </div>,
    document.getElementById('root')

);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
        // console.log(registration)
        }
    );
serviceWorker.unregister();

module.hot.accept();
