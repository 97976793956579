import {FILTER_CHANGE_FORM, FILTER_HIDDEN_MODAL, FILTER_RESET_STATES, FILTER_SHOW_MODAL} from "./constants";

export const filterShowModal = (modal) => ({
    type: FILTER_SHOW_MODAL,
    modal,
});

export const filterHiddenModal = (modal) => ({
    type: FILTER_HIDDEN_MODAL,
    modal,
});

export const filterChangeForm = (key, value) => ({
    type: FILTER_CHANGE_FORM,
    key, value,
});

export const filterResetStates = () => ({
    type: FILTER_RESET_STATES,
});