import {all, call, put, take, takeEvery} from 'redux-saga/effects'
import {eventChannel} from 'redux-saga'
import Echo from 'laravel-echo';
import io from 'socket.io-client';
import {toastr} from "react-redux-toastr";
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {reset} from "redux-form";

import {
    CHANGE_STATE_TO_SOLD_REQUESTING,
    CONNECT_TO_SOCKET,
    CREATE_MESSAGE_REQUESTING,
    CREATE_ROOM_CHAT_REQUESTING,
    GET_LIST_MESSAGING_REQUESTING,
    GET_MESSAGES_REQUESTING
} from "./constants";
import {
    changeStateToSoldError,
    changeStateToSoldSuccess,
    createMessageError,
    createMessageSuccess,
    createRoomChatError,
    createRoomChatSuccess,
    entryNewMessage,
    getListMessagingError,
    getListMessagingRequesting,
    getListMessagingSuccess,
    getMessageError,
    getMessageSuccess
} from "./actions";

import {filterChangePrimaryProfile} from "../iu/actions";
import {handleShowError} from "../../utils/showError";

const ROOM_CHAT_URL = `${process.env.REACT_APP_API_URL}/roomChats`;
const MESSAGES_URL = `${process.env.REACT_APP_API_URL}/messages`;
const CHANGE_STATE_SOLD_URL = `${process.env.REACT_APP_API_URL}/soldPublication`;


const connect = (token) => {
    const echo = new Echo({
        broadcaster: 'socket.io',
        host: 'wss://api.tuconstruccion.com.co',
        client: io,
        auth: {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('@user:tuConstruccion'),
            },
        },
    });


    return new Promise(resolve => {
        resolve(echo);
    });
};

const createSocketChannel = (socket, me) => eventChannel((emit) => {
    const handler = (data) => {
        return emit(data);
    };

    socket.private('new-message.' + me.id).listen('MessageEvent', handler);

    return () => {
        socket.off('newTask', handler);
    };
});


const listenServerSaga = function* (action) {
    const {client} = action;
    const socket = yield call(connect);
    const socketChannel = yield call(createSocketChannel, socket, client);
    while (true) {
        const payload = yield take(socketChannel);
        yield put(entryNewMessage(payload.message.data));
        // toastr.message(payload.message.data.usuario_desde.nombre, payload.message.data.mensaje);
        // DropDownHolder.alert('warn', payload.message.data.usuario_desde.nombre, payload.message.data.mensaje, {
        //     message: payload.message.data.mensaje,
        //     source: payload.message.data.usuario_a.foto
        // })
    }
};


const getListChatApi = (token) => {

    return fetch(ROOM_CHAT_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            throw ''
        }).catch((error) => {
            throw error;
        })
};

function* getListChatFlow(action) {
    try {
        const {token} = action;
        const list = yield call(getListChatApi, token);
        yield put(getListMessagingSuccess(list));
    } catch (error) {
        yield put(getListMessagingError(error));
    }
}


const getMessagesChatApi = (room, token, cursor) => {

    return fetch(`${MESSAGES_URL}/${room}?page=${cursor}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getMessageChatFlow(action) {
    try {
        const {room, token, cursor} = action;
        const messages = yield call(getMessagesChatApi, room, token, cursor);
        yield put(getMessageSuccess(messages));
    } catch (error) {
        yield put(getMessageError(error));

    }
}


const createMessageChatApi = (room, token, to, message) => {
    if(message.length === 0){
        toastr.error('Debe escribir algo ...');
        return;
    }
    let body = {
        'message': message,
        'to': to,
        'room_chat_id': room
    };
    return fetch(`${MESSAGES_URL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            throw ''
        }).catch((error) => {
            throw error;
        })
};

function* createMessageChatFlow(action) {
    try {
        const {room, token, to, message} = action;
        const messages = yield call(createMessageChatApi, room, token, to, message);
        yield put(createMessageSuccess(messages));
        yield put(reset('roomChat'));
        yield put(getListMessagingRequesting(token));
    } catch (error) {
        yield put(createMessageError)
    }
}


const createRoomChatApi = (token, to, product) => {
    let body = {
        'product_id': product,
        'user_payer_id': to
    };
    return fetch(`${ROOM_CHAT_URL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            throw ''
        }).catch((error) => {
            throw error;
        })
};

function* createRoomChatFlow(action) {
    try {
        const {token, to, product} = action;
        const messages = yield call(createRoomChatApi, token, to, product);
        yield put(createRoomChatSuccess(messages));
        yield put(filterChangePrimaryProfile('Mensajes'));
    } catch (error) {
        yield put(createRoomChatError(error))
    }
}


const changeStateToSoldApi = (token, roomChat) => {

    return fetch(`${CHANGE_STATE_SOLD_URL}/${roomChat}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            console.log(json);
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw {error: json.data};
            if (json.code === 200)
                return json.data.data;
            throw ''
        }).catch((error) => {
            throw error;
        })
};

function* changeStateToSoldFlow(action) {
    try {
        const {token, roomChat} = action;
        const room = yield call(changeStateToSoldApi, token, roomChat);
        yield put(changeStateToSoldSuccess(room));
    } catch (error) {
        yield put(changeStateToSoldError(error));
        handleShowError(error);
    }
}

function* serverWatcher() {
    yield all([
        takeEvery(CHANGE_STATE_TO_SOLD_REQUESTING, changeStateToSoldFlow),
        takeEvery(CONNECT_TO_SOCKET, listenServerSaga),
        takeEvery(GET_LIST_MESSAGING_REQUESTING, getListChatFlow),
        takeEvery(GET_MESSAGES_REQUESTING, getMessageChatFlow),
        takeEvery(CREATE_MESSAGE_REQUESTING, createMessageChatFlow),
        takeEvery(CREATE_ROOM_CHAT_REQUESTING, createRoomChatFlow)
    ]);
}

export default serverWatcher