import React from 'react';
import {PageProduct} from "./page/product/page-product";
import PageFavorites from "./page/favorites/page-favorites";
import FilterUserPrincipalComponent from "../filter/filter_profile_principal";
import PageComments from "./page/comments/comments";
import {PageProfile} from "./page/profile/profile";
import PageChat from "./page/chat_messages/chat";
import PageSuvscription from "./page/subscription-view/page-subscription-view";
import {filterGender} from "../../redux/gender/actions";
import {showModalUser} from "../../redux/user/actions";
import {visibleTypeProfessional} from "../../redux/typeProfessional/actions";
import {getSubscriptionUserRequesting} from "../../redux/subscription/user/actions";
import {addFavoriteRequesting} from "../../redux/publicationFavorite/actions";
//button
//Buscador
/// img
import {connect} from "react-redux";

import {
    publicationChangeStatePutRequesting,
    publicationGetRequesting,
    publicationHiddenModal,
    publicationShowModal,
    publicationsMePostRequesting,
    cleanPublication
} from "../../redux/publication/actions";
import {
    publicationsTalkFocus,
    publicationsTalkHiddenModal,
    publicationsTalkShowModal,
    publicationsTalksMeGetRequesting
} from "../../redux/publicationsTalks/actions";
import {actualPage, filterChangeSecondaryProfile} from "../../redux/iu/actions";
import {toastr} from "react-redux-toastr";
import {getReviewRequesting, getSendReviewRequesting} from "../../redux/review/actions";

class ProfileComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            // client: {token},
            publication: {mePublicationsIds},
            publicationsTalk,
            publicationsMePostRequesting,
            publicationsTalksMeGetRequesting,
            actualPage,
            getSubscriptionUserRequesting,
            cleanPublication,
            getReviewRequesting,
            client: {client},
        } = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        //let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvYXBpL2F1dGgvdXNlciIsImlhdCI6MTU2NTcwNjMyMSwiZXhwIjoxNTY1OTIyMzIxLCJuYmYiOjE1NjU3MDYzMjEsImp0aSI6IkFJd2RIZ2VBcVp2NWhiMVkiLCJzdWIiOjIsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.qhkTqRo9Kd7EVzgMHO4diyJPos3RdDnmkWg88_5G3B4';
        cleanPublication();
        publicationsMePostRequesting(token, mePublicationsIds, 1);
        publicationsTalksMeGetRequesting(token, publicationsTalk.paginate);
        getSubscriptionUserRequesting(token);

    };

    state = {
        arrayFilter: ['Productos', 'Favoritos', 'Mensajes', 'Comentarios', 'Mi Suscripción'],
        focus: ''
    };

    handleFocus = (object, type) => {
        const {publicationGetRequesting, publicationsTalkFocus, client: {token}} = this.props;
        switch (type) {
            case 'publication':
                publicationGetRequesting(object, token);
                break;
            case 'publicationsTalk':
                publicationsTalkFocus(object);
                break;
        }
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {client: {client, token}, getReviewRequesting, getSendReviewRequesting} = this.props;
        if (nextProps.client.token !== null && nextProps.client.token !== token) {
            getReviewRequesting(nextProps.client.token, client.id);
            getSendReviewRequesting(nextProps.client.token, client.id);

        }

    };

    handleShowModal = (modal, type) => {
        const {publicationShowModal, publicationsTalkShowModal} = this.props;
        switch (type) {
            case 'publication':
                publicationShowModal(modal);
                break;
            case 'publicationsTalk':
                publicationsTalkShowModal(modal);
                break;
        }
    };

    handleHiddenModal = (modal, type) => {
        const {publicationHiddenModal, publicationsTalkHiddenModal} = this.props;
        switch (type) {
            case 'publication':
                publicationHiddenModal(modal);
                break;
            case 'publicationsTalk':
                publicationsTalkHiddenModal(modal);
                break;
        }
    };

    handleChangeState = (publication) => {
        const {
            // client: {token},
            publicationChangeStatePutRequesting,
        } = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        if (publication.observacion !== null && publication.estado.nombre === 'Inhabilitado')
            toastr.error('Error al activar', publication.observacion);
        if (publication.observacion === null)
            publicationChangeStatePutRequesting(token, publication.id);
    };

    handleFocusFilter = (target, type) => {
        const {filterChangeSecondaryProfile} = this.props;
        switch (type) {
            case 'secondary':
                filterChangeSecondaryProfile(target);
                break;
        }
    };

    handleAddFavorite = (publication) => {
        const {
            addFavoriteRequesting,
            client: {client, token}
        } = this.props;
        addFavoriteRequesting(token, client.id, publication.id)
    };

    render() {
        const {focus} = this.state;
        const {
            publication: {mePublications, publication, viewPublication, editPublication, publicationIdUpdate, requesting},
            publicationsTalk: {publicationsTalks, publicationsTalk, viewPublicationsTalk},
            iu: {filtersSecondary, filterSecondary, filterPrimary},
            client: {client, enabled},
            user: {isOpenModal},
            typePeople: {typePeoples},
            filterGender,
            visibleTypeProfessional,
            showModalUser
        } = this.props;
        return (
            <div>
                {/*<SliderPage/>*/}
                <br/>
                <br/>
                <br/>
                <br/>
                <PageProfile
                    user={client}
                    isOpenModal={isOpenModal}
                    typePeoples={typePeoples}
                    handleControlModal={showModalUser}
                    handleFilterGender={filterGender}
                    visibleProfessional={visibleTypeProfessional}
                />
                <div className="container-item-start">
                    <FilterUserPrincipalComponent handleFocus={this.handleFocusFilter.bind(this)}/>
                </div>
                {filterPrimary === 'Productos' && (
                    <PageProduct
                        client={client}
                        enabled={enabled}
                        visible={true}
                        filters={filtersSecondary}
                        filter={filterSecondary}
                        publications={mePublications}
                        publicationsTalks={publicationsTalks}
                        publication={publication}
                        publicationsTalk={publicationsTalk}
                        viewPublication={viewPublication}
                        viewPublicationsTalk={viewPublicationsTalk}
                        editPublication={editPublication}
                        focusFilter={this.handleFocusFilter}
                        focus={this.handleFocus}
                        showModal={this.handleShowModal}
                        hiddenModal={this.handleHiddenModal}
                        changeState={this.handleChangeState}
                        addFavorite={this.handleAddFavorite}
                        focusId={publicationIdUpdate}
                        requesting={requesting}
                    />
                )}

                {filterPrimary === 'Favoritos' && (
                    <div>
                        <PageFavorites/>
                    </div>
                )}
                {filterPrimary === 'Mensajes' && (
                    <PageChat/>
                )}
                {filterPrimary === 'Comentarios' && (
                    <PageComments/>
                )}
                {filterPrimary === 'Suscripciones' && (
                    <PageSuvscription/>
                )}
                {/*<FooterComponent/>*/}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    iu: state.iu,
    client: state.client,
    user: state.user,
    publication: state.publication,
    publicationsTalk: state.publicationsTalk,
    typePeople: state.typePeople
});

const connected = connect(mapStateToProps, {
    actualPage,
    //
    publicationsMePostRequesting,
    publicationGetRequesting,
    publicationShowModal,
    publicationHiddenModal,
    publicationChangeStatePutRequesting,
    //
    publicationsTalksMeGetRequesting,
    publicationsTalkFocus,
    publicationsTalkShowModal,
    publicationsTalkHiddenModal,
    //
    filterChangeSecondaryProfile,
    filterGender,
    visibleTypeProfessional,
    showModalUser,
    getSubscriptionUserRequesting,
    getReviewRequesting,
    //
    addFavoriteRequesting,
    getSendReviewRequesting,
    cleanPublication
})(ProfileComponent);

export default connected;

