import {createMuiTheme} from "@material-ui/core";
import {COLORS} from "./colors";

export const theme = createMuiTheme({
    overrides: {
        /////////////////////Modals/////////////////////
        MuiDialogTitle: {
            root: {
                paddingBottom: '5px',
            }
        },
        MuiDialog: {
            paper: {
                margin: '5px',
                padding: '5px',

            }
        },
        MuiDialogContent: {
            root: {
                padding: '15px',
                minWidth: '310px',
                width: '100%',

            }
        },
        /////////////////////Menur board//////////////////////
        MuiCollapse: {
            container: {
                borderLeft: '3px solid #cd7a1b'

            }
        },

        MuiTypography: {
            subheading: {
                color: '#7e7e7e'
            }
        },

        MuiListItem: {

            button: {
                width: '220px',
            },
            gutters: {
                width: '220px',
                padding: '14px',
            },

        },

        MuiMenu: {

            gutters: {
                width: 'hidden',
                maxWidth: 'auto'
            }
        },

        MuiMenuItem: {
            root: {
                width: '200px',


            },

            gutters: {
                width: 'hidden',
                maxWidth: 'auto'
            }
        },

        MuiDrawer: {
            paper: {
                width: '280px',
                elevation2: {
                    width: '500x',
                },
            }
        },
        MuiSvgIcon: {
            root: {
                color: '#393939',
                fontSize: '26px',
                '&:hover': {}
            }
        },
        MuiButtonBase: {
            root: {}
        },
        MuiList: {
            padding: {
                paddingTop: '0'
            },
            root: {
                color: COLORS.PRIMARY,
                width: '100%'
            }
        },

/////////////////////buscador//////////////////////
        MuiInputBase: {
            root: {
                width: '100%',
                // paddingLeft: '13px'
                paddingLeft:'7px'
            }
        },
        MuiPaper: {
            elevation1: {
                borderRadius: '8px',
                border: '1px solid #E8E8E8',
                // width: '100%',
                minWidth: '100px',
                height: '47px',
            },
            elevation8: {
                minWidth: '200px'
            },
            root: {
                padding: '5px 4px',
                display: 'flex',
                alignItems: 'center',
                color: 'red',

            },
            iconButton: {
                padding: '50',
            },

        },
/////////////////////input//////////////////////
        MuiOutlinedInput: {
            root: {
                width: '100%',
                height: '40px',
                color: '#7e7e7e'
            },

        },

        MuiInputLabel: {
            outlined: {
                transform: 'translate(13px,12px) scale(1)'
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
            }
        },
        MuiFormLabel: {
            root: {
                color: '#7e7e7e',
                fontSize: '15px'
            },
            '&&focused': {
                backgroundColor:'red',

            }
        },
        MuiSelect: {
            icon: {
                marginRight: '10px',
                // color:'red'
            }
        },
//////////////////button//////////////////////
        MuiIcon: {
            root: {
                fontSize: '15px',
                marginLeft: '5px',
                marginTop: '-2px'
            }
        },
        MuiButton: {
            hover: {
                backgroundColor: '#ff002a'
            },
            icon: {
                fontSize: '10px'
            },
            // Styles button 1
            containedPrimary: {
                backgroundColor: '#fa2265',
                '&:hover': {
                    backgroundColor: '#4e4e4e'
                },
                width: '100%',
                height: '90%',
                borderRadius: '6px',
                fontSize: '12px'
            },
            // Styles button 1
            containedSecondary: {
                backgroundColor: COLORS.TERTIARY,
                border: '2px solid COLORS.PRIMARY',
                '&:hover': {
                    color: '#ffffff',
                    backgroundColor: COLORS.SECONDARY,
                },
                width: '100%',
                height: '90%',
                borderRadius: '6px',
                fontSize: '12px'
            }
        },
    }
});
