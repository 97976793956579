import {
    TIME_FOCUS,
    TIME_RESET_STATES,
    TIMES_GET_ERROR,
    TIMES_GET_REQUESTING,
    TIMES_GET_SUCCESS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    times: [],
    time: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TIMES_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case TIMES_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                times: action.times,
            };
        case TIMES_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case TIME_FOCUS:
            return {
                ...state,
                time: action.time,
            };
        case TIME_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
            };
        default:
            return state;
    }
};

export default reducer;