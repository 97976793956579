import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {toastr} from "react-redux-toastr";
import {timeResetStates, timesGetError, timesGetSuccess} from "./actions";
import {TIMES_GET_REQUESTING} from "./constants";

const timeURL = `${process.env.REACT_APP_API_URL}/times`;

const timesGetApi = () => {
    return fetch(`${timeURL}`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;

        }).catch((error) => {
            throw error;
        })
};

function* timesGetFlow() {
    try {
        const times = yield call(timesGetApi);
        yield put(timesGetSuccess(times));
        yield put(timeResetStates());
    } catch (error) {
        yield put(timesGetError(error));
    }
}

function* timeWatcher() {
    yield all([
        takeEvery(TIMES_GET_REQUESTING, timesGetFlow),
    ])
}

export default timeWatcher;