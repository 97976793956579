import {
    LOGIN_ERROR,
    LOGIN_REQUESTING,
    LOGIN_SUCCESS,
    LOGOUT_ERROR,
    LOGOUT_REQUESTING,
    LOGOUT_SUCCESS,
    ERROR_CONTROL_LOGIN,
    LOGIN_FACEBOOK_ERROR,
    LOGIN_FACEBOOK_REQUESTING,
    LOGIN_FACEBOOK_SUCCESS,
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    errorLogin:false,

    values: {
        email: '',
        password: '',
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                error: '',
                logged: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                logged: true
            };
        case LOGIN_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                logged: false
            };
        case LOGOUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                logged:false,
                error: '',
            };
        case LOGOUT_ERROR:
            return {
                ...state,
                success: false,
                requesting: false,
                error: action.error,
            };
        case ERROR_CONTROL_LOGIN:
            return {
                ...state,
                errorLogin: !state.errorLogin
            };
        case LOGIN_FACEBOOK_REQUESTING:
            return {
                ...state,
                requesting: true,
            };
        case LOGIN_FACEBOOK_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                response: action.response
            };
        case LOGIN_FACEBOOK_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reducer;