export const GET_REVIEW_REQUESTING = 'GET_REVIEW_REQUESTING';
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
export const GET_REVIEW_ERROR = 'GET_REVIEW_ERROR';

export const CONTROL_MODAL_REVIEW = 'CONTROL_MODAL_REVIEW';

export const CREATE_REVIEW_REQUESTING = 'CREATE_REVIEW_REQUESTING';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_ERROR = 'CREATE_REVIEW_ERROR';


export const GET_SEND_REVIEW_REQUESTING = 'GET_SEND_REVIEW_REQUESTING';
export const GET_SEND_REVIEW_SUCCESS = 'GET_SEND_REVIEW_SUCCESS';
export const GET_SEND_REVIEW_ERROR = 'GET_SEND_REVIEW_ERROR';
