import {
    MODULE_FOCUS,
    MODULE_RESET_STATES,
    MODULES_GET_ERROR,
    MODULES_GET_REQUESTING,
    MODULES_GET_SUCCESS, TYPE_MODULE_FOCUS
} from "./constants";

export const modulesGetRequesting = () => ({
    type: MODULES_GET_REQUESTING,
});

export const modulesGetSuccess = (modules) => ({
    type: MODULES_GET_SUCCESS,
    modules,
});

export const modulesGetError = (error) => ({
    type: MODULES_GET_ERROR,
    error,
});

export const moduleFocus = (module) => ({
    type: MODULE_FOCUS,
    module,
});

export const typeModuleFocus = (typeModule) => ({
    type: TYPE_MODULE_FOCUS,
    typeModule,
});

export const moduleResetStates = () => ({
    type: MODULE_RESET_STATES,
});