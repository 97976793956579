import {call, put, all, takeEvery} from 'redux-saga/effects';
import {getTypePeopleError, getTypePeopleSuccess} from "./actions";
import {GET_TYPE_PEOPLE_REQUESTING} from "./constants";
import {handleApiErrors} from "../../commons/errors/apiErrors";


const TYPE_PEOPLE_URL = `${process.env.REACT_APP_API_URL}/typePeoples`;

const getTypePeopleAPI = () => {

    return fetch(TYPE_PEOPLE_URL, {
        method: 'GET'
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getTypePeopleFlow(action) {
    try {
        const {} = action;
        const typePeoples = yield call(getTypePeopleAPI);
        yield put(getTypePeopleSuccess(typePeoples));
    }catch (error) {
        yield put(getTypePeopleError(error));
    }
}

function* typePeopleWatcher() {
    yield all([
        takeEvery(GET_TYPE_PEOPLE_REQUESTING, getTypePeopleFlow)
    ]);
}

export default typePeopleWatcher;