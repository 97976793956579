import {
    CLEAN_PUBLICATION,
    PUBLICATION_CHANGE_STATE_PUT_ERROR,
    PUBLICATION_CHANGE_STATE_PUT_REQUESTING,
    PUBLICATION_CHANGE_STATE_PUT_SUCCESS,
    PUBLICATION_CREATE_POST_ERROR,
    PUBLICATION_CREATE_POST_REQUESTING,
    PUBLICATION_CREATE_POST_SUCCESS,
    PUBLICATION_GET_ERROR,
    PUBLICATION_GET_REQUESTING,
    PUBLICATION_GET_SUCCESS,
    PUBLICATION_HIDDEN_MODAL,
    PUBLICATION_PHOTO_DELETE_ERROR,
    PUBLICATION_PHOTO_DELETE_REQUESTING,
    PUBLICATION_PHOTO_DELETE_SUCCESS,
    PUBLICATION_RESET_STATES,
    PUBLICATION_SHOW_MODAL,
    PUBLICATION_UPDATE_PUT_ERROR,
    PUBLICATION_UPDATE_PUT_REQUESTING,
    PUBLICATION_UPDATE_PUT_SUCCESS,
    PUBLICATIONS_GET_ERROR,
    PUBLICATIONS_GET_REQUESTING,
    PUBLICATIONS_GET_SUCCESS,
    PUBLICATIONS_ME_POST_ERROR,
    PUBLICATIONS_ME_POST_REQUESTING,
    PUBLICATIONS_ME_POST_SUCCESS,
    PUBLICATIONS_OWNER_USER_POST_ERROR,
    PUBLICATIONS_OWNER_USER_POST_REQUESTING,
    PUBLICATIONS_OWNER_USER_POST_SUCCESS,
    PUBLICATIONS_OWNER_USER_RESET_STATE,
    PUBLICATIONS_SEARCH_POST_ERROR,
    PUBLICATIONS_SEARCH_POST_REQUESTING,
    PUBLICATIONS_SEARCH_POST_SUCCESS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    publications: [],
    publicationsIds: [],
    hasMore: true,
    hasLess: false,
    publication: {},
    paginate: 1,
    search: '',
    createPublication: false,
    viewPublication: false,
    editPublication: false,
    ownerPublications: [],
    ownerPublicationsIds: [],
    mePublications: [],
    mePublicationsIds: [],
    photoId: '',
    publicationIdUpdate: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PUBLICATIONS_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                publications: action.publicationsIds.length > 0 ? state.publications : [],
                publicationsIds: action.publicationsIds.length > 0 ? state.publicationsIds : [],
            };
        case PUBLICATIONS_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                publications: [...state.publications, ...action.publications],
                publicationsIds: [...state.publicationsIds, ...action.publications.map((publication) => publication.id)],
                hasMore: action.publications.length > 0,
            };
        case PUBLICATIONS_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATION_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                publication: action.publication,
            };
        case PUBLICATION_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                publication: {
                    ...state.publication,
                    ...action.publication
                },
            };
        case PUBLICATION_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATIONS_OWNER_USER_POST_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                paginate: action.paginate,
            };
        case PUBLICATIONS_OWNER_USER_POST_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                ownerPublications: [...state.ownerPublications, ...action.publications],
                ownerPublicationsIds: [...state.ownerPublicationsIds, ...action.publications.map((publication) => publication.id)],
                hasLess: state.paginate > 1,
                hasMore: action.publications.length > 0,
            };
        case PUBLICATIONS_OWNER_USER_POST_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATIONS_ME_POST_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                paginate: action.paginate,
            };
        case PUBLICATIONS_ME_POST_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                mePublications: [...state.mePublications, ...action.publications],
                mePublicationsIds: [...state.mePublicationsIds, ...action.publications.map((publication) => publication.id)],
                hasLess: state.paginate > 1,
                hasMore: action.publications.length > 0,
            };
        case PUBLICATIONS_ME_POST_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATIONS_SEARCH_POST_REQUESTING:
            return {
                ...state,
                search: action.search,
                requesting: true,
                success: false,
                error: '',
                publications: [],
            };
        case PUBLICATIONS_SEARCH_POST_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                publications: action.publications,
            };
        case PUBLICATIONS_SEARCH_POST_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATION_CREATE_POST_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
            };
        case PUBLICATION_CREATE_POST_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                mePublications: [
                    action.publication,
                    ...state.mePublications
                ],
                publications: [action.publication, ...state.publications]
            };
        case PUBLICATION_CREATE_POST_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATION_UPDATE_PUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
            };
        case PUBLICATION_UPDATE_PUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                mePublications: state.mePublications.map((publication) => publication.id === action.publication.id ? action.publication : publication),
            };
        case PUBLICATION_UPDATE_PUT_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATION_CHANGE_STATE_PUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                publicationIdUpdate: action.publicationId
            };
        case PUBLICATION_CHANGE_STATE_PUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                mePublications: state.mePublications.map((publication) => publication.id === action.publication.id ? action.publication : publication),
                publicationIdUpdate: 0
            };
        case PUBLICATION_CHANGE_STATE_PUT_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
                publicationIdUpdate: 0
            };
        case PUBLICATION_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
                paginate: state.paginate >= 1 && state.ownerPublications.length === 0 && state.mePublications.length === 0 ? state.paginate : 1,
            };
        case PUBLICATION_PHOTO_DELETE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                photoId: action.photoId,
            };
        case PUBLICATION_PHOTO_DELETE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                publication: {
                    ...state.publication,
                    fotos: state.publication.fotos.filter((photo) => photo.id !== state.photoId)
                },
            };
        case PUBLICATION_PHOTO_DELETE_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case PUBLICATION_SHOW_MODAL:
            return {
                ...state,
                createPublication: action.modal === 'createPublication',
                viewPublication: action.modal === 'viewPublication',
                editPublication: action.modal === 'editPublication',
            };
        case PUBLICATION_HIDDEN_MODAL:
            return {
                ...state,
                createPublication: action.modal === 'createPublication' && false,
                viewPublication: action.modal === 'viewPublication' && false,
                editPublication: action.modal === 'editPublication' && false,
            };
        case PUBLICATIONS_OWNER_USER_RESET_STATE:
            return {
                ...state,
                ownerPublications: [],
                ownerPublicationsIds: [],
            };
        case CLEAN_PUBLICATION:
            return {
                ...state,
                ownerPublications: [],
                ownerPublicationsIds: [],
                // publications: [],
            };
        default:
            return state;
    }
};

export default reducer;
