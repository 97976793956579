import React from 'react';
import Login from './../../0-Login/modals/login';
import Swiper from 'react-id-swiper';
// Need to add Pagination, Navigation modules
import {Navigation, Pagination} from 'swiper/dist/js/swiper.esm'
import "react-id-swiper/lib/styles/css/swiper.css";
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import {Link} from "react-router-dom";
////Modal
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
////img
import ImgUser from '../../../assets/img/logo-no-text.jpg';
////list
import Icon from '@material-ui/core/Icon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import {FormattedNumber} from "react-intl";
import {COLORS} from "../../../theme/colors";

///carrusel
const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    grabCursor: true,
    loop: false,
    direction: 'horizontal',
    watchOverflow: true,
    spaceBetween: 30,
    effect: 'fade',

    breakpoints: {
        // 100: {
        //         //     slidesPerView: 1,
        //         //     spaceBetween: 20
        //         // },
        //         // 200: {
        //         //     slidesPerView: 1,
        //         //     spaceBetween: 20
        //         // },
        //         // 300: {
        //         //     slidesPerView: 1,
        //         //     spaceBetween: 20
        //         // },
        //         //
        //         // 400: {
        //         //     slidesPerView: 1,
        //         //     spaceBetween: 20
        //         //
        //         // },
        //         // // when window width is <= 1024px
        //         // 1024: {
        //         //     slidesPerView: 1,
        //         //     spaceBetween: 20
        //         // },
        //         // 1500: {
        //         //     slidesPerView: 1,
        //         //     spaceBetween: 20
        //         // }
    },
    containerClass: 'swiper-container',
    rebuildOnUpdate: true,
    shouldSwiperUpdate: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    }
};

export class ProductModalComponent extends React.Component {

    render() {
        const {
            hiddenModal, type, visible, publication, viewChat, handleNewChat, auth, enabled, handleControl, client, publicationsOwnerUserPostRequesting, cleanPublication,
            setFocusUser, getReviewRequesting, token, ownerPublicationsIds, paginate, publicationShowModal, addFavorite
        } = this.props;
        let slides = null;

        if (publication.hasOwnProperty('fotos') && publication.fotos.length > 0) {
            slides = (
                publication.fotos.map((photo, index) => {
                    return (
                        <div className="item-1-product" key={index}>
                            <img src={photo.url} alt={publication.nombre + index}/>
                        </div>
                    )
                })
            )
        }

        if (publication.hasOwnProperty('vendedor')) {
            return (
                <Dialog onClose={() => hiddenModal('viewPublication', type)}
                        aria-labelledby="customized-dialog-title"
                        open={visible}>
                    <DialogTitle className="container-text" id="customized-dialog-title">
                        <h1>{publication.nombre}</h1>
                        <div style={{position: 'absolute', right: 10, top: 10}}>
                            <IconButton aria-label="Close" onClick={() => hiddenModal('viewPublication', type)}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className="container-modal">

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '.5rem 1rem',
                            flexWrap: 'wrap'
                        }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Avatar className="styles-img-avatar">
                                    <Link to={`/profileVisitor/${publication.vendedor.id}`} style={{color: 'white'}}
                                          onClick={() => {
                                              cleanPublication();
                                              setFocusUser(publication.vendedor);
                                              getReviewRequesting(token, publication.vendedor.id);
                                              publicationsOwnerUserPostRequesting(publication.vendedor.id, ownerPublicationsIds, paginate);
                                              publicationShowModal('');
                                          }}
                                    >
                                        <img
                                            src={publication.vendedor.foto ? publication.vendedor.foto : 'https://resources.tuconstruccion.com.co/resources/photos/user.png'}
                                            alt={publication.vendedor.nombre}/>
                                    </Link>

                                </Avatar>

                                <div>
                                    <ListItemText primary={publication.vendedor.nombre}
                                                  secondary={client.logged ? publication.vendedor.correo_electronico : null}/>
                                    <Rater total={5} rating={publication.vendedor.calificacion} interactive={false}/>
                                    <IconButton aria-label="Close" style={{position: 'absolute', right: '19px'}}
                                                onClick={() => {
                                                    window.open('https://wa.me/57' + publication.vendedor.telefono + '?text=' + encodeURIComponent('Hola soy un usuario de TuConstrucción, vi tu publicación de ' + publication.nombre), '_blank')

                                                }}>
                                        <p style={{fontSize: 12, color: '#22C04A'}}>Escribir Whatsapp</p>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div className="line"/>

                        <div className="container-text" style={{display: 'flex', flexDirection: 'column'}}>
                            <div className="item-1-product" style={{width: '100%'}}>
                                <Swiper {...params}>
                                    {slides}
                                </Swiper>


                                <div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end'
                                    }}>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <Icon style={{color: COLORS.PRIMARY, fontSize: 24}}>subject</Icon>
                                            <p style={{fontWeight: 'bold'}}>Descripción</p>
                                        </div>

                                        {publication.favorito ?
                                            <Icon color="secondary"
                                                  style={{fontSize: 30}}>favorite</Icon>
                                            :
                                            <Button onClick={() => addFavorite(publication)}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    <Icon style={{
                                                        fontSize: 30,

                                                        color: 'rgba(0, 0, 0, 0.54)',

                                                    }}>favorite_border</Icon>
                                                </div>

                                            </Button>
                                        }
                                    </div>
                                    <div className="line" style={{marginTop: 5}}/>
                                    <p style={{
                                        textAlign: 'start',
                                        marginLeft: 29,
                                        marginBottom: 10,
                                        marginTop: 10
                                    }}>{publication.descripcion}</p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Icon style={{color: COLORS.PRIMARY, fontSize: 24}}>subject</Icon>
                                    <p style={{fontWeight: 'bold'}}>Caracteristicas</p>
                                </div>
                                <div className="line" style={{marginBottom: 22}}/>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <div>
                                        <div style={{alignItems: 'flex-start'}}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                marginTop: 6
                                            }}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                    <Icon style={{
                                                        color: COLORS.PRIMARY,
                                                        fontSize: 24
                                                    }}>attach_money</Icon>
                                                    <p style={{fontWeight: 'bold', margin: '2.9px'}}>Precio : </p>
                                                </div>
                                                <p style={{marginTop: '5px', marginLeft: '31px', textAlign: 'left'}}>
                                                    <FormattedNumber
                                                        value={publication.precio_descuento !== "0" && publication.precio_descuento < publication.precio ? publication.precio_descuento : publication.precio}
                                                        style={'currency'}
                                                        currency={'USD'}
                                                        minimumFractionDigits={0}
                                                        maximumFractionDigits={0}/>
                                                </p>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'left'}}>
                                                <Icon style={{color: COLORS.PRIMARY, fontSize: 24}}>
                                                    report
                                                </Icon>
                                                <p style={{fontWeight: 'bold'}}>
                                                    Estado del producto
                                                </p>
                                            </div>
                                            <p style={{marginTop: '5px', marginLeft: '31px', textAlign: 'left'}}>
                                                {publication.categoria.nombre === 'Servicios' ? 'Sin estado' : publication.tipo_modulo.nombre}
                                            </p>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            marginTop: 6
                                        }}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <Icon style={{
                                                    color: COLORS.PRIMARY,
                                                    fontSize: 24
                                                }}>photo_size_select_small</Icon>
                                                <p style={{fontWeight: 'bold', margin: '2.9px'}}>Dimensiones</p>
                                            </div>
                                            <p style={{marginTop: '5px', marginLeft: '31px', textAlign: 'left'}}>
                                                {publication.dimensiones}
                                            </p>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            marginTop: 6
                                        }}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <Icon
                                                    style={{color: COLORS.PRIMARY, fontSize: 24}}>fitness_center</Icon>
                                                <p style={{fontWeight: 'bold', margin: '2.9px'}}>Peso</p>
                                            </div>
                                            <p style={{marginTop: '5px', marginLeft: '31px', textAlign: 'left'}}>
                                                {publication.peso}
                                            </p>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginTop: 6,
                                            marginBottom: 12
                                        }}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <Icon style={{color: COLORS.PRIMARY, fontSize: 24}}>update</Icon>
                                                <p style={{fontWeight: 'bold', margin: '2.9px'}}>Actualizado</p>
                                            </div>
                                            <p style={{marginTop: '5px', marginLeft: '31px', textAlign: 'left'}}>
                                                {publication.actualizado}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <Icon style={{color: COLORS.PRIMARY, fontSize: 24}}>stars</Icon>
                                                <p style={{fontWeight: 'bold', margin: '2.9px'}}>Categoria</p>
                                            </div>
                                            <p style={{marginTop: '5px', marginLeft: '31px', textAlign: 'left'}}>
                                                {publication.categoria.nombre}
                                            </p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <Icon style={{color: COLORS.PRIMARY, fontSize: 24}}>attach_money</Icon>
                                            <p style={{fontWeight: 'bold', margin: '2.9px'}}>Descuento : </p>
                                        </div>
                                        <p style={{marginTop: '5px', marginLeft: '31px', textAlign: 'left'}}>
                                            <FormattedNumber
                                                value={publication.precio - publication.precio_descuento}
                                                style={'currency'}
                                                currency={'USD'}
                                                minimumFractionDigits={0}
                                                maximumFractionDigits={0}/>
                                        </p>

                                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 6}}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <Icon style={{color: COLORS.PRIMARY, fontSize: 24}}>grade</Icon>
                                                <p style={{fontWeight: 'bold', margin: '2.9px'}}>Subcategoria</p>
                                            </div>
                                            <p style={{marginTop: '5px', marginLeft: '31px', textAlign: 'left'}}>
                                                {publication.subcategoria.nombre}
                                            </p>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            marginTop: 6
                                        }}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <Icon style={{color: COLORS.PRIMARY, fontSize: 24}}>style</Icon>
                                                <p style={{fontWeight: 'bold', margin: '2.9px'}}>Marca</p>
                                            </div>
                                            <p style={{marginTop: '5px', marginLeft: '31px', textAlign: 'left'}}>
                                                {publication.marca}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/*{viewChat && (*/}
                        {/*    auth !== null && auth*/}
                        {/*        ? <div className="item-2-product" onClick={() => handleNewChat(publication)}>*/}
                        {/*            <Button variant="contained"*/}
                        {/*                    color="secondary">*/}
                        {/*                Chat*/}
                        {/*            </Button>*/}
                        {/*        </div>*/}
                        {/*        : <div className="item-2-product">*/}
                        {/*            <Button variant="contained"*/}
                        {/*                    color="secondary">*/}
                        {/*                Iniciar sesión*/}
                        {/*            </Button>*/}
                        {/*        </div>*/}
                        {/*)}*/}
                        {!enabled && (
                            <div className="item-2-product">
                                <Button variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            handleControl('modal login');
                                        }}>
                                    Iniciar sesión
                                </Button>
                                <Login/>
                            </div>
                        )}
                        {enabled && publication.vendedor.id !== client.id && (
                            <div className="item-2-product">
                                <Link to={'/profile'} style={{color: 'white'}}>
                                    <Button variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                hiddenModal('viewPublication', type);
                                                if (!publication.chat) {
                                                    handleNewChat(publication);
                                                }
                                            }}
                                    >
                                        Chat
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>

                </div>
            )
        }
    }
}
