import React from 'react';
import {MuiThemeProvider} from "@material-ui/core";
import LoginModalComponent from "../0-Login/modals/login";
import PostProductModalComponent from "../2-product/modals/post-product";
import SubscriptionModalComponent from "../3-profile-page/modals/subscription";
import CatalogModalComponent from "../3-profile-page/modals/catalog";
import ModalFrequencyComponent from "../0-Login/modals/frequency";
import ModalDateTreatmentComponent from "../0-Login/modals/data-treatment";
import ModalQualityPliticsComponent from "../0-Login/modals/quality-politics";
import ModalTermsConditionsComponent from "../0-Login/modals/terms-conditions";


import {checkJWTRequesting, disabled} from "../../redux/client/actions";
import {logoutRequesting} from "../../redux/login/actions";
import {getAllSubscriptionRequesting, showModalSubscription} from "../../redux/subscription/actions";
import {getPaymentRequesting} from "../../redux/payment/actions";
import {getUserRequesting, showModalCatalog, showModalUser} from "../../redux/user/actions";
import {closeShowModalPassword} from "../../redux/reset_password/actions";
import {Link} from "react-router-dom";

import {categoriesGetRequesting} from "../../redux/category/actions";
//btn
import Button from '@material-ui/core/Button';
//Img
import ImgLogin2 from './../../assets/img/Diseño-header.png';
//Menu desplegable
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
//Drawer (menu lateral)
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import StarBorder from '@material-ui/icons/StarBorder';
import {theme} from "../../theme/theme";
import RegisterModalComponent from "../0-Login/modals/register";
import {connect} from "react-redux";
import {publicationShowModal} from "../../redux/publication/actions";
import {filterChangePrimaryProfile} from "../../redux/iu/actions";


import ImgUser from "../../assets/img/Logo-para-app.jpg";


class MenuHome extends React.Component {

    state = {
        open: false,
        isOpenDrawer: false,
        isOpenOptionOne: false,
        isOpenOptionTwo: false,
        isOpen: false,
        isCatalog: false,
    };

    constructor(props) {
        super(props);

        const {checkJWTRequesting, categoriesGetRequesting} = this.props;
        // checkJWTRequesting(token);
        categoriesGetRequesting();
    }

    // control de modal
    handleControlModal = (modal) => {
        this.setState({
            isOpenPostProduct: modal === 'modal publicar',
            isOpenSubscription: modal === 'modal subscripcion',
            isOpenCatalog: modal === 'modal catalogo',
        });
    };
    // control menu bar
    handleDrawerOpen = () => {
        this.setState({isOpenDrawer: true});
    };
    handleDrawerClose = () => {
        this.setState({isOpenDrawer: false});
    };
    handleControlCollapse = () => {
        this.setState({
            isOpenOptionOne: !this.state.isOpenOptionOne
        });
    };
    handleControlCollapseTwo = () => {
        this.setState({
            isOpenOptionTwo: !this.state.isOpenOptionTwo
        });
    };
    // control menu desplegable
    handleToggle = () => {
        this.setState(state => ({open: !state.open}));
    };

    handleLogout = () => {
        const {logoutRequesting, client: {token}, disabled} = this.props;
        logoutRequesting(token);
        this.handleClose();
        disabled();
    };
    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({open: false});
    };

    handleShowModal = (modal, type) => {
        const {publicationShowModal} = this.props;
        switch (type) {
            case 'publication':
                publicationShowModal(modal);
                break;
        }
    };

    handleCloseDrawer = () => {
        this.setState({
            isOpenDrawer: false,
        });
    };


    render() {
        const {isOpenPostProduct, open, isOpenDrawer, isOpenOptionOne} = this.state;
        const {
            showModalSubscription,
            publication: {createPublication},
            user: {isOpenRegister, isOpenLogin, isOpenCatalog, isOpenFrequency, isOpenDateTreatment, isOpenQualityPlitics,isOpenModalTermsConditionsComponent},
            client: {client, logged, token},
            showModalUser,
            logoutRequesting,
            getAllSubscriptionRequesting,
            getPaymentRequesting,
            getUserRequesting,
            subscription: {isOpenSubscription},
            showModalCatalog,
            closeShowModalPassword,
            filterChangePrimaryProfile
        } = this.props;
        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline/>
                    <header>
                        <LoginModalComponent
                            visible={isOpenLogin}
                            handleControl={showModalUser}
                        />
                        <RegisterModalComponent
                            visible={isOpenRegister}
                            handleControl={showModalUser}
                        />
                        <ModalFrequencyComponent
                            visible={isOpenFrequency}
                            handleControl={showModalUser}
                        />
                        <ModalDateTreatmentComponent
                            visible={isOpenDateTreatment}
                            handleControl={showModalUser}
                        />



                        <    ModalTermsConditionsComponent
                            visible={isOpenModalTermsConditionsComponent}
                            handleControl={showModalUser}
                        />

                        <ModalQualityPliticsComponent
                            visible={isOpenQualityPlitics}
                            handleControl={showModalUser}
                        />

                        <SubscriptionModalComponent
                            visible={isOpenSubscription}
                            handleControl={showModalSubscription}
                        />
                        <CatalogModalComponent
                            visible={isOpenCatalog}
                            handleControl={showModalCatalog}
                        />
                        {createPublication && (
                            <PostProductModalComponent visible={createPublication}/>
                        )}
                        <div className="body-data-site-header">
                            <div className="container-header-1">
                                <div>
                                    <IconButton

                                        color="inherit"
                                        aria-label="Open drawer"
                                        onClick={this.handleDrawerOpen}
                                    >
                                        <MenuIcon style={{color: 'black'}}/>
                                    </IconButton>
                                    <Drawer
                                        onClose={this.handleDrawerClose}
                                        anchor="left"
                                        open={isOpenDrawer}
                                    >
                                        <div>
                                            <IconButton onClick={this.handleDrawerClose}>
                                                {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                                            </IconButton>
                                        </div>
                                        <Divider/>
                                        <div className="avatar-menu-home">
                                            <img
                                                src={client.hasOwnProperty('foto') ? client.foto : ImgUser}
                                                alt="Logo"/>
                                            <div className="container-sub-text">
                                                <h1>{client.hasOwnProperty('nombre') ? client.nombre : 'Tu Construccion'}</h1>
                                                {/*<p>texto segundo</p>*/}
                                            </div>
                                        </div>
                                        {client.hasOwnProperty('id')

                                            ? <List>
                                                <Link to={'/'} onClick={() => {
                                                    this.handleCloseDrawer()
                                                }}>
                                                    <ListItem divider={true}
                                                              style={{borderBottomColor: '#fff159'}}>
                                                        <div style={{
                                                            width: 10,
                                                            height: 10,
                                                            borderRadius: 5,
                                                            backgroundColor: '#fff159',
                                                            position: 'absolute',
                                                            bottom: -5,
                                                            right: 0
                                                        }}/>
                                                        {/*<ListItemIcon>*/}
                                                        {/*{index % 2 === 0*/}
                                                        {/*? <InboxIcon/>*/}
                                                        {/*:*/}
                                                        {/*<MailIcon/>*/}
                                                        {/*}*/}
                                                        {/*</ListItemIcon>*/}
                                                        <ListItemText primary={'Inicio'}/>
                                                    </ListItem>
                                                </Link>
                                                <Link to={'profile'} onClick={() => {
                                                    this.handleCloseDrawer()
                                                }}>
                                                    <ListItem button
                                                              divider={true}
                                                              style={{borderBottomColor: '#fff159'}}
                                                    >
                                                        <div style={{
                                                            width: 10,
                                                            height: 10,
                                                            borderRadius: 5,
                                                            backgroundColor: '#fff159',
                                                            position: 'absolute',
                                                            bottom: -5,
                                                            right: 0
                                                        }}/>
                                                        {/*<ListItemIcon>*/}
                                                        {/*{index % 2 === 0*/}
                                                        {/*? <InboxIcon/>*/}
                                                        {/*:*/}
                                                        {/*<MailIcon/>*/}
                                                        {/*}*/}
                                                        {/*</ListItemIcon>*/}
                                                        <ListItemText primary={'Perfil'}/>
                                                    </ListItem>
                                                </Link>
                                                <ListItem button
                                                          divider={true}
                                                          style={{borderBottomColor: '#fff159'}}
                                                          onClick={() => {
                                                              getPaymentRequesting(token);
                                                              showModalSubscription('modal subscripcion');
                                                              getAllSubscriptionRequesting(token);
                                                              this.handleCloseDrawer();
                                                          }}>
                                                    <div style={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: 5,
                                                        backgroundColor: '#fff159',
                                                        position: 'absolute',
                                                        bottom: -5,
                                                        right: 0
                                                    }}/>
                                                    {/*<ListItemIcon>*/}
                                                    {/*{index % 2 === 0*/}
                                                    {/*? <InboxIcon/>*/}
                                                    {/*:*/}
                                                    {/*<MailIcon/>*/}
                                                    {/*}*/}
                                                    {/*</ListItemIcon>*/}
                                                    <ListItemText primary={'Suscripciones'}/>
                                                </ListItem>

                                                <ListItem button
                                                          divider={true}
                                                          style={{borderBottomColor: '#fff159'}}
                                                          onClick={() => {
                                                              getUserRequesting(token);
                                                              showModalCatalog('modal catalogo');
                                                              this.handleCloseDrawer();
                                                          }}>
                                                    <div style={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: 5,
                                                        backgroundColor: '#fff159',
                                                        position: 'absolute',
                                                        bottom: -5,
                                                        right: 0
                                                    }}/>
                                                    {/*<ListItemIcon>*/}
                                                    {/*{index % 2 === 0*/}
                                                    {/*? <InboxIcon/>*/}
                                                    {/*:*/}
                                                    {/*<MailIcon/>*/}
                                                    {/*}*/}
                                                    {/*</ListItemIcon>*/}
                                                    <ListItemText primary={'Directorio'}/>
                                                </ListItem>
                                                <Link to={'profile'}>
                                                    <ListItem button
                                                              divider={true}
                                                              style={{borderBottomColor: '#fff159'}}
                                                              onClick={() => {
                                                                  filterChangePrimaryProfile('Mensajes');
                                                                  this.handleCloseDrawer();
                                                              }}
                                                    >
                                                        <div style={{
                                                            width: 10,
                                                            height: 10,
                                                            borderRadius: 5,
                                                            backgroundColor: '#fff159',
                                                            position: 'absolute',
                                                            bottom: -5,
                                                            right: 0
                                                        }}/>
                                                        {/*<ListItemIcon>*/}
                                                        {/*{index % 2 === 0*/}
                                                        {/*? <InboxIcon/>*/}
                                                        {/*:*/}
                                                        {/*<MailIcon/>*/}
                                                        {/*}*/}
                                                        {/*</ListItemIcon>*/}
                                                        <ListItemText primary={'Mensajes'}/>
                                                    </ListItem>
                                                </Link>
                                                <ListItem divider={true}
                                                          style={{borderBottomColor: '#fff159'}}
                                                          button onClick={() => this.handleLogout()}>
                                                    <div style={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: 5,
                                                        backgroundColor: '#fff159',
                                                        position: 'absolute',
                                                        bottom: -5,
                                                        right: 0
                                                    }}/>
                                                    {/*<ListItemIcon>*/}
                                                    {/*{index % 2 === 0*/}
                                                    {/*? <InboxIcon/>*/}
                                                    {/*:*/}
                                                    {/*<MailIcon/>*/}
                                                    {/*}*/}
                                                    {/*</ListItemIcon>*/}
                                                    <ListItemText primary={'Cerrar sesión'}/>
                                                </ListItem>
                                            </List>
                                            : <List>
                                                <ListItem
                                                    divider={true}
                                                    style={{borderBottomColor: '#fff159'}}
                                                    button onClick={() => {
                                                    showModalUser('modal login');
                                                    closeShowModalPassword();
                                                    this.handleCloseDrawer();
                                                }}>
                                                    <div style={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: 5,
                                                        backgroundColor: '#fff159',
                                                        position: 'absolute',
                                                        bottom: -5,
                                                        right: 0
                                                    }}/>
                                                    {/*<ListItemIcon>*/}
                                                    {/*{index % 2 === 0*/}
                                                    {/*? <InboxIcon/>*/}
                                                    {/*:*/}
                                                    {/*<MailIcon/>*/}
                                                    {/*}*/}
                                                    {/*</ListItemIcon>*/}
                                                    <ListItemText primary={'Iniciar sesión'}/>
                                                </ListItem>
                                                <ListItem
                                                    divider={true}
                                                    style={{borderBottomColor: '#fff159'}}
                                                    button onClick={() => {
                                                    showModalUser('modal register');
                                                    closeShowModalPassword();
                                                    this.handleCloseDrawer();
                                                }}>
                                                    <div style={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: 5,
                                                        backgroundColor: '#fff159',
                                                        position: 'absolute',
                                                        bottom: -5,
                                                        right: 0
                                                    }}/>
                                                    {/*<ListItemIcon>*/}
                                                    {/*{index % 2 === 0*/}
                                                    {/*? <InboxIcon/>*/}
                                                    {/*:*/}
                                                    {/*<MailIcon/>*/}
                                                    {/*}*/}
                                                    {/*</ListItemIcon>*/}
                                                    <ListItemText primary={'Registro'}/>
                                                </ListItem>
                                            </List>}
                                        <Divider/>
                                        {/*<List>*/}
                                        {/*{['Menu plegable'].map((text, index) => (*/}
                                        {/*<ListItem button key={text} onClick={this.handleControlCollapse}>*/}
                                        {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon/> :*/}
                                        {/*<MailIcon/>}</ListItemIcon>*/}
                                        {/*<ListItemText primary={text}/>*/}
                                        {/*{isOpenOptionOne ? <ExpandLess/> : <ExpandMore/>}*/}
                                        {/*</ListItem>*/}
                                        {/*))}*/}
                                        {/*</List>*/}
                                        <Collapse in={isOpenOptionOne} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <StarBorder/>
                                                    </ListItemIcon>
                                                    <ListItemText inset primary="Starred"/>
                                                </ListItem>
                                            </List>
                                        </Collapse>
                                    </Drawer>
                                </div>
                                <div>
                                    <Link to={'/'}>
                                        <img src={ImgLogin2} alt="Logo"/>
                                    </Link>
                                </div>
                                <div className="btn-off">
                                    {client.hasOwnProperty('id')
                                        ? <Button variant="contained" color="secondary"

                                                  style={{backgroundColor: ' #393939'}}
                                                  onClick={() => this.handleShowModal('createPublication', 'publication')}>
                                            Publicar
                                        </Button>
                                        : <Button variant="contained" color="secondary"

                                                  style={{backgroundColor: ' #393939'}}
                                                  onClick={() => {
                                                      showModalUser('modal login');
                                                      closeShowModalPassword();
                                                  }}>
                                            Publicar
                                        </Button>
                                    }
                                </div>
                            </div>
                            <div className="container-header-2">
                                <div className="item-header-1">
                                    <Popper open={open} anchorEl={this.anchorEl} transition disablePortal
                                            style={{height: '100px'}}>
                                        {({TransitionProps, placement}) => (
                                            <Grow
                                                {...TransitionProps}
                                                id="menu-list-grow"
                                                style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={this.handleClose}>
                                                        <MenuList>
                                                            <Link to={'/profile'} style={{textDecoration: 'none'}}>
                                                                <MenuItem onClick={this.handleClose}>Mi
                                                                    perfil</MenuItem>
                                                            </Link>
                                                            <MenuItem onClick={() => this.handleLogout()}>Cerrar
                                                                sesion</MenuItem>
                                                            {/*{!logged && <MenuItem  onClick={() => showModalUser('modal login')}>Login</MenuItem>}*/}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>


                                <div className="item-container-header">
                                    {client.hasOwnProperty('id')
                                        ? <Button variant="contained" color="secondary"
                                                  style={{backgroundColor: ' #393939'}}
                                                  onClick={() => this.handleShowModal('createPublication', 'publication')}>
                                            Publicar
                                        </Button>
                                        : <Button variant="contained" color="secondary"
                                                  style={{backgroundColor: ' #393939'}}
                                                  onClick={() => {
                                                      showModalUser('modal login');
                                                      closeShowModalPassword();
                                                  }}>
                                            Publicar
                                        </Button>
                                    }
                                    {logged &&
                                    <>
                                        <Button style={{color: 'black'}} onClick={() => {
                                            getUserRequesting(token);
                                            showModalCatalog('modal catalogo')
                                        }}>
                                            Directorio
                                            <i className="icon-book styles-icons"/>
                                        </Button>
                                        <Button style={{color: 'black'}} onClick={() => {
                                            getPaymentRequesting(token);
                                            showModalSubscription('modal subscripcion');
                                            getAllSubscriptionRequesting(token);
                                        }}>
                                            Suscripción
                                            <i className="icon-basket styles-icons"/>
                                        </Button>
                                    </>
                                    }
                                    {!logged &&
                                    <>
                                        <Button style={{color: 'black'}}
                                                onClick={() => showModalUser('modal register')}
                                        >Registro
                                            <i className="icon-user-add styles-icons"/></Button>
                                        <div className="container-header-item"
                                             onClick={() => showModalUser('modal login')}>
                                            <Button style={{color: 'black'}}>Inciar sesión<i
                                                className="icon-user styles-icons"/></Button>
                                        </div>
                                    </>
                                    }
                                    {logged &&
                                    <Button
                                        style={{color: 'black'}}
                                        buttonRef={node => {
                                            this.anchorEl = node;
                                        }}
                                        aria-owns={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleToggle}
                                    >
                                        Cuenta
                                        <img src={client.hasOwnProperty('foto') && client.foto}
                                             alt="Logo"/>
                                    </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </header>
                </MuiThemeProvider>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    publication: state.publication,
    user: state.user,
    client: state.client,
    subscription: state.subscription
});

const connected = connect(mapStateToProps, {
    publicationShowModal,
    showModalUser,
    checkJWTRequesting,
    logoutRequesting,
    getAllSubscriptionRequesting,
    getPaymentRequesting,
    showModalSubscription,
    getUserRequesting,
    disabled,
    showModalCatalog,
    closeShowModalPassword,
    filterChangePrimaryProfile,
    categoriesGetRequesting
})(MenuHome);

export default connected;


