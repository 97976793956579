export const FORGOT_PASSWORD_POST_REQUESTING = 'FORGOT_PASSWORD_POST_REQUESTING';
export const FORGOT_PASSWORD_POST_SUCCESS = 'FORGOT_PASSWORD_POST_SUCCESS';
export const FORGOT_PASSWORD_POST_ERROR = 'FORGOT_PASSWORD_POST_ERROR';

export const VERIFY_TOKEN_RESET_PASSWORD_POST_REQUESTING = 'VERIFY_TOKEN_RESET_PASSWORD_POST_REQUESTING';
export const VERIFY_TOKEN_RESET_PASSWORD_POST_SUCCESS = 'VERIFY_TOKEN_RESET_PASSWORD_POST_SUCCESS';
export const VERIFY_TOKEN_RESET_PASSWORD_POST_ERROR = 'VERIFY_TOKEN_RESET_PASSWORD_POST_ERROR';

export const RESET_PASSWORD_PUT_REQUESTING = 'RESET_PASSWORD_PUT_REQUESTING';
export const RESET_PASSWORD_PUT_SUCCESS = 'RESET_PASSWORD_PUT_SUCCESS';
export const RESET_PASSWORD_PUT_ERROR = 'RESET_PASSWORD_PUT_ERROR';

export const RESET_STATES = 'RESET_STATES';

export const SHOW_MODAL_RESET_PASSWORD='SHOW_MODAL_RESET_PASSWORD';

export const CLOSE_SHOW_MODAL_PASSWORD = 'CLOSE_SHOW_MODAL_PASSWORD';