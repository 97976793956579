import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {theme} from "../../../theme/theme";
//select
import {MenuItem, MuiThemeProvider} from "@material-ui/core";
//img
//input
import TextField from '@material-ui/core/TextField';
import ImagesInput from './../../inputs-file/images';
import {Field, Form, reduxForm, reset} from "redux-form";
import {connect} from "react-redux";

import {
    categoriesModuleGetRequesting,
    categoryFocus,
    moduleTypeFocus,
    subcategoryFocus
} from "../../../redux/category/actions";
import {moduleFocus, typeModuleFocus} from "../../../redux/module/actions";
import {timesGetRequesting} from "../../../redux/time/actions";
import {
    publicationCreatePostRequesting,
    publicationHiddenModal,
    publicationResetStates
} from "../../../redux/publication/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import {themeUI} from "../../../theme/themeMaterialUI";
import NumberFormat from 'react-number-format';

const NumberFormatCustom = (props) => {
    const {inputRef, onChange} = props;
    return (
        <NumberFormat

            style={{borderWidth: 0, fontSize: '16px'}}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString={true}
            prefix="$ "
        />
    );
};

const NumberFormatQuantity = (props) => {
    const {inputRef, onChange} = props;
    return (
        <NumberFormat
            style={{borderWidth: 0, fontSize: '16px'}}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalSeparator=","
            thousandSeparator="."
            isNumericString={true}
        />
    );
};

const inputTextField = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {
    return (
        <MuiThemeProvider theme={themeUI}>
            <TextField
                {...input}
                {...rest}
                label={label}
                type={type}
                value={input.value}
                name={input.name}
                id="outlined-name"
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
            />
        </MuiThemeProvider>
    )
};


const inputPrice = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {
    return (
        <MuiThemeProvider theme={themeUI}>
            <TextField
                {...input}
                {...rest}
                label={label}
                type={type}
                name={input.name}
                id="outlined-name"
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}
            />
        </MuiThemeProvider>
    )
};

const InputQuantity = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {
    return <MuiThemeProvider theme={themeUI}>
        <TextField
            {...input}
            {...rest}
            label={label}
            type={type}
            name={input.name}
            id="outlined-name"
            margin="normal"
            variant="outlined"
            fullWidth
            multiline
            InputProps={{
                inputComponent: NumberFormatQuantity,
            }}
        />
    </MuiThemeProvider>
};

class PostProductModalComponent extends React.Component {

    constructor(props) {
        super(props);
        const {timesGetRequesting} = this.props;
        timesGetRequesting();
    }

    state = {
        'errorForm': {},
    };

    handleSubmit = (values) => {
        const {
            // client: {token},
            publicationCreatePostRequesting
        } = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        publicationCreatePostRequesting(token, values);
    };

    handleFocus = (object, type) => {
        const {moduleFocus, typeModuleFocus, categoryFocus, subcategoryFocus, moduleTypeFocus} = this.props;
        switch (type) {
            // case 'module':
            //     moduleFocus(object);
            //     break;
            case 'typeModule':
                moduleTypeFocus(object);
                break;
            case 'category':
                categoryFocus(object);
                break;
            case 'subcategory':
                subcategoryFocus(object);
                break;
        }
    };

    handleCategoriesModuleGet = (moduleId) => {
        const {categoriesModuleGetRequesting} = this.props;
        categoriesModuleGetRequesting(moduleId);
    };

    handleResetForm = () => {
        reset('createPublication');
    };

    handleHiddenModal = (modal, type) => {
        const {publicationHiddenModal} = this.props;
        switch (type) {
            case 'publication':
                publicationHiddenModal(modal);
                break;
        }
    };

    handleError = (error) => {
        const {publicationResetStates} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        publicationResetStates();
    };

    render() {
        const {
            visible,
            handleSubmit,
            module: {modules, typesModule, typeModule},
            time: {times},
            category: {categories, subcategories, moduleTypes, moduleType},
            publication: {error, requesting},
        } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                {error !== '' && this.handleError(error)}
                <Dialog onClose={() => this.handleHiddenModal('createPublication', 'publication')}
                        aria-labelledby="customized-dialog-title" open={visible}>
                    <DialogTitle id="customized-dialog-title" style={{fontSize: 5}}>
                        Publicar
                        <div style={{position: 'absolute', right: 10, top: 10}}>
                            <IconButton aria-label="Close"
                                        onClick={() => this.handleHiddenModal('createPublication', 'publication')}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className="container-sub-text">
                        <Form onSubmit={handleSubmit(this.handleSubmit)}>
                            <div className="container-post-item">
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Icon style={{color: '#4f4f4f', fontSize: 22, marginRight: 10}}>image</Icon>
                                    <h2>Subir fotos</h2>
                                </div>

                                <div className="line"/>
                                <Field name={'photos'} maxFiles={10} component={ImagesInput}/>
                            </div>
                            <h2>Datos de la publicacíon</h2>
                            <div className="line"/>
                            <Field name={'name'} type={'text'} component={inputTextField}
                                   label={'Titulo de la publicación *'}/>
                            <Field name={'description'} type={'text'} component={inputTextField}
                                   label={'Descripción *'}
                                   multiline={true}
                            />
                            <Field name={'price'}

                                   type={'number'} component={inputPrice} label={'Precio *'}/>
                            <Field name={'discountPrice'} type={'number'} component={inputPrice}
                                   label={'Precio con descuento'}/>
                            <Field name={'brand'} type={'text'} component={inputTextField} label={'Marca *'}/>
                            <Field name={'quantity'} type={'number'} component={InputQuantity} label={'Cantidad'}/>
                            {categories.length > 0 && (
                                <Field select name={'category'}
                                       type={'select'}
                                       component={inputTextField}
                                       label={'Categoria *'}
                                       SelectProps={{
                                           MenuProps: {
                                               PaperProps: {
                                                   style: {
                                                       transform: 'translate3d(0, 0, 0)',
                                                       height: '450px'
                                                   }
                                               }
                                           }
                                       }}
                                       onChange={(input) => {
                                           this.handleFocus(categories.find((category) => category.id === input.target.value), 'category');
                                       }}>
                                    {categories.map((category, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index} value={category.id}>
                                                <p className="menuItemStyleP">{category.nombre}</p>
                                            </MenuItem>
                                        );
                                    })}
                                </Field>
                            )}
                            {subcategories.length > 0 && (
                                <Field select name={'subcategory'} type={'select'} component={inputTextField}
                                       label={'Subcategoria *'}
                                       onChange={(input) => {
                                           this.handleFocus(subcategories.find((subcategory) => subcategory.id === input.target.value), 'subcategory');
                                       }}>
                                    {subcategories.map((subcategory, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index} value={subcategory.id}>
                                                <p className="menuItemStyleP">{subcategory.nombre}</p>
                                            </MenuItem>
                                        );
                                    })}
                                </Field>
                            )}
                            {moduleTypes.length > 0 && (
                                <Field select name={'typeModule'} type={'select'} component={inputTextField}
                                       label={'Condición *'}
                                       onChange={(input) => {
                                           this.handleFocus(moduleTypes.find((type) => type.id === input.target.value), 'typeModule');
                                       }}>
                                    {moduleTypes.map((type, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index} value={type.id}>
                                                <p className="menuItemStyleP">{type.nombre}</p>
                                            </MenuItem>
                                        );
                                    })}
                                </Field>
                            )}


                            {moduleType.hasOwnProperty('nombre') && moduleType.nombre === 'Alquiler' && (
                                <Field select multiple name={'times'} type={'select'} component={inputTextField}
                                       label={'Tiempos de alquiler *'}>
                                    {times.length > 0 && times.map((time, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index} value={time.id}>
                                                <p className="menuItemStyleP">{time.nombre}</p>
                                            </MenuItem>
                                        );
                                    })}
                                </Field>
                            )}


                            <div className="container-post-item">
                                <div style={{display: 'flex', flexDirection: 'row', margin: '12px 0'}}>
                                    <Icon style={{color: '#4f4f4f', fontSize: 22, marginRight: 10}}>assistant</Icon>
                                    <h2>Datos opcionales</h2>
                                </div>
                                <div className="line"/>
                            </div>
                            <Field name={'weight'} type={'text'} component={inputTextField} label={'Peso aprox (kg)'}/>
                            <Field name={'dimensions'} type={'text'} component={inputTextField}
                                   label={'Medidas aprox (cm)'}/>
                            <Field name={'shippingValue'} type={'number'} component={inputPrice}
                                   label={'Precio de envio'}/>
                            {/*<Field select name={'module'} type={'select'} component={inputTextField} label={'Modulo *'}*/}
                            {/*       onChange={(input) => {*/}
                            {/*           this.handleFocus(modules.find((module) => module.id === input.target.value), 'module');*/}
                            {/*           this.handleCategoriesModuleGet(input.target.value);*/}
                            {/*       }}>*/}
                            {/*    {modules.length > 0 && modules.map((module, index) => {*/}
                            {/*        return (*/}
                            {/*            <MenuItem className="menuItemStyle" key={index} value={module.id}>*/}
                            {/*                <p className="menuItemStyleP">{module.label}</p>*/}
                            {/*            </MenuItem>*/}
                            {/*        );*/}
                            {/*    })}*/}
                            {/*</Field>*/}
                            <DialogActions>
                                <Button variant="contained" color='primary'
                                        onClick={() => {
                                            this.handleResetForm();
                                            this.handleHiddenModal('createPublication', 'publication');
                                        }}>
                                    Cancelar
                                </Button>
                                <Button variant="contained" style={{
                                    color: '#fff',
                                    backgroundColor: '#393939',
                                    width: '100%',
                                    height: '90%',
                                    borderRadius: '6px',
                                    fontSize: '12px'
                                }}
                                        onClick={() => this.handleResetForm()}>
                                    Reiniciar
                                </Button>

                                <Button disabled={requesting} variant="contained" color='secondary' type={'submit'}>
                                    {requesting ? <CircularProgress style={{color: 'white'}} size={22}/> : 'Publicar'}

                                </Button>
                            </DialogActions>
                        </Form>
                    </DialogContent>
                </Dialog>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = state => ({
    publication: state.publication,
    module: state.module,
    category: state.category,
    time: state.time,
    stateForm: state.form,
});

const connected = connect(mapStateToProps, {
    publicationCreatePostRequesting,
    categoriesModuleGetRequesting,
    categoryFocus,
    subcategoryFocus,
    moduleFocus,
    typeModuleFocus,
    timesGetRequesting,
    publicationHiddenModal,
    publicationResetStates,
    moduleTypeFocus
})(PostProductModalComponent);

const formed = reduxForm({
    form: 'createPublication',
})(connected);

export default formed;
