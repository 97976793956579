import {
    CLEAN_PUBLICATION,
    PUBLICATION_CHANGE_STATE_PUT_ERROR,
    PUBLICATION_CHANGE_STATE_PUT_REQUESTING,
    PUBLICATION_CHANGE_STATE_PUT_SUCCESS,
    PUBLICATION_CLEAR_FORM,
    PUBLICATION_CREATE_POST_ERROR,
    PUBLICATION_CREATE_POST_REQUESTING,
    PUBLICATION_CREATE_POST_SUCCESS,
    PUBLICATION_GET_ERROR,
    PUBLICATION_GET_REQUESTING,
    PUBLICATION_GET_SUCCESS,
    PUBLICATION_HIDDEN_MODAL, PUBLICATION_PHOTO_DELETE_ERROR,
    PUBLICATION_PHOTO_DELETE_REQUESTING,
    PUBLICATION_PHOTO_DELETE_SUCCESS,
    PUBLICATION_RESET_STATES,
    PUBLICATION_SHOW_MODAL,
    PUBLICATION_UPDATE_PUT_ERROR,
    PUBLICATION_UPDATE_PUT_REQUESTING,
    PUBLICATION_UPDATE_PUT_SUCCESS,
    PUBLICATIONS_GET_ERROR,
    PUBLICATIONS_GET_REQUESTING,
    PUBLICATIONS_GET_SUCCESS,
    PUBLICATIONS_ME_POST_ERROR,
    PUBLICATIONS_ME_POST_REQUESTING,
    PUBLICATIONS_ME_POST_SUCCESS,
    PUBLICATIONS_OWNER_USER_POST_ERROR,
    PUBLICATIONS_OWNER_USER_POST_REQUESTING,
    PUBLICATIONS_OWNER_USER_POST_SUCCESS,
    PUBLICATIONS_OWNER_USER_RESET_STATE,
    PUBLICATIONS_SEARCH_POST_ERROR,
    PUBLICATIONS_SEARCH_POST_REQUESTING,
    PUBLICATIONS_SEARCH_POST_SUCCESS,
} from "./constants";

export const publicationsGetRequesting = (publicationsIds, values, paginate) => ({
    type: PUBLICATIONS_GET_REQUESTING,
    publicationsIds, values, paginate,
});

export const publicationsGetSuccess = (publications) => ({
    type: PUBLICATIONS_GET_SUCCESS,
    publications,
});

export const publicationsGetError = (error) => ({
    type: PUBLICATIONS_GET_ERROR,
    error,
});

export const publicationGetRequesting = (publication, token) => ({
    type: PUBLICATION_GET_REQUESTING,
    publication,
    token
});

export const publicationGetSuccess = (publication) => ({
    type: PUBLICATION_GET_SUCCESS,
    publication,
});

export const publicationGetError = (error) => ({
    type: PUBLICATION_GET_ERROR,
    error,
});

export const publicationsOwnerUserPostRequesting = (userId, publicationsIds) => ({
    type: PUBLICATIONS_OWNER_USER_POST_REQUESTING,
    userId, publicationsIds,
});

export const publicationsOwnerUserPostSuccess = (publications) => ({
    type: PUBLICATIONS_OWNER_USER_POST_SUCCESS,
    publications,
});

export const publicationsOwnerUserPostError = (error) => ({
    type: PUBLICATIONS_OWNER_USER_POST_ERROR,
    error,
});

export const publicationsOwnerUserResetStates = () => ({
    type: PUBLICATIONS_OWNER_USER_RESET_STATE,
});

export const publicationsMePostRequesting = (token, publicationsIds, paginate) => ({
    type: PUBLICATIONS_ME_POST_REQUESTING,
    token, publicationsIds, paginate,
});

export const publicationsMePostSuccess = (publications) => ({
    type: PUBLICATIONS_ME_POST_SUCCESS,
    publications,
});

export const publicationsMePostError = (error) => ({
    type: PUBLICATIONS_ME_POST_ERROR,
    error,
});

export const publicationsSearchPostRequesting = (search, cityId) => ({
    type: PUBLICATIONS_SEARCH_POST_REQUESTING,
    search, cityId,
});

export const publicationsSearchPostSuccess = (publications) => ({
    type: PUBLICATIONS_SEARCH_POST_SUCCESS,
    publications,
});

export const publicationsSearchPostError = (error) => ({
    type: PUBLICATIONS_SEARCH_POST_ERROR,
    error,
});

export const publicationCreatePostRequesting = (token, values) => ({
    type: PUBLICATION_CREATE_POST_REQUESTING,
    token, values,
});

export const publicationCreatePostSuccess = (publication) => ({
    type: PUBLICATION_CREATE_POST_SUCCESS,
    publication,
});

export const publicationCreatePostError = (error) => ({
    type: PUBLICATION_CREATE_POST_ERROR,
    error,
});

export const publicationUpdatePutRequesting = (token, values) => ({
    type: PUBLICATION_UPDATE_PUT_REQUESTING,
    token, values,
});

export const publicationUpdatePutSuccess = (publication) => ({
    type: PUBLICATION_UPDATE_PUT_SUCCESS,
    publication,
});

export const publicationUpdatePutError = (error) => ({
    type: PUBLICATION_UPDATE_PUT_ERROR,
    error,
});

export const publicationChangeStatePutRequesting = (token, publicationId) => ({
    type: PUBLICATION_CHANGE_STATE_PUT_REQUESTING,
    token, publicationId,
});

export const publicationChangeStatePutSuccess = (publication) => ({
    type: PUBLICATION_CHANGE_STATE_PUT_SUCCESS,
    publication,
});

export const publicationChangeStatePutError = (error) => ({
    type: PUBLICATION_CHANGE_STATE_PUT_ERROR,
    error,
});

export const publicationPhotoDeleteRequesting = (token, photoId) => ({
    type: PUBLICATION_PHOTO_DELETE_REQUESTING,
    token, photoId,
});

export const publicationPhotoDeleteSuccess = (message) => ({
    type: PUBLICATION_PHOTO_DELETE_SUCCESS,
    message,
});

export const publicationPhotoDeleteError = (error) => ({
    type: PUBLICATION_PHOTO_DELETE_ERROR,
    error,
});

export const publicationClearForm = () => ({
    type: PUBLICATION_CLEAR_FORM,
});

export const publicationShowModal = (modal) => ({
    type: PUBLICATION_SHOW_MODAL,
    modal,
});

export const publicationHiddenModal = (modal) => ({
    type: PUBLICATION_HIDDEN_MODAL,
    modal,
});

export const publicationResetStates = () => ({
    type: PUBLICATION_RESET_STATES,
});

export const cleanPublication = () => ({
    type: CLEAN_PUBLICATION
});