import {
    MODULE_FOCUS,
    MODULE_RESET_STATES,
    MODULES_GET_ERROR,
    MODULES_GET_REQUESTING,
    MODULES_GET_SUCCESS, TYPE_MODULE_FOCUS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    modules: [],
    module: {},
    typesModule: [],
    typeModule: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MODULES_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case MODULES_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                modules: action.modules,
            };
        case MODULES_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case MODULE_FOCUS:
            return {
                ...state,
                module: action.module,
                typesModule: action.module.tipos_modulos,
            };
        case TYPE_MODULE_FOCUS:
            return {
                ...state,
                typeModule: action.typeModule,
            };
        case MODULE_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
                module: {},
                typesModule: [],
                typeModule: {},
            };
        default:
            return state;
    }
};

export default reducer;