import {
    CHECK_JWT_ERROR,
    CHECK_JWT_REQUESTING,
    CLIENT_SET,
    CLIENT_UNSET,
    GET_ME_ERROR,
    GET_ME_REQUESTING,
    GET_ME_SUCCESS,
    SAVE_DEVICE,
    DELETE_DEVICE,
    UPDATE_CLIENT,
    ENABLED, 
    DISABLED,
} from "./constants";


export const clientSet = (token) => ({
    type: CLIENT_SET,
    token
});

export const clientUnset = () => ({
    type: CLIENT_UNSET
});

export const checkJWTRequesting = (token) => ({
    type: CHECK_JWT_REQUESTING,
    token
});

export const checkJWTError = (error) => ({
    type: CHECK_JWT_ERROR,
    error
});

export const getMeRequesting = (token) => ({
    type: GET_ME_REQUESTING,
    token
});

export const getMeSuccess = (client) => ({
    type: GET_ME_SUCCESS,
    client
});

export const getMeError = (error) => ({
    type: GET_ME_ERROR,
    error
});

export const saveDevice = (token,values) => ({
    type: SAVE_DEVICE,
    token,
    values,
});

export const deleteDevice = (token) => ({
    type: DELETE_DEVICE,
    token,
});


export const updateClient = (client)=>({
    type:UPDATE_CLIENT,
    client
});

export const enabled = () => ({
   type: ENABLED
});

export const disabled = () => ({
    type: DISABLED
});