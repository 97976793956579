import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {MuiThemeProvider} from "@material-ui/core";
import {theme} from "../../../theme/theme";
import Icon from '@material-ui/core/Icon';
import Rater from 'react-rater'

//input
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {PageProfile} from "../page/profile/profile";
import ImgUser from "../../../assets/img/img-user.jpg";


export class EditProfileComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {};

    render() {
        const {handleControl, visible} = this.props;
        return (

            <MuiThemeProvider theme={theme}>
                <Dialog
                    onClose={handleControl.bind(this)} aria-labelledby="customized-dialog-title"
                    open={visible}>

                    <DialogTitle id="customized-dialog-title">
                        Editar perfil
                        <div style={{position: 'absolute', right: 10, top: 10}}>
                            <IconButton aria-label="Close" onClick={handleControl.bind(this)}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div className="avatar-modal container-text ">
                            <img src={ImgUser} alt="Logo"/>
                            <Button variant="contained" color="secondary">
                                Editar
                                <Icon>add_a_photo</Icon>
                            </Button>
                            <h2>julian camilo naranjo</h2>
                            <div className="rater-component" style={{justifyContent: 'center'}}>
                                <Rater total={5} rating={4}/>
                                <p>5.5</p>
                            </div >
                        </div>
                        <div className="container-modal-item1">
                            <TextField
                                id="outlined-name"
                                label="Nombre"
                                value={this.state.name}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                id="outlined-name"
                                label="Apellidos"
                                value={this.state.name}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div className="container-modal-item1">
                            <TextField
                                id="outlined-name"
                                label="Ubicacíon"
                                value={this.state.name}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                id="outlined-name"
                                label="Telefono"
                                value={this.state.name}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div className="container-modal-item1">
                            <TextField
                                id="outlined-name"
                                label="Nombre"
                                value={this.state.name}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                id="outlined-name"
                                label="Apellidos"
                                value={this.state.name}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.handleControlModal}
                                color="secondary">
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={this.handleControlModal}
                                color='secondary'>
                          Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        )
    }
}
