import {
    ERROR_SUBSCRIPTION,
    GET_ALL_SUBSCRIPTION_ERROR,
    GET_ALL_SUBSCRIPTION_REQUESTING,
    GET_ALL_SUBSCRIPTION_SUCCESS,
    SET_FOCUS_SUBSCRIPTION,
    SHOW_MODAL_SUBSCRIPTION
} from './constants';

//obtener todas las suscripciones

export const getAllSubscriptionRequesting=(token) =>({
    type: GET_ALL_SUBSCRIPTION_REQUESTING,
    token
});

export const getAllSubscriptionSuccess=(subscriptions) =>({
    type: GET_ALL_SUBSCRIPTION_SUCCESS,
    subscriptions
});

export const getAllSubscriptionError=(error) =>({
    type: GET_ALL_SUBSCRIPTION_ERROR,
    error
});

export const errorControlSubscription =()=>({
    type: ERROR_SUBSCRIPTION,
});

export const setFocusSubscription =(subscription)=>({
    type: SET_FOCUS_SUBSCRIPTION,
    subscription
});

export const showModalSubscription = (modal) => ({
    type: SHOW_MODAL_SUBSCRIPTION,
    modal
});