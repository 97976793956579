import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {connect} from "react-redux";
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import {controlModalReview, createReviewRequesting} from "../../../redux/review/actions";
import TextField from '@material-ui/core/TextField';
import {COLORS} from "../../../theme/colors";
import CircularProgress from "@material-ui/core/CircularProgress";

class reviewComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        values: {
            rater: 3
        }
    };

    handleChangeValue = (name, value) => {

        this.setState({
            values: {
                ...this.state.values,
                [name]: value
            }
        })
    };


    handleSubmitReview = () => {
        const {createReviewRequesting, client: {token}, chat: {focus}} = this.props;
        const {values} = this.state;
        createReviewRequesting(token, focus, values);
    };

    render() {
        const {

            controlModalReview,
            review: {modal, requesting},

        } = this.props;
        const {values: {rater}} = this.state;
        return (

            <Dialog
                onClose={() => controlModalReview()} aria-labelledby="customized-dialog-title"
                open={modal}>

                <DialogTitle id="customized-dialog-title">
                    Comentario
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={() => controlModalReview()}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <p style={{color: '#BEBEBE', textAlign: 'center'}}>¿Como te fue en la negociación con este
                        usuario?</p>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Rater total={5} rating={rater}
                               style={{fontSize: 55, color: COLORS.PRIMARY, alignSelf: 'center'}}
                               name={'rating'}
                               onRate={({rating}) => this.handleChangeValue('rater', rating)}/>
                        <TextField
                            label="Comentario"
                            placeholder="Escribe un comentario"
                            margin="normal"
                            name={'comment'}
                            variant="outlined"
                            onChange={(event) => {
                                this.handleChangeValue(event.target.name, event.target.value)
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => controlModalReview()}
                            style={{backgroundColor: COLORS.PRIMARY, color: 'white'}}>
                        Cerrar
                    </Button>
                    <Button variant="contained" onClick={() => this.handleSubmitReview()}
                            disabled={requesting}
                            style={{backgroundColor: COLORS.PRIMARY, color: 'white'}}>
                        {requesting ? (<CircularProgress style={{color: 'white'}} size={22}/>) : 'Comentar'}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    review: state.review,
    client: state.client,
    chat: state.chat
});

const connected = connect(mapStateToProps, {
    controlModalReview,
    createReviewRequesting
})(reviewComponent);

export default connected;
