export const CREATE_USER_REQUESTING = 'CREATE_USER_REQUESTING';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const UPDATE_USER_REQUESTING = 'UPDATE_USER_REQUESTING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const SHOW_MODAL_USER='SHOW_MODAL_USER';
export const ERROR_CONTROL_CREATE_USER ='ERROR_CONTROL_CREATE_USER';
export const ERROR_CONTROL_UPDATE_USER ='ERROR_CONTROL_UPDATE_USER';

export const GET_USER_REQUESTING = 'GET_USER_REQUESTING';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const FILTER_TYPE_PEOPLE_REQUESTING = 'FILTER_TYPE_PEOPLE_REQUESTING';
export const FILTER_TYPE_PEOPLE_SUCCESS = 'FILTER_TYPE_PEOPLE_SUCCESS';
export const FILTER_TYPE_PEOPLE_ERROR = 'FILTER_TYPE_PEOPLE_ERROR';

export const SET_FOCUS_USER = 'SET_FOCUS_USER';

export const SHOW_MODAL_CATALOG = 'SHOW_MODAL_CATALOG';

export const SHOW_USER_REQUESTING = 'SHOW_USER_REQUESTING';
export const SHOW_USER_SUCCESS = 'SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'SHOW_USER_ERROR';




