import {all, fork} from 'redux-saga/effects';
import CitySagas from './city/sagas';
import ModuleSagas from './module/sagas';
import CategorySagas from './category/sagas';
import TimeSagas from './time/sagas';
import PublicationSagas from './publication/sagas';
import PublicationsTalkSagas from './publicationsTalks/sagas';
import SocketSagas from './chat/sagas';
import SubscriptionSagas from './subscription/sagas';
import PaymentSagas from './payment/sagas';
import UserSubscriptionSagas from './subscription/user/sagas'
import UserSagas from './user/sagas';
import LoginSagas from './login/sagas';
import ClientSagas from './client/sagas';
import TypePeopleSagas from './typePeople/sagas';
import TypeProfessional from './typeProfessional/sagas';
import TypeProfile from './typeProfile/sagas';
import GenderSagas from './gender/sagas';
import ResetPasswordSagas from './reset_password/sagas';
import ReviewSagas from './review/sagas';
import PublicationFavoriteSagas from './publicationFavorite/sagas';
import PhoneSagas from './phone/sagas';

export function* IndexSagas() {
    yield all([
        fork(CitySagas),
        fork(ModuleSagas),
        fork(CategorySagas),
        fork(TimeSagas),
        fork(PublicationSagas),
        fork(PublicationsTalkSagas),
        fork(SocketSagas),
        fork(SubscriptionSagas),
        fork(PaymentSagas),
        fork(UserSubscriptionSagas),
        fork(UserSagas),
        fork(TypePeopleSagas),
        fork(TypeProfessional),
        fork(TypeProfile),
        fork(GenderSagas),
        fork(ClientSagas),
        fork(LoginSagas),
        fork(ResetPasswordSagas),
        fork(ReviewSagas),
        fork(PublicationFavoriteSagas),
        fork(PhoneSagas)
    ])
}
