import {ErrorComponent} from "../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import React from "react";


export const handleShowError = (error) => {
    let toastOptions = {
        component: (
            <ErrorComponent message={error}/>
        )
    };
    toastr.error('Error', toastOptions);
};

