import {GET_TYPE_PROFILE_ERROR, GET_TYPE_PROFILE_REQUESTING, GET_TYPE_PROFILE_SUCCESS} from "./constants";

export const getTypeProfileRequesting = () => ({
    type: GET_TYPE_PROFILE_REQUESTING
});

export const getTypeProfileSuccess = (typeProfiles) => ({
    type: GET_TYPE_PROFILE_SUCCESS,
    typeProfiles
});

export const getTypeProfileError = (error) => ({
    type: GET_TYPE_PROFILE_ERROR,
    error
});