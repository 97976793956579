import {call, put, all, takeEvery} from 'redux-saga/effects';
import {
    GET_SUBSCRIPTION_USER_REQUESTING,
    CREATE_SUBSCRIPTION_USER_REQUESTING
} from './constants';
import {getAllSubscriptionRequesting} from "../actions";
import {showModalPayment} from "../../payment/actions";
import {showModalSubscription} from "../actions";
import {
    getSubscriptionUserSuccess,
    getSubscriptionUserError,
    createSubscriptionUserSuccess,
    createSubscriptionUserError,
} from './actions';
import {toastr} from "react-redux-toastr";
import {handleApiErrors} from "../../../commons/errors/apiErrors";

const subscriptionsUserURL = `${process.env.REACT_APP_API_URL}/subscriptions`;
const subscriptionsCreateUserURL = `${process.env.REACT_APP_API_URL}/userSubscriptions/app`;

//Crear suscripcion
const createSubscriptionUserApi = (user, subscription, payment, token, statePayment, referencePay) => {

    let body = new FormData();
    if (user !== undefined)
        body.append('user_id', user);
    if (subscription !== undefined)
        body.append('subscription_id', subscription);
    if (payment !== undefined)
        body.append('payment_id', payment);
    if (statePayment !== undefined)
        body.append('state_payment', statePayment);
    if (referencePay !== undefined)
        body.append('reference_pay', referencePay);
    return fetch(subscriptionsCreateUserURL, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* createSubscriptionUserFlow(action) {
    try {
        const {user, subscription, payment, token, statePayment, referencePay} = action;
        const subscriptionCreated = yield call(createSubscriptionUserApi, user, subscription, payment, token, statePayment, referencePay);
        yield put(createSubscriptionUserSuccess(subscriptionCreated));
        yield put(getAllSubscriptionRequesting(token));
        if(subscriptionCreated.estado_pago==='PENDING_TRANSACTION_CONFIRMATION'){
            toastr.info('Suscripcion', 'Se ha enviado el recibo de pago a tu correo electrónico');
        }else {
            toastr.success('Suscripcion', 'Se ha unido a la suscripcion');
        }
        yield put(showModalPayment(''));
        yield put(showModalSubscription(''));
    } catch (error) {
        yield put(createSubscriptionUserError(error));
    }
}


//Obtener suscripciones
const getSubscriptionsUserApi = (token) => {

    const url = `${subscriptionsUserURL}/app/user`;

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getSubscriptionsUserFLow(action) {
    try {
        const {token} = action;
        const getSubscriptions = yield call(getSubscriptionsUserApi, token);
        yield put(getSubscriptionUserSuccess(getSubscriptions));
    } catch (error) {
        yield put(getSubscriptionUserError(error));
    }
}

function* usersWatcher() {
    yield all([
        takeEvery(GET_SUBSCRIPTION_USER_REQUESTING, getSubscriptionsUserFLow),
        takeEvery(CREATE_SUBSCRIPTION_USER_REQUESTING, createSubscriptionUserFlow),
    ])
}

export default usersWatcher
