import React from 'react';
import Logo from './../../assets/img/Diseño-header-logo.png';
import Digital from './../../assets/img/logo-digitalparchapp.png';
import Pagos from './../../assets/img/PayU2.png';
import PlayStore from './../../assets/img/PlayStore.png';
import AppleStore from './../../assets/img/ApleStore.png';
import Transportadoras from './../../assets/img/transportadoras.png';
import {useDispatch} from "react-redux";
import {showModalUser} from "../../redux/user/actions";

export const FooterComponent = (height = 60) => {
    const dispatch = useDispatch();


    return <div>
        <div className="container-footer">
            <div className="container-item3">
                <div className="image">
                    <img src={Logo} alt={'logo'} style={{width: 170}}/>
                </div>
            </div>
            <div className="container-item3">
                {/*<div className="item1-footer">
                            <h3>Ciudad de cobertura</h3>
                            <h4>Bogotá</h4>
                        </div>
*/}
                <div className="item1-footer">
                    <h3>Categoría principales</h3>
                    <h4>Hogar</h4>
                    <h4>Oficina</h4>
                    <h4>Autos</h4>
                    <h4>Servicios</h4>
                </div>


                <div className="item1-footer">
                    <h3>Información de interes</h3>

                    <div className="item1-footer" onClick={() => dispatch(showModalUser('modal quality politics'))}>
                        <h4>Política de Calidad e Idoneidad de los Productos a Ofrecer y roductos Prohibidos.</h4>
                    </div>

                    <div className="item1-footer" onClick={() => dispatch(showModalUser('modal data treatment'))}>
                        <h4>Política de tratamiento de datos personales.</h4>
                    </div>

                    <div className="item1-footer" onClick={() => dispatch(showModalUser('modal terms conditions'))}>
                        <h4>Terminos y condiciones</h4>
                    </div>

                    <div className="item1-footer" onClick={() => dispatch(showModalUser('modal frequency'))}>
                        <h4>Preguntas Frecuentes</h4>
                    </div>

                    <h4><a target="_blank" href="https://forms.gle/KbF54i4sV2nTeU7Y6">Peticiones, quejas y reclamos</a>
                    </h4>

                </div>

                <div className="item1-footer" onClick={() => dispatch(showModalUser('modal register'))}>
                    <h3>Trabaja con nosotros</h3>
                    <h4>Presta tus servicios en nuestra plataforma</h4>
                    {/*<a href="https://contratistas.spedy.com.co"><p>Ingresa tus datos</p></a>*/}
                </div>


                <div className="item1-footer item1-footer-center">
                    <h3>Redes sociales</h3>
                    <h4><a target="_blank" href="https://www.facebook.com/tuconstruccion.com.co/"
                           style={{color: '#9aaebc'}}><i
                        className="icon-facebook-squared  icon-redes "/>Facebook</a></h4>
                    <h4><a target="_blank" href="https://www.instagram.com/tuconstruccion.com.co/?hl=es-la"
                           style={{color: '#9aaebc'}}><i
                        className="icon-instagram  icon-redes "/>Instagram</a></h4>

                </div>

                <div className="item1-footer">
                    <h3>Descarga nuestra app</h3>
                    <a target="_blank" href="https://apps.apple.com/us/app/id1487830029">
                        <img src={AppleStore}
                             alt="payU" style={{width: 110, height: 'auto'}}/>
                    </a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tuconstrucion&hl=es">
                        <img src={PlayStore}
                             alt="payU" style={{width: 110, height: 'auto'}}/>
                    </a>
                </div>

                <div className="item1-footer">
                    <h3>Pagos seguros</h3>
                    <img src={Pagos} title="" alt=""
                    />
                </div>

                <div className="item1-footer2">
                    <h3>Transportadoras sugeridas</h3>
                    <img
                        src={Transportadoras}
                        title="" alt=""/>
                </div>

            </div>
        </div>
        <div className="container-footer2">
            <div className="logo-digital">
                <h3>Digital parchapp</h3>
                <img src={Digital} alt=""/>
            </div>
        </div>

    </div>
};

{/*<div className="footer">*/
}
{/*    <div className="contain">*/
}
{/*        <div className="col">*/
}
{/*            <h1>TuConstrucción</h1>*/
}
{/*            <div className="image">*/
}
{/*                <img src={Logo} alt={'logo'} style={{width: 122}}/>*/
}
{/*            </div>*/
}
{/*            /!*<ul>*!/*/
}
{/*            /!*    <li>Términos y condiciones</li>*!/*/
}
{/*            /!*    <li><img src="https://byviti.com/wp-content/uploads/2019/07/fajascolombianas.jpg"*!/*/
}
{/*            /!*             alt="camaraComercio" style={{width: 125, height: 75}}/></li>*!/*/
}
{/*            /!*</ul>*!/*/
}
{/*        </div>*/
}
{/*        <div className="col">*/
}
{/*            <div>*/
}
{/*                <h1>Pagos seguros</h1>*/
}
{/*                <img src="https://www.payulatam.com/co/wp-content/uploads/sites/2/2017/02/payu@2x.png"*/
}
{/*                     alt="payU" style={{width: 50, height: 25}}/>*/
}
{/*            </div>*/
}
{/*        </div>*/
}
{/*        <div className="col">*/
}
{/*            <h1>Cobertura</h1>*/
}
{/*            <ul>*/
}
{/*                <li><a*/
}
{/*                    target={'_blank'}*/
}
{/*                    style={{color: '#999999', textDecorationLine: 'none'}}*/
}
{/*                    href="https://www.google.com/maps/place/Bogot%C3%A1/@4.6486259,-74.2478915,11z/data=!3m1!4b1!4m5!3m4!1s0x8e3f9bfd2da6cb29:0x239d635520a33914!8m2!3d4.7109886!4d-74.072092?hl=es">Bogotá*/
}
{/*                    D.C</a></li>*/
}
{/*            </ul>*/
}
{/*        </div>*/
}
{/*        <div className="col">*/
}
{/*            <h1>Soporte</h1>*/
}
{/*            <ul>*/
}
{/*                <li><a*/
}
{/*                    style={{color: '#999999', textDecorationLine: 'none'}}*/
}
{/*                    href="https://wa.me/573203764679" target={'_blank'}>Contáctenos</a></li>*/
}
{/*            </ul>*/
}
{/*        </div>*/
}
{/*        <div className="col">*/
}
{/*            <h1>Plataformas</h1>*/
}
{/*            <ul style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>*/
}
{/*                <li style={{width: 32, margin: '0 10px 0 0'}}><a*/
}
{/*                    href='https://play.google.com/store/apps/details?id=com.tuconstrucion&hl=es'*/
}
{/*                    target={'_blank'}><img*/
}
{/*                    src={require('./../../assets/icons/android.png')} style={{width: '41px'}}/></a></li>*/
}
{/*                <li style={{width: 32, margin: '0 10px 0 0'}}><a href='https://svgshare.com/s/8zE'><img*/
}
{/*                    src={require('./../../assets/icons/apple (1).png')} style={{width: '41px'}}/></a></li>*/
}
{/*            </ul>*/
}
{/*        </div>*/
}
{/*        <div className="col social">*/
}
{/*            <h1>Social</h1>*/
}
{/*            <ul>*/
}
{/*                <li style={{width: 32, margin: '0 10px 0 0'}}><a href='https://svgshare.com/s/8zE'><img*/
}
{/*                    src="https://svgshare.com/i/5fq.svg" style={{width: '32'}}/></a></li>*/
}
{/*                <li style={{width: 37.5}}><a href='https://svgshare.com/s/8zE'><img*/
}
{/*                    src='https://svgshare.com/i/8zE.svg'*/
}
{/*                    title='instagram'/></a></li>*/
}

{/*            </ul>*/
}
{/*        </div>*/
}
{/*        <div className="clearfix"/>*/
}
{/*    </div>*/
}
{/*</div>*/
}

{/*<div>*/
}
{/*    <div className="container-footer">*/
}
{/*        <div className="container-item3">*/
}
{/*            <div className="logo-footer">*/
}
{/*                <img src={LogoDigital}/>*/
}
{/*            </div>*/
}
{/*        </div>*/
}
{/*        <div className="container-item3" style={{display: 'flex', justifyContent: 'space-evenly', width : '100%'}}>*/
}
{/*            <div className="item1-footer">*/
}
{/*                <h3>Ciudades cobertura</h3>*/
}
{/*                <h4>Bogota</h4>*/
}

{/*            </div>*/
}
{/*            <div className="item1-footer">*/
}
{/*                <h3>informacion de interes</h3>*/
}
{/*                <h4>Terminos de privacidad</h4>*/
}
{/*                <h4>Tratamientos de datos</h4>*/
}
{/*                <h4>Politicas de privacidad</h4>*/
}
{/*            </div>*/
}
{/*            <div className="item1-footer">*/
}
{/*                <div className="social-container">*/
}
{/*                    <h1>Redes sociales</h1>*/
}
{/*                    <ul className="social-icons">*/
}
{/*                        <li><a href="#" className="social-square"><i className="fa fa-facebook"/></a></li>*/
}
{/*                        <li><a href="#" className="social-square"><i className="fa fa-twitter"/></a></li>*/
}
{/*                    </ul>*/
}
{/*                    <ul className={'social-icons'}>*/
}
{/*                        <li><a href="#" className="social-square"><i className="fa fa-android"/></a></li>*/
}
{/*                        <li><a href="#" className="social-square"><i className="fa fa-apple"/></a></li>*/
}
{/*                    </ul>*/
}
{/*                </div>*/
}
{/*            </div>*/
}

{/*        </div>*/
}
{/*    </div>*/
}
{/*    <div className="container-footer2">*/
}
{/*        <h3>Tu Construcción</h3>*/
}
{/*        <div className="logo-digital">*/
}
{/*            <h3>*/
}
{/*                Developed by Digital parchapp</h3>*/
}
{/*            <img src={LogoDigital} alt="Logo Gou domicilio"/>*/
}
{/*        </div>*/
}
{/*    </div>*/
}
{/*</div>*/
}
