import {
    GET_GENDER_ERROR,
    GET_GENDER_REQUESTING,
    GET_GENDER_SUCCESS,
    FILTER_GENDER,
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    genders: [],
    gendersFilter: [],
    peoples: [],
    people: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GENDER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_GENDER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                genders: action.genders
            };
        case GET_GENDER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                genders: []
            };
        case FILTER_GENDER:
            console.log(action.typePeopleFocus);
            let gender = [];
            if (action.typePeopleFocus.nombre === 'Persona Natural' || action.typePeopleFocus.nombre === 'Profesional') {
                gender = state.genders.filter((gender) => gender.nombre !== 'Persona Jurídica');
            } else {
                gender = state.genders.filter((gender) => gender.nombre === 'Persona Jurídica');
            }
            return {
                ...state,
                gendersFilter: gender
            };
        default:
            return state;
    }
};

export default reducer;
