import {call, all, put, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    loginError,
    loginFacebookError,
    loginFacebookSuccess,
    loginSuccess,
    logoutError,
    logoutSuccess
} from "./actions";
import {LOGIN_FACEBOOK_REQUESTING, LOGIN_REQUESTING, LOGOUT_REQUESTING} from "./constants";
import {checkJWTRequesting, clientSet, getMeRequesting} from "../client/actions";
import {errorControlLogin} from "./actions";
import {toastr} from "react-redux-toastr";
import {clientUnset} from "../client/actions";
import {showModalUser} from "../user/actions";


const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/user`;
const logoutUrl = `${process.env.REACT_APP_API_URL}/auth/logout`;
const LOGIN_FACEBOOK_URL = `${process.env.REACT_APP_API_URL}/auth/facebook`;

const loginAPI = (values) => {
    let body = {
        'email': values.email,
        'password': values.password,
        'device': 'mobile',
    };
    return fetch(LOGIN_URL, {
        method: 'POST',
        headers: {
            'Content-Type' : 'application/json',
            'Accept' : 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if(json.code === 400)
                throw {
                error: json.data
                };
            if(json.hasOwnProperty('access_token')){
                localStorage.setItem('@user:tuConstruccion', json.access_token);
                return json.access_token;
            }
            throw ''
        }).catch((error) => {
            throw error;
        })
};

function* loginFlow(action) {
    try {
        const {values} = action;
        const token = yield call(loginAPI, values);
        yield put(loginSuccess(token));
        yield put(checkJWTRequesting(token));
        yield put(showModalUser(''));
        toastr.success('Usuario', 'Ingreso sastifactorio');
    }catch (error) {
        yield put(loginError(error));
        yield put(errorControlLogin());
    }
}

const logoutPostApi = (token) => {
    return fetch(logoutUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200) {
                localStorage.removeItem('@user:tuConstruccion');
                window.location.href = '/';
            } else {
                throw json.data;
            }
        }).catch((error) => {
            throw error
        })
};

function* logoutPostFlow(action) {
    try {
        const {token} = action;
        yield call(logoutPostApi, token);
        yield put(logoutSuccess());
        yield put(clientUnset());
    } catch (error) {
        yield put(logoutError(error));
    }
}

const loginFacebookApi = (response) => {


    let body = {
        name: response.name,
        email: response.email,
        photo: response.picture.data.url,
        device: 'desktop',
        token_facebook: response.userID,
        password: response.accessToken
    };

    return fetch(LOGIN_FACEBOOK_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.hasOwnProperty('access_token')) {
                localStorage.setItem('@user:tuConstruccion', json.access_token);
                return json.access_token;
            } else {
                throw json.data;
            }
        }).catch((error) => {
            throw error
        })
};

function* loginFacebookFlow(action) {
    try {
        const {response} = action;
        const token = yield call(loginFacebookApi, response);
        yield put(loginFacebookSuccess(token));
        yield put(getMeRequesting(token));
        yield put(showModalUser(''));
    } catch (error) {
        yield put(loginFacebookError(error));
    }
}

function* loginWatcher() {
    yield all([
        takeEvery(LOGIN_REQUESTING, loginFlow),
        takeEvery(LOGOUT_REQUESTING,logoutPostFlow),
        takeEvery(LOGIN_FACEBOOK_REQUESTING, loginFacebookFlow)
    ])
}

export default loginWatcher;