import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, all, put, takeEvery} from 'redux-saga/effects';
import {
    createUserError,
    createUserSuccess,
    updateUserSuccess,
    updateUserError,
    showModalUser,
    errorControlCreateUser,
    errorControlUpdateUser,
    getUserSuccess,
    getUserError,
    filterTypePeopleSuccess,
    filterTypePeopleError,
    showUserSuccess, showUserError
} from "./actions";
import {updateClient} from "../client/actions";
import {loginRequesting} from "../login/actions";
import {toastr} from "react-redux-toastr";
import {
    CREATE_USER_REQUESTING, FILTER_TYPE_PEOPLE_REQUESTING, GET_USER_REQUESTING, SHOW_USER_REQUESTING,
    UPDATE_USER_REQUESTING
} from "./constants";

const USER_URL = `${process.env.REACT_APP_API_URL}/users/app`;
const USERS_URL = `${process.env.REACT_APP_API_URL}/users`;

const createUserAPI = (user) => {
    let typePeople=  user.typePeople !=='type_people' && user.typePeople!==undefined ? JSON.parse(user.typePeople): '';
    let body = {
        'name' : user.name,
        'type_people': typePeople ? typePeople.id : '',
        'type_people_name':typePeople ? typePeople.nombre : '',
        'email': user.email,
        'city': user.city,
        'identification': user.identification,
        'phone': user.phone,
        'address': user.address,
        'person_contact': user.nameContact,
        'phone_contact': user.phoneContact,
        'password': user.password,
        'type_profile': user.typeProfile,
        'gender' : user.gender,
        'type_professional': user.typeProfessional ? user.typeProfessional: '',
        'password_confirmation' : user.confirmed_password,
        'email_confirmation' : user.confirmed_email
    };

    return fetch(USER_URL , {
        method : 'POST',
        headers: {
            'Content-type' : 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;

        })
};

function* createUserFlow(action) {
    try {
        const {user} = action;
        yield call(createUserAPI, user);
        yield put(createUserSuccess());
        toastr.success('Usuario', 'Usuario registrado');
        yield put(showModalUser(''));
        yield put(loginRequesting(user))
    }catch (error) {
        yield put(createUserError(error));
        yield put(errorControlCreateUser());
    }
}

const userUpdateAPI = (values,token,user,typePeoples) => {

    let body = new FormData();
    let typePeople= values.tipo_persona.hasOwnProperty('nombre') ?
        typePeoples.filter(typePeople=>typePeople.id===values.tipo_persona.id):
        typePeoples.filter(typePeople=>typePeople.id===values.tipo_persona);
    body.append('_method', 'PUT');
    body.append('name', values.nombre || '');
    body.append('identification', values.identificacion || '');
    body.append('phone', values.telefono || '');
    body.append('email', values.correo || '');
    body.append('address', values.direccion || '');
    body.append('person_contact', values.persona_contacto || '');
    body.append('phone_contact', values.telefono_contacto);
    body.append('type_profile', values.tipo_perfil.id || values.tipo_perfil);
    body.append('gender', values.genero.id || values.genero);
    body.append('type_people_name', typePeople ? typePeople[0].nombre :'');
    body.append('type_people', typePeople[0].id ||'');
    if(typePeople[0].nombre==='Profesional')
        body.append('type_professional',  values.tipo_profesional.id || values.tipo_profesional);
    if (values.photos.length > 0) {
        body.append(`photo_0`,values.photos[0]);
        body.append('photos_length', values.photos.length || '');
    }else {
        body.append('photos_length', 0);
    }
    body.append('city',   values.ciudad.id || values.ciudad );

    return fetch(`${USER_URL}/${user}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateUserFlow(action) {
    try {
        const {values,token, user,typePeoples} = action;
        const userUpdate = yield call(userUpdateAPI,values,token,user,typePeoples);
        yield put(updateUserSuccess(userUpdate));
        yield put(updateClient(userUpdate));
        yield put(showModalUser(''));
        toastr.success('Usuario editado', 'Usuario editado');
    } catch (error) {
        yield put(updateUserError(error));
        yield put(errorControlUpdateUser());
    }
}

const getUserAPI = (token) => {

    return fetch(`${USERS_URL}/index`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;

        }).catch((error) => {
            throw error;
        })
};

function* getUserFlow(action) {
    try{
        const {token} = action;
        const users = yield call(getUserAPI, token);
        yield put(getUserSuccess(users));
    }catch (error) {
        yield put(getUserError(error));
    }
}

const filterTypePeopleAPI = (typePeople, token) => {
    let newFilter = typePeople > 0 ? 'tipos' : 'todos';
    let body = {
        typePeople: typePeople
    };
    return fetch(`${USERS_URL}/typePeople/${newFilter}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;

        }).catch((error) => {
            throw error;
        })
};

function* filterTypePeopleFlow(action) {
    try{
        const {typePeople, token} = action;
        const users = yield call(filterTypePeopleAPI, typePeople, token);
        yield put(filterTypePeopleSuccess(users));
    }catch (error) {
        yield put(filterTypePeopleError(error));
    }
}

const showUserAPI = (id) => {

    return fetch(`${USERS_URL}/${id}`, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;

        }).catch((error) => {
            throw error;
        })
};

function* showUserFlow(action) {
    try {
        const {id} = action;
        const user = yield call(showUserAPI, id);
        yield put(showUserSuccess(user));
    }catch (error) {
        yield put(showUserError(error));
    }
}

function* userWatcher() {
    yield all([
        takeEvery(CREATE_USER_REQUESTING,createUserFlow),
        takeEvery(UPDATE_USER_REQUESTING,updateUserFlow),
        takeEvery(GET_USER_REQUESTING, getUserFlow),
        takeEvery(FILTER_TYPE_PEOPLE_REQUESTING, filterTypePeopleFlow),
        takeEvery(SHOW_USER_REQUESTING, showUserFlow)
    ])
}

export default userWatcher;
