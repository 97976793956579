import React from 'react';
//Material-ui
import {MuiThemeProvider} from '@material-ui/core/styles';
//button
import Trofeo from "../../../../assets/img/Trofeo.jpg";
import posed from "react-pose";
import {theme} from "../../../../theme/theme";
import {connect} from "react-redux";
import {actualPage, filterChangePrimaryProfile, filterChangeSecondaryProfile} from "../../../../redux/iu/actions";
import {getSubscriptionUserRequesting} from "../../../../redux/subscription/user/actions";
import lottie from "lottie-web";


////effect
const Box = posed.div({
    visible: {
        x: '0%',
        delayChildren: 1000,
        staggerChildren: 200,
        delay: 50
    },
    hidden: {x: '100%'}
});

const Item = posed.div({
    visible: {
        scale: 1,
    },
    hidden: {scale: 0}
});

const ItemHover = posed.div({
    hoverable: true,
    init: {
        scale: 1,
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        // boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
});

/////////////////////////

class PageSubscriptionView extends React.Component {

    constructor(props) {
        super(props);
        const {
            getSubscriptionUserRequesting,
            actualPage,
            filterChangePrimaryProfile,
        } = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        getSubscriptionUserRequesting(token);
        filterChangePrimaryProfile('Suscripciones');
        this.animation = React.createRef();
    }

    state = {
        isVisible: false,
        isOpen: false,
        value: 0,
        isOpenProduct: false,
        isOpenFilter: false,
        productPost: [1, 2, 3, 4, 5, 6],
        focusFilter1: '',
        focusFilter2: '',
    };

    componentDidMount() {
        this.setState({
            isVisible: true
        });
        lottie.loadAnimation({
            container: this.animation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/subscription.json'
        });
    }

    render() {
        const {
            mySubscription,
            client: {client},
            user: {isOpenModal},
            typePeople: {typePeoples},
            filterGender,
            visibleTypeProfessional,
            showModalUser,
            userSubscription: {userSubscriptions},
        } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <Box pose={this.state.isVisible ? 'visible' : 'hidden'}>
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<PageProfile*/}
                    {/*    user={client}*/}
                    {/*    isOpenModal={isOpenModal}*/}
                    {/*    typePeoples={typePeoples}*/}
                    {/*    handleControlModal={showModalUser}*/}
                    {/*    handleFilterGender={filterGender}*/}
                    {/*    visibleProfessional={visibleTypeProfessional}*/}
                    {/*/>*/}
                    {/*<div className="container-item-start">*/}
                    {/*    <FilterUserPrincipalComponent/>*/}
                    {/*</div>*/}
                    <div className="container-item-start">
                        <div className="line"/>
                        <div className="container-sub-text">
                            <h2>Mis suscripciones</h2>
                            <p> Historial de subscripciónes</p>
                        </div>
                        {userSubscriptions.length === 0 && (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div className="container-animations-empty-one">
                                    <div className={'animation'} ref={this.animation}/>
                                </div>
                                <p className="p-animation-empty-one" style={{fontSize: 22, color: 'rgba(0,0,0,.3)'}}>No
                                    cuentas con
                                    suscripciones ...</p>
                            </div>
                        )}
                        {userSubscriptions.length > 0 && userSubscriptions.map(mySubscription => (
                            <div className="container-profile-product" style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <div className="profile-product-item1">
                                    <div className="container-animations-empty-one" style={{width: 110}}>
                                        <div className={'animation'} ref={this.animation}/>
                                    </div>
                                </div>
                                <div className="container-sub-text">
                                    <p style={{
                                        fontWeight: 'bold',
                                        marginRight: 8
                                    }}>{mySubscription.suscripcion.nombre}</p>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                        , flexWrap: 'wrap'
                                    }}>
                                        <p style={{fontWeight: 'bold', marginRight: 8}}>Descripcion : </p>
                                        <p>{mySubscription.suscripcion.descripcion}</p>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}>
                                        <p style={{fontWeight: 'bold', marginRight: 8}}>Fecha de Inicio : </p>
                                        <p> {mySubscription.fecha_inicio}</p>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}>
                                        <p style={{fontWeight: 'bold', marginRight: 8}}>Fecha de vencimiento : </p>
                                        <p> {mySubscription.fecha_vencimiento}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Box>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    publication: state.publication,
    publicationsTalk: state.publicationsTalk,
    typePeople: state.typePeople,
    userSubscription: state.userSubscription
});

const connected = connect(mapStateToProps, {
    actualPage,
    filterChangeSecondaryProfile,
    getSubscriptionUserRequesting,
    filterChangePrimaryProfile
})(PageSubscriptionView);

export default connected;
