import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {MenuItem} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import {connect} from "react-redux";
import {Field, Form, reduxForm} from "redux-form";
import {createUserRequesting, errorControlCreateUser} from "../../../redux/user/actions";
import {filterGender, getGenderRequesting} from "../../../redux/gender/actions";
import {getTypeProfessionalRequesting, visibleTypeProfessional} from "../../../redux/typeProfessional/actions";
import {citiesGetRequesting} from "../../../redux/city/actions";
import {getTypePeopleRequesting} from "../../../redux/typePeople/actions";
import {getTypeProfileRequesting} from "../../../redux/typeProfile/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {MuiThemeProvider} from "@material-ui/core";
import {materialTheme} from "../../../commons/theme/theme";
import ButtonFacebook from "../../button-facebook";


const descriptionProfile = [
    {
        name: 'Público',
        description: 'Seleccionado este perfil las personas lo puden buscar en el directorio para ver sus productos'
    },
    {name: 'Privado', description: 'Seleccionado este perfil, las personas no lo podran buscar en el directorio'},
];


const inputTextField = ({input, name, label, type, id, placeholder, meta: {touched, error}, ...rest}) => {
    return (
        <MuiThemeProvider theme={materialTheme}>
            <TextField
                {...input}
                {...rest}
                label={label}
                type={type}
                value={input.value}
                name={input.name}
                placeholder={placeholder ? placeholder : ''}
                margin="normal"
                variant="outlined"
                fullWidth
                multiline={type === "text"}
                rowsMax={'10'}
            />
        </MuiThemeProvider>
    )
};

class RegisterModalComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            citiesGetRequesting,
            getTypeProfileRequesting,
            getTypePeopleRequesting,
            getGenderRequesting,
            getTypeProfessionalRequesting,

        } = this.props;
        citiesGetRequesting();
        getTypeProfileRequesting();
        getTypePeopleRequesting();
        getGenderRequesting();
        getTypeProfessionalRequesting();
    }

    state = {
        messageTypeProfile: null,
    };

    handleAction = (values) => {
        const {createUserRequesting, user: {phone}} = this.props;
        createUserRequesting({
            ...values,
            password: phone === 0 ? values.password : Math.random(),
            phone: phone === 0 ? values.phone : phone
        })
    };

    handleError = () => {
        const {user: {error}, errorControlCreateUser} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlCreateUser();
    };

    handleShowMessageTypeProfile = (value) => {
        const {
            typeProfile: {typeProfiles},
        } = this.props;
        let typeProfile = typeProfiles.find((typeProfile) => typeProfile.id === value);
        this.setState({
            messageTypeProfile: descriptionProfile.find((description) => description.name === typeProfile.nombre).description,
        });
    };

    render() {
        const {
            handleControl,
            visible,
            handleSubmit,
            city: {cities},
            typePeople: {typePeoples},
            typeProfessional,
            gender: {gendersFilter},
            typeProfile: {typeProfiles},
            filterGender,
            visibleTypeProfessional,
            user: {errorControlCreateUser, phone},
        } = this.props;
        return (

            <Dialog
                onClose={() => handleControl('')} aria-labelledby="customized-dialog-title"
                open={visible}>

                <DialogTitle id="customized-dialog-title">
                    Registro
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={() => handleControl('')}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent>
                    {errorControlCreateUser && this.handleError()}
                    <Form onSubmit={handleSubmit(this.handleAction)}>

                        <Field name={'name'} type={'text'} component={inputTextField}
                               label={'Nombre'}/>
                        {typePeoples.length > 0 &&
                        (
                            <Field select name={'typePeople'} type={'select'} component={inputTextField}
                                   label={'Seleccionar Tipo de persona'}
                                   onChange={(e) => {
                                       filterGender(typePeoples, e.target.value !== 'type_people' ? JSON.parse(e.target.value) : '');
                                       visibleTypeProfessional(e.target.value !== 'type_people' ? JSON.parse(e.target.value) : '');
                                   }}
                            >
                                {typePeoples.map((typePeople, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index}
                                                      value={JSON.stringify(typePeople)}>
                                                {typePeople.nombre !== 'Todos' && (
                                                    <p className="menuItemStyleP">{typePeople.nombre}</p>
                                                )}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Field>
                        )}

                        {typeProfessional.visible && typeProfessional.typeProfessionals.length > 0 &&
                        (
                            <Field select name={'typeProfessional'} type={'select'} component={inputTextField}
                                   label={'Seleccionar Tipo Profesional'}
                            >
                                {typeProfessional.typeProfessionals.map((typeProfessional, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index} value={typeProfessional.id}>
                                                <p className="menuItemStyleP">{typeProfessional.nombre}</p>
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Field>
                        )}

                        {gendersFilter.length > 0 &&
                        (
                            <Field select name={'gender'} type={'select'} component={inputTextField}
                                   label={'Seleccionar Genero'}
                            >
                                {gendersFilter.map((gendersFilter, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index} value={gendersFilter.id}>
                                                <p className="menuItemStyleP">{gendersFilter.nombre}</p>
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Field>
                        )}

                        {cities.length > 0 &&
                        (
                            <Field select name={'city'} type={'select'} component={inputTextField}
                                   label={'Ciudad'}
                            >
                                {cities.map((city, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index} value={city.id}>
                                                <p className="menuItemStyleP">{city.nombre}</p>
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Field>
                        )}

                        {typeProfiles.length > 0 &&
                        (
                            <Field select name={'typeProfile'} type={'select'} component={inputTextField}
                                   label={'Seleccionar el tipo de perfil'}
                                   onChange={(input) => this.handleShowMessageTypeProfile(input.target.value)}>
                                {typeProfiles.map((typeProfile, index) => {
                                        return (
                                            <MenuItem className="menuItemStyle" key={index} value={typeProfile.id}>
                                                <p className="menuItemStyleP">{typeProfile.nombre}</p>
                                            </MenuItem>

                                        );
                                    }
                                )}
                            </Field>
                        )}
                        {this.state.messageTypeProfile}
                        <Field name={'identification'} type="text" label={'Cedula-Nit'}
                               component={inputTextField}/>
                        <p>* Sin puntos, simbolos, ni codigo de verificación</p>
                        {phone === 0 &&
                        <Field name={'phone'} type="text" label={'Telefono'} component={inputTextField}/>}

                        <Field name={'email'} type="email" label={'Correo'} component={inputTextField}/>
                        <Field name={'confirmed_email'} type="email" label={'Confirmar correo'}
                               component={inputTextField}/>

                        <Field name={'address'} type="text" label={'Direccion'} component={inputTextField}/>

                        <Field name={'nameContact'} type="text" label={'Nombre de usuario'}
                               component={inputTextField}/>

                        <Field
                            name={'phoneContact'}
                            type="text"
                            label={'Teléfono alternativo'}
                            component={inputTextField}
                        />

                        {phone === 0 &&
                        <>
                            <Field name={'password'} type="password" autoComplete="current-password"
                                   label={'Contraseña'}
                                   component={inputTextField}/>
                            <Field name={'confirmed_password'} type="password" label={'Confirmar contraseña'}
                                   component={inputTextField}/>
                        </>
                        }


                        <DialogActions>
                            <ButtonFacebook/>
                            <Button style={{backgroundColor: 'blue', color: 'white'}}>Ver términos y
                                condiciones</Button>

                            <Button variant="contained" color="Secondary" type={'submit'}>Crear cuenta</Button>
                            <Button variant="contained" onClick={() => handleControl('')}
                                    color="Secondary">Cancelar</Button>
                        </DialogActions>
                    </Form>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    city: state.city,
    typePeople: state.typePeople,
    typeProfessional: state.typeProfessional,
    gender: state.gender,
    typeProfile: state.typeProfile,
});
const connected = connect(mapStateToProps, {
    citiesGetRequesting,
    getTypeProfileRequesting,
    getTypePeopleRequesting,
    getGenderRequesting,
    getTypeProfessionalRequesting,
    filterGender,
    visibleTypeProfessional,
    createUserRequesting,
    errorControlCreateUser
})(RegisterModalComponent);

const formed = reduxForm({
    form: 'registerUser',
})(connected);

export default formed;

