import {
    CREATE_USER_ERROR,
    CREATE_USER_REQUESTING,
    CREATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_REQUESTING,
    SHOW_MODAL_USER,
    ERROR_CONTROL_CREATE_USER,
    ERROR_CONTROL_UPDATE_USER,
    GET_USER_REQUESTING,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    FILTER_TYPE_PEOPLE_REQUESTING,
    FILTER_TYPE_PEOPLE_SUCCESS,
    FILTER_TYPE_PEOPLE_ERROR,
    SET_FOCUS_USER,
    SHOW_MODAL_CATALOG,
    SHOW_USER_REQUESTING,
    SHOW_USER_SUCCESS, SHOW_USER_ERROR
} from "./constants";


const initialState = {
    requesting: false,
    requestingFilter: false,
    success: false,
    error: '',
    values: {
        name: '',
        typePeople: '',
        typePeopleName: '',
        gender: '',
        genderName: '',
        typeProfile : '',
        identification: '',
        email: '',
        city: '',
        phone: '',
        address: '',
        nameContact: '',
        phoneContact: '',
        typeProfessional :'',
        typeProfessionalName :'',
        password: '',
    },
    isOpenRegister: false,
    isOpenLogin:false,
    isOpenModal:false,
    isOpenCatalog: false,
    isOpenQualityPlitics:false,
    isOpenDateTreatment:false,
    isOpenFrequency: false,
    isOpenModalTermsConditionsComponent: false,
    errorControlCreateUser: false,
    errorControlUpdateUser: false,
    users: [],
    focusUser: 0,
    userDataFocus: {},
    phone : false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case CREATE_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: ''
            };
        case CREATE_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };
        case SHOW_MODAL_USER :

            return {
              ...state,
                isOpenRegister: action.modal === 'modal register',
                isOpenLogin: action.modal === 'modal login',
                isOpenModal: action.modal === 'modal product',
                isOpenFrequency: action.modal === 'modal frequency',
                isOpenDateTreatment:action.modal === "modal data treatment",
                isOpenQualityPlitics:action.modal === "modal quality politics",
                isOpenModalTermsConditionsComponent:action.modal === "modal terms conditions",


                phone : action.phone
            };
        case ERROR_CONTROL_CREATE_USER:
            return {
              ...state,
               errorControlCreateUser:!state.errorControlCreateUser
            };
        case ERROR_CONTROL_UPDATE_USER:
            return {
                ...state,
                errorControlUpdateUser:!state.errorControlUpdateUser
            };
        case UPDATE_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: ''
            };
        case UPDATE_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };
        case GET_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                users: action.users
            };
        case GET_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: true,
                error: action.error,
                users: []
            };
        case FILTER_TYPE_PEOPLE_REQUESTING:
            return {
                ...state,
                requestingFilter: true,
                success: false,
                error: '',
            };
        case FILTER_TYPE_PEOPLE_SUCCESS:
            return {
                ...state,
                requestingFilter: false,
                success: true,
                error: '',
                users: action.users
            };
        case FILTER_TYPE_PEOPLE_ERROR:
            return {
                ...state,
                requestingFilter: false,
                success: false,
                error: action.error,
            };
        case SET_FOCUS_USER:
            return {
                ...state,
                focusUser: action.id,
                userDataFocus: action.user
            };
        case SHOW_MODAL_CATALOG:
            return {
                ...state,
                isOpenCatalog: action.modal === 'modal catalogo',
            };
        case SHOW_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case SHOW_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                focusUser: action.user
            };
        case SHOW_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                focusUser: []
            };
        default:
            return state;
    }
};

export default reducer;
