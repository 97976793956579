import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {MenuItem} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import {connect} from "react-redux";
import {Field, Form, reduxForm} from "redux-form";
import {createUserRequesting, errorControlCreateUser} from "../../../redux/user/actions";
import {filterGender, getGenderRequesting} from "../../../redux/gender/actions";
import {getTypeProfessionalRequesting, visibleTypeProfessional} from "../../../redux/typeProfessional/actions";
import {citiesGetRequesting} from "../../../redux/city/actions";
import {getTypePeopleRequesting} from "../../../redux/typePeople/actions";
import {getTypeProfileRequesting} from "../../../redux/typeProfile/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {MuiThemeProvider} from "@material-ui/core";
import {materialTheme} from "../../../commons/theme/theme";
import ButtonFacebook from "./register";


const descriptionProfile = [
    {
        name: 'Público',
        description: 'Seleccionado este perfil las personas lo puden buscar en el directorio para ver sus productos'
    },
    {name: 'Privado', description: 'Seleccionado este perfil, las personas no lo podran buscar en el directorio'},
];


const inputTextField = ({input, name, label, type, id, placeholder, meta: {touched, error}, ...rest}) => {
    return (
        <MuiThemeProvider theme={materialTheme}>
            <TextField
                {...input}
                {...rest}
                label={label}
                type={type}
                value={input.value}
                name={input.name}
                placeholder={placeholder ? placeholder : ''}
                margin="normal"
                variant="outlined"
                fullWidth
                multiline={type === "text"}
                rowsMax={'10'}
            />
        </MuiThemeProvider>
    )
};

class ModalQualityPliticsComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            citiesGetRequesting,
            getTypeProfileRequesting,
            getTypePeopleRequesting,
            getGenderRequesting,
            getTypeProfessionalRequesting,

        } = this.props;
        citiesGetRequesting();
        getTypeProfileRequesting();
        getTypePeopleRequesting();
        getGenderRequesting();
        getTypeProfessionalRequesting();
    }

    state = {
        messageTypeProfile: null,
    };

    handleAction = (values) => {
        const {createUserRequesting, user: {phone}} = this.props;
        createUserRequesting({
            ...values,
            password: phone === 0 ? values.password : Math.random(),
            phone: phone === 0 ? values.phone : phone
        })
    };

    handleError = () => {
        const {user: {error}, errorControlCreateUser} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlCreateUser();
    };

    handleShowMessageTypeProfile = (value) => {
        const {
            typeProfile: {typeProfiles},
        } = this.props;
        let typeProfile = typeProfiles.find((typeProfile) => typeProfile.id === value);
        this.setState({
            messageTypeProfile: descriptionProfile.find((description) => description.name === typeProfile.nombre).description,
        });
    };

    render() {
        const {
            handleControl,
            visible,
            handleSubmit,
            city: {cities},
            typePeople: {typePeoples},
            typeProfessional,
            gender: {gendersFilter},
            typeProfile: {typeProfiles},
            filterGender,
            visibleTypeProfessional,
            user: {errorControlCreateUser, phone},
        } = this.props;
        return (

            <Dialog
                onClose={() => handleControl('')} aria-labelledby="customized-dialog-title"
                open={visible}>

                <DialogTitle id="customized-dialog-title">
                    Política de Calidad e Identidad de los Productos a Ofrecer y roductos Prohibidos.
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={() => handleControl('')}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent>


                    {errorControlCreateUser && this.handleError()}
                    <Form onSubmit={handleSubmit(this.handleAction)}>

                        <div className="container-sub-text">


                            {/*<h2>POLÍTICA DE CALIDAD E IDONEIDAD DE LOS PRODUCTOS A OFRECER Y PRODUCTOS PROHIBIDOS</h2>*/}
                            <br/><br/>

                            <p>
                                En este documento Los Usuarios encontrarán un listado de productos y/o servicios cuyo
                                ofrecimiento, solicitud, publicación, compra, venta y/o alquiler a través de
                                “TuConstrucción” (portal web y aplicación móvil) (en adelante La Plataforma) están
                                prohibidos por Nova Inc Tecnology S. A. S (“La Compañía”) para garantizar el
                                cumplimiento de la normatividad vigente y/o de las políticas internas de La Compañía.
                                Los Usuarios son exclusivamente responsables de la legalidad y legitimidad de los
                                productos que publican ya sean para venta, compra o alquiler. Los Usuarios que se
                                encuentren en calidad de vendedores se obligan a cumplir con todas las normas
                                pertinentes relacionadas con el bien o servicio que ofrecen y se comprometen a contar
                                con los registros, licencias, permisos y/o autorizaciones exigidos en Colombia para la
                                oferta y enajenación del bien o servicio.      <br/><br/>
                                La Compañía no asume ninguna responsabilidad por la existencia en la plataforma de
                                productos (bienes y/o servicios) que infrinjan las políticas internas o cualquier norma
                                (ley, decreto, resolución, etc.) vigente.      <br/><br/>
                                La Compañía podrá suprimir las publicaciones que no cumplan con las políticas aquí
                                mencionadas. Con base en ello, se recomienda a Los Usuarios revisar este documento antes
                                de publicar un bien o servicio.      <br/><br/>
                                Este documento hace parte integral de los Términos y Condiciones Generales. Los Usuarios
                                que acepten los Términos y Condiciones Generales, aceptan las políticas aquí contenidas.
                                <br/><br/>
                                CALIDAD E IDONEIDAD DE LOS PRODUCTOS      <br/><br/>
                                La Compañía como propietaria de La Plataforma busca que Los Usuarios, ya sea en calidad
                                de vendedores o compradores, encuentren en La Plataforma un espacio donde puedan ofrecer
                                productos (bienes y/o servicios) de utilidad para el sector de la construcción y que
                                cumplan con estándares mínimos de calidad e idoneidad. Esto con la finalidad de
                                propender por los derechos de Los Usuarios como consumidores de un bien o servicio.
                                Por lo anterior, La Compañía conmina a todos Los Usuarios, especialmente a aquellos que
                                ostenten la calidad de vendedores, a cumplir con su deber de asegurar la idoneidad y
                                seguridad de los bienes y servicios que ofrezcan a través de La Plataforma, así como la
                                calidad de los mismos.      <br/><br/>
                                La calidad e idoneidad de los bienes y/o servicios ofrecidos en ningún caso podrán ser
                                inferiores o contravenir lo previsto en reglamentos técnicos y medidas sanitarias que
                                dispongan las autoridades competentes.      <br/><br/>
                                Debido a que en la plataforma Los Usuarios pueden ofrecer o solicitar productos, nuevos
                                o usados, para compra, venta o en alquiler, se conmina a Los Usuarios a que, cuando se
                                ofrezcan en forma pública productos imperfectos, reparados, remanufacturados,
                                repotencializados o descontinuados, indiquen dicha circunstancia en forma precisa y
                                notoria, de conformidad con la normatividad vigente.      <br/><br/>
                                Así mismo, Los Usuarios que ostenten la calidad de vendedores deberán anunciar a Los
                                Usuarios compradores el término de garantía del producto ofrecido y las condiciones en
                                las que pueden hacer valer dicha garantía.      <br/><br/>
                                En ningún caso, La Compañía será responsable por el cumplimiento de las obligaciones que
                                adquieren Los Usuarios respecto de la calidad, idoneidad, seguridad y el buen estado y
                                funcionamiento de los productos ofrecidos. La Plataforma solo es un especio en el que
                                Los Usuarios registrados podrán contactarse entre sí para concertar algún negocio. La
                                Compañía no tiene injerencia o participación en el desarrollo o perfeccionamiento de
                                acuerdos, convenios o contratos que lleguen a celebrar Los Usuarios y por lo tanto, La
                                Compañía no tendrá ninguna responsabilidad sobre los términos que pacten Los Usuarios.
                                La Compañía no reconocerá y hará valer garantía alguna sobre los bienes o servicios que
                                publiquen Los Usuarios.      <br/><br/>
                                Los Usuarios se obligan a cumplir con las disposiciones legales en materia de venta de
                                bienes y servicios, especialmente el estatuto del consumidor, esto es, la Ley 1480 de
                                2011 y las posteriores normas que la derogue, modifique o adicione, lo que implica que
                                Los Usuarios se obligan siempre de buena fe, con lealtad, con claridad y honestidad
                                frente a los consumidores que adquieran sus productos.      <br/><br/>
                                Los Usuarios oferentes deberán deberán informar a sus consumidores los siguientes datos:
                                a) Identificación de la persona natural o jurídica que vende los bienes o servicios,
                                esto es, razón social, NIT;      <br/><br/>
                                b) Principal domicilio geográfico de la empresa (ciudad) y dirección;      <br/><br/>
                                c) Correo electrónico donde podrá recibir notificaciones comerciales y/o judiciales u
                                otros medios electrónicos de contacto que se encuentren registrados en la Cámara de
                                Comercio donde fue inscrita la empresa; y      <br/><br/>
                                d) Número telefónico y demás información necesaria que permita a los consumidores
                                identificar el origen y responsable de los bienes y servicios que adquiere.
                                Los Usuarios manifiestan que las características de los productos que ofrece a través de
                                La Plataforma corresponden a la realidad y la publicidad incluida en sus perfiles
                                establece todas las condiciones y utilidades reales contenidas en cada uno de los
                                productos.      <br/><br/>
                                El incumplimiento a los derechos del consumidor facultará a La Compañía para dar de baja
                                el perfil de Los Usuarios incumplidos sin aviso alguno, sin perjuicio de las acciones
                                legales a que haya lugar.      <br/><br/>

                                ARTÍCULOS PROHIBIDOS      <br/><br/>

                                Al hacer uso de la plataforma Los Usuarios tienen prohibido publicar y/o solicitar los
                                siguientes bienes y/o servicios:      <br/><br/>

                                Armas, municiones y material explosivo, partes y/o accesorios de armas de fuego,
                                proyectiles, máquinas, planos, herramientas, productos o dispositivos cuyo fin sea la
                                fabricación, modificación y/o recarga de armas de fuego, adecuación de explosivos y/o
                                relacionada con estos; apuntador, puntero y/o señalador láser, con potencia de salida
                                mayor o igual (=) a un (1) milivatio (mW). (SIC, Res. No. 57151 de 2016); fuegos
                                artificiales.      <br/><br/>

                                Medicamentos para consumo humano o animal, productos para la salud y/o la estética de
                                personas o animales, alimentos destinados al consumo humano o animal, por ejemplo y sin
                                limitarse a estos: medicamentos de venta libre y/o que requieran prescripción médica ya
                                sea para consumo humano o animal; cremas, ungüentos, aerosoles, lociones, pomadas, etc.,
                                que sean destinadas al contacto con la piel y/o pelo ya sea humano o animal; productos
                                relacionados con la salud que deban ser aprobados por la autoridad competente; productos
                                alimenticios (incluidos suplementos en polvo, capsulas y/o bebidas) para el consumo
                                humano o animal; lentes con graduación, Lentes de contacto o cualquier tipo de lente de
                                uso humano que deba ser formulado, recetado, revisado por un profesional de la salud
                                (optómetra y/u oftalmólogo); accesorios para animales; joyería, bisutería y/o cualquier
                                accesorio que no esté destinado para realizar labores de construcción o que no se
                                utilice como dotación para esta actividad.      <br/><br/>

                                Flora o fauna silvestre; venta de animales domésticos y/o de granja y sus accesorios;
                                productos destinados para la caza y/o tratamiento de especies trampas, jaulas, peceras,
                                etc.; plantas y/o flores, materas y/o jardineras, floreros, sustratos y/o accesorios
                                para cultivo y cuidado de vegetación; restos o partes de animales.      <br/><br/>

                                Sustancias psicoactivas: estupefacientes, drogas, alucinógenos o cualquier tipo de
                                sustancia no permitida por la ley, en ninguna de las formas existentes; bebidas
                                alcohólicas; elementos destinados para producir, modificar, procesar, consumir o
                                promover producción, distribución y consumo de sustancias psicoactivas; contenido que
                                promueva expresamente el consumo de sustancias psicoactivas; tabaco y productos
                                relacionados; cigarrillos o dispositivos electrónicos para fumar así como sus accesorios
                                y/o complementos.      <br/><br/>

                                Equipo Médico: No se permite la publicación y/o solicitud de ninguna clase de elementos
                                y/o insumos, destinados al personal médico o que cuya finalidad sea la prestación de
                                servicios de salud.      <br/><br/>

                                Bienes y servicios financieros: dinero de curso legal, dinero falsificado y/o de
                                juguete, o dinero que se encuentra fuera de circulación y/o destinado a colección;
                                préstamos o créditos financieros; cheques o chequeras; acciones, bonos, títulos o
                                cualquier documento destinado para cotizar en bolsas de valores; cuentas de ahorro,
                                cuentas corrientes, CDT’S, fiducias o cualquier otro producto bancario o financiero;
                                Tarjetas de crédito o débito que estén o no en vigentes y servicios para obtener, leer,
                                o duplicar los códigos de estas; pólizas o contratos de seguro.      <br/><br/>

                                Documentos y bases de datos: bases de datos ya sean personales y/o empresariales; compra
                                y/o venta de perfiles de redes sociales; insumos informáticos (hardware y/o software) o
                                servicios relacionados con el envío de correos no deseados; documentos de identificación
                                (cédulas de ciudadanía, pasaportes, cédulas de extranjería, registros civiles, tarjetas
                                profesionales, etc.); documentos que acrediten educación formal o no formal (títulos
                                profesionales, diplomas, certificados, etc.); órdenes de incapacidad, autorizaciones
                                para procedimientos médicos, fórmulas médicas; patentes, licencias, modelos y diseños
                                industriales o similares.      <br/><br/>

                                Bienes y/o servicios de índole sexual: revistas, fotografías, videos, juegos, figuras,
                                libros, etc., en cualquier formato, de contenido pornográfico o sexual; ropa interior o
                                exterior que pueda ser usada con fines sexuales; servicios de exhibición por plataformas
                                virtuales (webcam); enlaces a sitios de contenido pornográfico o sexual; servicios de
                                escort, strippers, citas y encuentros o cualquier publicación que incluya la posible
                                realización encubierta de un servicio sexual; en general, cualquier producto relacionado
                                con prácticas sexuales.      <br/><br/>

                                Servicios turísticos: No se permite la publicación o solicitud de productos o servicios
                                relacionados con el turismo (pasajes aéreos, terrestres, fluviales, marítimos,
                                recorridos o excursiones, servicios hoteleros, etc.)      <br/><br/>

                                Información y documentos que promuevan la violencia y/o la discriminación, ya sea por
                                origen racial o étnico, orientación política, convicciones religiosas o filosóficas, la
                                pertenencia a sindicatos, organizaciones sociales, de derechos humanos; información que
                                promueva intereses de cualquier partido político; datos relativos a la salud, a la vida
                                sexual y los datos biométricos; y en general cualquier tipo de dato que esté catalogado
                                como sensible por la ley y/o que pueda transgredir los derechos individuales de los
                                usuarios.      <br/><br/>

                                Servicio postal: No está permitido la publicación o solicitud de servicios de correo
                                postal o servicios relacionados como suscripciones o afiliaciones a compañías que
                                presten estos servicios.      <br/><br/>

                                Información relacionada con niños, niñas y adolescentes: Está prohibida la publicación o
                                solicitud de información cuyo titular sea un niño, niña y adolescente; así mismo está
                                prohibido cualquier comunicado que promueva el trabajo y/o la explotación infantil, la
                                violencia contra niños niñas y adolescentes; en general cualquier información que
                                vulnere los derechos de los niños, niñas y adolescentes.      <br/><br/>

                                Juegos de azar y lotería: productos relacionados con juegos de loterías, rifas y/o
                                máquinas de azar, accesorios o partes de máquinas para casinos; boletos para rifas,
                                bingos, billetes de lotería o similares.      <br/><br/>

                                Productos fuera del comercio, por ejemplo, y sin limitarse a estos, bienes cuya venta o
                                servicio se encuentre prohibida por la ley; bienes que hayan sido reportados como
                                hurtados; bienes que sean propiedad de alguna entidad estatal y/o que hayan sido
                                entregados por el Estado como consecuencia de la inclusión en programas sociales, de
                                investigación y desarrollo en ciencia y tecnología y/o de cualquier programa del Estado;
                                bienes considerados como patrimonio cultural, patrimonio material, patrimonio material
                                mueble, patrimonio material inmueble, patrimonio natural, patrimonio subacuático,
                                patrimonio arqueológico y paleontológico, patrimonio inmaterial o intangible y/o
                                patrimonio vivo de la Nación;      <br/><br/>

                                Productos que no cuenten con las autorizaciones, etiquetas y/o certificaciones de
                                seguridad emitidas por la autoridad competente para su venta y/o circulación; productos
                                que representen un riesgo para la integridad del usuario.      <br/><br/>

                                Programas o servicios para hackear dispositivos electrónicos, se incluyen y sin
                                limitarse a estos, cualquier hardware y/o software destinado a hackear correos
                                electrónicos, invadir aparatos electrónicos (celulares, computadores, tabletas, etc.);
                                manuales, guías, libros, en cualquier formato cuya finalidad sea enseñar a hackear.

                                El listado anterior no constituye un recuento taxativo de los artículos prohibidos. La
                                Compañía como propietaria de La Plataforma se reserva el derecho de incluir otros
                                productos (bienes y/o servicios) que a criterio de la compañía no guarden relación con
                                La Plataforma y/o se considere puedan causar daños y perjuicios a terceros, se presuman
                                de origen ilícito o destinados para actividades ilícitas.      <br/><br/>
                            </p>


                        </div>

                        <DialogActions>
                            <ButtonFacebook/>
                            <Button aria-label="Close" onClick={() => handleControl('')} variant="contained"
                                    color="secondary">Cerrar</Button>
                        </DialogActions>


                    </Form>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    city: state.city,
    typePeople: state.typePeople,
    typeProfessional: state.typeProfessional,
    gender: state.gender,
    typeProfile: state.typeProfile,
});
const connected = connect(mapStateToProps, {
    citiesGetRequesting,
    getTypeProfileRequesting,
    getTypePeopleRequesting,
    getGenderRequesting,
    getTypeProfessionalRequesting,
    filterGender,
    visibleTypeProfessional,
    createUserRequesting,
    errorControlCreateUser

})(ModalQualityPliticsComponent);

const formed = reduxForm({
    form: 'modal data treatment',
})(connected);

export default formed;

