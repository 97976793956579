import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    forgotPasswordPostError,
    forgotPasswordPostSuccess,
    resetStates,
    resetPasswordPutError,
    resetPasswordPutSuccess, verifyTokenResetPasswordPostError,
    verifyTokenResetPasswordPostSuccess,
    showModalResetPassword
} from "./actions";
import {toastr} from "react-redux-toastr";
import {
    FORGOT_PASSWORD_POST_REQUESTING,
    RESET_PASSWORD_PUT_REQUESTING,
    VERIFY_TOKEN_RESET_PASSWORD_POST_REQUESTING
} from "./constants";

const forgotPasswordUrl = `${process.env.REACT_APP_API_URL}/forgotPassword`;
const verifyTokenResetPassword = `${process.env.REACT_APP_API_URL}/verifyTokenResetPassword`;
const resetPassword = `${process.env.REACT_APP_API_URL}/resetPassword`;

const forgotPasswordPostApi = (values) => {
    let body = {
        'email': values.email,
    };
    return fetch(forgotPasswordUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error
        })
};

function* forgotPasswordPostFlow(action) {
    try {
        const {values} = action;
        const message = yield call(forgotPasswordPostApi, values);
        yield put(forgotPasswordPostSuccess(message));
        toastr.success('Correo electrónico enviado', 'Hemos enviado un correo electrónico al correo indicado, por favor verifica tu bandeja de entrada.');
    } catch (error) {
        yield put(forgotPasswordPostError(error));
    }
}

const verifyTokenResetPasswordPostApi = (values) => {
    let body = {
        'token': values.token,
    };
    return fetch(verifyTokenResetPassword, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error
        })
};

function* verifyTokenResetPasswordPostFlow(action) {
    try {
        const {values} = action;
        const message = yield call(verifyTokenResetPasswordPostApi, values);
        yield put(verifyTokenResetPasswordPostSuccess(message));
    } catch (error) {
        yield put(verifyTokenResetPasswordPostError(error))
    }
}

const resetPasswordPutApi = (values) => {
    let body = {
        'token': values.token,
        'password': values.contraseña,
        'password_confirmation': values.re_contraseña,
    };
    return fetch(resetPassword, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error
        })
};

function* resetPasswordPutFlow(action) {
    try {
        const {values} = action;
        const message = yield call(resetPasswordPutApi, values);
        yield put(resetPasswordPutSuccess(message));
        yield put(resetStates());
        yield put(showModalResetPassword(''));
        toastr.success('Cambio de contraseña confirmado', 'Ya puedes iniciar sesión.');
    } catch (error) {
        yield put(resetPasswordPutError(error));
    }
}

function* passwordResetWatcher() {
    yield all([
        takeEvery(FORGOT_PASSWORD_POST_REQUESTING, forgotPasswordPostFlow),
        takeEvery(VERIFY_TOKEN_RESET_PASSWORD_POST_REQUESTING, verifyTokenResetPasswordPostFlow),
        takeEvery(RESET_PASSWORD_PUT_REQUESTING, resetPasswordPutFlow),
    ])
}

export default passwordResetWatcher;