import * as firebase from "firebase/app";
import '@firebase/messaging';
import React from 'react';

export const askForPermissioToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();
        return token;
    } catch (error) {
        console.error(error);
    }
};

// const onMessageFirebase = () => {
//     console.log(props.client);
//     try {
//         const messaging = firebase.messaging();
//         messaging.onMessage((payload) => {
//             console.log(payload.notification.data.type.split('_')[0]);
//             let type = payload.notification.data.type.split('_')[0];
//             switch (type) {
//                 case 'AcceptService':
//                     // userServicesGetRequesting(client.token);
//                     break;
//             }
//             toastr.success(payload.notification.title, payload.notification.body);
//         });
//     } catch (error) {
//         console.error(error);
//     }
// };
//
// const mapStateToProps = state => ({
//     client: state.client,
// });
//
// const connected = connect(mapStateToProps, {
//     userServicesGetRequesting,
// })(onMessageFirebase);
//
// export default connected;