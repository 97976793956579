import {call, all, put, takeEvery} from 'redux-saga/effects';
import {loginSuccess} from "../login/actions";
import {checkJWTRequesting} from "../client/actions";
import {phoneVerifyError, phoneVerifySuccess} from "./actions";
import {PHONE_VERIFY_REQUESTING} from "./constants";
import {showModalUser} from "../user/actions";

const verifyPhoneUrl = `${process.env.REACT_APP_API_URL}/auth/phone`;


const loginOk = (token) => {
    if (token != null) {
        localStorage.setItem('@user:tuConstruccion', token);
    }
};


const verifyPhoneApi = (phone) => {
    let body = {
        phone: phone
    };
    return fetch(`${verifyPhoneUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.hasOwnProperty('access_token'))
                return json.access_token;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* verifyPhoneFlow(action) {
    const {phone} = action;
    try {
        const token = yield call(verifyPhoneApi, phone);
        yield put(phoneVerifySuccess());
        yield put(loginSuccess(token));
        yield put(checkJWTRequesting(token));
        yield call(loginOk, token);
        yield put(showModalUser(''));
    } catch (error) {
        if (error.code === 400) {
            if (error.data === 'Telefono no registrado') {
                yield put(showModalUser('modal register', phone));
            }
        }
        yield put(phoneVerifyError(error));
    }
}

function* phoneWatcher() {
    yield all([
        takeEvery(PHONE_VERIFY_REQUESTING, verifyPhoneFlow),
    ])
}

export default phoneWatcher;
