import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {MenuItem, MuiThemeProvider} from "@material-ui/core";
import {theme} from "../../../theme/theme";
import InputRange from 'react-input-range';


import TextField from '@material-ui/core/TextField';
import {Form, Field, reduxForm, reset} from "redux-form";
import {connect} from "react-redux";
import {categoryFocus, subcategoryFocus, categoryResetStates,moduleFocus} from "../../../redux/category/actions";
import {filterChangeForm, filterResetStates} from "../../../redux/filter/actions";
import {citiesGetRequesting} from "../../../redux/city/actions";
import {publicationsGetRequesting} from "../../../redux/publication/actions";

const inputCategories = ({input, name, id, meta: {touched, error}, ...rest}) => {
    return (
        <TextField
            {...input}
            {...rest}
            value={input.value}
            autoFocus
            margin={'normal'}
            type={'select'}
            variant="outlined"
            multiline
            rowsMax={'10'}
            id={input.id}
        />
    )
};

const inputSubcategories = ({input, name, id, meta: {touched, error}, ...rest}) => {
    return (
        <TextField
            {...input}
            {...rest}
            value={input.value}
            autoFocus
            margin={'normal'}
            type={'select'}
            variant="outlined"
            multiline
            rowsMax={'10'}
            id={input.name}
        />
    )
};

const inputPrices = ({input, name, prices, id, meta: {touched, error}, ...rest}) => {
    return (
        <InputRange
            {...input}
            {...rest}
            allowSameValues
            formatLabel={value => `$ ${value}`}
            inputRange={input.name}
            minValue={0}
            maxValue={3000000}
            value={prices}
        />
    );
};

const inputCities = ({input, name, id, meta: {touched, error}, ...rest}) => {
    return (
        <TextField
            {...input}
            {...rest}
            value={input.value}
            autoFocus
            margin={'normal'}
            type={'select'}
            variant="outlined"
            multiline
            rowsMax={'10'}
            id={input.name}
        />
    )
};

class FilterProductModalComponent extends React.Component {

    constructor(props) {
        super(props);
        const {citiesGetRequesting} = this.props;
        citiesGetRequesting();
    }

    state = {
        value: {min: 2, max: 10},
        value2: 5
    };

    handleSetFilter = () => {
        const {
            publicationsGetRequesting,
            filter: {values}
        } = this.props;
        publicationsGetRequesting([], values, 1);
    };

    handleFocus = (object, type) => {
        const {categoryFocus, subcategoryFocus, moduleFocus} = this.props;
        switch (type) {
            case 'category':
                categoryFocus(object);
                break;
            case 'subcategory':
                subcategoryFocus(object);
                break;
            case 'typeModule' :
                moduleFocus(object);
                break;
        }
    };

    handleFilterChangeForm = (key, value) => {
        const {filterChangeForm} = this.props;
        filterChangeForm(key, value);
    };

    handleFilterReset = () => {
        const {filterResetStates, categoryResetStates, reset} = this.props;
        filterResetStates();
        categoryResetStates();
        reset('filter');
    };

    render() {
        const {
            handleSubmit, hiddenModal, type, visible,
            category: {categories, category, subcategories, subcategory,typeModules,typeModule},
            filter: {values, orders, dates},
            city: {cities},
        } = this.props;

        return (
            <MuiThemeProvider theme={theme}>
                <Dialog aria-labelledby="customized-dialog-title" open={visible}>
                    <DialogTitle id="customized-dialog-title">
                        Filtro
                        <div style={{position: 'absolute', right: 10, top: 10}}>
                            <IconButton aria-label="Close" onClick={() => {
                                hiddenModal('filter', type);
                                this.handleFilterReset()
                            }}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <Form onSubmit={handleSubmit(this.handleSetFilter)}>
                        <Field id="outlined-select-currency"
                               select
                               label="Categoria"
                               name={'category'}
                               onChange={(value) => {
                                   this.handleFocus(categories.find((category) => category.id === value.target.value), 'category');
                                   this.handleFilterChangeForm('categoria', value.target.value);
                               }}
                               value={category.hasOwnProperty('id') ? category.id : ''}
                               component={inputCategories}>
                            {categories.length > 0 && categories.map((category, index) => {
                                return (
                                    <MenuItem className="menuItemStyle" key={index} value={category.id}>
                                        <p className="menuItemStyleP">{category.nombre}</p>
                                    </MenuItem>
                                );
                            })}
                        </Field>
                        <Field id="outlined-select-currency"
                               select
                               label="Condición"
                               name={'typeModule'}
                               onChange={(value) => {
                                   this.handleFocus(typeModules.find((typeModule) => typeModule.id === value.target.value), 'typeModule');
                                   this.handleFilterChangeForm('typeModule', value.target.value);
                               }}
                               value={typeModule.hasOwnProperty('id') ? typeModule.id : ''}
                               component={inputCategories}>
                            {typeModules.length > 0 && typeModules.map((category, index) => {
                                return (
                                    <MenuItem className="menuItemStyle" key={index} value={category.id}>
                                        <p className="menuItemStyleP">{category.nombre}</p>
                                    </MenuItem>
                                );
                            })}
                        </Field>
                        <Field id="outlined-select-currency"
                               select
                               label="Subcategoria"
                               name={'subcategory'}
                               onChange={(value) => {
                                   this.handleFocus(subcategories.find((subcategory) => subcategory.id === value.target.value), 'subcategory');
                                   this.handleFilterChangeForm('subcategoria', value.target.value);
                               }}
                               value={subcategory.hasOwnProperty('id') ? subcategory.id : 'Subcategorias'}
                               component={inputSubcategories}>
                            {subcategories.length > 0 && subcategories.map((subcategory, index) => {
                                return (
                                    <MenuItem className="menuItemStyle" key={index} value={subcategory.id}>
                                        <p className="menuItemStyleP">{subcategory.nombre}</p>
                                    </MenuItem>
                                );
                            })}
                        </Field>
                        <DialogContent className="container-text">
                            <h2>Filtrar por precio (Co)</h2>
                            <div className="line"/>
                            <div className="slider-control" >
                                <Field prices={values.prices}
                                       onChange={(value) => {
                                           this.handleFilterChangeForm('prices', value);
                                       }}

                                       name={'prices'}
                                       component={inputPrices}
                                />
                            </div>

                            <div className="slider-control">
                                <div className="line"/>
                                {cities.length > 0 && (
                                    <Field id="outlined-select-currency"
                                           select
                                           label="Ciudad"
                                           name={'city'}
                                           onChange={(value) => {
                                               this.handleFilterChangeForm('ciudad', value.target.value);
                                           }}
                                           value={values.ciudad ? values.ciudad : 'Ciudad'}
                                           component={inputCities}>
                                        {cities.map((city, index) => {
                                            return (
                                                <MenuItem className="menuItemStyle" key={index} value={city.id}>
                                                    <p className="menuItemStyleP">{city.nombre}</p>
                                                </MenuItem>
                                            );
                                        })}
                                    </Field>
                                )}
                            </div>

                            <div className="container-filter-item">
                                <h2>Ordenar por </h2>
                                <div className="line"/>
                                <div className="filter-item-1">
                                    {orders.map((order, index) => (
                                        <div
                                            className={values.ordenar === order.value ? "filter-sub-item-1 filter-active" : "filter-sub-item-1"}
                                            onClick={() => this.handleFilterChangeForm('ordenar', order.value)}
                                            key={index}>
                                            <img src={order.photo} alt={order.label}/>
                                            <p>{order.label}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="container-filter-item">
                                <h2>Publicado hace </h2>
                                <div className="line"/>
                                <div className="filter-item-1 ">
                                    {dates.map((date, index) => (
                                        <div
                                            className={values.fecha === date.value ? "filter-sub-item-1 filter-active" : "filter-sub-item-1"}
                                            onClick={() => this.handleFilterChangeForm('fecha', date.value)}
                                            key={index}>
                                            <img src={date.photo} alt={date.label}/>
                                            <p>{date.label}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </DialogContent>
                    </Form>
                    <DialogActions>
                        <Button color="secondary" variant="contained"
                                onClick={() => {
                                    hiddenModal('filter', type);
                                    this.handleFilterReset()
                                }}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={() => this.handleFilterReset()}
                                color='secondary'>
                            Reiniciar
                        </Button>
                        <Button color='secondary' variant="contained"
                                onClick={() => {
                                    this.handleSetFilter();
                                    hiddenModal('filter', type);
                                }}>
                            Aplicar
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = state => ({
    filter: state.filter,
    category: state.category,
    city: state.city,
});

const connected = connect(mapStateToProps, {
    categoryFocus,
    subcategoryFocus,
    categoryResetStates,
    filterChangeForm,
    filterResetStates,
    citiesGetRequesting,
    publicationsGetRequesting,
    moduleFocus,
    reset,
})(FilterProductModalComponent);

const formed = reduxForm({
    form: 'filter'
})(connected);

export default formed;
