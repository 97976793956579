import {FILTER_CHANGE_FORM, FILTER_HIDDEN_MODAL, FILTER_RESET_STATES, FILTER_SHOW_MODAL} from "./constants";
import ImgUser from "../../assets/img/logo-no-text.jpg";
import Menor from '../../assets/Modal/signo-menor.png'
import Mayor from '../../assets/Modal/signo-menos.png';
import Dia from '../../assets/Modal/1a.png';
import Semana from '../../assets/Modal/3a.png';
import Mes from '../../assets/Modal/5a.png';

const initialState = {
    values: {
        ciudad: null,
        categoria: null,
        subcategoria: null,
        prices: {min: 0, max: 3000000},
        fecha: 'Todos',
        ordenar: null,
        column: 'price',
        typeModule: null
    },
    orders: [
        {value: 'asc', label: 'Menor precio', photo: Menor},
        {value: 'desc', label: 'Mayor precio', photo: Mayor},
    ],
    dates: [
        {value: 'Dia', label: 'Hace un dia', photo: Dia},
        {value: 'Semana', label: 'Hace una semana', photo: Semana},
        {value: 'Mes', label: 'Hace un mes', photo: Mes},
    ],
    modalFilter: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTER_SHOW_MODAL:
            return {
                ...state,
                modalFilter: action.modal === 'filter',
            };
        case FILTER_HIDDEN_MODAL:
            return {
                ...state,
                modalFilter: action.modal === 'filter' && false,
            };
        case FILTER_CHANGE_FORM:
            return {
                ...state,
                values: {...state.values, [action.key]: action.value}
            };
        case FILTER_RESET_STATES:
            return {
                ...state,
                values: {
                    ciudad: null,
                    categoria: null,
                    subcategoria: null,
                    prices: {min: 0, max: 3000000},
                    fecha: 'Mes',
                    ordenar: null,
                    column: 'price',
                    typeModule: null
                }
            };
        default:
            return state;
    }
};

export default reducer;
