export const PUBLICATIONS_GET_REQUESTING = 'PUBLICATIONS_GET_REQUESTING';
export const PUBLICATIONS_GET_SUCCESS = 'PUBLICATIONS_GET_SUCCESS';
export const PUBLICATIONS_GET_ERROR = 'PUBLICATIONS_GET_ERROR';

export const PUBLICATION_GET_REQUESTING = 'PUBLICATION_GET_REQUESTING';
export const PUBLICATION_GET_SUCCESS = 'PUBLICATION_GET_SUCCESS';
export const PUBLICATION_GET_ERROR = 'PUBLICATION_GET_ERROR';

export const PUBLICATIONS_SEARCH_POST_REQUESTING = 'PUBLICATIONS_SEARCH_POST_REQUESTING';
export const PUBLICATIONS_SEARCH_POST_SUCCESS = 'PUBLICATIONS_SEARCH_POST_SUCCESS';
export const PUBLICATIONS_SEARCH_POST_ERROR = 'PUBLICATIONS_SEARCH_POST_ERROR';

export const PUBLICATIONS_OWNER_USER_POST_REQUESTING = 'PUBLICATIONS_OWNER_USER_POST_REQUESTING';
export const PUBLICATIONS_OWNER_USER_POST_SUCCESS = 'PUBLICATION_OWNER_USER_POST_SUCCESS';
export const PUBLICATIONS_OWNER_USER_POST_ERROR = 'PUBLICATIONS_OWNER_USER_POST_ERROR';

export const PUBLICATIONS_OWNER_USER_RESET_STATE = 'PUBLICATIONS_OWNER_USER_RESET_STATE';

export const PUBLICATIONS_ME_POST_REQUESTING = 'PUBLICATIONS_ME_POST_REQUESTING';
export const PUBLICATIONS_ME_POST_SUCCESS = 'PUBLICATIONS_ME_POST_SUCCESS';
export const PUBLICATIONS_ME_POST_ERROR = 'PUBLICATIONS_ME_POST_ERROR';

export const PUBLICATION_CREATE_POST_REQUESTING = 'PUBLICATION_CREATE_POST_REQUESTING';
export const PUBLICATION_CREATE_POST_SUCCESS = 'PUBLICATION_CREATE_POST_SUCCESS';
export const PUBLICATION_CREATE_POST_ERROR = 'PUBLICATION_CREATE_POST_ERROR';

export const PUBLICATION_UPDATE_PUT_REQUESTING = 'PUBLICATION_UPDATE_PUT_REQUESTING';
export const PUBLICATION_UPDATE_PUT_SUCCESS = 'PUBLICATION_UPDATE_PUT_SUCCESS';
export const PUBLICATION_UPDATE_PUT_ERROR = 'PUBLICATION_UPDATE_PUT_ERROR';

export const PUBLICATION_CHANGE_STATE_PUT_REQUESTING = 'PUBLICATION_CHANGE_STATE_PUT_REQUESTING';
export const PUBLICATION_CHANGE_STATE_PUT_SUCCESS = 'PUBLICATION_CHANGE_STATE_PUT_SUCCESS';
export const PUBLICATION_CHANGE_STATE_PUT_ERROR = 'PUBLICATION_CHANGE_STATE_PUT_ERROR';

export const PUBLICATION_PHOTO_DELETE_REQUESTING = 'PUBLICATION_PHOTO_DELETE_REQUESTING';
export const PUBLICATION_PHOTO_DELETE_SUCCESS = 'PUBLICATION_PHOTO_DELETE_SUCCESS';
export const PUBLICATION_PHOTO_DELETE_ERROR = 'PUBLICATION_PHOTO_DELETE_ERROR';

export const PUBLICATION_CLEAR_FORM = 'PUBLICATION_CLEAR_FORM';

export const PUBLICATION_SHOW_MODAL = 'PUBLICATION_SHOW_MODAL';
export const PUBLICATION_HIDDEN_MODAL = 'PUBLICATION_HIDDEN_MODAL';

export const PUBLICATION_RESET_STATES = 'PUBLICATION_RESET_STATES';

export const CLEAN_PUBLICATION = 'CLEAN_PUBLICATION';