import {call, put, all, takeEvery} from 'redux-saga/effects';
import {
    addFavoriteError,
    addFavoriteSuccess,
    getPublicationFavoritesError,
    getPublicationFavoriteSuccess, removeFavoriteError, removeFavoriteSuccess
} from "./actions";
import {ADD_FAVORITE_REQUESTING, GET_PUBLICATION_FAVORITES_REQUESTING, REMOVE_FAVORITE_REQUESTING} from "./constants";
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {publicationGetSuccess} from "../publication/actions";


const PUBLICATION_FAVORITE_URL = `${process.env.REACT_APP_API_URL}/userFavorites`;
const ADD_FAVORITE_URL = `${process.env.REACT_APP_API_URL}/addFavorite`;
const REMOVE_FAVORITE_URL = `${process.env.REACT_APP_API_URL}/removeFavorite`;

const getPublicationFavoriteAPI = (token) => {
    return fetch(`${PUBLICATION_FAVORITE_URL}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;

        }).catch((error) => {
            throw error;
        })
};

function* getPublicationFavoriteFlow(action) {
    try{
        const {token} = action;
        const publicationFavorites = yield call(getPublicationFavoriteAPI, token);
        yield put(getPublicationFavoriteSuccess(publicationFavorites));
    }catch (error) {
        yield put(getPublicationFavoritesError(error));
    }
}

const addFavoriteAPI = (token, id, publication) => {
    return fetch(`${ADD_FAVORITE_URL}/${id}/${publication}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* addFavoritesFlow(action) {
    try{
        const {token, id, publication} = action;
        const messages = yield call(addFavoriteAPI, token, id, publication);
        yield put(addFavoriteSuccess(messages));
        yield put(publicationGetSuccess(messages));
    }catch (error) {
        yield put(addFavoriteError(error));
    }
}

const removeFavoriteAPI = (id, publication) => {
    return fetch(`${REMOVE_FAVORITE_URL}/${id}/${publication}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* removeFavoritesFlow(action) {
    try{
        const {id, publication} = action;
        const messages = yield call(removeFavoriteAPI, id, publication);
        yield put(removeFavoriteSuccess(messages));
    }catch (error) {
        yield put(removeFavoriteError(error));
    }
}

function* publicationFavoriteWatcher() {
    yield all([
        takeEvery(GET_PUBLICATION_FAVORITES_REQUESTING, getPublicationFavoriteFlow),
        takeEvery(ADD_FAVORITE_REQUESTING, addFavoritesFlow),
        takeEvery(REMOVE_FAVORITE_REQUESTING, removeFavoritesFlow)
    ])
}

export default publicationFavoriteWatcher;