import {
    FORGOT_PASSWORD_POST_ERROR,
    FORGOT_PASSWORD_POST_REQUESTING,
    FORGOT_PASSWORD_POST_SUCCESS, RESET_PASSWORD_PUT_ERROR,
    RESET_PASSWORD_PUT_REQUESTING,
    RESET_PASSWORD_PUT_SUCCESS, RESET_STATES,
    VERIFY_TOKEN_RESET_PASSWORD_POST_ERROR,
    VERIFY_TOKEN_RESET_PASSWORD_POST_REQUESTING,
    VERIFY_TOKEN_RESET_PASSWORD_POST_SUCCESS,
    SHOW_MODAL_RESET_PASSWORD, CLOSE_SHOW_MODAL_PASSWORD
} from "./constants";

export const forgotPasswordPostRequesting = (values) => ({
    type: FORGOT_PASSWORD_POST_REQUESTING,
    values,
});

export const forgotPasswordPostSuccess = (message) => ({
    type: FORGOT_PASSWORD_POST_SUCCESS,
    message,
});

export const forgotPasswordPostError = (error) => ({
    type: FORGOT_PASSWORD_POST_ERROR,
    error,
});

export const verifyTokenResetPasswordPostRequesting = (values) => ({
    type: VERIFY_TOKEN_RESET_PASSWORD_POST_REQUESTING,
    values,
});

export const verifyTokenResetPasswordPostSuccess = (message) => ({
    type: VERIFY_TOKEN_RESET_PASSWORD_POST_SUCCESS,
    message,
});

export const verifyTokenResetPasswordPostError = (error) => ({
    type: VERIFY_TOKEN_RESET_PASSWORD_POST_ERROR,
    error,
});

export const resetPasswordPutRequesting = (values) => ({
    type: RESET_PASSWORD_PUT_REQUESTING,
    values
});

export const resetPasswordPutSuccess = (message) => ({
    type: RESET_PASSWORD_PUT_SUCCESS,
    message,
});

export const resetPasswordPutError = (error) => ({
    type: RESET_PASSWORD_PUT_ERROR,
    error,
});

export const resetStates = () => ({
    type: RESET_STATES
});

export const showModalResetPassword= (modal) => ({
    type: SHOW_MODAL_RESET_PASSWORD,
    modal
});

export const closeShowModalPassword = () => ({
    type: CLOSE_SHOW_MODAL_PASSWORD
});