import React from 'react';

//Material-ui
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Rater from 'react-rater'

//button
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import "react-id-swiper/lib/styles/css/swiper.css";
import posed from "react-pose";
import {theme} from "../../../../theme/theme";
import {FilterProfileSecundaryComponent} from "../../../filter/filter_profile_secundary";
import {ProductModalComponent} from "../../../2-product/modals/detail-product";
import {FilterProfilePrincipalComponent} from "../../../filter/filter_profile_principal";
import FilterProductModalComponent from "../../../2-product/modals/filter-product";

//img
import ImgUser from "../../../../assets/img/img-user.jpg";
import lottie from "lottie-web";

//////////effect/////////

const Box = posed.div({
    visible: {
        x: '0%',
        delayChildren: 1000,
        staggerChildren: 200,
        delay: 50
    },
    hidden: {x: '100%'}
});

const Item = posed.div({
    visible: {
        scale: 1,
    },
    hidden: {scale: 0}
});

const ItemHover = posed.div({
    hoverable: true,
    init: {
        scale: 1,
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        // boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
});

///////////effect///////

export class PageComments
    extends React.Component {

    constructor(props) {
        super(props);
        this.animation = React.createRef();
    };

    state = {
        isVisible: false,
        isOpen: false,
        value: 0,
        isOpenProduct: false,
        isOpenFilter: false,
        productPost: [1, 2, 3, 4, 5, 6],
        focusFilter: '',
        focusFilter2: '',

    };

    componentDidMount() {
        this.setState({
            isVisible: true
        });
        lottie.loadAnimation({
            container: this.animation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/review.json'
        });
    }

    handleFocusPrimary = (focusFilter1) => {
        this.setState({
            focusFilter1: focusFilter1
        });
    };

    handleFocus = (focusFilter2) => {
        this.setState({
            focusFilter2: focusFilter2
        });
    };

// control de modal
    handleControlModal = (modal) => {
        this.setState({
            isOpenProduct: modal === 'modal product',
            isOpenFilter: modal === 'modal filter',
        });
    };

    render() {
        const {isOpenProduct, isOpenFilter, productPost} = this.state;
        ////////////////////////////////////////////////////////
        const {comments} = this.props;
        return (

            <MuiThemeProvider theme={theme}>
                <Box pose={this.state.isVisible ? 'visible' : 'hidden'}>
                    {/*<ProductModalComponent*/}
                        {/*visible={isOpenProduct}*/}
                        {/*handleControl={this.handleControlModal.bind(this)}*/}
                    {/*/>*/}
                    {/*<FilterProductModalComponent*/}
                        {/*visible={isOpenFilter}*/}
                        {/*handleControl={this.handleControlModal.bind(this)}*/}
                    {/*/>*/}
                    <div className="container-item-start">
                        <div className="line"/>
                        {comments.length > 0 && (
                            <div className="container-sub-text">
                                <h2>Comentarios</h2>
                                <p>Opiniones de usuarios</p>
                            </div>
                        )}
                        {comments.length === 0 ?
                            <div style={{display : 'flex', justifyContent : 'center'}}>
                                <div className="container-animations-empty-one">
                                    <div className={'animation'} ref={this.animation}/>
                                </div>
                                <p className="p-animation-empty-one" style={{fontSize: 22, color: 'rgba(0,0,0,.3)'}}>No cuentas con
                                    reseñas ...</p>
                            </div>

                            :
                            comments.length > 0 && comments.map(comment => (
                            <div>
                                    <div className={productPost !== comment ? "container-profile-product" : "container-profile-product"}>
                                        <div className="profile-product-item1">
                                            <img src={comment.publicacion.foto} alt="Logo"/>
                                        </div>
                                        <div className="container-sub-text">
                                            <Rater total={5} rating={comment.puntuacion}/>
                                            <h2>{comment.usuario_emisor.nombre}</h2>
                                            <p>"{comment.mensaje}"</p>
                                            <p>Publicado : {comment.fecha.date.length > 19 ? comment.fecha.date.substring(0, 19) : ''}</p>
                                        </div>
                                        {/*<div className="profile-product-item3 container-sub-text">*/}
                                        {/*    <div>*/}
                                        {/*        <Button variant="contained" color="secondary"*/}
                                        {/*                onClick={this.handleControlModal}>*/}
                                        {/*            <Icon>bookmark_border</Icon>*/}
                                        {/*        </Button>*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <Button variant="contained" color="secondary"*/}
                                        {/*                onClick={this.handleControlModal}>*/}
                                        {/*            <Icon>delete_sweep</Icon>*/}
                                        {/*        </Button>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                    </div>
                            </div>
                        ))}
                    </div>
                </Box>
            </MuiThemeProvider>
        )
    }
}
