import {SEARCH_CHANGE_FORM, SEARCH_CLEAR_FORM} from "./constants";

const initialState = {
    values: {
        search: '',
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_CHANGE_FORM:
            return {
                ...state,
                values: {...state.values, [action.key]: action.value},
            };
        case SEARCH_CLEAR_FORM:
            return {
                ...state,
                values: {
                    search: '',
                },
            };
        default:
            return state;
    }
};

export default reducer;