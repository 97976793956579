import {CITIES_GET_ERROR, CITIES_GET_REQUESTING, CITIES_GET_SUCCESS, CITY_RESET_STATES} from "./constants";

export const citiesGetRequesting = () => ({
    type: CITIES_GET_REQUESTING,
});

export const citiesGetSuccess = (cities) => ({
    type: CITIES_GET_SUCCESS,
    cities,
});

export const citiesGetError = (error) => ({
    type: CITIES_GET_ERROR,
    error,
});

export const cityResetState = () => ({
    type: CITY_RESET_STATES,
});