import {
    ADD_FAVORITE_ERROR,
    ADD_FAVORITE_REQUESTING, ADD_FAVORITE_SUCCESS,
    GET_PUBLICATION_FAVORITES_ERROR,
    GET_PUBLICATION_FAVORITES_REQUESTING,
    GET_PUBLICATION_FAVORITES_SUCCESS, REMOVE_FAVORITE_ERROR, REMOVE_FAVORITE_REQUESTING, REMOVE_FAVORITE_SUCCESS
} from "./constants";

export const getPublicationFavoriteRequesting = (token) => ({
    type: GET_PUBLICATION_FAVORITES_REQUESTING,
    token
});

export const getPublicationFavoriteSuccess = (publicationFavorites) => ({
    type: GET_PUBLICATION_FAVORITES_SUCCESS,
    publicationFavorites
});

export const getPublicationFavoritesError = (error) => ({
    type: GET_PUBLICATION_FAVORITES_ERROR,
    error
});

export const addFavoriteRequesting = (token, id, publication) => ({
    type: ADD_FAVORITE_REQUESTING,
    token,
    id,
    publication
});

export const addFavoriteSuccess = (messages) => ({
    type: ADD_FAVORITE_SUCCESS,
    messages
});

export const addFavoriteError = (error) => ({
    type: ADD_FAVORITE_ERROR,
    error
});

export const removeFavoriteRequesting = (id, publication) => ({
    type: REMOVE_FAVORITE_REQUESTING,
    id,
    publication
});

export const removeFavoriteSuccess = (messages) => ({
    type: REMOVE_FAVORITE_SUCCESS,
    messages
});

export const removeFavoriteError = (error) => ({
    type: REMOVE_FAVORITE_ERROR,
    error
});
