import {GET_TYPE_PROFILE_ERROR, GET_TYPE_PROFILE_REQUESTING, GET_TYPE_PROFILE_SUCCESS} from "./constants";

const initialState = {
    requesting: true,
    success: false,
    error: '',
    typeProfiles: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TYPE_PROFILE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_TYPE_PROFILE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                typeProfiles: action.typeProfiles
            };
        case GET_TYPE_PROFILE_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                typeProfiles: []
            };
        default:
            return state;
    }
};

export default reducer;