import React from 'react';

//Material-ui
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Rater from 'react-rater'

//button
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import "react-id-swiper/lib/styles/css/swiper.css";
import posed from "react-pose";
import {theme} from "../../../../theme/theme";
import {FilterProfileSecundaryComponent} from "../../../filter/filter_profile_secundary";
import {ProductModalComponent} from "../../../2-product/modals/detail-product";
import FilterProductModalComponent from "../../../2-product/modals/filter-product";

//Lista de productos
import Masonry from 'react-masonry-component';
import {FormattedNumber} from "react-intl";
//img
import FotoProduct1 from "../../../../assets/img/product1.jpg";
import FotoProduct2 from "../../../../assets/img/product2.png";
import FotoProduct3 from "../../../../assets/img/product3.jpg";

////////////////effect////////////////
const Box = posed.div({
    visible: {
        x: '0%',
        delayChildren: 1000,
        staggerChildren: 200,
        delay: 50
    },
    hidden: {x: '100%'}
});
const Item = posed.div({
    visible: {
        scale: 1,
    },
    hidden: {scale: 0}
});
const ItemHover = posed.div({
    hoverable: true,
    init: {
        scale: 1,
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        // boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
});

const masonryOptions = {
    transitionDuration: '0.8s',
    itemSelector: '.sub-item-1-supply',
    percentPosition: true,
    columnWidth: '.sub-item-1-supply',

};
const imagesLoadedOptions = {background: '.my-bg-image-el'};

/////////////////////////
export class PageProduct extends React.Component {

    render() {

        const {
            visible,
            filters,
            filter,
            publications,
            viewPublication,
            publication,
            type,
            authUser,
            focusFilter,
            focus,
            handleNewChat,
            showModal,
            hiddenModal,
            enabled,
            user,
            client,
            addFavorite
        } = this.props;

        const childElements = publications.map((publication, index) => {
            return (
                <div key={index} className=" container-item-start">
                    <div className="sub-item-1-supply container-text"
                         onClick={() => {
                             focus(publication, type);
                             showModal('viewPublication', type)
                         }}>
                        <div className="sub-item-2-supply item-1-supply">
                            <h1>{publication.nombre}</h1>
                            <p>{publication.subcategoria.nombre}</p>
                        </div>
                        <img src={publication.foto ? publication.foto : FotoProduct1} alt={publication.nombre}/>
                        <div className="sub-item-2-supply">
                            <p>{publication.descripcion.length > 65 ? `${publication.descripcion.substring(0, 65)}...` : publication.descripcion}</p>
                        </div>
                        <div className="sub-item-3-supply item-1-supply">
                            <h2><FormattedNumber value={publication.precio} style={'currency'} currency={'USD'}
                                                 minimumFractionDigits={0} maximumFractionDigits={0}/></h2>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        focus(publication);
                                        showModal('viewPublication', type)
                                    }}>
                                Ver
                                <Icon>mood</Icon>
                            </Button>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <MuiThemeProvider theme={theme}>
                <Box pose={visible ? 'visible' : 'hidden'}>
                    {viewPublication && (
                        <ProductModalComponent
                            addFavorite={addFavorite}
                            client={client}
                            enabled={enabled}
                            publication={publication}
                            visible={viewPublication}
                            showModal={showModal}
                            hiddenModal={hiddenModal}
                            type={type}
                            viewChat={true}
                            auth={authUser}
                            handleNewChat={handleNewChat}
                        />
                    )}
                    <div className="container-item-start">
                        <div className="line"/>
                        <div className="container-sub-text">
                            <h2>Productos</h2>
                            <p>Aquí podrás ver los productos publicados de {user.nombre}</p>
                        </div>
                        {/*<FilterProfileSecundaryComponent filters={filters} filter={filter} focus={focusFilter}/>*/}
                        <div>
                            <div className="container-products">
                                <Masonry
                                    elementType={'div'}
                                    options={masonryOptions}
                                    disableImagesLoaded={false}
                                    updateOnEachImageLoad={false}
                                    imagesLoadedOptions={imagesLoadedOptions}
                                    enableResizableChildren={true}>
                                    {childElements}
                                </Masonry>
                            </div>

                            {/*{focusFilter2 === 'Vendidos' && product.sale === true && (*/}
                            {/*<div className={productPost !== product ? "" : ""}>*/}
                            {/*<div className="container-products">*/}
                            {/*<Masonry*/}
                            {/*// className="container-supply"*/}
                            {/*elementType={'div'}*/}
                            {/*options={masonryOptions}*/}
                            {/*disableImagesLoaded={false}*/}
                            {/*updateOnEachImageLoad={false}*/}
                            {/*imagesLoadedOptions={imagesLoadedOptions}*/}
                            {/*enableResizableChildren={true}>*/}
                            {/*{childElements}*/}
                            {/*</Masonry>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*)}*/}
                        </div>
                    </div>
                </Box>
            </MuiThemeProvider>
        )
    }
}
