import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {getTypeProfileError, getTypeProfileSuccess} from "./actions";
import {GET_TYPE_PROFILE_REQUESTING} from "./constants";

const TYPE_PROFILE_URL = `${process.env.REACT_APP_API_URL}/typeProfiles`;

const getTypeProfileAPI = () => {

    return fetch(TYPE_PROFILE_URL, {
        method: 'GET'
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getTypeProfileFlow(action) {
    try {
        const {} = action;
        const typeProfiles = yield call(getTypeProfileAPI);
        yield put(getTypeProfileSuccess(typeProfiles));
    }catch (error) {
        yield put(getTypeProfileError(error));
    }
}

function* typeProfileWatcher() {
    yield all([
        takeEvery(GET_TYPE_PROFILE_REQUESTING, getTypeProfileFlow)
    ])
}

export default typeProfileWatcher;