import React from 'react';

//Material-ui
import {MuiThemeProvider} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Rater from 'react-rater'
import {EditProfileComponent} from "../../modals/edit_profile";

//button
import Button from '@material-ui/core/Button';
import "react-id-swiper/lib/styles/css/swiper.css";
import posed from "react-pose";
import {theme} from "../../../../theme/theme";

//img
import ImgUser from "../../../../assets/img/img-user.jpg";

//effect
const Box = posed.div({
    visible: {
        x: '0%',
        delayChildren: 1000,
        staggerChildren: 200,
        delay: 50
    },
    hidden: {x: '100%'}
});
const Item = posed.div({
    visible: {
        scale: 1,
    },
    hidden: {scale: 0}
});
const ItemHover = posed.div({
    hoverable: true,
    init: {
        scale: 1,
        // boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.1,
        // boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
});

/////////////////////////
export class PageProfile
    extends React.Component {
    state = {
        isVisible: false,
        isOpenModal: false,
    };
    componentDidMount() {
        this.setState({
            isVisible: true
        });
    }
// control de modal
    handleControlModal = (modal) => {
        this.setState({
            isOpenModal: modal === 'modal product',
        });
    };
    render() {
        const {isOpenModal} = this.state;
        const {user} = this.props;
        ////////////////////////////////////////////////////////
        return (

            <MuiThemeProvider theme={theme}>
                <EditProfileComponent
                    visible={isOpenModal}
                    handleControl={this.handleControlModal.bind(this)}
                />
                <Box pose={this.state.isVisible ? 'visible' : 'hidden'}>
                    <div className="container-item-start">
                        <div className="container-sub-text">
                            <h1>Perfil</h1>
                            <br/>
                        </div>
                        <div className="avatar-menu-home">
                            <img src={user.foto} alt="Logo"/>
                            <div className="container-text">
                                <h2>{user.nombre}</h2>
                                <div className="rater-component">
                                    <Rater total={5} rating={user.calificacion} interactive={false}/>
                                    <p>{user.hasOwnProperty('calificacion') && user.calificacion.toString().substring(0,3)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </Box>
            </MuiThemeProvider>
        )
    }
}
