import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {toastr} from "react-redux-toastr";
import {
    categoriesGetError,
    categoriesGetSuccess,
    categoriesModuleGetError,
    categoriesModuleGetSuccess,
    categoryResetStates
} from "./actions";
import {CATEGORIES_GET_REQUESTING, CATEGORIES_MODULE_GET_REQUESTING} from "./constants";

const categoryURL = `${process.env.REACT_APP_API_URL}/categories`;

const categoriesGetApi = () => {
    return fetch(`${categoryURL}`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;

        }).catch((error) => {
            throw error;
        })
};

function* categoriesGetFlow() {
    try {
        const categories = yield call(categoriesGetApi);
        yield put(categoriesGetSuccess(categories));
       // yield put(categoryResetStates());
    } catch (error) {
        yield put(categoriesGetError(error));
    }
}

const categoriesModuleGetApi = (moduleId) => {
    return fetch(`${categoryURL}Module/${moduleId}`, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200 || json.data.data.length > 0)
                return json.data.data;
            return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* categoriesModuleGetFlow(action) {
    try {
        const {moduleId} = action;
        const categories = yield call(categoriesModuleGetApi, moduleId);
        yield put(categoriesModuleGetSuccess(categories));
        yield put(categoryResetStates());
    } catch (error) {
        yield put(categoriesModuleGetError(error));
    }
}

function* categoryWatcher() {
    yield all([
        takeEvery(CATEGORIES_GET_REQUESTING, categoriesGetFlow),
        takeEvery(CATEGORIES_MODULE_GET_REQUESTING, categoriesModuleGetFlow),
    ])
}

export default categoryWatcher;
