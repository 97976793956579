export const FirebaseConfig = {
    apiKey: "AIzaSyDtdqcLjNTAPVt0N1iThueYAn0R1ewL-0Q",
    authDomain: "tuconstruccion-557e9.firebaseapp.com",
    databaseURL: "https://tuconstruccion-557e9.firebaseio.com",
    projectId: "tuconstruccion-557e9",
    storageBucket: "",
    messagingSenderId: "998273245223",
    appId: "1:998273245223:web:2106fef1c28bbc1e"
};

