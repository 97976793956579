import {
    GET_SUBSCRIPTION_USER_ERROR,
    GET_SUBSCRIPTION_USER_REQUESTING,
    GET_SUBSCRIPTION_USER_SUCCESS,
    CREATE_SUBSCRIPTION_USER_ERROR,
    CREATE_SUBSCRIPTION_USER_REQUESTING,
    CREATE_SUBSCRIPTION_USER_SUCCESS,

} from './constants';

//Crear suscripcion

export const createSubscriptionUserRequesting = (user,subscription,payment, token,statePayment,referencePay) => ({
    type: CREATE_SUBSCRIPTION_USER_REQUESTING,
    user,
    subscription,
    token,
    payment,
    statePayment,
    referencePay
});

export const createSubscriptionUserSuccess = (subscription) => ({
    type: CREATE_SUBSCRIPTION_USER_SUCCESS,
    subscription
});

export const createSubscriptionUserError = (error) => ({
    type: CREATE_SUBSCRIPTION_USER_ERROR,
    error
});

//obtener suscripciones
export const getSubscriptionUserRequesting=(token) =>({
    type: GET_SUBSCRIPTION_USER_REQUESTING,
    token
});

export const getSubscriptionUserSuccess=(subscriptions) =>({
    type: GET_SUBSCRIPTION_USER_SUCCESS,
    subscriptions
});

export const getSubscriptionUserError=(error) =>({
    type: GET_SUBSCRIPTION_USER_ERROR,
    error
});
