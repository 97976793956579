import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {Field, reduxForm, Form} from "redux-form";
import TextField from '@material-ui/core/TextField';
import UserCatalog from "../../../assets/img/img-user.jpg";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import {filterTypePeopleRequesting, setFocusUser} from "../../../redux/user/actions";
import {
    publicationsOwnerUserPostRequesting,
    cleanPublication,
    publicationShowModal
} from "../../../redux/publication/actions";
import {getReviewRequesting} from "../../../redux/review/actions";
import {filterChangePrimaryProfile} from "../../../redux/iu/actions";
import Slide from '@material-ui/core/Slide';
import CircularProgress from "@material-ui/core/CircularProgress";

const InputRejectedTypeProfessional = ({input, name, label, type, id, meta: {touched, error}, ...rest}) => {
    return (
        <TextField
            value={input.value}
            autoFocus
            margin="normal"
            variant="outlined"
            id="outlined-name"
            name="name"
            label={"Perfil"}
            type="text"
            fullWidth
            multiline
            rowsMax={'10'}
            {...input}
            {...rest}
        />
    )
};


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CatalogModalComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        isModalPayment: false,

    };
    // control de modal
    handleControlModal = (modal) => {
        this.setState({
            isModalPayment: modal === 'modal de pagos',
        });
    };

    handleAction = () => {
        const {filterTypePeopleRequesting, stateForm} = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        filterTypePeopleRequesting(stateForm.values.type_people_id, token);
    };

    render() {
        const {
            handleControl, visible, user: {users, requestingFilter}, typePeople, handleSubmit, setFocusUser, publicationsOwnerUserPostRequesting,
            client: {token},
            publication: {ownerPublicationsIds, paginate},
            cleanPublication,
            filterChangePrimaryProfile,
            getReviewRequesting,
            publicationShowModal,
        } = this.props;
        const {isModalPayment} = this.state;
        return (
            <Dialog
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'xs'}
                onClose={handleControl.bind(this)} aria-labelledby="customized-dialog-title"
                open={visible}>
                <DialogTitle id="customized-dialog-title">
                    Catálogo
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={handleControl.bind(this)}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{padding : '0 10px'}}>
                    <Form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="container-filter-catalog ">
                            <h2>Filtrar</h2>
                            <Field
                                name={'type_people_id'}
                                select
                                label="Seleccione una opción"
                                component={InputRejectedTypeProfessional}

                            >
                                {typePeople && typePeople.typePeoples.map((typePeople, index) =>
                                    <MenuItem value={typePeople.id} key={index} className="menuItemStyle">
                                        <p className="menuItemStyleP">{typePeople.nombre}</p>
                                    </MenuItem>
                                )}
                            </Field>
                            <Button variant="contained" color='secondary' type={'submit'} disabled={requestingFilter}>
                                {requestingFilter ? <CircularProgress style={{color: 'white'}} size={22}/> : 'Filtrar'}

                            </Button>
                        </div>
                    </Form>
                    <div className="container-sub-text">
                        <p style={{margin: '20px 0', fontWeight: 'bold'}}>Encuentra lo que buscas</p>
                    </div>
                    <div>
                        {users !== undefined ? users.length > 0 && users.map((user, index) => {
                            return (
                                <Link to={`/profileVisitor/${user.id}`} onClick={() => {
                                    cleanPublication();
                                    setFocusUser(user);
                                    getReviewRequesting(token, user.id);
                                    publicationsOwnerUserPostRequesting(user.id, ownerPublicationsIds, paginate);
                                    handleControl('');
                                    filterChangePrimaryProfile('Productos');
                                    publicationShowModal('');
                                }}>
                                    <div className="container-catalog  container-text">
                                        {/*<Link to={`/profileVisitor/${user.id}`} onClick={ () =>*/}
                                        {/*{   cleanPublication();*/}
                                        {/*    setFocusUser(user);*/}
                                        {/*    getReviewRequesting(token, user.id);*/}
                                        {/*    publicationsOwnerUserPostRequesting(user.id, ownerPublicationsIds, paginate);*/}
                                        {/*    handleControl('');*/}
                                        {/*    filterChangePrimaryProfile('Productos');*/}
                                        {/*    publicationShowModal('');*/}
                                        {/*}}>*/}
                                        <div className="catalog-item1 ">
                                            <img src={user.foto} alt="Logo"/>
                                        </div>
                                        {/*</Link>*/}
                                        <div className="catalog-item1 ">
                                            <h1>{user.nombre}</h1>
                                            <div className="line"/>
                                            <p><i className="icon-pin styles-icons"/>{user.tipo_persona.nombre}</p>
                                        </div>
                                    </div>
                                </Link>
                            )
                        }) : null}
                    </div>

                </DialogContent>
                <DialogActions>

                    <Button variant="contained" onClick={() => handleControl('')}
                            color="secondary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    typePeople: state.typePeople,
    stateForm: state.form.catalogUser,
    publication: state.publication
});
const connected = connect(mapStateToProps, {
    filterTypePeopleRequesting,
    setFocusUser,
    publicationsOwnerUserPostRequesting,
    getReviewRequesting,
    cleanPublication,
    filterChangePrimaryProfile,
    publicationShowModal
})(CatalogModalComponent);

const formed = reduxForm({
    form: 'catalogUser',
})(connected);

export default formed;
