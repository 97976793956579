//Suscripciones

export const GET_ALL_SUBSCRIPTION_REQUESTING = 'GET_ALL_SUBSCRIPTION_REQUESTING';
export const GET_ALL_SUBSCRIPTION_SUCCESS = 'GET_ALL_SUBSCRIPTION_SUCCESS';
export const GET_ALL_SUBSCRIPTION_ERROR = 'GET_ALL_SUBSCRIPTION_ERROR';

export const ERROR_SUBSCRIPTION='ERROR_SUBSCRIPTION';

export const SHOW_MODAL_SUBSCRIPTION='SHOW_MODAL_SUBSCRIPTION';

export const SET_FOCUS_SUBSCRIPTION = 'SET_FOCUS_SUBSCRIPTION';


