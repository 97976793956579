import React from 'react';

import {SliderPage} from "../slider/slider-page";
import {FooterComponent} from "../footer/footer";
import {PageProduct} from "./page/product/page-product";
import FilterVisitorPrincipalComponent from "../filter/filter_profile_principal_visitor";
import {PageComments} from "./page/comments/comments";
import {PageProfile} from "./page/profile/profile";
import {getReviewRequesting} from "../../redux/review/actions";
import {showUserRequesting} from "../../redux/user/actions";
import {publicationsMePostRequesting} from "../../redux/publication/actions";
import Rater from 'react-rater'
import {addFavoriteRequesting} from "../../redux/publicationFavorite/actions";
//button
//Buscador
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';


/// img
import FotoProduct1 from "../../assets/img/product1.jpg";
import ImgUser from "../../assets/img/img-user.jpg";
import {connect} from "react-redux";
import {actualPage} from "../../redux/iu/actions";
import {
    publicationsOwnerUserPostRequesting,
    publicationShowModal,
    publicationHiddenModal,
    publicationGetRequesting
} from "../../redux/publication/actions";
import {createRoomChatRequesting} from "../../redux/chat/actions";

class ProfileVisitorComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            publicationsOwnerUserPostRequesting,
            publication: {ownerPublicationsIds, paginate},
            actualPage,
            getReviewRequesting,
            user: {focusUser},
            showUserRequesting,
            publicationsMePostRequesting
        } = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        if (!focusUser.id){
            showUserRequesting(this.props.match.params.id);
            publicationsOwnerUserPostRequesting(focusUser.id || this.props.match.params.id, ownerPublicationsIds, paginate);
            getReviewRequesting(token, this.props.match.params.id);
        }
    }


    handleFocus = (object, type) => {
        const {publicationGetRequesting, publicationsTalkFocus, client: {token}} = this.props;
        switch (type) {
            case 'publication':
                publicationGetRequesting(object, token);
                break;
            case 'publicationsTalk':
                publicationsTalkFocus(object);
                break;
        }
    };

    handleShowModal = (modal, type) => {
        const {publicationShowModal, publicationsTalkShowModal} = this.props;
        switch (type) {
            case 'publication':
                publicationShowModal(modal);
                break;
            case 'publicationsTalk':
                publicationsTalkShowModal(modal);
                break;
        }
    };

    handleHiddenModal = (modal, type) => {
        const {publicationHiddenModal, publicationsTalkHiddenModal} = this.props;
        switch (type) {
            case 'publication':
                publicationHiddenModal(modal);
                break;
            case 'publicationsTalk':
                publicationsTalkHiddenModal(modal);
                break;
        }
    };

    handleFocusFilter = (target, type) => {
        const {filterChangeSecondaryProfile} = this.props;
        switch (type) {
            case 'secondary':
                filterChangeSecondaryProfile(target);
                break;
        }
    };

    handleAddFavorite = (publication) => {
        const {
            addFavoriteRequesting,
            client : {client, token}
        } = this.props;
        addFavoriteRequesting(token, client.id, publication.id)
    };

    handleNewChat = (publication) => {
        const {
            // client: {token},
            createRoomChatRequesting,
        } = this.props;
        let token = localStorage.getItem('@user:tuConstruccion');
        createRoomChatRequesting(token, publication.vendedor.id, publication.id);
    };

    render() {
        const {
            publication: {ownerPublications, publication, viewPublication},
            iu: {filtersSecondary, filterSecondary, filterPrimary},
            user: {focusUser},
            review: {reviews},
            client: {enabled, client}
            // client: {token},
        } = this.props;
        let token = '';
        return (
            <div>
                {/*<SliderPage/>*/}
                <br/>
                <br/>
                <br/>
                <br/>
                <PageProfile
                    user={focusUser}
                />
                <div className="container-item-start">
                    <FilterVisitorPrincipalComponent/>
                </div>
                {filterPrimary === 'Productos' && (
                    ownerPublications.length > 0 && (
                        <PageProduct
                            user={focusUser}
                            client={client}
                            enabled={enabled}
                            userAuth={token !== ''}
                            visible={true}
                            filters={filtersSecondary}
                            filter={filterSecondary}
                            publications={ownerPublications}
                            publication={publication}
                            viewPublication={viewPublication}
                            type={'publication'}
                            focusFilter={this.handleFocusFilter}
                            focus={this.handleFocus}
                            showModal={this.handleShowModal}
                            hiddenModal={this.handleHiddenModal}
                            handleNewChat={this.handleNewChat}
                            addFavorite={this.handleAddFavorite}
                        />
                    )
                )}
                {filterPrimary === 'Comentarios' && (
                    <div>
                        <PageComments comments={reviews}/>
                    </div>
                )}
                {/*<FooterComponent/>*/}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    iu: state.iu,
    client: state.client,
    publication: state.publication,
    user: state.user,
    review: state.review
});

const connected = connect(mapStateToProps, {
    actualPage,
    //
    publicationsOwnerUserPostRequesting,
    publicationShowModal,
    publicationHiddenModal,
    publicationGetRequesting,
    getReviewRequesting,
    createRoomChatRequesting,
    showUserRequesting,
    addFavoriteRequesting
})(ProfileVisitorComponent);

export default connected;