import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {MenuItem} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import {connect} from "react-redux";
import {Field, Form, reduxForm} from "redux-form";
import {createUserRequesting, errorControlCreateUser} from "../../../redux/user/actions";
import {filterGender, getGenderRequesting} from "../../../redux/gender/actions";
import {getTypeProfessionalRequesting, visibleTypeProfessional} from "../../../redux/typeProfessional/actions";
import {citiesGetRequesting} from "../../../redux/city/actions";
import {getTypePeopleRequesting} from "../../../redux/typePeople/actions";
import {getTypeProfileRequesting} from "../../../redux/typeProfile/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {MuiThemeProvider} from "@material-ui/core";
import {materialTheme} from "../../../commons/theme/theme";
import ButtonFacebook from "./quality-politics";


const descriptionProfile = [
    {
        name: 'Público',
        description: 'Seleccionado este perfil las personas lo puden buscar en el directorio para ver sus productos'
    },
    {name: 'Privado', description: 'Seleccionado este perfil, las personas no lo podran buscar en el directorio'},
];


const inputTextField = ({input, name, label, type, id, placeholder, meta: {touched, error}, ...rest}) => {
    return (
        <MuiThemeProvider theme={materialTheme}>
            <TextField
                {...input}
                {...rest}
                label={label}
                type={type}
                value={input.value}
                name={input.name}
                placeholder={placeholder ? placeholder : ''}
                margin="normal"
                variant="outlined"
                fullWidth
                multiline={type === "text"}
                rowsMax={'10'}
            />
        </MuiThemeProvider>
    )
};

class ModalDateTreatmentComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            citiesGetRequesting,
            getTypeProfileRequesting,
            getTypePeopleRequesting,
            getGenderRequesting,
            getTypeProfessionalRequesting,

        } = this.props;
        citiesGetRequesting();
        getTypeProfileRequesting();
        getTypePeopleRequesting();
        getGenderRequesting();
        getTypeProfessionalRequesting();
    }

    state = {
        messageTypeProfile: null,
    };

    handleAction = (values) => {
        const {createUserRequesting, user: {phone}} = this.props;
        createUserRequesting({
            ...values,
            password: phone === 0 ? values.password : Math.random(),
            phone: phone === 0 ? values.phone : phone
        })
    };

    handleError = () => {
        const {user: {error}, errorControlCreateUser} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlCreateUser();
    };

    handleShowMessageTypeProfile = (value) => {
        const {
            typeProfile: {typeProfiles},
        } = this.props;
        let typeProfile = typeProfiles.find((typeProfile) => typeProfile.id === value);
        this.setState({
            messageTypeProfile: descriptionProfile.find((description) => description.name === typeProfile.nombre).description,
        });
    };

    render() {
        const {
            handleControl,
            visible,
            handleSubmit,
            city: {cities},
            typePeople: {typePeoples},
            typeProfessional,
            gender: {gendersFilter},
            typeProfile: {typeProfiles},
            filterGender,
            visibleTypeProfessional,
            user: {errorControlCreateUser, phone},
        } = this.props;
        return (

            <Dialog
                onClose={() => handleControl('')} aria-labelledby="customized-dialog-title"
                open={visible}>

                <DialogTitle id="customized-dialog-title">
                    POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES DE NOVA INC TECNOLOGY S.A.S.
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={() => handleControl('')}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent>


                    {errorControlCreateUser && this.handleError()}
                    <Form onSubmit={handleSubmit(this.handleAction)}>

                        <div className="container-sub-text">
                            <p>En este documento Los Usuarios encontrarán la información relacionada sobre el
                                tratamiento que realiza Nova Inc Tecnology S. A. S (“La Compañía”) como consecuencia de
                                la recolección de los datos que se efectúa a través de “TuConstrucción” (portal web y
                                aplicación móvil) (en adelante La Plataforma) o de cualquier otro medio por el que La
                                Compañía obtenga los datos. <br/><br/>

                                Este documento hace parte integral de los Términos y Condiciones Generales. Los Usuarios
                                que acepten los Términos y Condiciones Generales, aceptan las políticas aquí contenidas.<br/><br/>

                                I. DEFINICIONES<br/><br/>

                                a) Autorización: Consentimiento previo, expreso e informado del Titular para llevar a
                                cabo el Tratamiento de datos personales.<br/><br/>

                                b) Aviso de privacidad: Comunicación verbal o escrita (documento físico o electrónico)
                                generada por el responsable, mediante la cual se le informa al Titular acerca de la
                                existencia de las Políticas de Tratamiento de información que le serán aplicables, la
                                forma de acceder a las mismas y las características y finalidades del Tratamiento que se
                                pretende dar a los datos personales.<br/><br/>

                                c) Base de Datos: Conjunto organizado de datos personales que sea objeto de Tratamiento.<br/><br/>

                                d) Datos de Identificación: Hace referencia a aquella información que tiene como
                                finalidad identificar e individualizar al titular de los datos.<br/><br/>

                                e) Datos de Ubicación: Hace referencia aquella información que permite localizar al
                                titular de los datos.<br/><br/>

                                f) Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias
                                personas naturales determinadas o determinables.<br/><br/>

                                g) Dato Público: Es el dato que no sea semiprivado, privado o sensible. Es considerado
                                como públicos, entre otros, los datos relativos al estado civil de las personas, a su
                                profesión u oficio, y a su calidad de comerciante o de servidor público. Por su
                                naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros
                                públicos, documentos públicos, gacetas y boletines oficiales y sentencias judiciales
                                debidamente ejecutoriadas que no estén sometidas a reserva.<br/><br/>

                                h) Dato Privado: Considerado también como personal. Hacer referencia a toda información
                                que identifique o permita individualizar a una persona.<br/><br/>

                                i) Dato Semiprivado: Es semiprivado el dato que no tiene naturaleza íntima, reservada ni
                                pública y cuyo conocimiento o divulgación puede interesar no solo a su titular sino a
                                cierto sector o grupo de personas o a la sociedad en general, como el dato financiero y
                                crediticio de actividad comercial o de servicios. Este tipo de dato requerirá
                                autorización para su circulación.<br/><br/>

                                j) Datos Sensibles: Adoptamos la definición que estipula el título III de la ley
                                estatutaria 1581 de 2012.<br/><br/>

                                k) Datos Socioeconómicos: Hace referencia a aquella información que permite conocer,
                                verificar y clasificar el nivel social y económico al que pertenece el titular de los
                                datos.<br/><br/>

                                l) Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí
                                misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del
                                responsable del Tratamiento.<br/><br/>

                                m) Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por
                                sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los
                                datos.<br/><br/>

                                n) Titular de la información: Persona natural cuyos datos personales sean objeto de
                                Tratamiento.<br/><br/>

                                o) Transmisión de datos personales: Es el tratamiento de datos personales que implica la
                                comunicación de los mismos dentro o fuera del territorio de la República de Colombia
                                cuando tenga por objeto la realización de un Tratamiento por el Encargado por cuenta del
                                responsable.<br/><br/>

                                p) Transferencia de datos personales: La transferencia de datos tiene lugar cuando el
                                responsable y/o Encargado del Tratamiento de datos personales, ubicado en Colombia,
                                envía la información o los datos personales a un receptor, que a su vez es responsable
                                del Tratamiento y se encuentra dentro o fuera del país.<br/><br/>

                                q) Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales,
                                tales como la recolección, almacenamiento, uso, circulación o supresión.<br/><br/>

                                II. PRINCIPIOS APLICABLES AL TRATAMIENTO DE DATOS<br/><br/>

                                a) Principio de legalidad en materia de Tratamiento de datos: El Tratamiento a que se
                                refiere el presente manual es una actividad reglada que debe sujetarse a lo establecido
                                en la ley 1581 de 2012 y en las demás disposiciones que la desarrollen.
                                b) Principio de finalidad: El Tratamiento debe obedecer a una finalidad legítima de
                                acuerdo con la Constitución y la Ley, la cual debe ser informada al Titular.
                                c) Principio de libertad: El Tratamiento sólo puede ejercerse con el consentimiento,
                                previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o
                                divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve
                                el consentimiento.<br/><br/>
                                d) Principio de veracidad o calidad: La información sujeta a Tratamiento debe ser veraz,
                                completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de
                                datos parciales, incompletos, fraccionados o que induzcan a error;
                                e) Principio de transparencia: En el Tratamiento debe garantizarse el derecho del
                                Titular a obtener del responsable del tratamiento o del Encargado del Tratamiento, en
                                cualquier momento y sin restricciones, información acerca de la existencia de datos que
                                le conciernan.<br/><br/>
                                f) Principio de acceso y circulación restringida: El Tratamiento se sujeta a los límites
                                que se derivan de la naturaleza de los datos personales, de las disposiciones de la ley
                                y la Constitución. En este sentido, el Tratamiento sólo podrá hacerse por personas
                                autorizadas por el Titular y/o por las personas previstas en la presente ley.
                                Los datos personales, salvo la información pública, no podrán estar disponibles en
                                Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea
                                técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o
                                terceros autorizados conforme a la presente ley;<br/><br/>
                                g) Principio de seguridad: La información sujeta a Tratamiento por el responsable del
                                tratamiento o Encargado del Tratamiento a que se refiere la ley 1581 de 2012, se deberá
                                manejar con las medidas técnicas, humanas y administrativas que sean necesarias para
                                otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o
                                acceso no autorizado o fraudulento;<br/><br/>
                                h) Principio de confidencialidad: Todas las personas que intervengan en el Tratamiento
                                de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar
                                la reserva de la información, inclusive después de finalizada su relación con alguna de
                                las labores que comprende el Tratamiento, pudiendo sólo realizar suministro o
                                comunicación de datos personales cuando ello corresponda al desarrollo de las
                                actividades autorizadas en la ley 1581 de 2012.<br/><br/>

                                III. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO DE LA INFORMACIÓN:<br/><br/>

                                NOVA INC TECNOLOGY S. A. S. (en adelante “NOVA INC” o “La Compañía”), NIT No.
                                900.938.821 – 1, es una sociedad comercial privada, creada bajo las leyes de la
                                República de Colombia, con domicilio principal en la ciudad de Bogotá D. C., registrada
                                en la Cámara de Comercio de Bogotá.<br/><br/>

                                Dirección: Av. Carrera 9 No. 113 – 52, Oficina 19-01<br/><br/>
                                Número de contacto: +57 1 5473733<br/><br/>
                                Correo electrónico: info@novainctec.com<br/><br/>

                                IV. DATOS RECAUDADOS:<br/><br/>

                                La Compañía recauda cualquier tipo de dato que sea necesario para el desarrollo de su
                                objeto social. Los datos recaudados pueden ser públicos (los que permitan identificar al
                                usuario como nombre, identificación), privados (correo electrónico personal, teléfono,
                                dirección, fotografías, entre otros) y semiprivados (datos crediticios y/o financieros,
                                reporte en las centrales de riesgo, etc.) de conformidad con la relación que exista
                                entre La Compañía y el titular de la información.<br/><br/>

                                4. 1. FINALIDADES Y TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS PERSONALES:<br/><br/>

                                La Compañía dará el tratamiento a los datos recolectados, que se almacenen, usen,
                                circulen, conserven, transfirieran, transmitan, cedan o supriman, de conformidad con las
                                siguientes finalidades:<br/><br/>

                                4. 1. 1. En relación con la naturaleza y objeto social de NOVA INC TECNOLOGY S.A.S.<br/><br/>

                                El tratamiento de los datos se realizará con la finalidad de llevar el registro y
                                control de la información relacionada con los socios que conforman La Compañía,
                                empleados, proveedores, contratistas, clientes, usuarios de “TuConstrucción” (en
                                adelante “La Plataforma”), en desarrollo de su objeto social consistente en prestación
                                de servicios destinados a la creación de nuevas plataformas tecnológicas, inversión en
                                nuevas líneas de negocio e impulso de marcas novedosas con gran contenido de valor
                                agregado en el área de fabricación, distribución y comercialización internacional de
                                productos de vanguardia de las tecnologías de la información y la comunicación y
                                actividades relacionadas.<br/><br/>

                                4. 1. 2. En relación con el funcionamiento de NOVA INC TECNOLOGY S.A.S.<br/><br/>

                                A) Recurso Humano<br/><br/>

                                La Compañía podrá recaudar datos del personal a través de la información consignada en
                                hojas de vida, entrevistas, formularios y otros medios, públicos o privados, en los que
                                el titular consigne los datos y los haya puesto en conocimiento de NOVA INC TECNOLOGY S.
                                A. S. o a los que la empresa haya podido tener acceso por encontrarse en alguna red
                                pública de información.<br/><br/>

                                El tratamiento de los datos se realizará para la convocatoria, vinculación, desarrollo
                                de funciones o prestación de servicios, retiro o terminación, verificación de faltas,
                                cumplimiento de horario, pago de salarios, seguridad social, dependiendo del tipo de
                                relación jurídica entablada con NOVA INC (incluye, entre otros, aspirantes a cargos,
                                empleados, exempleados, aprendices y practicantes).<br/><br/>

                                B) Proveedores y contratistas de NOVA INC TECNOLOGY S.A.S.<br/><br/>

                                La Compañía recauda datos de los proveedores y contratistas por medio de propuestas
                                comerciales, contratos, actas y/o cualquier otro medio que La Compañía considere
                                pertinente a la finalidad.<br/><br/>

                                El tratamiento de los datos se realizará para los fines relacionados con el desarrollo
                                de su objeto social y/o para la ejecución del servicio contratado; realizar el control y
                                la prevención del fraude y del lavado de activos; Verificar el cumplimiento de los
                                servicios contratados; cumplir las obligaciones tributarias y contables de La
                                Compañía.<br/><br/>

                                C) Usuarios y Visitantes de “TuConstrucción”<br/><br/>

                                La Compañía como propietaria de La Plataforma, recauda los datos de los usuarios y
                                visitantes a través de formularios, físicos o virtuales, y de los canales que dispone La
                                Compañía como línea telefónica, correo electrónico y/o cualquier otro medio que
                                considere pertinente para las finalidades.<br/><br/>

                                El tratamiento de los datos se realiza desde la inscripción del Usuario en La Plataforma
                                con la finalidad de individualizar el usuario; facilitar la conexión entre los usuarios
                                vendedores y los usuarios compradores dentro de La Plataforma; tener contacto con el
                                titular de los datos personales; informar sobre cambios de nuestros productos y/o nuevos
                                servicios que estén relacionados o no con el uso de La Plataforma; notificar sobre las
                                modificaciones que se efectúen a los Términos y Condiciones como a las Políticas anexas
                                a estos; evaluar la calidad de nuestros productos y servicios; remitir información a las
                                autoridades que así lo requieran, ya sean administrativas y/o judiciales; Cumplir las
                                demás obligaciones de La Compañía.<br/><br/>

                                V. DERECHOS DE LOS TITULARES<br/><br/>

                                5.1. Conocer, actualizar y rectificar sus datos personales frente a La Compañía como
                                responsable y encargado del tratamiento. Este derecho se podrá ejercer entre otros ante
                                datos parciales, inexactos, incompletos, fraccionados, que induzcan a error o aquellos
                                cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.<br/><br/>

                                5.2. Solicitar prueba de la autorización otorgada a La Compañía como responsable y
                                encargado del tratamiento, salvo cuando expresamente se exceptúe como requisito para el
                                tratamiento, de conformidad con lo previsto en el artículo 10 de la Ley 1581 de 2012 o
                                cualquier norma que la modifique o reglamente.<br/><br/>

                                5.3. Ser informado por La Compañía como responsable y encargado del tratamiento, previa
                                solicitud, respecto del uso que ha dado a los datos personales del titular.<br/><br/>

                                5.4. Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones
                                a lo dispuesto en la Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen o
                                complementen.<br/><br/>

                                5.5. Revocar la autorización y/o solicitar la supresión del dato personal cuando en el
                                tratamiento no se respeten los principios, derechos y garantías constitucionales y
                                legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria
                                y Comercio haya determinado que en el tratamiento el responsable o encargado han
                                incurrido en conductas contrarias a la Ley 1581 de 2012 y a la Constitución.<br/><br/>

                                5.6. Acceder en forma gratuita a sus datos personales que hayan sido objeto de
                                Tratamiento.<br/><br/>

                                VI. DATOS SENSIBLES<br/><br/>

                                El titular tiene derecho a no suministrar cualquier información sensible solicitada por
                                La Compañía relacionada, entre otros, con datos sobre su origen racial o étnico, la
                                pertenencia a sindicatos, organizaciones sociales o de derechos humanos, convicciones
                                políticas, religiosas, de la vida sexual, biométricos o datos de salud.<br/><br/>

                                VII. DATOS DE MENORES DE EDAD<br/><br/>

                                El suministro de los datos personales de menores de edad es facultativo y debe
                                realizarse con autorización de los padres de familia o representantes legales del niño,
                                niña y/o adolescente titular de la información.<br/><br/>

                                VIII. AUTORIZACIÓN DEL TITULAR<br/><br/>

                                Sin perjuicio de las excepciones previstas en la ley, en el tratamiento se requiere la
                                autorización previa, expresa e informada del Titular, la cual deberá ser obtenida por
                                cualquier medio que pueda ser objeto de consulta y verificación posterior.<br/><br/>

                                Se entenderá que la autorización cumple con estos requisitos cuando se manifieste (i)
                                por escrito, (ii) de forma oral o (iii) mediante conductas inequívocas del titular que
                                permitan concluir de forma razonable que otorgó la autorización<br/><br/>

                                IX. CASOS EN QUE NO SE REQUIERE LA AUTORIZACIÓN<br/><br/>

                                La autorización del Titular no será necesaria cuando se trate de:<br/><br/>

                                9.1. Información requerida por La Compañía en ejercicio de sus funciones legales o por
                                orden judicial.<br/><br/>
                                9.2. Datos de naturaleza pública.<br/><br/>
                                9.3. Casos de urgencia médica o sanitaria.<br/><br/>
                                9.4. Tratamiento de información autorizado por la ley para fines históricos,<br/><br/>
                                estadísticos o científicos.<br/><br/>
                                9.5. Datos relacionados con el registro civil de las personas.<br/><br/>

                                X. ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS<br/><br/>

                                Para realizar peticiones, consultas o reclamos con el fin de ejercer los derechos a
                                conocer, actualizar, rectificar, suprimir los datos o revocar la autorización otorgada,
                                el titular o sus causahabientes pueden utilizar cualquiera de los siguientes canales de
                                comunicación:<br/><br/>

                                Dirección: Av. Carrera 9 No. 113 – 52, Oficina 19-01<br/><br/>
                                Número de contacto: +57 1 5473733<br/><br/>
                                Correo electrónico: info@novainctec.com<br/><br/>

                                XI. PROCEDIMIENTO PARA EJERCER LOS DERECHOS:<br/><br/>

                                11.1. Consultas<br/><br/>

                                Se absolverán en un término máximo de diez (10) días hábiles contados a partir de la
                                fecha de su recibo.<br/><br/>

                                Cuando no fuere posible responder la consulta dentro de dicho término, se informará al
                                interesado antes del vencimiento de los 10 días, expresando los motivos de la demora y
                                señalando la fecha en que se atenderá su solicitud, la cual en ningún caso podrá superar
                                los cinco (5) días hábiles siguientes al vencimiento del primer plazo.<br/><br/>

                                11.2. Reclamos<br/><br/>

                                Los titulares o sus causahabientes que consideren que la información contenida en una
                                base de datos de La Compañía debe ser objeto de corrección, actualización o supresión, o
                                que adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la
                                Ley 1581 de 2012, podrán presentar un reclamo ante La Compañía a través de cualquiera de
                                los canales de comunicación descritos anteriormente; y éste deberá contener la siguiente
                                información:<br/><br/>

                                a) Nombre e identificación del titular<br/><br/>
                                b) La descripción precisa y completa de los hechos que dan lugar al reclamo<br/><br/>
                                c) La dirección física o electrónica para remitir la respuesta e informar sobre el
                                estado del trámite.<br/><br/>
                                d) Los documentos y demás pruebas que se pretendan hacer valer.<br/><br/>

                                En caso de que La Compañía no sea competente para resolver el reclamo presentado ante la
                                misma, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e
                                informará de la situación al interesado.<br/><br/>

                                Si el reclamo resulta incompleto, La Compañía requerirá al interesado dentro de los
                                cinco (5) días siguientes a su recepción para que subsane las fallas. Transcurridos dos
                                (2) meses desde la fecha del requerimiento, sin que el peticionario presente la
                                información solicitada, se entenderá que ha desistido de aquél.<br/><br/>

                                Una vez recibido el reclamo completo, La Compañía incluirá en la respectiva base de
                                datos una leyenda que diga “reclamo en trámite” y el motivo del mismo, en un término no
                                mayor a dos (2) días hábiles. Dicha leyenda se mantendrá hasta que el reclamo sea
                                decidido.<br/><br/>

                                El término máximo para atender el reclamo será de quince (15) días hábiles contados a
                                partir del día siguiente a la fecha de su recibo, y si no fuere posible responder en
                                dicho término, La Compañía informará al interesado los motivos de la demora y la fecha
                                en que aquél se atenderá sin que supere, en ningún caso, los ocho (8) días hábiles
                                siguientes al vencimiento del primer término.<br/><br/>

                                11.3 Verificación del Peticionario:<br/><br/>

                                La Compañía ha adoptado las siguientes medidas para la validación de la calidad del
                                peticionario:<br/><br/>

                                a) Los titulares de los datos personales deberán acreditar a La Compañía su calidad de
                                Titular de la información o en su defecto la autorización respectiva, para que se le
                                pueda dar trámite a su petición. Para el efecto deberán exhibir el documento de
                                identificación que se registró ante La Compañía.<br/><br/>

                                b) Si la petición se realiza por medio electrónico o por cualquier otro medio no
                                presencial, el Titular de la información deberá acompañar su petición con una fotocopia
                                escaneada de su documento de identidad junto con la solicitud que deberá estar
                                firmada.<br/><br/>

                                En todos los casos, La Compañía podrá ponerse en contacto con el titular de la
                                información por los medios de notificación que reposan en sus bases de datos para
                                constatar la autenticidad de la petición.<br/>
                                <br/>

                                XII. SEGURIDAD DE LA INFORMACIÓN

                                <br/><br/>

                                La Compañía podrá almacenar la información personal recolectada en las distintas bases
                                de datos a través de los siguientes medios:<br/><br/>

                                a) Servidor propio interno o externo y/o a cargo de un tercero<br/><br/>
                                b) Archivo propio interno o externo y/o a cargo de un tercero<br/><br/>
                                c) Los demás que se consideren necesarios para el almacenamiento de la
                                información<br/><br/>

                                La Compañía adoptará las medidas técnicas, humanas y administrativas que sean necesarias
                                para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso
                                o acceso no autorizado o fraudulento.<br/><br/>

                                Al aceptar los Términos y Condiciones de La Plataforma y/o al suscribir algún contrato,
                                acuerdo o documento con La Compañía, el titular de los datos se compromete a cumplir con
                                el acápite o cláusula (según sea el caso) de confidencialidad, lo que implica que éste
                                debe conservar absoluta reserva sobre la información a la que llegare a tener acceso y
                                que no sea de su propiedad.<br/><br/>

                                En todo caso, La Compañía no será responsable en el evento que terceros no autorizados
                                desplieguen acciones ilegales encaminadas a interceptar o vulnerar los sistemas y/o
                                bases de datos en los que reposa la información recolectada por La Compañía. Del mismo
                                modo, tampoco será responsable por la indebida utilización de los datos obtenidos por
                                esos medios fraudulentos.<br/><br/>

                                XIII. TRANSMISIÓN Y TRANSFERENCIA DE DATOS:<br/><br/>

                                Los datos personales que obtenga La Compañía podrán ser transmitidos a terceros con los
                                que se tenga relación contractual a nivel nacional o internacional para la custodia y
                                almacenamiento, siempre que estos terceros cuenten con los estándares de seguridad en
                                protección de datos los personales fijados por la Superintendencia de Industria y
                                Comercio.<br/><br/>

                                La transferencia a nivel nacional o internacional de datos se efectuará únicamente en
                                los siguientes casos:<br/><br/>

                                a) Respecto de información sobre la cual el Titular haya otorgado su autorización
                                expresa e inequívoca para la transferencia;<br/><br/>

                                b) Cuando se trate del intercambio de datos de carácter médico, cuando así lo exija el
                                Tratamiento del Titular por razones de salud o higiene pública;<br/><br/>

                                c) Para transferencias bancarias o bursátiles, conforme a la legislación que les resulte
                                aplicable;<br/><br/>

                                d) Para transferencias acordadas en el marco de tratados internacionales en los cuales
                                la República de Colombia sea parte, con fundamento en el principio de reciprocidad;<br/><br/>

                                e) Cuando se trate de transferencias necesarias para la ejecución de un contrato entre
                                el Titular y el responsable del tratamiento, o para la ejecución de medidas
                                precontractuales siempre y cuando se cuente con la autorización del Titular;<br/><br/>

                                f) Transferencias legalmente exigidas para la salvaguardia del interés público, o para
                                el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.<br/><br/>

                                La Compañía como responsable y encargada del tratamiento de datos, deberá verificar con
                                Superintendencia de Industria y Comercio, que los terceros que recibirán los datos
                                transferidos cumplen con los estándares de seguridad en protección de datos los
                                personales fijados por esa entidad.<br/><br/>

                                La Compañía al momento de realizar una transferencia o transmisión nacional o
                                internacional de datos personales, deberá contar además de la autorización expresa del
                                titular, un acuerdo con el receptor de la información ya sea encargado o responsable,
                                por medio del cual estos se comprometan al cumplimiento de lo establecido en la Ley 1581
                                de 2012, y a guardar la reserva y confidencialidad de toda la información personal que
                                reciba.<br/><br/>

                                XIV. VIGENCIA DE LA POLÍTICA DE TRATAMIENTO DE LA INFORMACIÓN Y PERÍODO DE VIGENCIA DE
                                LAS BASES DE DATOS DE NOVA INC TECNOLOGY S.A.S.:<br/><br/>

                                La presente política rige a partir de su expedición y las bases de datos sujetas a
                                tratamiento se mantendrán vigentes mientras ello resulte necesario para las finalidades
                                establecidas en el punto IV de esta política.<br/><br/>


                                <h1 id="customized-dialog-title">AVISO DE PRIVACIDAD</h1><br/><br/>


                                NOVA INC TECNOLOGY S. A. S. en cumplimiento de la Ley 1581 de 2012 y demás normas
                                concordantes, es responsable y encargado del tratamiento de sus datos personales.<br/><br/>

                                Los datos personales que NOVA INC TECNOLOGY S. A. S. solicita serán utilizados para los
                                siguientes fines:<br/><br/>

                                Adelantar el registro de los visitantes, usuarios, clientes, proveedores, contratistas y
                                demás personas que tengan contacto con NOVA INC TECNOLOGY S. A. S. a través de sus
                                oficinas o de sus plataformas (“TuConstrucción” aplicación móvil y portal web; y,
                                www.novainctec.com)<br/><br/>
                                Envío de comunicaciones físicas y/o electrónicas con el fin de brindar información los
                                servicios ofrecidos por NOVA INC TECNOLOGY S. A. S.<br/><br/>
                                Notificar sobre las modificaciones que se realicen a los Términos y Condiciones y las
                                Políticas anexas.<br/><br/>
                                Evaluar la calidad de los servicios prestados por NOVA INC TECNOLOGY S. A. S.
                                Remitir información a las autoridades que así lo requieran, ya sean administrativas y/o
                                judiciales Trámite a la solicitud de consultas, quejas, felicitaciones y reclamos.
                                Elaborar estadísticas que se relacionen con el uso de los servicios ofrecidos por NOVA
                                INC TECNOLOGY S. A. S<br/><br/>

                                Como titular de información tiene derecho a conocer, actualizar y rectificar sus datos
                                personales y, sólo en los casos en que sea procedente, a suprimirlos o revocar la
                                autorización otorgada para su tratamiento.<br/><br/>

                                Si requiere más información para conocer nuestra política de tratamiento de datos
                                personales y los cambios sustanciales que se produzcan en ella, visite
                                www.tuconstruccion.com.co y/o www.novainctec.com<br/><br/>

                                Si desea presentar una consulta, reclamo o petición de información relacionada con la
                                protección de datos personales puede utilizar los siguientes medios:<br/><br/>

                                Dirección:<br/><br/>
                                Av. Carrera 9 No. 113 – 52, Oficina 19-0, Bogotá D. C.<br/><br/>
                                Número de contacto:<br/><br/>
                                +57 1 5473733<br/><br/>
                                Correo electrónico:<br/><br/>
                                info@novainctec.com<br/><br/>


                            </p>
                        </div>

                        <DialogActions>
                            <ButtonFacebook/>
                            <Button aria-label="Close" onClick={() => handleControl('')} variant="contained"
                                    color="secondary">Cerrar</Button>
                        </DialogActions>

                    </Form>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    city: state.city,
    typePeople: state.typePeople,
    typeProfessional: state.typeProfessional,
    gender: state.gender,
    typeProfile: state.typeProfile,
});
const connected = connect(mapStateToProps, {
    citiesGetRequesting,
    getTypeProfileRequesting,
    getTypePeopleRequesting,
    getGenderRequesting,
    getTypeProfessionalRequesting,
    filterGender,
    visibleTypeProfessional,
    createUserRequesting,
    errorControlCreateUser
})(ModalDateTreatmentComponent);

const formed = reduxForm({
    form: 'modal data treatment',
})(connected);

export default formed;

