import {
    PUBLICATIONS_TALK_FOCUS,
    PUBLICATIONS_TALK_HIDDEN_MODAL,
    PUBLICATIONS_TALK_RESET_STATES,
    PUBLICATIONS_TALK_SHOW_MODAL,
    PUBLICATIONS_TALKS_ME_GET_ERROR,
    PUBLICATIONS_TALKS_ME_GET_REQUESTING,
    PUBLICATIONS_TALKS_ME_GET_SUCCESS
} from "./constants";


export const publicationsTalksMeGetRequesting = (token, paginate) => ({
    type: PUBLICATIONS_TALKS_ME_GET_REQUESTING,
    token, paginate,
});

export const publicationsTalksMeGetSuccess = (publicationsTalks) => ({
    type: PUBLICATIONS_TALKS_ME_GET_SUCCESS,
    publicationsTalks,
});

export const publicationsTalksMeGetError = (error) => ({
    type: PUBLICATIONS_TALKS_ME_GET_ERROR,
    error,
});

export const publicationsTalkShowModal = (modal) => ({
    type: PUBLICATIONS_TALK_SHOW_MODAL,
    modal,
});

export const publicationsTalkHiddenModal = (modal) => ({
    type: PUBLICATIONS_TALK_HIDDEN_MODAL,
    modal,
});

export const publicationsTalkFocus = (publicationsTalk) => ({
    type: PUBLICATIONS_TALK_FOCUS,
    publicationsTalk,
});

export const publicationsTalkResetStates = () => ({
    type: PUBLICATIONS_TALK_RESET_STATES,
});