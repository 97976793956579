import {handleApiErrors} from "../../commons/errors/apiErrors";
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {checkJWTError, clientSet, enabled, getMeError, getMeRequesting, getMeSuccess} from "./actions";
import {CHECK_JWT_REQUESTING, DELETE_DEVICE, GET_ME_REQUESTING, SAVE_DEVICE} from "./constants";
import {CONNECT_TO_SOCKET} from "../chat/constants";

const CHECK_JWT_URL = `${process.env.REACT_APP_API_URL}/auth/verifyUser`;
const SAVE_DEVICE_URL = `${process.env.REACT_APP_API_URL}/user/devices`;
const DELETE_DEVICE_URL = `${process.env.REACT_APP_API_URL}/users/device/delete`;
const GET_ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

const checkJWTAPI = (token) => {
    return fetch(CHECK_JWT_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.code === 400)
                throw json.data.data;
            if (json.data.code === 200)
                return true
        }).catch((error) => {
            throw error;
        })
};

function* checkJWTFlow(action) {
    try {
        const {token} = action;
        yield call(checkJWTAPI, token);
        yield put(getMeRequesting(token));
    } catch (error) {
        yield put(checkJWTError(error));
    }
}

const getMeAPI = (token) => {
    return fetch(GET_ME_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw '';
        }).catch((error) => {
            throw error;
        })
};

function* meGetFlow(action) {
    try {
        const {token} = action;
        const client = yield call(getMeAPI, token);
        yield put(getMeSuccess(client));
        yield put(clientSet(token));
        yield put({type: CONNECT_TO_SOCKET, client});
        // yield put(getReviewRequesting(token, client.id));
        yield put(enabled())
    } catch (error) {
        yield put(getMeError(error));
    }
}

const saveDeviceAPI = (token, values) => {

    let data = {
        os: values.os,
        device: values.device,
    };
    return fetch(SAVE_DEVICE_URL, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            else
                throw ''
        }).catch((error) => {
            throw error;
        })
};

function* saveDeviceFlow(action) {
    try {
        const {token, values} = action;
        yield call(saveDeviceAPI, token, values);
    } catch (error) {
    }
}

const deleteDeviceAPI = (token) => {

    return fetch(DELETE_DEVICE_URL, {

        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                throw json.data.data;
            else
                throw ''
        }).catch((error) => {
            throw error;
        })
};

function* deleteDeviceFlow(action) {
    try {
        const {token} = action;
        yield call(deleteDeviceAPI, token);
    } catch (error) {
    }
}

function* clientWatcher() {
    yield all([
        takeEvery(CHECK_JWT_REQUESTING, checkJWTFlow),
        takeEvery(GET_ME_REQUESTING, meGetFlow),
        takeEvery(SAVE_DEVICE, saveDeviceFlow),
        takeEvery(DELETE_DEVICE, deleteDeviceFlow),
    ])
}

export default clientWatcher;