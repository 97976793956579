import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {MenuItem} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import {connect} from "react-redux";
import {Field, Form, reduxForm} from "redux-form";
import {createUserRequesting, errorControlCreateUser} from "../../../redux/user/actions";
import {filterGender, getGenderRequesting} from "../../../redux/gender/actions";
import {getTypeProfessionalRequesting, visibleTypeProfessional} from "../../../redux/typeProfessional/actions";
import {citiesGetRequesting} from "../../../redux/city/actions";
import {getTypePeopleRequesting} from "../../../redux/typePeople/actions";
import {getTypeProfileRequesting} from "../../../redux/typeProfile/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {MuiThemeProvider} from "@material-ui/core";
import {materialTheme} from "../../../commons/theme/theme";
import ButtonFacebook from "./quality-politics";


const descriptionProfile = [
    {
        name: 'Público',
        description: 'Seleccionado este perfil las personas lo puden buscar en el directorio para ver sus productos'
    },
    {name: 'Privado', description: 'Seleccionado este perfil, las personas no lo podran buscar en el directorio'},
];


const inputTextField = ({input, name, label, type, id, placeholder, meta: {touched, error}, ...rest}) => {
    return (
        <MuiThemeProvider theme={materialTheme}>
            <TextField
                {...input}
                {...rest}
                label={label}
                type={type}
                value={input.value}
                name={input.name}
                placeholder={placeholder ? placeholder : ''}
                margin="normal"
                variant="outlined"
                fullWidth
                multiline={type === "text"}
                rowsMax={'10'}
            />
        </MuiThemeProvider>
    )
};

class ModalFrequencyComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            citiesGetRequesting,
            getTypeProfileRequesting,
            getTypePeopleRequesting,
            getGenderRequesting,
            getTypeProfessionalRequesting,

        } = this.props;
        citiesGetRequesting();
        getTypeProfileRequesting();
        getTypePeopleRequesting();
        getGenderRequesting();
        getTypeProfessionalRequesting();
    }

    state = {
        messageTypeProfile: null,
    };

    handleAction = (values) => {
        const {createUserRequesting, user: {phone}} = this.props;
        createUserRequesting({
            ...values,
            password: phone === 0 ? values.password : Math.random(),
            phone: phone === 0 ? values.phone : phone
        })
    };

    handleError = () => {
        const {user: {error}, errorControlCreateUser} = this.props;
        let toastOptions = {
            component: (
                <ErrorComponent message={error}/>
            )
        };
        toastr.error('Error', toastOptions);
        errorControlCreateUser();
    };

    handleShowMessageTypeProfile = (value) => {
        const {
            typeProfile: {typeProfiles},
        } = this.props;
        let typeProfile = typeProfiles.find((typeProfile) => typeProfile.id === value);
        this.setState({
            messageTypeProfile: descriptionProfile.find((description) => description.name === typeProfile.nombre).description,
        });
    };

    render() {
        const {
            handleControl,
            visible,
            handleSubmit,
            city: {cities},
            typePeople: {typePeoples},
            typeProfessional,
            gender: {gendersFilter},
            typeProfile: {typeProfiles},
            filterGender,
            visibleTypeProfessional,
            user: {errorControlCreateUser, phone},
        } = this.props;
        return (

            <Dialog
                onClose={() => handleControl('')} aria-labelledby="customized-dialog-title"
                open={visible}>

                <DialogTitle id="customized-dialog-title">
                    Preguntas frecuentes
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={() => handleControl('')}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent>


                    {errorControlCreateUser && this.handleError()}
                    <Form onSubmit={handleSubmit(this.handleAction)}>

                        <div className="container-sub-text">
                            <h1>PREGUNTAS FRECUENTES</h1>
                            <br/><br/>
                            <p>

                                1. Cómo elegir a qué vendedor comprarle <br/><br/>
                                R: Los usuarios compradores pueden tener en cuenta la calificación de los usuarios
                                vendedores, la calificación la asignan otros usuarios con los que se haya efectuado
                                alguna operación. Entre mayor número de estrellas tenga el usuario, mayor convicción se
                                tiene sobre el tiempo y calificaciones positivas que tiene el usuario en TuConstruccion
                                y por la tanto serán más confiables.
                                <br/><br/>
                                2. Cómo pagar las compras de productos ofrecidos a través de TuConstruccion
                                <br/><br/>
                                R: TuConstruccion no interviene en las transacciones entre nuestros usuarios, por lo
                                cual les recomendamos utilizar pasarelas de pago reconocidas u optar por pagos contra
                                entrega para disminuir el riesgo de fraude.<br/><br/>
                                3. Cómo remitir/recibir el producto que vendí/compré/alquilé a un usuario de
                                TuConstruccion<br/><br/>
                                R: TuConstruccion no interviene en la recepción, envíos y/o distribución de los
                                productos que se publican a través de nuestra plataforma. En consecuencia, sugerimos a
                                los usuarios tanto compradores como vendedores utilizar transportadoras reconocidas y
                                autorizadas, solicitar siempre al usuario vendedor la(s) guía(s) de envío y/o soporte(s)
                                de la compra.<br/><br/>
                                Recordamos a Los Usuarios que es su responsabilidad como usuarios compradores solicitar
                                factura de venta o documento equivalente a los usuarios vendedores cuando concreten y
                                finalicen una negociación.<br/><br/>
                                4. Cómo recibir y/o devolver productos en épocas de pandemia de Covid - 19
                                <br/><br/>
                                R: Cada usuario debe propender por cuidar su salud y la de los demás. Por eso
                                TuConstruccion recomienda a los usuarios seguir los protocolos correspondientes a la
                                emergencia actual causada por la pandemia de COVID-19. Pueden encontrar mayor
                                información sobre las medidas adoptadas por el Ministerio de Salud en el siguiente link:
                                https://www.minsalud.gov.co/salud/publica/PET/Paginas/Documentos-Administrativos-covid-19.aspx

                                5. Cómo puedo resolver un problema con una compra
                                <br/><br/>
                                R: TuConstruccion no interviene en las negociaciones que se realizan entre usuarios. Si
                                un usuario tiene una reclamación con respecto a una compra realizada a otro usuario
                                registrado en nuestra plataforma, puede elegir alguna de las siguientes opciones para
                                tratar de resolver su inconformidad:<br/><br/>
                                a) Contactar directamente al usuario vendedor para llegar a una acuerdo con él.
                                <br/><br/>
                                b) En caso de no llegar a un acuerdo con el usuario vendedor y esto genera algún
                                perjuicio, el usuario afectado puede adelantar las acciones ante las autoridades
                                pertinentes contra el usuario vendedor.<br/><br/>

                                c) Reportar al usuario vendedor en la plataforma a través del siguiente enlace:
                                https://forms.gle/AKgXQdQPXfrDPF7t8<br/><br/>

                                6. Qué puedo hacer si fui víctima de un fraude<br/><br/>

                                R: TuConstruccion no interfiere en ninguna manera en las transacciones que se realizan
                                entre los usuarios. El pago, la forma de entrega y otros detalles, son acordados
                                únicamente entre el usuario vendedor y el usuario comprador.
                                <br/><br/>
                                Para proteger los datos de nuestros usuarios, no podemos brindar información a personas
                                ajenas a las autoridades. Por esto, TuConstruccion cooperará directamente con las
                                autoridades que así lo requieran, cuando se presente una solicitud de información
                                relacionada a cualquier actividad fraudulenta o criminal.<br/><br/>

                                7. Cómo puedo reportar a un usuario o a un anuncio por comportamiento o contenido
                                inapropiado<br/><br/>

                                R: TuConstruccion no garantiza la forma en la que los usuarios se comportan al hacer uso
                                de la plataforma y de cómo se relacionan con otros usuarios fuera de ella.
                                En el evento que un usuario intente mantener conversaciones inapropiadas o utilice
                                lenguaje ofensivo, discriminatorio, y/o realice publicaciones que van en contra de las
                                políticas de la plataforma, puede notificarlo a través de este link:
                                https://forms.gle/AKgXQdQPXfrDPF7t8
                                Si el usuario infractor conoce su número de teléfono, bloquee su WhatsApp y sus
                                llamadas. De ser necesario, puede adelantar las acciones que considere ante las
                                autoridades competentes.<br/><br/>

                                8. Recomendaciones para el uso seguro de TuConstruccion y para minimizar los riesgos de
                                fraude<br/><br/>
                                R: TuConstruccion no controla la veracidad y/o autenticidad de información que publican
                                los usuarios dentro de la plataforma. Los usuarios son quienes determinan las
                                condiciones de las operaciones que realizan entre ellos, ya sean de compraventa o
                                alquiler. Por eso les sugerimos tomar algunas precauciones:
                                <br/><br/>

                                • Se recomienda realizar la transacción para pagos contra entrega o por pasarelas de
                                pago reconocidas.<br/><br/>

                                • Se recomienda corroborar los datos del comprador antes de enviar el producto, ya sea
                                que se encuentre dentro o fuera del país.<br/><br/>

                                • Se sugiere a los usuarios mantener la negociación dentro del chat de TuConstruccion.
                                Por lo que recomendamos evitar otros canales de comunicación como WhatsApp, Skype,
                                Messenger u otras aplicaciones de mensajería instantánea.<br/><br/>

                                • Se recomienda a los usurarios no enviar datos personales relacionados con información
                                bancaria (p. ej.: números de tarjetas débito o crédito, claves virtuales de cuentas
                                bancarias, entre otros), historial médico, o cualquier otra información que pudiera ser
                                utilizada erróneamente.<br/><br/>

                                • Se sugiere a los usuarios que tengan calidad de compradores, verificar la información
                                del usuario vendedor y prestar especial atención si:
                                <br/><br/>
                                • El usuario vendedor solicita información no relevante para la negociación, como por
                                ejemplo historial médico, certificados de ingresos, claves virtuales, direcciones
                                físicas o de correo electrónico de personas ajenas a la negociación.<br/><br/>

                                • El usuario vendedor insinúa que la entrega del producto se realice en un lugar
                                diferente al que colocó en su anuncio.<br/><br/>

                                • El usuario vendedor dice no poder encontrarse en un lugar público e insiste en el
                                envío del producto.<br/><br/>

                                • Al igual que los usuarios compradores, se les recomienda a Los usuarios vendedores
                                verificar la información que reciben del usuario comprador y prestar especial atención
                                en el evento que:<br/><br/>

                                • El usuario comprador insista en realizar el pago del producto a través de plataformas
                                desconocidas o de las que no exista suficiente información en internet.<br/><br/>

                                • El usuario comprador insista en efectuar el pago en moneda extranjera.<br/><br/>

                                • El usuario comprador insista en pagar con cheques. Si los usuarios vendedores aceptan
                                dicho medio de pago, se les sugiere corroborar con la entidad bancaria si el cheque
                                tiene fondos, o que esperen el tiempo necesario para la acreditación del mismo, antes de
                                enviar tu producto.<br/><br/>

                                Si Los Usuarios sospechan que otro usuario registrado es fraudulento, pueden reportarlo
                                en el siguiente link: https://forms.gle/AKgXQdQPXfrDPF7t8<br/><br/>

                                9. Cómo puedo eliminar mi cuenta de TuConstrucción
                                <br/><br/>
                                Si Los Usuarios desean darse de baja de La Plataforma, pueden solicitarlo en el
                                siguiente link: https://forms.gle/AKgXQdQPXfrDPF7t8<br/><br/>
                                Si Los usuarios desean eliminar la cuenta por completo, las publicaciones y toda la
                                información que se haya compartido en La Plataforma también se eliminará.<br/><br/>

                                Si Los Usuarios desean darse de baja sin eliminar la cuenta, el perfil dejará de ser
                                visible para otros usuarios y la información se conservará hasta que el usuario decida
                                reactivar su perfil.
                            </p>
                        </div>



                        <DialogActions>
                            <ButtonFacebook/>
                            <Button aria-label="Close" onClick={() => handleControl('')} variant="contained"
                                    color="secondary">Cerrar</Button>
                        </DialogActions>


                    </Form>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    city: state.city,
    typePeople: state.typePeople,
    typeProfessional: state.typeProfessional,
    gender: state.gender,
    typeProfile: state.typeProfile,
});
const connected = connect(mapStateToProps, {
    citiesGetRequesting,
    getTypeProfileRequesting,
    getTypePeopleRequesting,
    getGenderRequesting,
    getTypeProfessionalRequesting,
    filterGender,
    visibleTypeProfessional,
    createUserRequesting,
    errorControlCreateUser
})(ModalFrequencyComponent);

const formed = reduxForm({
    form: 'frequencyUser',
})(connected);

export default formed;

