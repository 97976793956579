//suscripciones usuario

export const GET_SUBSCRIPTION_USER_REQUESTING = 'GET_SUBSCRIPTION_USER_REQUESTING';
export const GET_SUBSCRIPTION_USER_SUCCESS = 'GET_SUBSCRIPTION_USER_SUCCESS';
export const GET_SUBSCRIPTION_USER_ERROR = 'GET_SUBSCRIPTION_USER_ERROR';

export const CREATE_SUBSCRIPTION_USER_REQUESTING ='CREATE_SUBSCRIPTION_USER_REQUESTING';
export const CREATE_SUBSCRIPTION_USER_SUCCESS ='CREATE_SUBSCRIPTION_USER_SUCCESS';
export const CREATE_SUBSCRIPTION_USER_ERROR ='CREATE_SUBSCRIPTION_USER_ERROR';


