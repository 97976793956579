import React from 'react';
import $ from 'jquery';


import slider1 from "../../assets/img/slider1.png";
import slider2 from "../../assets/img/slider2.png";
import slider3 from "../../assets/img/slider3.png";


export class SliderPage extends React.Component {

    componentDidMount() {

        $('.slide_1r').each(function () {
            var $this = $(this);
            var $group = $this.find('.slide_1_group');
            var $slide_1s = $this.find('.slide_1');
            var bulletArray = [];
            var currentIndex = 0;
            var timeout;

            function move(newIndex) {
                var animateLeft, slide_1Left;

                advance();

                if ($group.is(':animated') || currentIndex === newIndex) {
                    return;
                }

                bulletArray[currentIndex].removeClass('active');
                bulletArray[newIndex].addClass('active');

                if (newIndex > currentIndex) {
                    slide_1Left = '100%';
                    animateLeft = '-100%';
                } else {
                    slide_1Left = '-100%';
                    animateLeft = '100%';
                }

                $slide_1s.eq(newIndex).css({
                    display: 'block',
                    left: slide_1Left
                });
                $group.animate({
                    left: animateLeft
                }, function () {
                    $slide_1s.eq(currentIndex).css({
                        display: 'none'
                    });
                    $slide_1s.eq(newIndex).css({
                        left: 0
                    });
                    $group.css({
                        left: 0
                    });
                    currentIndex = newIndex;
                });
            }

            function advance() {
                clearTimeout(timeout);
                timeout = setTimeout(function () {
                    if (currentIndex < ($slide_1s.length - 1)) {
                        move(currentIndex + 1);
                    } else {
                        move(0);
                    }
                }, 4000);
            }

            $('.next_btn').on('click', function () {
                if (currentIndex < ($slide_1s.length - 1)) {
                    move(currentIndex + 1);
                } else {
                    move(0);
                }
            });

            $('.previous_btn').on('click', function () {
                if (currentIndex !== 0) {
                    move(currentIndex - 1);
                } else {
                    move(2);
                }
            });

            $.each($slide_1s, function (index) {
                var $button = $('<a class="slide_1_btn">&bull;</a>');

                if (index === currentIndex) {
                    $button.addClass('active');
                }
                $button.on('click', function () {
                    move(index);
                }).appendTo('.slide_1_buttons');
                bulletArray.push($button);
            });

            advance();
        });
    }


    render() {
        return (
            <div className="styles-slider-container">
                <div className="slide_1r">
                    <div className="slide_1_viewer">
                        <div className="slide_1_group">

                            <div className="slide_1" style={{
                                backgroundImage: `url('${slider1}')`,
                                backgroundPosition: 'center',
                                backgroundSize: '100%',
                                backgroundRepeat: 'no-repeat'
                            }}>
                                {/*<h1>Este es un texto de ejemplo</h1>*/}
                                {/*<p>Este es un parrafo de prueba</p>*/}
                            </div>

                            <div className="slide_1" style={{
                                backgroundImage: `url('${slider2}')`,
                                backgroundPosition: 'center center',
                                backgroundSize: '100%',
                                backgroundRepeat: 'no-repeat'
                            }}>
                                {/*<h1>Este es un texto de ejemplo</h1>*/}
                                {/*<p>Este es un parrafo de prueba</p>*/}
                            </div>


                            <div className="slide_1" style={{
                                backgroundImage: `url('${slider3}')`,
                                backgroundPosition: 'center center',
                                backgroundSize: '100%',
                                backgroundRepeat: 'no-repeat'
                            }}>
                                {/*<h1>Este es un texto de ejemplo</h1>*/}
                                {/*<p>Este es un parrafo de prueba</p>*/}
                            </div>
                        </div>

                    </div>
                </div>


                <div className="slide_1_buttons">
                </div>

                <div className="directional_nav">
                    <div className="previous_btn" title="Previous">
                        <svg
                            x="0px" y="0px" width="45px" height="45px" viewBox="-11 -11.5 65 66">
                            <g>
                                <g>
                                    <path fill="#ffffff" d="M-10.5,22.118C-10.5,4.132,4.133-10.5,22.118-10.5S54.736,4.132,54.736,22.118
			c0,17.985-14.633,32.618-32.618,32.618S-10.5,40.103-10.5,22.118z M-8.288,22.118c0,16.766,13.639,30.406,30.406,30.406 c16.765,0,30.405-13.641,30.405-30.406c0-16.766-13.641-30.406-30.405-30.406C5.35-8.288-8.288,5.352-8.288,22.118z"/>
                                    <path fill="#ffffff"
                                          d="M25.43,33.243L14.628,22.429c-0.433-0.432-0.433-1.132,0-1.564L25.43,10.051c0.432-0.432,1.132-0.432,1.563,0	c0.431,0.431,0.431,1.132,0,1.564L16.972,21.647l10.021,10.035c0.432,0.433,0.432,1.134,0,1.564	c-0.215,0.218-0.498,0.323-0.78,0.323C25.929,33.569,25.646,33.464,25.43,33.243z"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="next_btn" title="Next">
                        <svg
                            x="0px" y="0px" width="45px" height="45px" viewBox="-11 -11.5 65 66">
                            <g>
                                <g>
                                    <path fill="#ffffff"
                                          d="M22.118,54.736C4.132,54.736-10.5,40.103-10.5,22.118C-10.5,4.132,4.132-10.5,22.118-10.5	c17.985,0,32.618,14.632,32.618,32.618C54.736,40.103,40.103,54.736,22.118,54.736z M22.118-8.288	c-16.765,0-30.406,13.64-30.406,30.406c0,16.766,13.641,30.406,30.406,30.406c16.768,0,30.406-13.641,30.406-30.406 C52.524,5.352,38.885-8.288,22.118-8.288z"/>
                                    <path fill="#ffffff"
                                          d="M18.022,33.569c 0.282,0-0.566-0.105-0.781-0.323c-0.432-0.431-0.432-1.132,0-1.564l10.022-10.035 			L17.241,11.615c 0.431-0.432-0.431-1.133,0-1.564c0.432-0.432,1.132-0.432,1.564,0l10.803,10.814c0.433,0.432,0.433,1.132,0,1.564 L18.805,33.243C18.59,33.464,18.306,33.569,18.022,33.569z"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>


        )
    }
}

//   <div>
//
//                 <section id="slider" className="container">
//                     <ul className="slider-wrapper">
//                         <li className="current-slide">
//                             <img src={slider1} alt=""/>
//
//                             <div className="text-slider">
//                                 <h2>Ejemplo texto 1</h2>
//                                 <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </h3>
//                             </div>
//
//                         </li>
//                         <li>
//                             <img src={slider2} alt=""/>
//                             <div className="text-slider">
//                                 <h2>Ejemplo texto 2</h2>
//                                 <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h3>
//                             </div>
//                         </li>
//                         <li>
//                             <img src={slider3} alt=""/>
//                             <div className="text-slider">
//                                 <h2>Ejemplo texto 3</h2>
//                                 <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h3>
//                             </div>
//
//                         </li>
//                     </ul>
//
//                     <div className="slider-shadow">
//
//                     </div>
//                     <ul id="control-buttons" className="control-buttons">
//
//                     </ul>
//                 </section>
//
//             </div>
