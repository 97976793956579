import {
    FORGOT_PASSWORD_POST_ERROR,
    FORGOT_PASSWORD_POST_REQUESTING,
    FORGOT_PASSWORD_POST_SUCCESS, RESET_PASSWORD_PUT_ERROR, RESET_PASSWORD_PUT_REQUESTING, RESET_PASSWORD_PUT_SUCCESS,
    RESET_STATES,
    VERIFY_TOKEN_RESET_PASSWORD_POST_ERROR,
    VERIFY_TOKEN_RESET_PASSWORD_POST_REQUESTING,
    VERIFY_TOKEN_RESET_PASSWORD_POST_SUCCESS,
    SHOW_MODAL_RESET_PASSWORD, CLOSE_SHOW_MODAL_PASSWORD,
} from "./constants";


const initialState = {
    requestingForgot: false,
    successForgot: false,
    showForget: true,

    requestingVerify: false,
    successVerify: false,
    showVerify: false,

    requestingReset: false,
    successReset: false,
    showReset: false,

    isForgotPassword: false,

    message: '',
    error: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_POST_REQUESTING:
            return {
                ...state,
                requestingForgot: true,
                successForgot: false,
                message: '',
                error: '',
                showForget: true,
            };
        case FORGOT_PASSWORD_POST_SUCCESS:
            return {
                ...state,
                requestingForgot: false,
                successForgot: true,
                error: '',
                showForget: false,
                showVerify: true,
            };
        case FORGOT_PASSWORD_POST_ERROR:
            return {
                ...state,
                requestingForgot: false,
                successForgot: false,
                message: '',
                error: action.error,
                showForget: true,
            };
        case VERIFY_TOKEN_RESET_PASSWORD_POST_REQUESTING:
            return {
                ...state,
                requestingVerify: true,
                successVerify: false,
                message: '',
                error: '',
                showVerify: true,
            };
        case VERIFY_TOKEN_RESET_PASSWORD_POST_SUCCESS:
            return {
                ...state,
                requestingVerify: false,
                successVerify: true,
                message: action.message,
                error: '',
                showVerify: false,
                showReset: true
            };
        case VERIFY_TOKEN_RESET_PASSWORD_POST_ERROR:
            return {
                ...state,
                requestingVerify: false,
                successVerify: false,
                message: '',
                error: action.error,
                showVerify: true,
            };
        case RESET_PASSWORD_PUT_REQUESTING:
            return {
                ...state,
                requestingReset: true,
                successReset: false,
                message: '',
                error: '',
                showReset: true,
            };
        case RESET_PASSWORD_PUT_SUCCESS:
            return {
                ...state,
                requestingReset: false,
                successReset: true,
                message: action.message,
                error: '',
                showReset: false,
                showForget: true,
            };
        case RESET_PASSWORD_PUT_ERROR:
            return {
                ...state,
                requestingReset: false,
                successReset: false,
                message: '',
                error: action.error,
                showReset: true,
            };
        case SHOW_MODAL_RESET_PASSWORD:
            return{
                ...state,
                isForgotPassword:action.modal==='olvido contraseña'
            };
        case RESET_STATES:
            return {
                ...state,
                requestingForgot: false,
                successForgot: false,
                showForget: true,

                requestingVerify: false,
                successVerify: false,
                showVerify: false,

                requestingReset: false,
                successReset: false,
                showReset: false,

                message: '',
                error: '',
            };
        case CLOSE_SHOW_MODAL_PASSWORD:
            return {
                ...state,
                isForgotPassword: false,
            };
        default:
            return state;
    }
};
export default reducer;