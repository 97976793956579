import React from 'react';
import {connect} from "react-redux";
import {loginFacebookRequesting} from "../../redux/login/actions";
import FacebookLogin from 'react-facebook-login';


class ButtonFacebook extends React.Component {

    state = {
        isMobile: false,
    };

    handleRegisterFacebook = (response) => {
        const {loginFacebookRequesting} = this.props;
        loginFacebookRequesting(response);
    };

    render() {
        return (
            <div className="btn-container" style={{display: 'flex', justifyContent: 'center'}}>
                <FacebookLogin
                    appId={'709756802822980'}
                    autoload={false}
                    version={'3.3'}
                    language={'es-LA'}
                    size={'small'}
                    textButton={'Ingresar con Facebook'}
                    fields={'name, email, picture.type(large)'}
                    isMobile={this.state.isMobile}
                    disableMobileRedirect={true}
                    callback={this.handleRegisterFacebook}
                    icon="fa-facebook"
                    cssClass={'button-facebook'}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {
    loginFacebookRequesting,
})(ButtonFacebook);

export default connected;
