import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

//input
import Trofeo from "../../../assets/img/Trofeo.jpg";
import {SubscriptionPaymentModalComponent} from "../../3-profile-page/modals/subscription-payment";
import {connect} from "react-redux";
import {setFocusSubscription} from "../../../redux/subscription/actions";
import {
    paySubscriptionCreditCardRequesting,
    paymentPseRequesting,
    paymentPseError
} from "../../../redux/payment/actions";
import {paySubscriptionCashRequesting} from "../../../redux/payment/actions";
import {showModalPayment, getListBanksRequesting} from "../../../redux/payment/actions";
import {createSubscriptionUserRequesting} from "../../../redux/subscription/user/actions";


class SubscriptionModalComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        const {getListBanksRequesting} = this.props;
        getListBanksRequesting();
    }

    handleSubscriptionCreated = (subscription) => {
        const {client: {token, client}, createSubscriptionUserRequesting} = this.props;
        createSubscriptionUserRequesting(client.id, subscription, 1, token, '', '');
    };


    render() {
        const {
            showModalPayment,
            handleControl,
            paySubscriptionCreditCardRequesting,
            visible,
            payment: {payments, isModalPayment, banks, response, requesting},
            subscription: {subscriptions, subscriptionFocus},
            setFocusSubscription,
            client,
            paySubscriptionCashRequesting,
            paymentPseRequesting,
            paymentPseError
        } = this.props;
        return (

            <Dialog
                onClose={() => handleControl('')} aria-labelledby="customized-dialog-title"
                open={visible}>

                <DialogTitle id="customized-dialog-title">
                    Suscripción
                    <div style={{position: 'absolute', right: 10, top: 10}}>
                        <IconButton aria-label="Close" onClick={() => handleControl('')}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>
                <SubscriptionPaymentModalComponent
                    payments={payments}
                    client={client}
                    subscription={subscriptionFocus}
                    handlePaySubscriptionCardCredit={paySubscriptionCreditCardRequesting}
                    handlePaySubscriptionCash={paySubscriptionCashRequesting}
                    visible={isModalPayment}
                    handleControl={showModalPayment}
                    banks={banks}
                    response={response}
                    clearPSE={paymentPseError}
                    requesting={requesting}
                    handlePayPse={paymentPseRequesting}
                />
                <DialogContent>
                    <div className="container-subscription container-text">
                        {subscriptions.length > 0 && subscriptions.map((subscription, index) => {
                            return (
                                <div className="subscription-item1 ">
                                    <h1>{subscription.nombre}</h1>
                                    <div className="line-gold"/>
                                    <p><i className="icon-money styles-icons"/>{subscription.precio}</p>
                                    <img src={Trofeo} alt="Logo"/>
                                    <br/>
                                    <Typography gutterBottom>
                                        {subscription.descripcion}
                                    </Typography>
                                    {subscription.precio > 0 || subscription.nombre !== 'Free'
                                        ?
                                        <Button onClick={() => {
                                            setFocusSubscription(subscription);
                                            showModalPayment('modal de pagos')
                                        }}
                                                variant="contained" color="secondary">
                                            Comprar
                                        </Button>
                                        :
                                        <Button onClick={() => {
                                            this.handleSubscriptionCreated(subscription.id)
                                        }}
                                                variant="contained" color="secondary">
                                            Suscribirme
                                        </Button>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => handleControl('')}
                            color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    subscription: state.subscription,
    payment: state.payment
});

const connected = connect(mapStateToProps, {
    setFocusSubscription,
    paySubscriptionCreditCardRequesting,
    showModalPayment,
    paySubscriptionCashRequesting,
    createSubscriptionUserRequesting,
    getListBanksRequesting,
    paymentPseRequesting,
    paymentPseError
})(SubscriptionModalComponent);

export default connected;

