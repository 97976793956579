import React from 'react';

import {PageHome} from "./page/page-home";
import {SliderPage} from "../slider/slider-page";
import {FooterComponent} from "../footer/footer";
import {connect} from "react-redux";
import {
    publicationsGetRequesting,
    publicationGetRequesting,
    publicationShowModal,
    publicationHiddenModal,
    publicationsSearchPostRequesting,
    publicationsOwnerUserPostRequesting,
    cleanPublication
} from "../../redux/publication/actions";
import {filterShowModal, filterHiddenModal, filterChangeForm, filterResetStates} from "../../redux/filter/actions";
import {
    categoriesGetRequesting,
    categoryFocus,
    subcategoryFocus,
    categoryResetStates
} from "../../redux/category/actions";
import {modulesGetRequesting} from "../../redux/module/actions";
import {searchChangeForm} from "../../redux/search/actions";
import {createRoomChatRequesting} from "../../redux/chat/actions";
import {actualPage} from "../../redux/iu/actions";
import {showModalUser, setFocusUser} from "../../redux/user/actions";
import {getReviewRequesting} from "../../redux/review/actions";
import {addFavoriteRequesting} from "../../redux/publicationFavorite/actions";



class HomeComponent extends React.Component {

    constructor(props) {
        super(props);
        const {
            filter: {values},
            publication: {publicationsIds, paginate},
            publicationsGetRequesting,
            categoriesGetRequesting,
            modulesGetRequesting,
            actualPage,
        } = this.props;
        publicationsGetRequesting(publicationsIds, values, paginate);
        categoriesGetRequesting();
        modulesGetRequesting();
        actualPage('/');
    }

    getPublication = (publicationId) => {
        const {publicationGetRequesting, client: {token}} = this.props;
        publicationGetRequesting(publicationId, token);
    };

    handleFocus = (object, type = '') => {
        const {
            categoryFocus, subcategoryFocus, filterChangeForm,
        } = this.props;
        switch (type) {
            case 'category':
                categoryFocus(object);
                filterChangeForm('categoria', object.id);
                break;
            case 'subcategory':
                subcategoryFocus(object);
                break;
        }
    };

    showModal = (modal, type = '') => {
        const {publicationShowModal, filterShowModal} = this.props;
        switch (type) {
            case 'publication':
                publicationShowModal(modal);
                break;
            case 'filter':
                filterShowModal(modal);
                break;
            case 'chat':
                console.log(modal);
                break;
            case 'login':
                console.log(modal);
                break;
        }
    };

    hiddenModal = (modal, type = '') => {
        const {publicationHiddenModal, filterHiddenModal} = this.props;
        switch (type) {
            case 'publication':
                publicationHiddenModal(modal);
                break;
            case 'filter':
                filterHiddenModal(modal);
                break;
            case 'chat':
                console.log(modal);
                break;
            case 'login':
                console.log(modal);
                break;
        }
    };

    handleFilterCategory = () => {
        const {
            filter: {values},
            publicationsGetRequesting
        } = this.props;
        publicationsGetRequesting([], values, 1);
    };

    handleResetStates = (type) => {
        const {
            categoryResetStates,
            filterResetStates,
        } = this.props;
        switch (type) {
            case 'category':
                categoryResetStates();
                filterResetStates();
                break;
        }
    };

    handleSearchChangeForm = (key, value) => {
        const {searchChangeForm} = this.props;
        searchChangeForm(key, value);
    };

    handleSearch = () => {
        const {
            publication: {search},
            search: {values},
            filter: {ciudad},
            publicationsSearchPostRequesting,
        } = this.props;
        if (values.search !== search)
            publicationsSearchPostRequesting(values.search, ciudad);
    };

    handleNewChat = (publication) => {
        const {
            client: {token},
            createRoomChatRequesting,
        } = this.props;
        createRoomChatRequesting(token, publication.vendedor.id, publication.id);
    };

    handleAddFavorite = (publication) => {
        const {
            addFavoriteRequesting,
            client : {client, token}
        } = this.props;
        addFavoriteRequesting(token, client.id, publication.id)
    };
    handleGetMorePublications = () => {
        const {
            filter: {values},
            publication: {publicationsIds, hasMore, success},
            publicationsGetRequesting
        } = this.props;
        if (hasMore && success)
            publicationsGetRequesting(publicationsIds, values);
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
    }

    render() {
        const {
            publication: {publications, viewPublication, requesting, publication, success, paginate, ownerPublicationsIds, publicationShowModal},
            category: {categories, category, active, focus},
            filter: {modalFilter},
            client: {token, enabled, client},
            user: {isOpenLogin},
            showModalUser,
            publicationsOwnerUserPostRequesting,
            cleanPublication,
            setFocusUser,
            getReviewRequesting,
        } = this.props;
        if (success)
            this.handleGetMorePublications();
        return (
            <div>
                <SliderPage/>
                <PageHome type={'publication'}
                          active={active}
                          focus={focus}
                          publicationShowModal={publicationShowModal}
                          paginate={paginate}
                          ownerPublicationsIds={ownerPublicationsIds}
                          publicationsOwnerUserPostRequesting={publicationsOwnerUserPostRequesting}
                          cleanPublication={cleanPublication}
                          setFocusUser={setFocusUser}
                          getReviewRequesting={getReviewRequesting}
                          client={client}
                          handleControl={showModalUser}
                          enabled={enabled}
                          userAuth={token}
                          publications={publications}
                          viewPublication={viewPublication}
                          publication={publication}
                          categories={categories}
                          categoryFocus={category}
                          modalFilter={modalFilter}
                          requestingPublication={requesting}
                          getMorePublications={this.handleGetMorePublications}
                          handleNewChat={this.handleNewChat}
                          handleSearchChangeForm={this.handleSearchChangeForm}
                          handleSearch={this.handleSearch}
                          getPublication={this.getPublication}
                          handleFocus={this.handleFocus}
                          handleFilterCategory={this.handleFilterCategory}
                          handleResetStates={this.handleResetStates}
                          showModal={this.showModal}
                          hiddenModal={this.hiddenModal}
                          addFavorite={this.handleAddFavorite}
                />
                <FooterComponent/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    publication: state.publication,
    filter: state.filter,
    category: state.category,
    search: state.search,
    user: state.user
});

const connected = connect(mapStateToProps, {
    actualPage,
    publicationsGetRequesting,
    publicationGetRequesting,
    publicationShowModal,
    publicationHiddenModal,
    publicationsSearchPostRequesting,
    filterShowModal,
    filterHiddenModal,
    filterChangeForm,
    filterResetStates,
    categoriesGetRequesting,
    categoryFocus,
    subcategoryFocus,
    categoryResetStates,
    modulesGetRequesting,
    searchChangeForm,
    createRoomChatRequesting,
    showModalUser,
    publicationsOwnerUserPostRequesting,
    cleanPublication,
    setFocusUser,
    getReviewRequesting,
    addFavoriteRequesting
})(HomeComponent);

export default connected;